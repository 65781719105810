//import store from "@/store/store";

import {tryCatch} from "@/components/shared/util";
import invoiceService from "@/services/invoice.service";

const COMMON_ROLE_SUPER_ADMIN = "SUPER_ADMINISTRATOR"
const COMMON_ROLE_ADMIN = "ADMINISTRATOR"
const COMMON_ROLE_ACCOUNTANT = "ACCOUNTANT"
const COMMON_ROLE_MANAGER = "MANAGER"

const COMMON_ROLE_TITANSUPERADMIN = "TITAN_SUPER_ADMINISTRATOR"
const COMMON_ROLE_TITANADMIN = "TITAN_ADMINISTRATOR"
const COMMON_ROLE_TITANACCOUNTANT = "TITAN_ACCOUNTANT"
const COMMON_ROLE_TITANCOMMERCIAL = "TITAN_COMMERCIAL"
const COMMON_ROLE_TITANMANAGER = "TITAN_MANAGER"

const companyRoles = {
  TITAN_SUPER_ADMINISTRATOR: 90,
  TITAN_ADMINISTRATOR: 80,
  TITAN_MANAGER: 70,
  TITAN_ACCOUNTANT: 60,
  TITAN_COMMERCIAL: 50,
  SUPER_ADMINISTRATOR: 40,
  ADMINISTRATOR: 30,
  MANAGER: 20,
  ACCOUNTANT: 10,
}
export function getCompanyUser(user, company_oid) {
  if (!user.companies || user.companies.length === 0) return {};
  for (let i = 0; i < user.companies.length; i++) {
    if (user.companies[i].oid === company_oid) {
      return user.companies[i]
    }
  }
  return {};
}

// Helpers to extract user's role infos
export function getUserRoleOID(user, company_oid) {
  let compUser = getCompanyUser(user, company_oid)
  if (compUser.role_oid !== "")
    return compUser.role_oid;
  return compUser.role;
}

export function getUserRoleName(user, company_oid) {
  let compUser = getCompanyUser(user, company_oid)
  if (compUser.oid === company_oid)
    return compUser.role;
  return "";
}

export function getUserRolePosition(user, company_oid) {
  let compUser = getCompanyUser(user, company_oid)
  if (compUser.oid === company_oid)
      return compUser.position;
  return "";
}

export function getUserDefaultFullCompany(user) {
  // console.log(user.companies_full)
  if (user.companies_full && user.companies_full.length){
    // console.log(user.companies_full.find(company => company.oid === user.company_oid))
    return user.companies_full.find(company => company.oid === user.company_oid)
  }
  return {}
}

export function UpdatePMDefaultInTemporaryCompany(user, pm_oid) {
  // console.log(user.companies_full)
  if (user.companies_full && user.companies_full.length){
    // console.log(user.companies_full.find(company => company.oid === user.company_oid))
    user.companies_full.map((company) => {
      
    if( company.oid === user.company_oid)
      company.default_payment_method = pm_oid
    return company
    })
  }
}

export function getUserTitanCompany(user) {
  if (!user.company_oid)
    return null
  if (user.company_oid !== "5e5ea38138fc39b913d4d4e5")
    return null;
  return user.companies.find(company => company.oid === "5e5ea38138fc39b913d4d4e5")
}

export function getUserDefaultCompany(user) {
  if (!user.companies)
    return null
  return user.companies.find(company => company.oid === user.company_oid)
}

export function isDefaultCompanyRole(user, role) {
  if (user === null)
    return false

  let company = getUserDefaultCompany(user)
  if (company === undefined || company === null) {
    return false
  }
  return company.role === role
}

export function isTitanRole(user, role) {
  if (user === null)
    return false

  let company = getUserTitanCompany(user)
  if (company === undefined || company === null) {
    return false
  }
  return company.role === role
}

export function isDefaultCompanyAdmin(user) {
  return isDefaultCompanyRole(user, COMMON_ROLE_SUPER_ADMIN) || isDefaultCompanyRole(user, COMMON_ROLE_ADMIN) || isTitanAdmin(user)
}

export function isDefaultCompanyCommercial(user) {
  return isDefaultCompanyRole(user, COMMON_ROLE_TITANCOMMERCIAL) || isTitanSuperAdmin(user)
}

export function isDefaultCompanyAccountant(user) {
  return isDefaultCompanyRole(user, COMMON_ROLE_ACCOUNTANT) || isTitanAccountant(user)
}

export function isDefaultCompanyManager(user) {
  return isDefaultCompanyRole(user, COMMON_ROLE_MANAGER) || isTitanManager(user) || isDefaultCompanyAdmin(user) 
}

export function isTitanSuperAdmin(user) {
  return isTitanRole(user, COMMON_ROLE_TITANSUPERADMIN)
}

export function isTitanAdmin(user) {
  return isTitanRole(user, COMMON_ROLE_TITANADMIN) || isTitanSuperAdmin(user)
}

export function isTitanAccountant(user) {
  return isTitanRole(user, COMMON_ROLE_TITANACCOUNTANT) || isTitanSuperAdmin(user)
}

export function isTitanManager(user) {
  return isTitanRole(user, COMMON_ROLE_TITANMANAGER) || isTitanAdmin(user)
}

export function isTitanCommercial(user) {
  return isTitanRole(user, COMMON_ROLE_TITANCOMMERCIAL) || isTitanSuperAdmin(user)
}

export function isTitanUser(user) {
  return isTitanCommercial(user) || isTitanManager(user) || isTitanAccountant(user)
}

export function getCommonRolesTitan() {
  return [commonRole(COMMON_ROLE_ADMIN), commonRole(COMMON_ROLE_ACCOUNTANT), commonRole(COMMON_ROLE_MANAGER),
    commonRole(COMMON_ROLE_TITANADMIN),
    commonRole(COMMON_ROLE_TITANSUPERADMIN), commonRole(COMMON_ROLE_TITANACCOUNTANT),
    commonRole(COMMON_ROLE_TITANCOMMERCIAL), commonRole(COMMON_ROLE_TITANMANAGER)]
}
export function getCommonRoles() {
  return [commonRole(COMMON_ROLE_ADMIN), commonRole(COMMON_ROLE_ACCOUNTANT), commonRole(COMMON_ROLE_MANAGER)]
}
export function isHigherRole(user, userToCompare, companyOid) {
  let a = companyRoles[getUserRoleName(user, companyOid)] || 0;
  let b = companyRoles[getUserRoleName(userToCompare, companyOid)] || 0;
  //console.log (getUserRoleName(user, companyOid),a,getUserRoleName(userToCompare, companyOid),b, a >= b)
  return a >= b;
}
export function roleLevel(user,companyOid) {
  return companyRoles[getUserRoleName(user, companyOid)];
}

function commonRole(role) {
  return {oid: role, name: role}
}

export function getRoleNameForList(role) {
  let name = role;
  if (this.$te("user.roles." + role)) {
    name = this.$t("user.roles." + role);
  }
  return name;
}

export async function getCompanyProjects(companyOid) {
  let projectList = [];
  if (companyOid) {
    const result = await tryCatch(() =>
      invoiceService.listRecurring(companyOid)
    );
    projectList = result.response;
  }
  return projectList
}


//TODO: Calculateur des roles comme sur back.
