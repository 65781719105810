import version from '../public/version.json';

export var common = {
  avatarChanged: false,
  multi_graph: false,
  migrationDate: new Date(2023, 2, 29, 18,47,0).getTime(),
  api: {
    url: process.env.VUE_APP_API_URL,
    site_specific_url: process.env.VUE_APP_API_URL,
  },
}
export const settings = {
  version,
  supportEmails: ["tryme@titandc.io", "support@titandc.net"],
  showCaptcha: (process.env.VUE_APP_CAPTCHA ?? 'False') === 'True' ,
  showTour: (process.env.VUE_APP_TOUR ?? 'False') === 'True' ,
  stripeOID:"61c3026431f9a2a277e026d2",
  api: {
    url: process.env.VUE_APP_API_URL,
    recaptcha_public_key: process.env.VUE_APP_RECAPTCHA_KEY,
  },
  webapp: {
    url: "https://sc.titandc.net"
  },
  stripePublishableKey: process.env.VUE_APP_STRIPE_KEY,
  langs: [
    { code: 'fr', name: 'Français' },
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'de', name: 'Deutsche' },
    { code: 'it', name: 'Italiano' },
  ],
  reverseRE: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/,
  sshValidRE: /^(ssh-rsa AAAAB3NzaC1yc2|ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNT|ecdsa-sha2-nistp384 AAAAE2VjZHNhLXNoYTItbmlzdHAzODQAAAAIbmlzdHAzOD|ecdsa-sha2-nistp521 AAAAE2VjZHNhLXNoYTItbmlzdHA1MjEAAAAIbmlzdHA1Mj|ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|ssh-dss AAAAB3NzaC1kc3)[0-9A-Za-z+/]+[=]{0,3}( .*)?$/,
  passwordValidRE: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
  refresh: {
    servers: true,
    server: true,
    network: true,
    every: process.env.VUE_APP_REFRESH || (10  * 1000),
    quick: (5  * 1000),
  },
  pricingFactor: 1000,
  quantity_max: "100",
  defaultGaugeSize: {
    'bar': {
      width: 140,
      height: 70,
      minW: 12,
      minH: 4,
      preserveRatio: true,
    },
    'arc': {
      width: 100,
      height: 115,
      minW: 10,
      minH: 5,
      preserveRatio: true,
    },
    'circle': {
      width: 150,
      height: 200,
      minW: 10,
      minH: 6,
      preserveRatio: true,
    },
    'stroke': {
      width: 150,
      height: 200,
      minW: 10,
      minH: 5,
      preserveRatio: true,
    },
  },
  allowedContentType: ["application/pdf", "image/jpeg", "image/png", "image/x-ms-bmp"],
  allowedExtension: ".pdf, .jpeg, .jpg, .png, .bmp",
  checkContentType: false,
  defaultItemsPerPage: 10,
  paramTypeSingle: "single",
  paramTypeList: "list",
  apiTools: ["ping", "nmap", "traceroute", "abuse"],
  paymentState: ["waiting", "requires_action", "processing", "succeeded", "error", "canceled"],
  frequencies: ["MONTHLY", "QUARTERLY", "BIANNUALLY", "ANNUALLY"],
  subscription_type: [0, 1, 2, 3, 4, 5],
  subscriptionStatus: ["ongoing", "pending", "canceled"],
  type_of_subscription: {
    standard: 0,
    outsourcing: 1,
    drp: 2,
    ip: 3,
    storage: 4,
    credit: 5,
  },
  remainingAmountThreshold: 1,
}

export const max_addons = {
  'SC1': {
    cpu:6,
    ram:8,
    disk: 2000,
    plan:"SC1"
  },
  'SC2': {
    cpu:12,
    ram: 32,
    disk:2500,
    plan:"SC2"
  },
  'SC3': {
    cpu: 32,
    ram: 256,
    disk: 7000,
    plan:"SC3",
  }
}

setDrpUrlFromLocalStorage()
function setDrpUrlFromLocalStorage() {
  common.api.site_specific_url = process.env.VUE_APP_API_URL;
  const drpUrl = localStorage.getItem("DrpUrl");
  if (drpUrl) {
    common.api.site_specific_url = drpUrl;
    // console.log('url', common.api.url)
  }
}
