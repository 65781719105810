import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/login/Login.vue'
import Login2fa from '../views/login/Login2fa.vue'
import LegalNoticeFR from "../views/LegalNoticeFR";
import LegalNoticeEN from "../views/LegalNoticeEN";
import PrivacyPolicyFR from "../views/PrivacyPolicyFR";
import PrivacyPolicyEN from "../views/PrivacyPolicyEN";
import UnAuthorized from "../views/UnAuthorized.vue";
import authService from "../services/auth.service";
import store from "../store/store";
import {
  // getUserRoleName,
  // isDefaultCompanyAccountant,
  // //isDefaultCompanyAdmin,
  // isDefaultCompanyManager,
  // isTitanAccountant,
  // isTitanAdmin,
  roleLevel,
  //isTitanSuperAdmin
} from "@/services/utils/company";

const AdminTemplates = () => import(/* webpackChunkName: "AdminTemplates" */ '../views/adminTemplates/AdminTemplates.vue')
const AdminCompta = () => import(/* webpackChunkName: "AdminCompta" */ '../views/adminCompta/AdminCompta.vue')
const AdminGlobal = () => import(/* webpackChunkName: "AdminGlobal" */ '../views/adminServers/AdminGlobal.vue')
const AdminContacts = () => import(/* webpackChunkName: "AdminContacts" */ '../components/admin/company/AdminContacts.vue')
const AdminCompanie = () => import(/* webpackChunkName: "AdminCompanie" */ '../views/adminCompanies/AdminCompanie.vue')
const AdminTools = () => import(/* webpackChunkName: "AdminTools" */ '../components/admin/company/AdminTools.vue')
const Affiliations = () => import(/* webpackChunkName: "Affiliations" */ '../views/affiliations/Affiliations.vue')
const AffiliationsLinks = () => import(/* webpackChunkName: "Affiliations_links" */ '../views/affiliations/AffiliationsLinks.vue')
const Company = () => import(/* webpackChunkName: "Company" */ '../views/company/Company.vue')
const Invoices = () => import(/* webpackChunkName: "Invoices" */ '../components/all/company/Invoices.vue')
const InvoicesRecurring = () => import(/* webpackChunkName: "InvoicesRecurring" */ '../components/all/company/InvoicesRecurring.vue')
const SubscriptionDetails = () => import(/* webpackChunkName: "SubscriptionDetails" */ '../views/adminCompta/AdminSubscriptionTab.vue')
const Account = () => import(/* webpackChunkName: "Account" */ '../views/account/Account.vue')
const ServerReset = () => import(/* webpackChunkName: "ServerReset" */ '../views/cart/ServerReset.vue')
const ServerDetail = () => import(/* webpackChunkName: "ServerDetailView" */ '../views/servers/ServerDetail.vue')
const CaddieFinal = () => import(/* webpackChunkName: "CaddieFinal" */ '../views/cart/CaddieFinal.vue')
const Caddie = () => import(/* webpackChunkName: "Caddie" */ '../views/cart/Caddie.vue')
const Servers = () => import(/* webpackChunkName: "ServersView" */ '../views/servers/Servers.vue')
const Tickets = () => import(/* webpackChunkName: "Tickets" */ '../views/tickets/Tickets.vue')
const TicketsDetails = () => import(/* webpackChunkName: "TicketsDetails" */ '../views/tickets/TicketsDetails.vue')
const TicketsCreation = () => import(/* webpackChunkName: "TicketsCreation" */ '../views/tickets/TicketsCreation.vue')
const Register = () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
const Network = () => import(/* webpackChunkName: "Network" */ '../views/networks/Network.vue')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/Dashboard.vue')
const Faq = () => import(/* webpackChunkName: "Faq" */ '../views/Faq.vue')
const CGV = () => import(/* webpackChunkName: "CGV" */ '../views/CGV.vue')
const LoginPassword = () => import(/* webpackChunkName: "LoginPassword" */ '../views/login/LoginPassword.vue')
const LoginEmail = () => import(/* webpackChunkName: "LoginEmail" */ '../views/login/LoginEmail.vue')

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      layout: 'simple'
    }
  },
  {
    path: '/2fa',
    name: 'login-2fa',
    component: Login2fa,
    meta: {
      public: true,
      layout: 'simple'
    }
  },
  {
    path: '/admin-templates',
    name: 'admin-templates',
    component: AdminTemplates
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      public: true,
      layout: 'simple'
    }
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    meta: {
      public: true,
      layout: 'simple'
    },
    component: LoginEmail
  },
  {
    path: '/email-validation',
    name: 'email-validation',
    meta: {
      public: true,
      layout: 'simple'
    },
    component: LoginEmail
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      public: true,
      layout: 'simple'
    },
    component: LoginPassword
  },
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      level: [90,80,70,50,40,30,20]
    }
  },
  {
    path: '/cgv-sign',
    name: 'cgv',
    meta: {
      public: true,
      layout: 'simple'
    },
    component: CGV
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      level: [90,80,70,50,40,30,20]
    }
  },
  {
    path: '/network',
    name: 'network',
    component: Network,
    meta: {
      level: [90,80,70,40,30,20]
    }
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    meta: {
      level: [90,80,40,30,10]
    }
  },
  {
    path: '/companies/:company_id/invite',
    name: 'company-invite',
    component: Register,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/tickets/new',
    name: 'tickets-creation',
    component: TicketsCreation,
    props: {
      admin: false
    },
    meta: {
      level: [10,20,30,40,50,60,70,80,90]
    }
  },
  {
    path: '/tickets/:ticket_oid',
    name: 'tickets-detail',
    component: TicketsDetails,
    meta: {
      level: [10,20,30,40,50,60,70,80,90]
    }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: Tickets,
    meta: {
      level: [10,20,30,40,50,60,70,80,90]
    }
  },
  {
    path: '/servers',
    name: 'servers',
    component: Servers,
    meta: {
      level: [90,80,70,40,30,20]
    }
  },
  {
    path: '/servers/:server_oid',
    name: 'server-detail',
    component: ServerDetail,
    meta: {
      level: [90,80,70,40,30,20]
    }
  },
  {
    path: '/orders/new',
    name: 'orders-creation',
    component: Caddie,
    meta: {
      level: [90,80,40,30]
    }
  },
  {
    path: '/orders/cart',
    name: 'orders-cart',
    component: CaddieFinal,
    meta: {
      level: [90,80,40,30]
    }
  },
 
  {
    path: '/servers/:server_oid/reset',
    name: 'server-reset',
    component: ServerReset,
    meta: {
      level: [90,80,70,40,30,20]
    }
  },
  {
    path: '/account/preferences',
    name: 'account',
    component: Account,
    meta: {
      level: [10,20,30,40,50,60,70,80,90]
    }
  },
  // Admin
  {
    path: '/admin/companies',
    name: 'admin-companies',
    component: AdminCompanie,
    meta: {
      level: [90]
    }
  },
  {
    path: '/admin/contacts',
    name: 'admin-contacts',
    component: AdminContacts,
    meta: {
      level: [90]
    }
  },
  {
    path: '/admin/super-admin',
    name: 'super-admin',
    component: AdminGlobal,
    meta: {
      level: [90,80,70]
    }
  },
  {
    path: '/admin/compta',
    name: 'admin-compta',
    component: AdminCompta,
    meta: {
      level: [90,60,50]
    }
  },
  {
    path: '/admin/tools',
    name: 'admin-tools',
    component: AdminTools,
    meta: {
      level: [90,60,50]
    }
  },
  {
    path: '/admin/tickets/new',
    name: 'admin-ticket-creation',
    component: TicketsCreation,
    props: {
      admin: true
    },
    meta: {
      level: [90,80,70,60,50]
    }
  },
  {
    path: '/affiliations',
    name: 'affiliations',
    component: Affiliations,
    meta: {
      level: [90,80,70,60,50]
    }
  },
  {
    path: '/affiliations/links',
    name: 'affiliations-links',
    component: AffiliationsLinks,
    meta: {
      level: [90,80,70,60,50]
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Invoices,
    meta: {
      level: [90,60,50,40]
    }
  },
  {
    path: '/invoices/:invoiceId',
    name: 'invoices-details',
    component: Invoices,
    meta: {
      level: [90,60,50,40]
    }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: InvoicesRecurring,
    meta: {
      level: [90,60,50,40]
    }
  },
  {
    path: '/subscription/:subscription_oid',
    name: 'subscription-details',
    component: SubscriptionDetails,
    meta: {
      level: [90,60,50,40]
    }
  },
  {
    path: '/legal-notice/fr',
    component: LegalNoticeFR,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/legal-notice/en',
    component: LegalNoticeEN,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/privacy-policy/fr',
    component: PrivacyPolicyFR,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/privacy-policy/en',
    component: PrivacyPolicyEN,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/unauthorized',
    component: UnAuthorized,
    meta: {
      public: true,
      layout: 'simple'
    },
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter: (to, from, next) => {
      next('/')
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes:routes,
});

export function scrollMeTo(refName) {
  document.getElementById(refName).scrollIntoView({behavior:'smooth'});
}

router.afterEach(() => {
   
  // play("rocketchat-widget","rocketchat-widget-animation")
})

// navigation guards
router.beforeEach(async (to, from, next) => {
  //console.log('to', to, 'from', from, 'next', next)
  //console.log(authService.getAuthenticated())

  if (!to.meta.public) {
    //console.log('Meta not public route')
    if (!authService.getAuthenticated()) {
      // console.log('not logged ')
      return next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      });
    }
  }
  // if (to.path === from.path) {
  //   return
  // }if (to.path === from.path) {
  //   return
  // }

  if (store.state.auth.user) {
    store.dispatch('app/closeSidebar')
  }

  let page = null;
  // let isAdmin = store.state.auth.user && (isTitanAccountant(store.state.auth.user) || isTitanAdmin(store.state.auth.user))
  // if (["super-admin", "admin-companies", "admin-compta", "admin-ticket-creation"].includes(to.name) && !isAdmin) {
  //   page = '/'
  // }
  if (store.state.auth.user) {
    let role = roleLevel(store.state.auth.user, store.state.auth.user.company_oid);
    let default_page = '/';
    switch (role) {
      case undefined:
        default_page = '/unauthorized';
        break
      // ACCOUNTANT
      case 10:
        default_page = '/company';
        break;
      // MANAGER
      case 20:
        break;
      // ADMINISTRATOR
      case 30:
        break;
      // SUPER_ADMINISTRATOR
      case 40:
        break;
      // TITAN_COMMERCIAL
      case 50:
        break;
      // TITAN_ACCOUNTANT
      case 60:
        default_page = '/admin/compta';
        break;
      // TITAN_MANAGER
      case 70:
        break;
      // TITAN_ADMINISTRATOR
      case 80:
        break;
      // TITAN_SUPER_ADMINISTRATOR
      case 90:
        break;
    }
    if (to.meta) {
      if (to.meta.level && !to.meta.level.includes(role)) {
        //console.log("unauthorized")
        page = default_page;
      }
    }
    //console.log("role", role,"page", page)
  }
  
  // if (to.path.toLowerCase().indexOf("orders")>0) {
  //   if (to.path.toLowerCase().indexOf("cart")>0) {
  //     if (!isDefaultCompanyManager(store.state.auth.user) && !isDefaultCompanyAccountant(store.state.auth.user)) {
  //       page = '/'
  //     }
  //   } else if (!isDefaultCompanyManager(store.state.auth.user)) {
  //     page = '/'
  //   }
  // }
  //
  // if (to.path.toLowerCase().indexOf("servers")>0 && !isDefaultCompanyManager(store.state.auth.user)) {
  //   page = '/'
  // }

  if (to.meta.public === true) {
    return next(page);
  } else if (authService.need2FACode()) {
    return next({
      path: '/2fa', query: {
        redirect: to.fullPath
      }
    });
  }
  
  /*if (store.state.auth.user.default_company.role.manager &&
    (
      to.name.startsWith('invoices') ||
      to.name.startsWith('subscriptions') ||
      to.name === 'company' ||
      to.name === 'orders-cart' ||
      to.name === 'orders-creation' ||
      to.name === 'affiliations'
    )
  ) {
    next('/')
  }*/

  /*if (store.state.auth.user.default_company.role.accountant &&
    (
      to.name.startsWith("network") ||
      to.name.startsWith("server") ||
      to.name === 'dashboard' ||
      to.name === 'orders-cart' ||
      to.name === 'orders-creation'
    )
  ) {
     next('/invoices')
  }*/
  next(page);
});

export default router
