<template>
  <div
    class="container-fluid ticket-container"
    :class="isAdminMode ? 'admin' : ''"
  >
    <h1 class="mt-3">{{ $t("pages.tickets.title-new") }}</h1>

    <Alert
      :delay="5000"
      :show="message_success"
      success
      @closed="message_success = null"
    >
      {{ message_success }}
    </Alert>
    <Alert
      :delay="5000"
      :show="message_error"
      danger
      @closed="message_error = null"
    >
      {{ message_error }}
    </Alert>

    <form>
      <div
        v-if="isAdminMode"
        class="mb-3 ticket-modal-input"
      >
        <label class="ticketInput"><h3>{{ $t("pages.tickets.company") }}</h3></label>
        <company-searching-filter
          id="clientSelector"
          ref="companyFilter"
          :custom-class="formErrors['company'] ? 'is-invalid' : ''"
          @selected="selectedCompany"
          @input="formErrors['company'] = false"
        />
      </div>

      <div class="mb-3 ticket-modal-input">
        <label class="ticketInput"><h3>{{ $t("pages.tickets.object") }}</h3></label>
        <input
          id="input-text-tickets-new-obj"
          v-model.trim="ticket.object"
          autocomplete="off"
          :class="{ 'is-invalid': formErrors['object'] }"
          :placeholder="$t('pages.tickets.object-placeholder')"
          class="form-control form-required"
          maxlength="64"
          minlength="8"
          type="text"
          @input="formErrors['object'] = false"
        >
      </div>

      <div class="mb-3 ticket-modal-input">
        <label class="ticketInput"><h3>{{ $t("pages.tickets.service") }}</h3></label>
        <b-form-select
          id="select-tickets-new-cat"
          v-model="ticket.category"
          class="form-control"
          :disabled="!ticket.object"
        >
          <b-form-select-option
            v-for="category in categories"
            :key="category"
            :value="category"
          >
            {{ $t("pages.tickets.categories." + category) }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="mb-3 ticket-modal-input">
        <label class="ticketInput"><h3>{{ $t("pages.tickets.level") }}</h3></label>
        <b-form-select
          id="select-tickets-new-level"
          v-model="ticket.level"
          class="form-control"
          :disabled="!ticket.object"
        >
          <b-form-select-option
            v-for="level in levels"
            :key="level"
            :value="level"
          >
            {{ $t("pages.tickets.levels." + level) }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div
        v-if="!$route.query.server_oid && !serverOid"
        class="mb-3 ticket-modal-input"
      >
        <label class="ticketInput"><h3>{{ $t("pages.tickets.server") }}</h3></label>
        <!--<select v-model="ticket.server_uuid" class="custom-select custom-select-lg w-75">
          <option value="">— {{ $t('pages.tickets.server') }} —</option>
          <option v-for="server in servers" :key="server.oid" :value="server.oid">{{server.name}}</option>
        </select>-->
        <div
          class="w-100"
        >
          <select-multi-servers
            id="custom-serverDataList-tickets-new-server-selection"
            :key="ticket.company"
            :selected-company="
              ticket.company && isAdminMode ? ticket.company : null
            "
            v-model="servers"
            :disabled="!ticket.object"
          />
        </div>
      </div>
      <div
        v-else
        class="mb-3 ticket-modal-input"
      >
        <label class="ticketInput"><h3>{{ $t("pages.tickets.server") }}</h3></label>
        <h2>{{ serverName }}</h2>
      </div>

      <div class="mb-3 ticket-modal-input">
        <div class="d-flex align-items-center ticketInput">
          <h3 style="padding-top: 6px">{{ $t("pages.tickets.message") }}</h3>
          <button
            id="button-attach-ticket"
            v-b-popover.hover.html="$t('tickets.attachments.sent.popup')"
            class="btn btn-circle ms-3"
            :disabled="!ticket.object"
            @click.prevent="showUploadFiles()"
          >
            <i class="fas fa-paperclip"/>
          </button>
        </div>
        <b-form-textarea
          id="input-textarea-tickes-new-content"
          v-model.trim="ticket.content"
          :class="{ 'is-invalid': formErrors['content'] }"
          :placeholder="$t('pages.tickets.message-placeholder')"
          class="form-control form-required"
          minlength="3"
          rows="10"
          :disabled="!ticket.object"
          @input="formErrors['content'] = false"
        />
      </div>

      <div
        v-if="showButtons"
        class="d-flex justify-content-between mb-3"
      >
<!--        <router-link-->
<!--          id="link-cancel-ticket"-->
<!--          :to="{ name: 'tickets' }"-->
<!--          class="btn-cancel"-->
<!--        >-->
<!--          {{ $t("actions.cancel") }}-->
<!--        </router-link>-->

        <button
          id="btn-submit-tickets-new"
          class="btn-cancel"
          @click.prevent="cancelTicketCreation()"
        >
          {{ $t("modal.cancel") }}
        </button>
        <button
          id="btn-submit-tickets-new"
          class="btn-validate"
          :disabled="!ticket.object"
          @click.prevent="submit()"
        >
          {{ $t("modal.confirm") }}
        </button>
      </div>
    </form>
    <UploadFiles
      ref="uploadFiles"
      @close="closeUploadFiles"
      @upload="upload($event)"
    />
    <DialogConfirm
      id="ticket-creation-modal"
      ref="ticketCreationModal"
      :button="$t('modal.dialog-ticket-creation.button')"
      :close="resetTicketCreation"
      :confirm="confirmTicketCreation"
      :title="$t('modal.dialog-ticket-creation.title')"
      :success="$t('modal.dialog-ticket-creation.success')"
    />
  </div>
</template>

<script>
import ticketService from "../../services/ticket.service";
import serverService from "../../services/server.service";
import { mapState } from "vuex";
import Alert from "../../components/shared/Alert";
//import serverDataList from "../../components/all/servers/ServerDataList";
import {  tryCatch } from "@/components/shared/util";
import CompanySearchingFilter from "@/components/shared/CompanySearchingFilter";
import {BFormSelect, BFormSelectOption, BFormTextarea, vBPopover} from "bootstrap-vue-3";
import {removeChatGPTOnlyFirstLine, textAreaFormatter} from "@/services/utils/text";
import DialogConfirm from "@/components/shared/DialogConfirm";
import SelectMultiServers from "@/components/shared/SelectMultiServers";
import UploadFiles from "@/components/shared/UploadFiles.vue";
import { fileUpload } from "@/services/utils/ticket";

export default {
  directives: {
    "b-popover": vBPopover
  },
  components: {
    UploadFiles,
    SelectMultiServers,
    BFormTextarea,
    BFormSelectOption,
    BFormSelect,
    CompanySearchingFilter,
    Alert,
    //serverDataList,
    DialogConfirm,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    returnToTickets: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    serverOid: {
      type: String,
      default: null,
    },
  },
  emits: ["cancel", "confirm"],
  data() {
    return {
      isAdminMode: this.admin,
      message_success: null,
      message_error: null,
      ticket: {
        company: null,
        category: "technical",
        content: "",
        level: "low",
        object: "",
        servers_oid: [],
        attachments: [],
      },
      formErrors: {},
      servers: [],
      categories: ["technical", "billing", "sales"],
      levels: ["low", "medium", "high"],
      serverName: "",
      attachments: [],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  async mounted() {
    if (!this.admin) {
      if (this.$route.query.server_oid) {
        await this.setServerName(this.$route.query.server_oid)
      }
      if (this.serverOid) {
        await this.setServerName(this.serverOid)
      }
    } else {
      if (this.user.signature && !this.ticket.content) {
        this.ticket.content = "\n\n" + this.user.signature.replace(/\\n/g, "\n");
      }
    }
  },

  methods: {
    textAreaFormatter,
    selectedCompany(arg) {
      if (arg) this.ticket.company = arg.oid;
      else this.ticket.company = null;
    },
    validForm() {
      this.formErrors = {};

      if (!this.ticket.object) {
        this.formErrors["object"] = true;
      }

      if (this.admin && !this.ticket.company) {
        this.formErrors["company"] = true;
      }

      if (!this.ticket.content) {
        this.formErrors["content"] = true;
      }

      return !Object.keys(this.formErrors).length;
    },
    async confirmTicketCreation() {
      // Ferme le modal de création de ticket.
      this.$refs.ticketCreationModal.hide();

      // Vérifie si le formulaire est valide.
      if (!this.validForm()) return;

      // Ajoute les identifiants des serveurs au ticket si des serveurs sont sélectionnés.
      this.ticket.servers_oid = this.servers.map(server => server.oid);
      if (this.serverOid) this.ticket.servers_oid.push(this.serverOid)

      // Crée le ticket en fonction du rôle de l'utilisateur.
      const res = await tryCatch(() =>
        this.admin ? ticketService.createForAClient(this.ticket) : ticketService.create(this.ticket)
      );

      // Met à jour le message d'erreur et émet un événement de confirmation.
      this.message_error = res.errorMsg;
      this.$emit("confirm");

      // Redirige vers la page des tickets si la création est réussie.
      if (res.isSuccess) {
        this.$router.push({ path: "/tickets", query: { new: true } });
      }
    },
    async getServerName(oid) {
      let res = await tryCatch(() => serverService.get(oid));
      return res.response.name;
    },
    resetTicketCreation() {
      this.$refs.ticketCreationModal.hide();
      //this.$router.push({ path: "/tickets" });
    },
    submit() {
      this.ticket.content = removeChatGPTOnlyFirstLine(this.ticket.content);
      this.ticket.content = textAreaFormatter(this.ticket.content);
      this.$refs.ticketCreationModal.show();
    },
    showUploadFiles() {
      this.$refs.uploadFiles.show();
    },
    closeUploadFiles(fileList) {
      this.upload(fileList);
    },
    upload(fileList) {
      this.ticket.attachments = fileUpload(fileList)
      // console.log("ticket attachments", this.ticket.attachments)
      this.$refs.uploadFiles.hide();
    },
    cancelTicketCreation() {
      this.$emit("cancel");
      if (this.returnToTickets) this.$router.push({ path: "/tickets" });
    },
    async setServerName(oid) {
      this.ticket.category = "technical";
      this.servers.oid = [oid];
      this.serverName = await this.getServerName(this.servers.oid);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/vendors/bootstrap.vars'

select
  color: black
</style>
