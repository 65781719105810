import {common} from '@/settings'
import axios from "axios";
import {tryCatch} from "@/components/shared/util";
import {getSearchParams} from "@/services/utils/searchBuilder";
import {isEqual} from "lodash-es";

const API_URL = common.api.url
const API_URL_COMPUTE = common.api.url + "/compute"
const API_LICENSES = "/license"
const API_ITEMS = "/item"
const API_ITEMS_PRICE = "/price"
const API_PRICINGS = "/pricing"

class ItemService {
  newPricing(data) {
    return axios.post(API_URL + API_PRICINGS, data)
  }

  removePricing(uuid) {
    return axios.delete(API_URL + API_PRICINGS + "/" + uuid)
  }

  listInterventions(){
    return axios.get(API_URL+API_ITEMS+ API_ITEMS_PRICE+"?recurring_bills=false&enabled=true")
  }

  listPacks(pack){
    return axios.get(API_URL+API_ITEMS+ API_ITEMS_PRICE+"?recurring_bills=false&enabled=true&sub_type="+pack)
  }

  getItemList(parameters = undefined) {
    return axios.get(API_URL + API_ITEMS + API_ITEMS_PRICE + (parameters === undefined ? "" : ("?" + parameters)))
  }

  getLicencesOS(os_oid) {
    return axios.get(API_URL + API_LICENSES + "/association/" + os_oid)
  }

  getItemServer(parameters = undefined) {
    return axios.get(API_URL + API_ITEMS + "/server" + (parameters === undefined ? "" : ("?" + parameters)))
  }

  async loadAndFilterItems(oldItems = []) {
    let result = await tryCatch(() =>
      this.getItemList(getSearchParams({discountable: true}))
    );
    let itemsList = result.response;
    let items = []

    if (oldItems === null)
      return items

    oldItems.forEach((oldItem) => {
      itemsList.forEach((item) => {
        if (isEqual(item.oid, oldItem.oid)) {
          items.push({
            name: item.name,
            description: item.description,
            type: oldItem.type,
            oid: oldItem.oid,
            amount: oldItem.amount,
            max_quantity: oldItem.max_quantity,
          })
        }
      })
    })

    return items
  }

  createItem(data) {
    return axios.post(API_URL + API_ITEMS, data)
  }

  updateItem(uuid, data) {
    return axios.put(API_URL + API_ITEMS + "/" + uuid, data)
  }


  addItemsToRec(oid, data) {
    return axios.post(API_URL + "/subscription/" + oid + "/items", data)
  }

  getPricingForItem(oid, parameters = undefined) {
    return axios.get(API_URL + "/pricing?item_oid=" + oid + (parameters === undefined ? "" : ("&" + parameters)))
  }
  
  deletePricing(oid) {
    return axios.delete(API_URL + "/pricing/" + oid)
  }

  addPricing(data) {
    return axios.post(API_URL + "/pricing", data)
  }

  async getItemDetails(uuid) {
    return axios.get(API_URL_COMPUTE + "/items/" + uuid)
  }

}

export default new ItemService()
