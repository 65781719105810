import userService from "@/services/user.service";
import {tryCatch} from "@/components/shared/util";
import {settings} from "@/settings";
import {isDefaultCompanyAdmin, isDefaultCompanyManager, isTitanAdmin} from "@/services/utils/company";

export async function fixItemsPerPage() {
  let result = await tryCatch(() =>
    userService.getPreferences()
  );
  if (result.isSuccess && result.response.sorting_preferences.default) {
    // console.log("user limit", result.response.sorting_preferences.default.limit)
    return result.response.sorting_preferences.default.limit;
  }
  // console.log("default limit", settings.defaultItemsPerPage)
  return settings.defaultItemsPerPage;
}

export function isAuthorizedTo(user, value) {
  if (settings.apiTools.includes(value.toLowerCase())) {
    if (isTitanAdmin(user) || isDefaultCompanyAdmin(user) || isDefaultCompanyManager(user)) {
      return true;
    }
  }
  return false;
}
