module.exports = {
  "or": "Oder",

  "affiliation": {
    "tooltip-uncomplete": "Bestätigung steht noch aus, diese Transaktion wird Anfang des Monats bestätigt",
    "title": "Mitgliedschaften",
    "threshold": "Rang",
    "thresholds": "Rangs",
    "transactions": "Transaktionen",
    "companies": "Verbundene Unternehmen",
    "click-to-copy": "Klicken Sie hier, um den Zugehörigkeitslink zu kopieren",
    "click-to-copy-success": "Kopiert",
    "code": "Programmcode",
    "name": "Programmname",
    "date": "Datum",
    "total": "Insgesamt verfügbar",
    "forecast-total": "Gesamtprognose",
    "valid-date": "Ist aktiv seit",
    "valid-date-future": "Gilt am",
    "amount": "Monatlicher Umsatz zu generieren",
    "percentage": "Prozentsatz",
    "empty-transactions": "Noch keine Transaktionen…",
    "empty-companies": "Noch keine Firmen…",
    "monthly-ca": "Monatlicher Umsatz generiert",
    "ca": "Umsatz generiert",
    "percentage-apply": "Angewandter Prozentsatz",
    "teasing-warning": "Derzeit verfügt Ihr Unternehmen nicht über ein Partnerprogramm.\n" +
      "Um dieses Programm zu aktivieren, kontaktieren Sie bitte unsere Verkaufsabteilung per E-Mail oder Telefon.",
    "teasing-detail": "Unser Partnerprogramm ermöglicht es Ihnen, sich von der Rechnungsstellung an Ihre Kunden zu befreien, aber auch eine Vergütung für alle im Rahmen dieses Programms erbrachten Leistungen zu erhalten.",
    "tabs": {
      "info": "Informationen",
      "earn": "Mein Verdienst",
      "my-links": "Meine Links",
      "generate-unique-link": "Einen neuen Link generieren"
    }
  },

  "affiliation-links": {
    "title": "Meine Partnerlinks",
    "new": "Einen benutzerdefinierten Link erstellen",
    "code": "Allgemeiner Mitgliedscode",
    "company": "Unternehmen",
    "used_by_company": "Von der Firma verwendet?",
    "email": "E-Mail",
    "actions": "Aktionen",
    "false": "Nein",
    "true": "Ja",
    "modal": {
      "title": "Diesen Partnerlink entfernen",
      "danger": "Diese Maßnahme ist unumkehrbar.",
      "message": "",
      "button": "Löschen",
      "extra": "Sind Sie sicher?"
    }
  },

  "help": {
    "key-rename": "Den Namen des Schlüssels umbenennen",
    "key-expire-edit": "Ablaufdatum des Schlüssels bearbeiten",
    "ssh-rename": "Schlüsselname umbenennen",
    "ssh-rewrite": "Schlüssel bearbeiten",
    "network": "Wenn Sie Ihren Server an Ihren Vswitch anschließen, wird automatisch eine Netzwerkkarte angeschlossen.",
    "server-password": "Ihr SSH-Login finden Sie im Administrationspanel, im Bereich «Weitere Informationen»\". Um sich mit dem Root Modus anzumelden, verwenden Sie bitte den Befehl \"su\" gefolgt von Ihrem Passwort.",
    "server-ssh": "Die Verwaltung der Tasten erfolgt über das Menü Ihres Kontos.",
    "server-create": "-",
    "server-snapshots": "Auch wenn es nützlich ist, ersetzt ein Snapshot in keiner Weise ein Backup.",
    "image-rename": "Benennen Sie das Bild um.",
    "image-os-rename": "Betriebssystemnamen ändern.",
    "image-os-version-rename": "OS-Version umbenennen.",
    "server-rename": "Server umbenennen.",
    "server-rename-reverse": "Reverse umbenenne.",
    "server-open-ssh": "Öffnen Sie ein SSH-Terminal.",
    "server-iso-mount": "Mounte ein ISO.",
    "server-iso-unmount": "Unmounten einer ISO.",
    "server-pin-dash": "Pin im Dashboard.",
    "account-ssh": "Ihre Schlüssel werden bei der Erstellung des Servers sichtbar sein.",
    "account-api": "Verfügbar in der nächsten Version.",
    "account-2fa": "",
    "user-pref": "Um Ihr SmallCloud-Erlebnis anzupassen, speichern Sie hier Ihre Anzeigeeinstellungen. Sie können sie jederzeit ändern.",
    "licenses": "WARNUNG: Die Wahl Ihrer lizenz ist endgültig. Sobald der Server bestellt wurde, können Sie Ihre Lizenz nicht mehr ändern.",
    "company-user": "Der Typ 'Buchhalter' ermöglicht den Zugriff auf Rechnungen und Zahlungsabwicklung.\nDer Typ 'Manager' bietet Zugang zur technischen Serververwaltung.\nDer Typ 'Administrator' gewährt Zugriff auf alle Funktionen.",
    "company-del-user": "Benutzer löschen.",
    "company-send-inv": "Einladung erneut versenden.",
    "company-role-edit": "Zugriff bearbeiten.",
    "company-del-invitation": "Einladung entfernen.",
    "company-del-role": "Zugriff löschen.",
    "show-user-vcard": "Siehe die vCard des Benutzers.",
    "reinit-filter": "Filter zurücksetzen",
    "tickets-prio-change": "Die Priorität ändern",
    "tickets-header-link": "Erstellen Sie ein neues Ticket im Support.",
    "tickets-link-to-vm": "Öffnen Sie den Server in einem neuen Tab",
    "subscription-rename": "Das Projekt umbenennen.",
    "subscription-change-freq": "Die Frequenz ändern.",
    "subscription-see-all": "Alle Rechnungen zu diesem Projekt anzeigen",
    "subscription-see-next": "Siehe Ihre nächste Deadline.",
    "subscription-next-frequency": "Die neue Frequenz wird bei der Verlängerung der Rechnung berücksichtigt.",
    "network-rename": "Vswitch umbenennen",
    "company-oid": "Eindeutige Kennung des Unternehmens.",
    "ticket-indexer": "Tickets von IMAP aktualisieren",
    "subscription-payment-method": "Zahlungsmethode für das Abonnement zuweisen oder zurücksetzen",
  },

  "severity": {
    "0": "Einstellungen prüfen",
    "1": "Information",
    "2": "Warnung",
    "3": "Aufmerksam",
  },

  "actions": {
    "send": "Senden Sie",
    "filter": "Filter",
    "confirm": "Bestätigen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "generate": "Erzeugen Sie",
    "close": "Schließen",
    "delete": "Löschen",
    "back": "Zurück",
    "next": "Weiter",
    "buy": "Kaufen",
    "quit": "Verlassen",
    "validate": "Bestätigen",
    "pay": "Bezahlen",
    "enable": "Aktivieren",
    "disable": "Desaktivieren",
    "apply": "Anwenden",
    "reboot": "Jetzt neustarten",
    "reboot-acceptation": "Ich bin damit einverstanden, dass mein Server sofort neu gestartet wird",
    "more": "Mehr anzeigen",
    "less": "Weniger anzeigen",
    "search-servers": "Server finden",
    "search-networks": "Netzwerke finden",
    "click-to-copy": "Zum Kopieren anklicken",
    "copied": "Kopiert",
    "change-avatar": "Logo ändern",
    "remove-avatar": "Avatar löschen",
    "create_notification": "Benachrichtigung erstellen",
    "buy_space": "Speicher kaufen",
    "create": "Erstellen",
    "update": "Aktualisieren",
    "refund": "Erstattung",
    "add": "Hinzufügen",
    "message-avatar": "Zum Bearbeiten klicken",
    "download": "Herunterladen",
    "new": "Neu",
    "modify": "Ändern",
    "create-list": "Eine Liste erstellen",
    "show-list": "Liste anzeigen/bearbeiten",
    "open-all": "Alles öffnen",
    "close-all": "Alles schließen",
    "re-init": "Neu initialisieren",
    "new-f": "Neu",
    "open-list": "Liste anzeigen",
    "close-list": "Liste schließen",
    "terminate": "Kündigen",
    "cancel-terminate": "Kündigung abbrechen",
    "multi-pay": "Mehrzahlung",
    "cancel-delete": "Löschung abbrechen",
    "phone-link": "Direkter Anruf",
    "init-drp": "Zugriff auf den DRP",
    "reset-drp": "DRP verlassen",
  },
  "popup": {
    "coming-soon": "Coming soon",
  },
  "modal": {
    "definitive-action": "Diese Aktion ist endgültig",
    "cancel": "Abbrechen",
    "confirm": "Bestätigen",
    "finalize": "Die Bestellung abschließen",
    "confirm-text": "Um diesen Vorgang zu bestätigen, drücken Sie unten auf  \"BESTÄTIGEN\".",
    "confirm-placeholder": "BESTÄTIGEN eingeben",
    "confirmation-word": "BESTÄTIGEN",
    "server-list": {
      "attached-servers": "Verbundene Server",
      "not-attached-servers": "Nicht verbundene Server",
      "all": "Alle",
      "no-server": "Kein Server",
    },
    "dialog-reset-network": {
      "title": "Netzwerkschnittstelle zurücksetzen",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Durch Bestätigen dieser Aktion wird die Netzwerkschnittstelle des Servers zurückgesetzt.",
      "extra": "Möchten Sie die Netzwerkschnittstelle des Servers wirklich zurücksetzen?",
      "message2": "Durch Bestätigen dieser Aktion wird die Netzwerkschnittstelle des Servers zurückgesetzt.",
      "extra2": "Sind Sie sicher, dass Sie die Netzwerkschnittstelle dieser Server zurücksetzen möchten?"
    },
    "dialog-reset": {
      "title": "Server-Reset",
      "warning": "Diese Aktion ist unwiderruflich",
      "message": "Wenn Sie diese Aktion bestätigen, wird Ihr Server zurückgesetzt. Ihre Daten und Schnappschüsse werden dauerhaft gelöscht.",
      "extra": "Sind Sie sicher, dass Sie ein Server Reset durchführen wollen ?"
    },
    "dialog-terminate": {
      "title": "Kündigung des Servers",
      "warning": "CDiese Aktion ist gefährlich, aber umsteuerbar",
      "message": "Wenn Sie diese Aktion bestätigen, wird Ihr Server gekündigt. Ihre Daten und Schnappschüsse werden gelöscht.",
      "extra": "Sind Sie sicher, dass Sie diesen Server kündigen wollen?",
      "button": "Kündigen"
    },
    "dialog-cancel-terminate": {
      "title": "Stornierung der Serverkündigung",
      "warning": "Diese Aktion ist gefährlich",
      "message": "Wenn Sie diese Aktion bestätigen, wird die Kündigung Ihres Servers storniert.",
      "extra": "Sind Sie sicher, dass Sie die Kündigung dieses Servers abbrechen möchten ?",
      "button": "Kündigung abbrechen"
    },
    "dialog-snapshot-restore": {
      "title": "Snapshot wiederherstellen",
      "warning": "Diese Aktion ist unwiderruflich",
      "message": "Wenn Sie diese Aktion bestätigen, wird Ihr Snapshot auf Ihrem Server wiederhergestellt. Warnung: Bei diesem Vorgang werden die Daten auf Ihrer Festplatte überschrieben und durch die im Snapshot enthaltenen Daten ersetzt.",
      "extra": "Sind Sie sicher, dass Sie diesen Snapshot wiederherstellen wollen ?"
    },
    "dialog-snapshot-delete": {
      "title": "Snapshot löschen",
      "warning": "Diese Aktion ist unwiderruflich",
      "message": "Durch Bestätigen dieser Aktion wird dieser Schnappschuss gelöscht.",
      "extra": "Sind Sie sicher, dass Sie diesen Snapshot löschen wollen ?"
    },
    "dialog-ip-detach": {
      "title": "Eine IP trennen",
      "warning": "Diese Aktion ist unwiderruflich",
      "message": "Wenn Sie diese Aktion bestätigen, wird diese IP von diesem Server abgetrennt.",
      "extra": "Sind Sie sicher, dass Sie diese IP abtrennen wollen? ?"
    },
    "dialog-payment-method-remove": {
      "title": "Dieses Zahlungsmittel löschen.",
      "warning": "Diese Aktion ist wichtig.",
      "message": "Wenn Sie diese Aktion bestätigen, wird dieses Zahlungsmittel gelöscht.",
      "extra": "Sind Sie sicher, dass Sie dieses Zahlungsmittel entfernen möchten ?",
      "extra2": "Abonnements, die diese Zahlungsmethode verwenden, werden auf die Standardzahlungsmethode zurückgesetzt.",
    },
    "dialog-order-cancel": {
      "title": "Diese Bestellung abbrechen",
      "warning": "Diese Aktion ist wichtig.",
      "message": "Durch Bestätigung dieser Aktion wird dieser Auftrag storniert.",
      "extra": "Sind Sie sicher, dass Sie diese Bestellung abbrechen möchten ?"
    }
    , "dialog-changing-frequency": {
      "title": "Sind Sie sicher, dass Sie die Abrechnungsfrequenz ändern möchten ?",
      "warning": "Die Änderung wird mit Ihrer nächsten Abrechnung effektiv sein",
      "message": "Die neue Abrechnungsfrequenz : ",
    },
    "dialog-changing-project-name": {
      "title": "Sind Sie sicher, dass Sie den Namen des Projekts ändern möchten ?",
      "warning": "Die Namensänderung auf Ihrer Rechnung wird erst mit der nächsten Abrechnung effetktiv.",
      "message": "Ihr neuer Projektname wird sein : ",
    },
    "dialog-ConfirmRoleChange": {
      "title": "Sind Sie sicher, dass Sie die Funktion ändern wollen ",
      "warning": "Diese Aktion ist wichtig.",
      "message": "Seine neue Funktion wird sein : ",
    },
    "dialog-user-delete": {
      "title": "Diesen Benutzer löschen",
      "warning": "Diese Aktion ist endgültig",
      "message": "Durch Bestätigung dieser Aktion wird dieser Benutzer gelöscht.",
      "extra": "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten ?"
    },
    "dialog-invitation-delete": {
      "title": "Diese Einladug löschen",
      "warning": "Diese Aktion ist endgültig",
      "message": "Durch Bestätigung dieser Aktion wird diese Einladug gelöscht.",
      "extra": "Sind Sie sicher, dass Sie diese Einladug löschen möchten ?"
    },
    "dialog-ticket-level-change": {
      "title": "Änderung der Prioritätsstufe Ihres Tickets ",
      "warning": "Diese Aktion ist wichtig",
      "message": "Wenn Sie diese Aktion bestätigen, ändert sich die Priorität Ihres Tickets.",
      "extra": "Sind Sie sicher, dass Sie die Priorität Ihres Tickets ändern möchten ?"
    },
    "dialog-company-update": {
      "title": "Änderung der Firmenangaben",
      "success": "Diese neuen Informationen werden Ihre nächste Rechnung ändern.",
      "message": "Wenn Sie diese Aktion bestätigen, werden Ihre Unternehmensinformationen geändert.",
      "extra": "Sind Sie sicher, dass Sie diese Informationen ändern möchten ?",
    },
    "dialog-payment-cancel": {
      "title": "Stornierung einer Wartezahlung.",
      "warning": "Diese Aktion ist endgültig.",
      "message": "Wenn Sie diese Aktion bestätigen, wird Ihre Zahlung storniert.",
      "button": "Bestätigen"
    },
    "dialog-payment-refund": {
      "title": "Diese Zahlung zurückerstatten",
      "warning": "Diese Aktion ist endgültig",
      "message": "By confirming this action, you will make this refund.",
      "button": "Bestätigen"
    },
    "dialog-delete-image": {
      "title": "Löschen Sie das Bild.",
      "warning": "Diese Maßnahme ist endgültig",
      "message": "Wenn Sie diese Aktion bestätigen, wird Ihr Bild endgültig gelöscht.",
      "button": "Löschen"
    },
    "dialog-role-delete": {
      "title": "Rollenlöschung",
      "warning": "Diese Aktion ist irreversibel",
      "message": "Durch Bestätigen dieser Aktion wird Ihre Rolle gelöscht.",
      "extra": "Möchten Sie diese Rolle wirklich löschen?"
    },
    "dialog-ticket-creation": {
      "title": "Ticketerstellung",
      "success": "Das neue Ticket wird nach Bestätigung erstellt",
      "message": "Durch Bestätigen dieser Aktion wird das neue Ticket erstellt.",
      "button": "Bestätigen"
    },
    "dialog-ticket-close": {
      "title": "Ticket schließen",
      "title2": "Schließen des Tickets ohne Antwort",
      "warning": "Ticket wird nach Bestätigung geschlossen",
      "message": "Das Bestätigen dieser Aktion schließt das Ticket.",
      "button": "Bestätigen"
    },
    "dialog-item-pricing-update": {
      "title": "Neuer Preis hinzugefügt",
      "warning": "Diese Aktion ist wichtig",
      "message": "Sie ändern den Artikel {item} - Neuer Preis: {price}",
      "button": "Erstellen",
      "extra": "Sind Sie sicher?"
    },
    "dialog-item-pricing-remove": {
      "title": "Neuen Preis löschen",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Sie löschen den aktualisierten Artikel {item} - Der neue Preis war: {price}",
      "button": "Löschen",
      "extra": "Sind Sie sicher?"
    },
    "dialog-item-creation": {
      "title": "Artikel erstellen",
      "warning": "Diese Aktion ist wichtig",
      "message": "Sie erstellen Artikel {item} vom Typ: {type}",
      "button": "Erstellen",
      "extra": "Sind Sie sicher?"
    },
    "dialog-item-update": {
      "title": "Artikel aktualisieren",
      "warning": "Diese Aktion ist wichtig",
      "message": "Sie aktualisieren Artikel {item} vom Typ: {type}",
      "button": "Aktualisieren",
      "extra": "Sind Sie sicher?"
    },
    "dialog-item-account-remove": {
      "title": "Löschen eines neuen Kontocodes",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Sie löschen das Update des Artikels {item} - Der neue Kontocode war: {code}",
      "button": "Löschen",
      "extra": "Sind Sie sicher?"
    },
    "dialog-item-account-update": {
      "title": "Einen neuen Kontocode hinzugefügt",
      "warning": "Diese Aktion ist wichtig",
      "message": "Sie ändern Artikel {item} - Neuer Kontocode: {Code}",
      "button": "Erstellen",
      "extra": "Sind Sie sicher?"
    },
    "dialog-multi-pay-confirm": {
      "title": "Mehrfachzahlung",
      "warning": "Diese Aktion wird eine Zahlung registrieren",
      "message": "Bestätigen Sie die Erstellung dieser Zahlung?",
      "button": "Bestätigen",
    },
    "dialog-confirm-hyperV-migration": {
      "title": "Sie werden eine Migration auslösen",
      "warning": "Diese Aktion ist wichtig",
    },
    "dialog-confirm-hyperV-change-state": {
      "title": "Hypervisor-Statusänderung - { hostname } -",
      "danger": "Sie werden diesen Hypervisor deaktivieren",
      "warning": "Sie sind dabei, diesen Hypervisor zu aktivieren",
      "message": "Sind Sie sicher, dass Sie den Status des Hypervisors - { hostname } - ändern möchten?"
    },
    "dialog-confirm-hyperV-change-state-all": {
      "title": "Ändern des Zustands aller Hypervisoren",
      "danger": "Sie sind dabei, den Zustand aller Hypervisoren zu ändern",
      "message": "Sind Sie sicher, dass Sie mit dieser Operation fortfahren möchten?",
    },
    "dialog-quote-delete": {
      "title": "Zitat löschen",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Durch Bestätigen dieser Aktion wird dieses Angebot gelöscht",
      "extra": "Möchten Sie dieses Zitat wirklich löschen?"
    },
    "dialog-quote-accept": {
      "title": "Angebot annehmen",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Durch Bestätigen dieser Aktion wird dieses Angebot akzeptiert",
      "extra": "Sind Sie sicher, dass Sie dieses Angebot annehmen möchten?"
    },
    "dialog-notification-delete": {
      "title": "Benachrichtigung löschen",
      "warning": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Durch Bestätigen dieser Aktion wird diese Benachrichtigung gelöscht",
      "extra": "Möchten Sie diese Benachrichtigung wirklich löschen?"
    },
    "dialog-vat-update": {
      "title": "Sie werden dem Land {country} Mehrwertsteuer hinzufügen.",
      "warning": "Diese Aktion ist wichtig",
      "message": "Die neue Mehrwertsteuer für {country} beträgt {percentage} % am {date}",
      "button": "Aktualisieren",
      "extra": "Sind Sie sicher?"
    },
    "dialog-vat-remove": {
      "title": "Sie werden die Mehrwertsteuer aus dem Land {country} abziehen.",
      "warning": "Diese Aktion ist wichtig",
      "message": "MwSt. von {percentage} % am {date} in {country} wird entfernt.",
      "button": "Aktualisieren",
      "extra": "Sind Sie sicher?"
    },
    "dialog-start-demo": {
      "title": "Demo starten",
      "warning": "Diese Aktion stellt einen unbezahlten Server bereit",
      "message": "Durch Bestätigen dieser Aktion wird der Server ohne Bezahlung durch den Client bereitgestellt.",
      "button": "Start",
      "extra": "Sind Sie sicher, dass Sie den Demo-Modus aktivieren möchten?"
    },
    "dialog-stopp-demo": {
      "title": "Demo stoppen",
      "warning": "Diese Aktion stellt einen unbezahlten Server bereit",
      "message": "Durch die Bestätigung wird der Server deaktiviert, bis der Kunde bezahlt. Sie können den Demo-Modus trotzdem aktivieren.",
      "button": "Stopp",
      "extra": "Sind Sie sicher, dass Sie den Demo-Modus deaktivieren möchten?"
    },
    "dialog-enable-server": {
      "title": "Server aktivieren",
      "success": "Diese Aktion wird den Server aktivieren",
      "message": "Durch Bestätigen dieser Aktion wird der Server aktiviert, auch wenn der Kunde seine Rechnung nicht bezahlt hat. Sie können ihn jederzeit deaktivieren",
      "button": "Aktivieren",
      "extra": "Sind Sie sicher, dass Sie den Server aktivieren möchten?"
    },
    "dialog-disable-server": {
      "title": "Server deaktivieren",
      "danger": "Diese Aktion wird den Server deaktivieren",
      "message": "Durch Bestätigen dieser Aktion wird die gesamte Kommunikation mit dem Server deaktiviert. Sie können sie jederzeit wieder aktivieren",
      "button": "Deaktivieren",
      "extra": "Sind Sie sicher, dass Sie den Server deaktivieren möchten?"
    },
    "dialog-commercial-delete": {
      "title": "Verkäufe entfernen",
      "danger": "Diese Aktion ist irreversibel",
      "message": "Durch Bestätigen dieser Aktion wird diese Werbung gelöscht",
      "extra": "Möchten Sie diese Werbung wirklich löschen?"
    },
    "dialog-alert-delete": {
      "title": "Eine Aufgabe löschen",
      "danger": "Diese Aktion ist irreversibel",
      "message": "Durch Bestätigung dieser Aktion wird diese Aufgabe gelöscht",
      "extra": "Sind Sie sicher, dass Sie diese Aufgabe löschen möchten?",
    },
    "dialog-line-delete": {
      "title": "Eine Zeile löschen",
      "danger": "Diese Aktion ist irreversibel",
      "message": "Durch Bestätigen dieser Aktion wird diese Zeile gelöscht",
      "extra": "Möchten Sie diese Zeile wirklich löschen?"
    },
    "dialog-changing-outsourcing": {
      "title": "Änderung auslagern",
      "warning": "Sind Sie sicher, dass Sie die Auslagerungsstufe ändern möchten?",
      "message": "Durch Bestätigen dieser Aktion wird die Auslagerungsstufe geändert",
    },
    "dialog-delete-server": {
      "title": "Server sofort löschen",
      "danger": "Diese Aktion kann nicht rückgängig gemacht werden",
      "message": "Durch Bestätigung dieser Aktion wird der Server gelöscht.",
      "extra": "Sind Sie sicher, dass Sie diesen Server löschen möchten?"
    },
    "dialog-invoice-ip": {
      "title": "Vereinbarung vor Kündigung",
      "warning": "Die alte primäre IP-Adresse dieses Servers wird in Rechnung gestellt",
      "message": "Durch Bestätigung dieser Aktion wird die alte primäre IP-Adresse des Servers in Rechnung gestellt.",
      "extra": "Sind Sie sicher, dass Sie fortfahren möchten?"
    },
    "dialog-cancel-terminate-ip": {
      "title": "IP-Kündigung abbrechen",
      "warning": "Diese Aktion ist rückgängig zu machen",
      "message": "Durch Bestätigung dieser Aktion wird die Kündigung der IP abgebrochen.",
      "extra": "Sind Sie sicher, dass Sie die Kündigung dieser IP abbrechen möchten?",
      "button": "Kündigung abbrechen"
    },
    "dialog-changing-payment-state": {
      "title": "Sind Sie sicher, dass Sie den Zahlungsstatus ändern möchten?",
      "warning": "Die Änderung tritt sofort in Kraft.",
      "message": "Der neue Zahlungsstatus lautet:",
    },
    "dialog-subscription-update": {
      "title": "Abonnement aktualisieren",
      "warning": "Wenn Sie Artikel entfernt haben, gehen diese verloren.",
      "message": "Durch Bestätigung dieser Aktion wird das Abonnement modifiziert.",
      "extra": "Sind Sie sicher, dass Sie dieses Abonnement ändern möchten?",
    },
    "dialog-add-ipv6": {
      "title": "IPv6 zum Server hinzufügen",
      "success": "Die IPv6-Adresse wird nach Bestätigung hinzugefügt",
      "message": "Sie können sie später immer aus Ihrem Adresspool entfernen",
      "button": "Bestätigen",
    },
    "dialog-ticket-trash-delete": {
      "title": "Löschen eines 'Papierkorb'-Tickets",
      "danger": "Diese Aktion ist nicht rückgängig zu machen",
      "message": "Durch Bestätigung dieser Aktion wird das Ticket gelöscht (es wird nicht aus dem E-Mail-Postfach entfernt).",
      "extra": "Sind Sie sicher, dass Sie dieses Ticket löschen möchten?",
    },
    "dialog-ticket-trash-delete-many": {
      "title": "Mehrere 'Papierkorb'-Tickets löschen",
      "danger": "Diese Aktion ist nicht rückgängig zu machen",
      "message": "Durch Bestätigung dieser Aktion werden die ausgewählten Tickets gelöscht (sie werden nicht aus dem E-Mail-Postfach entfernt).",
      "extra": "Sind Sie sicher, dass Sie die ausgewählten Tickets löschen möchten?",
    },
    "dialog-apply-sub-payment-method": {
      "title": "Sind Sie sicher, dass Sie diese Zahlungsmethode verwenden möchten?",
      "warning": "Die Änderung tritt in Ihrem nächsten Abrechnungszyklus in Kraft",
      "message": "Zu verwendende Zahlungsmethode: {payment_method}",
    },
    "dialog-remove-sub-payment-method": {
      "title": "Sind Sie sicher, dass Sie zur Standardzahlungsmethode zurückkehren möchten?",
      "warning": "Die Änderung tritt in Ihrem nächsten Abrechnungszyklus in Kraft",
      "message": "Durch Bestätigung dieser Aktion wird die Zahlungsmethode zurückgesetzt",
    },
    "scheduled-date": {
      "title-schedule-reboot": "Einen Neustart planen",
      "title-schedule-snapshot": "Snapshots planen",
      "already-scheduled": "Datum bereits geplant | Termine bereits geplant",
      "new-schedule": "Neue Planung",
      "validation-error": "Die Zeit muss mindestens 5 Minuten in der Zukunft liegen",
      "dialog-delete-scheduled-date": {
        "title": "Planung löschen",
        "warning": "Die Änderung wird nach Bestätigung übernommen",
        "message": "Durch Bestätigen dieser Aktion wird die Planung gelöscht.",
        "extra": "Sind Sie sicher, dass Sie diese Planung löschen möchten?",
      },
    },
    "project-selection": {
      "title": "Projektliste",
      "placeholder": "Projektname",
      "name": "Projektname",
      "no-project": "Kein Projekt",
      "number-of-projects": "Kein Projekt | 1 Projekt | {nb} Projekte",
    },
    "server-selection": {
      "title": "Serverliste",
      "number-of-servers": "Kein Server | 1 Server | {nb} Server",
    },
  },

  "ip": {
    "type": "V",
    "reverse": "Reverse",
    "attach": "IPs anhängen",
    "detach": "Trennen einer IP",
    "buy": "IPs kaufen",
  },

  "cart": {
    "apply-all": "Gilt für alle Server",
    "dialog-update-all-pass": {
      "title": "Kopie der Authentifizierung auf Servern",
      "info": "",
      "message": "Möchten Sie dieses Passwort und/oder die SSH-Schlüssel für alle Server im Korb übernehmen?",
      "extra": "Sind Sie sicher?"
    },
    "quote": {
      "title": "Ein Angebot erstellen",
      "create": "Angebot erstellen",
      "validity_period": {
        "text": "Gültigkeitsdauer",
        "periode": "Monat"
      },
      "payment_methode": "Zahlungsmethode",
      "payment_type": {
        'PSEPA': "Lastschriftverfahren",
        'card': "CB",
      },
      "modal": {
        "title": "Angebot in den Warenkorb legen",
        "message": "Sie haben die Möglichkeit, Ihren aktuellen Warenkorb zu überschreiben, zusammenzuführen oder die aktuelle Aktion abzubrechen",
        "merge": "Zusammenführen",
        "erase": "Überschreiben",
        "cancel": "Abbrechen"
      },
    },
    "debug": {
      "validate": "Bestellung validieren",
    },
    "no-authentication": "Keine Möglichkeit der Authentifizierung",
    "update": "Für diesen Server bewerben",
    "title": "Ihr Warenkorb",
    "empty": "Keine Bestellung im Warenkorb…",
    "summary": "Zusammenfassung Ihrer Bestellung :",
    "quantity": "Menge",
    "add": "Zum Warenkorb hinzufügen",
    "continue": "Die Bestellung fortsetzen",
    "no_modal": "Diese Nachricht nicht mehr anzeigen",
    "pay": "Bezahlen",
    "payment": "Bezahlung",
    "order": "Server bestellen",
    "order-add": "Server bestellen",
    "removed": "Zeile gelöscht",
    "updated": "Aktualisierte Bestellung",
    "success": "Auftrag erfolgreich abgeschlossen",
    "selected_ssh_keys": "Ausgewählte SSH-Schlüssel :",
    "password_use": "Verwendetes Passwort :",
    "project_name": "Name des neuen Projekts",
    "project_name_create": "Projekt auswählen oder erstellen",
    "project_name_input_placeholder": "Namen des Projekts eingeben",
    "project_name_feedback": "Zwischen 4 und 60 Zeichen",
    "project_name_tip": "Wählen Sie ein bestehendes Projekt aus oder erstellen Sie es, oder es wird automatisch generiert.",
    "select_project_name": "Name des Anbauprojekts  : ",
    "project_name_placeholder": "Projektnamen",
    "project_name_selector": "Auswahl des Projektnamens",
    "error_redirect": "Sie werden zur Seite der Unterlagen weitergeleitet, wo Sie die Zahlung abschließen können.",
    "license": "Inklusive Lizenz",
    "update-credential": "Anmeldeinformationen aktualisiert.",
    "project": "Projekt",
    "frequency": "Frequenz",
    "unit_amount": "Betrag pro Einheit",
    "monthly_unit_amount": "Monatlicher Einheitsbetrag",
    "first_payment": "Erste Zahlung",
    "period": "Zeitraum von {date1} bis {date2}",
    "discount": "Rabatte",
    "monthly": "Monatliche Menge",
    "quarterly": "Vierteljährlicher Betrag",
    "biannually": "Halbjahresbetrag",
    "annually": "Jährlicher Betrag",
    "added_to_cart": "Zum Warenkorb hinzugefügt",
    "label1": "Firma",
    "label2": "Kommerziell",
    "button_paid": [
      "Bitte Zahlungsart wählen"
    ],
    "item_type": {
      "ip": "IP-Adresse"
    },
    "project-modal": {
      "title": "Projektnamen auswählen",
      "comment": [
        "Alle Elemente desselben Projekts werden auf einer einzigen Rechnung gruppiert.",
      ],
      "header": [
        "Liste der vorhandenen Projekte",
        "Neues Projekt",
      ],
      "divided": "ODER",
    },
  },

  "user": {
    "name": "Name",
    "title": "Titel",
    "phone": "Telefonnummer",
    "mobile": "Mobilnummer",
    "email": "Email",
    "authorization": "Typ",
    "role": "Zugriff",
    "roles": {
      "SUPER_ADMINISTRATOR": "Super Administrator",
      "ADMINISTRATOR": "Administrator",
      "ACCOUNTANT": "Buchhalter",
      "MANAGER": "Manager",
      "TITAN_SUPER_ADMINISTRATOR": "TITAN - Super Administrator",
      "TITAN_ADMINISTRATOR": "TITAN - Administrator",
      "TITAN_ACCOUNTANT": "TITAN - Buchhalter",
      "TITAN_COMMERCIAL": "TITAN - Werbespot",
      "TITAN_MANAGER": "TITAN - Manager",
      "UNKNOWN": "Unbekannt",
      "undefined": "Undefined",
      "none": "Alle",
    }
  },

  "role": {
    "name": "Rollenname",
    "allowed-servers": "Zulässige Server",
    "allowed-networks": "Zulässige Netzwerke",
    "authorization": "Type",
    "role": "Zugriff",
    "type": "Art",
    "servers": "Server",
    "networks": "Netzwerke",
    "custom-roles": "Zugriff",
    "add": "Zugriff hinzufügen",
    "number_of_users": "Benutzer",
    "number_of_invites": "Einladungen",
  },

  "tickets": {
    "modal": {
      "title": "Bestätigung der Nachricht"
    },
    "tickets": "Tickets",
    "tickets-empty": "Sie haben noch keine Tickets.",
    "open-ticket": "Ein Ticket eröffnen",
    "status": {
      "processing": "Laufend",
      "in_progress": "Laufend",
      "closed": "Geschlossen",
      "assigned": "Zugewiesen",
      "pending": "Standby",
      "pending_admin": "Ausstehend",
      "pending_client": "Laufend",
      "new_pending_admin": "Ausstehend",
      "new_pending_client": "Laufend",
      "new": "Neu",
    },
    "attachments": {
      "received": {
        "title": "Dateiliste",
        "header": [
          "Dateiname",
          "Nett",
        ],
        "popup": "Anhang anzeigen | Anhänge anzeigen",
        "error_message": "Fehler beim Lesen",
      },
      "sent": {
        "title": "Dokument(e) hinzufügen",
        "popup": "Dokumente hinzufügen",
        "text": [
          "Lege deine Dateien hier ab",
          "order",
          "klicken, um sie auszuwählen",
        ],
        "placeholder": "Dateiname",
      },
      "content_type_error": "Nicht autorisierter Dateityp",
    },
    "message": {
      "tooltip": [
        "Mehr anzeigen",
        "Weniger anzeigen"
      ]
    },
    "trash": {
      "message": [
        "Löschung erfolgreich",
        "Fehler während der Löschung",
      ],
    },
  },
  "invoices": {
    "invoice": "Rechnung",
    "order_title": "Bestellung",
    "credit_title": "Anerkennung",
    "next_invoice_title": "Nächste Rechnung :",
    "download-pdf": "PDF Herunterladen",
    "download-multi-disabled": "Wählen Sie Rechnungen aus, um sie herunterzuladen",
    "download-multi": "Ausgewählte Rechnungen herunterladen",
    "payment-multi-disabled": "Wählen Sie unbezahlte Rechnungen aus",
    "payment-multi": "Ausgewählte Rechnungen bezahlen",
    "payment-multi-unpaid": "Bitte wählen Sie nur unbezahlte Rechnungen aus.",
    "recurring": {
      "states": {
        "pending": "Standby",
        "ongoing": "Laufend",
        "canceled": "Gekündigt"
      }
    },
    "states": {
      "all": "Alle",
      "upcoming": "Demnächst",
      "ribbon_pending": "Pending",
      "pending": "Pending",
      "ribbon_unpaid": "Unbezahlt",
      "unpaid": "In Erwartung der Zahlung",
      "ribbon_paid": "Bezahlt",
      "paid": "Bezahlt",
      "ribbon_canceled": "Abgebrochen",
      "canceled": "Abgebrochen",
      "available": "Verfügbar",
      "spent": "Verbraucht",
      "expired": "Abgelaufen",
    },
    "types": {
      "order": "Bestellung",
      "invoice": "Rechnung",
      "credit": "Anerkennung"
    }
  },

  "kvm": {
    "title": "Fernzugriff",
    "p1": "Ihr Fernzugriff wird erstellt.",
    "p2": "Bitte warten Sie einen Moment.",
    "cancel": "Abbrechen",
    "start": "Fernzugriff",
    "stop": "Fernzugriff stoppen",
    "fullscreen": "Vollbildmodus",
    "fullscreen_leave": "Verlassen Sie den Vollbildmodus",
    "expand": "Erweitern",
    "compress": "Reduzieren",
    "pip": "In den PIP-Modus wechseln"
  },

  "menu": {
    "upgrade": {
      "text": "Eine neue Version der Applikation ist verfügbar: ",
      "button": "Seite neu laden",
      "later": "Später",
    },
    "faq": "FAQ",
    "accounts": "Konten",
    "menu": "Menü",
    "affiliations": "Mitgliedschaften",
    "ip-histo": "IPs-Verlauf",
    "dashboard": "Dashboard / Bilder",
    "vms-sidebar": "Server / Bilder",
    "vms": "Server",
    "image": "Bilder",
    "container": "Container",
    "dedicated-serveur": "Dedizierte Server",
    "vms-add": "Serverbestellung",
    "companies": "Firmen",
    "contacts": "Kontakte",
    "network": "Netzwerke",
    "templates": "Templates",
    "company": "Firma",
    "tickets": "Tickets",
    "recurring": "Abonnements",
    "documents": "Unterlagen",
    "settings": "Einstellungen",
    "logout": "Logout",
    "lang": "Sprache",
    "dark-mode-header": "Dark mode",
    "dark-mode": "Dark",
    "light-mode": "Light",
    "notifications": "Serverbenachrichtigungen",
    "intervention": "Andere Dinge",
    "stop_impersonate": "Zurück zum normalen Benutzer",
    "tools": "Werkzeuge",
    "cron": "Geplante Aufgaben",
  },

  "filters": {
    "search": "Suchen.",
    "search-server": "Nach einem Server suchen, OS, IP, MAC...",
    "reset": "Reset",
    "os": "Betriebssystem",
    "all": "Alle",
    "service": "Service",
    "level": "Priorität",
    "project": "Name des Projekts",
    "periodicity": "Periodizität",
    "affiliationLinks-per-page": "Anzahl der Links pro Seite : ",
    "servers-per-page": "Anzahl der Server pro Seite: ",
    "invoices-credits-per-page": "Anzahl der Rechnungen und Gutschriften pro Seite: ",
    "invoices-orders-per-page": "Anzahl der Aufträge und Rechnungen pro Seite: ",
    "recurring-per-page": "Anzahl der Abonnements pro Seite:",
    "images-per-page": "Anzahl der Bilder pro Seite :",
    "tickets-per-page": "Anzahl der Tickets pro Seite:",
    "payments-per-page": "Anzahl der Zahlungen pro Seite: ",
    "clients-per-page": "Anzahl der Kunden pro Seite: ",
    "orders-per-page": "Anzahl der Bestellungen pro Seite: ",
    "ipsHisto-per-page": "Anzahl der Ip pro Seite : ",
    "item-per-page": "Items pro Seite : ",
    "discount-per-page": "Discount pro Seite : ",
    "country-per-page": "Countries pro Seite : ",
    "events-per-page": "Ereignisse pro Seite : ",
    "elements-per-page": "Artikel pro Seite : ",
    "notifications-per-page": "Benachrichtigungen pro Seite : ",
    "invoices-quote-per-page": "Angebote pro Seite : ",
    "ip-per-page": "IPs pro Seite:",
    "license-per-page": "Anzahl der Lizenzen pro Seite:",
    "line-per-page": "Anzahl der Zeilen pro Seite:",
    "invoice_types": {
      "invoice": "Rechnung",
      "order": "Bestellung",
      "credit": "Gutschrift"
    },
    "status": {
      "started": "Gestartet",
      "stopped": "Gestoppt",
      "pending": "Laufend",
      "waiting": "Standby",
      "new": "Neu",
      "closed": "Geschlossen",
      "deleted": "Gelöscht"
    },
    "ticket": {
      "processing": "Laufend",
      "in_progress": "Laufend",
      "closed": "Geschlossen",
      "assigned": "Zugewiesen",
      "pending": "Laufend",
      "pending_admin": "Standby",
      "pending_client": "Laufend",
      "new": "Neu",
      "new_pending_admin": "Neu",
      "new_pending_client": "Neu",
      "number": "Ticketnummer",
      "object": "Objekt",
      "company": "Unternehmen",
    }
  },

  "ticket": {
    "server": "Server | Server",
    "status": "Stand",
    "number": "Nº",
    "user": "User",
    "object": "Objekt",
    "service": "Service",
    "product": "Produkt",
    "created_at": "Erstellungsdatum",
    "messages": "Nachrichten",
    "company": "Firma",
    "lock": "Sperren",
    "unlock": "Entsperren",
    "company_info": "Kundeninformationen",
    "last_reboot": "Letzter Neustart",
    "indexer": "Reindizieren",
    "state-date": "Letzter Status",
  },
  "admin": {
    "client": {
      "actif": {
        "true": "Inaktiv",
        "false": "Aktiv"
      },
      "filter": "Kundenfilter",
    },
    "discount": {
      "actif": {
        "true": "Aktiv",
        "false": "Inaktiv"
      },
      "type": {
        "FIX": "Fest",
        "PERCENTAGE": "Prozentsatz"
      },
    },
    "item": {
      "title": "Artikel",
      "button": "Neu",
      "sort": {
        "sort1": {
          "title": "Rabattfähig",
          "state": {
            "true": "Aktiv",
            "false": "Inaktiv"
          },
        },
        "sort2": {
          "title": "Aboberechtigt",
          "state": {
            "true": "Ja",
            "false": "Nein"
          },
        },
        "sort3": {
          "title": "Aktiv",
          "state": {
            "true": "Ja",
            "false": "Nein"
          },
        },
      },
      "header": {
        "name": "Name",
        "plan": "Planen",
        "category": "Kategorie",
        "enabled": "Aktiv",
        "type": "Typ",
        "description": "Beschreibung",
        "price": "Aktueller Preis exkl. MwSt.",
        "discount_enabled": "Rabatte",
        "status": "Status",
        "subtype": "Untertyp",
      },
      "type": {
        "CPU": "CPU",
        "DISK": "Disk",
        "RAM": "RAM",
        "HARDWARE": "Hardware",
        "SERVICE": "Service",
        "LICENSE": "License",
        "QOS": "QOS",
        "MAC": "MAC",
        "IP": "IP",
        "SNAPSHOT": "Snapshot",
        "STORAGE": "Pool Storage",
        "CREDIT": "Kredit",
        "REBOOT": "Serverneustart",
        "SMS": "SMS",
        "rent": "Miete",
        "buy": "Kaufen"
      },
      "creation": {
        "creation_title": "Neuen Artikel erstellen",
        "update_title": "Aktualisierung eines Artikels",
        "modal": {
          "label1": "Name",
          "placeholder1": "",
          "label2": "Beschreibung",
          "placeholder2": "Beschreibung",
          "label3": "Artikeltyp",
          "placeholder3": "",
          "label4": "Abrechnungsmethode",
          "placeholder4": "",
          "label5": "Rabatt",
          "placeholder5": "",
          "label6": "Aktiv",
          "placeholder6": "",
          "label7": "Preis exkl. MwSt.",
          "placeholder7": "Preis exkl. MwSt.",
          "label8": "Buchungscode",
          "placeholder8": "Buchungscode",
          "label9": "Plesk-Code",
          "placeholder9": "",
          "label10": "Untertyp",
          "label11": "Anzahl der Gutschriften",
          "edit_price": {
            "label1": "Preis exkl. MwSt.",
            "placeholder1": "Preis exkl. MwSt",
            "label2": "Bewerbungsdatum",
            "placeholder2": "Bewerbungsdatum",
          },
          "edit_code": {
            "label1": "Buchungscode",
            "placeholder1": "Buchungscode",
            "label2": "Bewerbungsdatum",
            "placeholder2": "Bewerbungsdatum",
          },
        },
        "message": {
          "create_success": "Erstellen erfolgreich",
          "update_success": "Update erfolgreich",
          "remove_success": "Löschen erfolgreich",
        },
      },
      "add_item": {
        "title": "Element zu einem Abonnement hinzufügen",
        "header1": "Artikel",
        "header2": "Seriennummer",
        "header3": "Server-OID",
        "header4": "Menge",
        "checkbox1": "Teilrechnung erstellen",
        "checkbox2": "Der Gegenstand ist bereitgestellt",
        "start_date": "Rechnungsstartdatum",
      },
      "manage_item": {
        "title": "Verwaltung der Abonnementartikel",
        "title-list": "Liste der Abonnementartikel",
        "delete-item": "Element löschen",
        "delete-all": "Abonnement löschen"
      },
    },
    "accounting": {
      "tabs": {
        "title": {
          "invoices": "Unterlagen",
          "taxes": "Steuern",
          "payment": "Zahlung",
          "orderForm": "Bestellung",
          "csv": "CSV-Export"
        },
      },
      "menu": [
        "Kunden",
        "Mitgliedschaften",
        "Unterlagen",
        "Abonnements",
        "Artikel",
        "Gutscheine",
        "Steuern",
        "Zahlungsfrist",
        "Tägliche Aufgaben",
        "Auslagerung",
        "Verschieden",
        "Lizenzen",
      ],
    },
    "company": {
      "contact": {
        "title": "Unternehmenskontakte",
        "user": "Benutzer",
        "email": "E-Mail-Adresse",
        "role": "Rolle",
        "phone": "Telefon",
        "role_from": "Sie sind dabei, die Rolle von {name} zu ändern",
        "role_to": "Ihre neue Rolle wird sein: {name}",
        "tooltip": [
          "Als dieser Benutzer anzeigen",
          "vCard erstellen",
          "Genaue Information",
        ],
      },
      "tabs": [
        "Kunden",
        "Zitat",
        "Kommerziell",
      ],
      "tab_title": "Werbung",
      "modal": {
        "title": "Verkäufer zuweisen",
      },
      "popup": [
        "Bearbeitung läuft",
        "Zum Bearbeiten anklicken",
      ],
      "detail": {
        "tab": [
          "Die Info",
          "MwSt",
          "Kontakte",
          "Kommerziell",
          "Mitgliedschaften",
          "Kontingente und Beschränkungen",
          "Unternehmensübergreifende Übertragung",
          "Zusätzliche Einstellungen",
        ],
        "info": {
          "label": [
            "Name der Firma",
            "Email",
            "Stufe der Reduktion",
            "Unternehmen deaktiviert",
            "Aktive Zahlungsmethode",
            "Kommerziell",
            "Zahlungsfrist",
            "Autorisierter Betrag",
            "Keiner",
            "Automatische Ressourcenbereitstellung",
            "Automatischer Versand der Rechnung per E-Mail",
            "Einzelzahlung für mehrere Rechnungen",
            "URL für den Notfallwiederherstellungsplan",
            "Telefonnummer",
            "Anzahl der Gutschriften",
            "Genehmigung zum Senden von SMS",
          ],
          "option": [
            "Keiner",
            "Sofort | {days} Tag | {days} Tage",
            "Monatsende | {days} Tag Monatsende | {days} Tage Monatsende",
          ],
          "help": [
            "Um einen neuen Rabatt hinzuzufügen, klicken Sie bitte auf diese Schaltfläche im Bearbeitungsmodus.",
          ],
        },
        "vat": {
          "label": [
            "Identifikationsnummer“," +
            "Gültig",
            "Geprüft",
          ],
          "information": [
            "Adresse",
            "Landesvorwahl",
            "Nachname",
            "Abfragedatum",
            "Umsatzsteuer-Identifikationsnummer",
            "Status",
          ],
          "status": {
            "true": "Gültig",
            "false": "Ungültig",
          },
          "popup": [
            "Überprüfen und Informationen anzeigen",
            "Prüfen",
            "Änderung abbrechen"
          ],
        },
        "commercial": {
          "message": [
            "Dieser Firma ist kein Verkäufer zugeordnet.",
          ],
          "title": "Unternehmensverkauf",
          "button": [
            "Verkäufer zuweisen",
            "Werbung wechseln",
          ],
          "header": [
            "Nachname",
            "Vorname",
            "Email",
          ],
          "modal": {
            "title": "Verkäufer zuweisen",
          },
        },
        "quota": {
          "label": [
            "Quote",
            "Beschränkungen",
          ],
          "header": [
            "Netzwerke",
            "Server",
            "ZENTRALPROZESSOR",
            "Erinnerung",
            "Scheibe",
            "Schnappschüsse",
            "Lagerung",
            "Schnittstellen",
          ],
        },
        "transfer": {
          "header": [
            "Übertragungsart",
            "Empfängerunternehmen",
            "Zu übertragende Abonnements",
            "Zu übertragende Server",
          ],
          "type": {
            "1": "Vollständiger Transfer zu einem anderen Unternehmen",
            "2": "Übertragung von Abonnement(s) zu einem anderen Unternehmen",
            "3": "Übertragung von Server(n) zu einem anderen Unternehmen",
          },
        },
      },
    },
    "coupon": {
      "title": "Rabattgutscheine",
      "button_new": "Neu",
      "button_close": "Schließen",
      "button_add": "Hinzufügen",
      "Status": {
        "title": "Staat",
        "enable": "Aktiv",
        "disable": "Inaktiv",
      },
      "Code": "Code",
      "use": "benutzen",
      "description": "Beschreibung",
      "expiry_date": "Ablaufdatum",
      "never": "Niemals",
      "no_coupon": "Kein Gutschein gefunden",
      "popover": {
        "modify": "ändern",
        "enable": "Aktivieren",
        "duplicate": "Duplizieren",
        "disable": "Deaktivieren",
        "suppress": "Löschen",
        "at_start_date": "Gültig ab Erstellung",
        "always": "immer",
        "unlimited": "0 = unbegrenzt",
        "need": "Sie müssen mindestens einen Artikel auswählen und einen Code eingeben",
        "need_member": "Sie müssen einen Code eingeben und entweder einen Gesamtrabatt eingeben oder mindestens einen Artikel auswählen",
      },
      "modal": {
        "title": "Gutscheindetail",
        "Code": "Code:",
        "description": "Beschreibung:",
        "expiry_date": "Ablaufdatum: ",
        "max_use": "Max. Nutzungen: unbegrenzt | Max. Nutzungen: {count}",
        "first_order": "Nur bei der 1. Bestellung einer Firma",
        "customers": "Zielkunde: | Zielkunden:",
        "items": "Kein Element | Ziel-Element: {count} | Ziel-Elemente: {count}",
        "number": "Nein",
        "invoice_date": "Rechnungsdatum",
        "client": "Kunde",
        "total": "Gesamt ohne MwSt.",
        "discount_amount": "Rabattbetrag"
      },
      "creation": {
        "title": "Erstellung/Aktualisierung eines Gutscheins",
        "tab1": {
          "title": "Gutscheineinstellungen",
          "label": "",
          "code": "Gutscheincode",
          "first_order": "Nur auf die 1. Bestellung einer Firma",
          "customers": "Auf einen bestimmten Kunden abzielen",
          "description": "Beschreibung",
          "items": "Anzahl der ausgewählten Artikel",
          "start_date": "Startdatum",
          "expiry_date": "Ablaufdatum",
          "max_use": "Maximale Anzahl der Gesamtnutzungen",
          "max_use_client": "Maximale Anzahl an Nutzungen pro Client",
          "amount": "Gesamtrabatt",
          "help": "Geben Sie entweder einen Gesamtrabatt oder eine Liste von Artikeln ein",
        },
        "tab2": {
          "title": "Artikelverwaltung",
          "label1": "Artikel ausgewählt | Artikel ausgewählt | Artikel ausgewählt",
          "label2": "Einen oder mehrere Artikel hinzufügen",
          "label3": "Maximale Menge pro Coupon",
          "header": [
            "Artikel",
            "Art der Kürzung",
            "Rabattwert",
            "Maximale Menge",
          ],
          "discount": {
            "type": {
              "FIX": "Fester Betrag",
              "PERCENTAGE": "Prozentsatz"
            },
            "type_sign": {
              "FIX": "€",
              "PERCENTAGE": "%"
            },
            "help": "Leer bedeutet kein Limit",
          },
        }
      },
      "Alert": [
        "Gutschein aktiviert",
        "Gutschein deaktiviert"
      ],
      "switch": {
        "coupon": "Allgemein",
        "member": "Mitglied",
        "affiliate": "Affiliate"
      },
    },
    "multi_payment": {
      "title": "Mehrfachzahlung",
      "amount_received": "Erhaltener Betrag",
      "total_amount_received": "Gesamtbetrag erhalten ({total} €)",
      "payment_date": "Zahlungsdatum",
      "payment_method": "Zahlungsmethode",
      "reference_number": "Referenznummer",
      "unpaid_invoices": {
        "title": "Unbezahlte Rechnungen",
        "header1": "Datum",
        "deadline": "Fälligkeitsdatum",
        "header2": "Rechnungsnummer",
        "header3": "Betrag",
        "header4": "Fälliger Betrag",
        "header5": "Zahlung",
      },
      "non_chargeable_amount": "Nicht zu verrechnender Betrag {amount}€",
    },
    "payment": {
      "modal": {
        "invoice_date": "Rechnungsdatum",
        "payment_amount": "Zahlungsbetrag",
        "refund_amount": "Erstatteter Betrag",
        "reference_number": "Referenz",
        "client": "Kunde",
        "invoice_number": "Rechnung",
        "status": "Status",
        "bank_status_code": "Bankstatuscode",
        "bank_status_message": "Nachricht des Bankstatus",
        "dispute": "Streitfall",
        "bank_payment_id": "Bankreferenz",
      },
      "tab": {
        "title": "Zahlung hinzufügen",
        "header1": "typ",
        "header2": "Transaktions-ID",
        "header3": "Betrag",
        "header4": "Ausführungsdatum",
        "modal_question": "Sind Sie sicher, dass Sie diese Zahlung erstellen möchten?",
      },
    },
    "refund": {
      "refund_number": "Referenz {Referenz}",
      "initial_amount": "- Abrechnungsbetrag inklusive Steuer: ",
      "message_no_invoice": "Bei dieser Abrechnung wurde keine Rechnung bezahlt",
      "checkbox1": "Keine Gutschrift erstellen",
      "checkbox2": "Teilrückerstattung",
      "help": "Wähle oder gib einen Grund ein",
      "related_invoice": "Zugehörige Rechnung | Zugehörige Rechnungen",
      "header1": "Name",
      "header2": "Menge",
      "header3": "Preis exkl. MwSt.",
      "credit": "Restguthaben exkl. MwSt.",
      "amount_excluding_vat": "Betrag ohne MwSt",
      "amount_including_vat": "Betrag inklusive Mehrwertsteuer",
      "total_exclusiv_vat": "Gesamt ohne Mehrwertsteuer",
      "total_inclusive_vat": "Gesamtbetrag inklusive Mehrwertsteuer",
      "prepend": "Rückerstattungsbetrag inklusive Steuern",
      "total_credit": "Gesamtes Restguthaben ohne MwSt",
      "total_refund": "Gesamtrückerstattung",
      "reasons": [
        "Grund der Rückerstattung",
        "Doppelte Zahlung",
        "Betrüger",
        "Vom Kunden angefordert",
      ],
      "placeholder": [
        "Bezug",
        "Bank",
        "Erstellungsdatum",
        "Grund, es zu haben",
        "Menge",
        "Preis",
        "Rückerstattungsbetrag ohne Gutschrift",
      ],
      "excluding_vat": "Exkl. MwSt",
      "including_vat": "Inkl. MwSt",
    },
    "migration": {
      "title": "Hypervisor-Migration",
      "label1": "Migrationsmodus",
      "label2": "Quell-Hypervisor auswählen",
      "label3": "Quellserver-UUID",
      "label4": "Ziel-Hypervisor auswählen",
      "label5": "Hypervisor",
      "no_hypervisor": "Kein Hypervisor verfügbar",
      "success_message1": "Hypervisor {hostname} wird {action} sein.",
      "success_message2": "Migration gestartet",
      "title-list": "Liste der Hypervisoren",
      "state": {
        "disable": "Deaktiviert",
        "enable": "Aktiviert",
      },
      "success_message": "Die Änderungen wurden erfolgreich durchgeführt",
    },
    "notification ": {
      "button_migration": "HyperV-Migration",
      "button_management": "hyperV-Verwaltung",
      "button_assignment": "Benachrichtigung zuweisen",
      "header": [
        "Schwere",
        "Titel",
        "Nachricht",
        "Neu starten",
        "Server"
      ],
      "modal": {
        "title": "Benachrichtigung zuweisen",
        "title_add": "Benachrichtigung",
        "label": [
          "Schwere",
          "Titel",
          "Nachricht",
          "Neustart erzwingen",
          "Server-OIDs"
        ],
      },
      "reboot": [
        "Ja",
        "Nein",
      ],
    },
    "vat": {
      "title": "Umsatzsteuersatz eines Landes ändern",
      "filter": "Mehrwertsteuer > 0",
      "header": [
        "Land",
        "Landesvorwahl",
        "Prozentsatz",
      ],
      "label": [
        "Datum der Bewerbung",
        "Prozentsatz"
      ],
      "message": [
        "Aktualisierung erfolgreich",
        "Gewähltes Datum < heutiges Datum"
      ],
    },
    "export": {
      "label": [
        "Datumsfilter",
        "Schriften",
        "Firmen",
        "Neue Unternehmen",
        "Aktualisierte Unternehmen",
        "CSV-Datei herunterladen",
        "Wird heruntergeladen...",
        "Kontakte",
        "Für CRM",
        "Neue Kontakte",
        "Aktualisierte Kontakte",
      ],
    },
    "payment_delay": {
      "title": "Zahlungsfristen",
      "button": "Neu",
      "sort": {
        "state": {
          "true": "Aktiv",
          "false": "Inaktiv"
        },
      },
      "header": [
        "Anzahl Tage",
        "Monatsende",
        "Anlage",
      ],
      "operation": {
        "title": [
          "Zahlungsbedingung anlegen",
          "Aktualisierung einer Zahlungsfrist",
        ],
        "header": [
          "Anzahl Tage",
          "Monatsende",
          "Anlage",
        ],
        "message": [
          "Erfolgreiche Erstellung",
          "Fehler beim Erstellen",
          "Aktualisierung erfolgreich",
          "Fehler beim Aktualisieren",
        ],
      },
    },
    "alert": {
      "title": "Tägliche Aufgaben",
      "button": "Neu",
      "sort": {
        "state": {
          "true": "Aktiv",
          "false": "Inaktiv",
        },
      },
      "header": [
        "Aufgabentyp",
        "Templatekategorie",
        "Templatename",
        "Templateerweiterung",
        "Versandtyp",
        "Max. Versand pro Tag",
        "Frist",
        "Fristtyp",
        "E-Mail Bcc",
        "Aktiv",
        "Server deaktivieren",
      ],
      "operation": {
        "title": [
          "Erstellung einer Aufgabe",
          "Aktualisierung einer Aufgabe",
        ],
        "header": [
          "Aufgabentyp",
          "Templatekategorie",
          "Templatename",
          "Templateerweiterung",
          "Versandtyp",
          "Max. Versand pro Tag",
          "Frist",
          "Fristtyp",
          "E-Mail Bcc",
          "Aktiv",
          "Server deaktivieren",
        ],
        "message": [
          "Erstellung erfolgreich",
          "Fehler bei der Erstellung",
          "Aktualisierung erfolgreich",
          "Fehler bei der Aktualisierung",
        ],
      },
      "type": {
        "before_server_deletion": "Vor Serverlöschung",
        "delete_server": "Serverlöschung",
        "delete_ip": "IPLöschung",
        "invoice_unpaid": "Rechnung unbezahlt",
        "payment_method_expired": "Zahlungsmethode abgelaufen",
        "invoice_renew": "Rechnungsstellung",
        "payment_error": "Zahlungsfehler",
        "cart_is_paid": "Warenkorb bezahlt",
        "ticket_before_closing": "Vor Ticketschließung",
        "ticket_closing": "Ticketschließung",
      },
      "template_category": {
        "server": "Server",
        "renewal": "Erneuerung",
        "payment": "Zahlung",
        "invoice": "Rechnungen",
        "ticket": "Ticket",
        "ip": "IP Adresse",
      },
      "send_type": {
        "email": "E-Mail",
      },
      "time_diff_type": {
        "day": "Tag",
        "month": "Monat",
      },
      "help": [
        "Anzahl der Versendungen am gleichen Tag oder Monat (-1: Immer, 0: Einmal)",
        "Anzahl der Tage/Monate vor (negativer Wert) oder nach (positiver Wert) dem Kontrolldatum",
        "E-Mail Adresse, die zusätzlich zur Kundenadresse benachrichtigt wird (optional)",
        "Aktiviert/Deaktiviert die Aufgabe",
        "Nur für unbezahlte Rechnungen. Führt die Serverdeaktivierung durch, wenn markiert.",
      ],
    },
    "miscellaneous": {
      "title": "Verschiedenes",
      "none_option": "Keiner",
      "param": {
        "title": "Einstellungen",
        "button": "Neu",
        "header": [
          "Name",
          "Art",
          "Wert",
          "Wortlaut",
          "Datum",
          "Mindestwert",
          "Maximalwert",
          "Wörterbuchschlüssel",
          "Wörterbuchwert",
        ],
        "operation": {
          "title": [
            "Schaffung",
            "Aktualisieren",
          ],
          "header": [
            "Name",
            "Art",
            "Wert",
            "Wortlaut",
            "Datum",
            "Mindestwert",
            "Maximalwert",
            "Wortlaut",
          ],
          "message": [
            "Erfolgreiche Erstellung",
            "Fehler beim Erstellen",
            "Aktualisierung erfolgreich",
            "Fehler beim Aktualisieren",
            "Erfolgreich gelöscht",
            "Fehler beim Löschen",
          ],
        },
      },
      "function": {
        "title": "Funktionen",
        "all": "Alle",
        "help": [
          "Wählen Sie ein Unternehmen aus oder lassen Sie es leer für alle Unternehmen",
          "Geben Sie die primäre IPv4 ein und klicken Sie auf die Schaltfläche. Das 'Weiterleitung'-Feld wird geleert.",
          "Geben Sie eine IPv4-Adresse ein und wählen Sie eine oder mehrere Überprüfungen aus, die durchgeführt werden sollen. Drücken Sie dann die Schaltfläche.",
        ],
        "function": [
          "Saldo Neuberechnung",
          "Zurücksetzen der Weiterleitung der primären IPv4",
          "Überprüfungen auf IP-Adresse",
          "Ein Abonnement erstellen",
        ],
        "message": {
          "successful": "Aktualisierung erfolgreich",
          "unsuccessful": "Aktualisierung fehlgeschlagen",
        },
        "api-result": {
          "title": "Ergebnisse der IP-Prüfungen für {ip}",
          "more": "Rohergebnis anzeigen",
          "less": "Rohergebnis ausblenden",
          "header": [
            "PING",
            "NMAP",
            "TRACEROUTE",
            "ABUSE",
          ],
          "show-result": "Klicken Sie auf die durchzuführende Steuerung",
          "result-ping": [
            "Anzahl der gesendeten Pakete: ",
            "Anzahl der empfangenen Pakete: ",
            "Prozentsatz der verlorenen Pakete: "
          ],
          "result-nmap": [
            "Liste offener Ports: ",
            "Keine offenen Ports"
          ],
          "result-traceroute": [
            "Hop {hop}: ",
            "Kein Ergebnis"
          ],
          "result-abuse": [
            "Teil der Whitelist",
            "IP-Adresse auf der schwarzen Liste",
            "IP-Adresse nicht auf der schwarzen Liste",
            "Kein Ergebnis"
          ],
        },
        "api-buttons": {
          "button-ping": "Einen Ping auf diese IP-Adresse starten",
          "button-nmap": "Einen Nmap auf diese IP-Adresse starten",
          "button-traceroute": "Einen Traceroute auf diese IP-Adresse starten",
          "button-abuse": "Einen Abuse auf diese IP-Adresse starten",
        },
        "outsourcing": {
          "header": [
            "Zu belastendes Unternehmen",
            "Server(s)",
            "Artikel",
            "Häufigkeit",
            "Projektname",
            "Unternehmen, das die Server besitzt",
            "Abonnementtyp",
          ],
          "number-of-servers": "Kein Server | 1 Server | {nb} Server",
        },
      },
      "ticket-trash": {
        "title": "Gelöschte Tickets",
      },
    },
    "outsourcing": {
      "title": "Outsourcing",
      "button": "Neu",
      "no_param": "Bitte erstellen Sie alle Parameter, die zum Erstellen von Service-Levels benötigt werden.",
      "header": [
        "Niveau",
        "Wortlaut",
        "Beschreibung",
        "Kritisch",
        "Auslagerung",
        "Priorität",
        "Eingriffszeit",
        "Ht-Preis",
        "Am",
        "Artikel",
],
      "operation": {
        "title": [
          "Schaffung",
          "Aktualisieren",
        ],
        "header": [
          "Auslagerungsgrad",
          "Wortlaut",
          "Beschreibung",
          "Kritisch",
          "Auslagerung",
          "Priorität",
          "Eingriffszeit",
          "Ht-Preis",
          "Bewerbungsdatum",
          "Artikel",
        ],
        "message": [
          "Erfolgreiche Erstellung",
          "Fehler beim Erstellen",
          "Aktualisierung erfolgreich",
          "Fehler beim Aktualisieren",
          "Erfolgreich gelöscht",
          "Fehler beim Löschen",
          "Bitte geben Sie die erforderlichen Felder ein",
          "Pflichtfelder",
        ],
      },
    },
    "param": {
      "outsourcing": {
        "outsourcing": "Serviceebene",
        "standard": "Standard-Outsourcing",
        "advanced": "Erweitertes Outsourcing",
        "full": "Vollständige Auslagerung",
      },
      "outsourcing_criticality": {
        "outsourcing_criticality": "Kritikalität",
        "low": "Niedrig",
        "medium": "Moderat",
        "high": "Hoch",
      },
      "outsourcing_priority": {
        "outsourcing_priority": "Priorität",
        "medium": "Durchschnitt",
        "high": "Hoch",
        "priority": "Priorität",
      },
      "outsourcing_level": {
        "outsourcing_level": "Auslagern",
        "none": "Keine",
        "medium": "Moderat",
        "full": "Abgeschlossen",
      },
      "intervention_type": {
        "intervention_type": "Interventionstyp",
        "day": "Tag",
        "hour": "Stunde",
        "minute": "Minute",
      },
    },
    "ticket-indexer": {
      "title": "Ticket-Neuindizierung",
      "header": [
        "Maximale Anzahl zu verarbeitender E-Mails",
        "Ältestes zu verarbeitendes Datum",
      ],
      "help": "Lassen Sie diese Werte leer, um alle E-Mails neu zu indizieren",
      "Nachricht": [
        "Aufgabe gestartet"
      ],
    },
    "subscription": {
      "type": {
        "0": "Standard",
        "1": "Managed Services",
        "2": "Notfallwiederherstellungsplan",
        "3": "IP",
        "4": "Speicherung",
        "5": "Guthaben",
      },
      "title": "Abonnementdetails",
      "return": "Zurück",
      "return-text": "Zurück zur Abonnementliste",
      "tab": [
        "Allgemein",
        "Rechnungen",
      ],
      "overview": {
        "header": [
          "Projektname",
          "Frequenz",
          "Startdatum",
          "Enddatum",
          "Aktualisierungsdatum",
          "Status",
          "Zahlung für Rechnungserneuerung deaktiviert",
        ],
        "footer": [
          "Gesamt ohne MwSt.",
          "MwSt.",
          "Gesamt inkl. MwSt.",
          "Rabatt",
          "Gesamtnetto ohne MwSt.",
        ],
        "update": {
          "success": "Aktualisierung des Abonnements erfolgreich",
          "nothing": "Keine Informationen zum Aktualisieren",
          "commercial-success": "Kommerzielle Aktualisierung erfolgreich",
          "date-warning": "Bitte geben Sie ein Enddatum ein",
          "partial-billing": "Teilrechnung erstellen",
        },
        "detail": {
          "title": "Abonnementartikel",
          "header": [
            "Artikel",
            "Beschreibung",
            "Server",
            "Menge",
            "Stückpreis",
            "Rabatt",
            "Nettobetrag",
            "Bereitgestellt",
          ],
        },
        "button": {
          "modify": "Abonnement bearbeiten",
          "add-item": "Artikel hinzufügen",
          "validate": "Änderungen bestätigen",
          "cancel": "Änderungen abbrechen",
          "create-invoice": "Rechnung erstellen",
          "generate-invoice": "Rechnung generieren",
        },
      },
    },
    "demo": {
      "enable": {
        "title": "Demo-Modus aktivieren",
        "server-name": "Servername",
        "transfer": "Den Server an einen Kunden übertragen?",
        "message-success": "Demo-Modus erfolgreich aktiviert",
        "message-error": "Fehler bei der Aktivierung des Demo-Modus",
      },
      "disable": {
        "title": "Deaktivierung des Demo-Modus",
        "server-name": "Servername",
        "return": "Rückkehr des Servers in den Titan-Pool",
        "true": "Ja",
        "false": "Nein",
        "project": "Zielabonnement",
        "frequency": "Periodizität",
        "billing": "Zu berechnen?",
        "billing-date": "Abrechnung ab",
        "message-success": "Demo-Modus erfolgreich deaktiviert",
        "message-error": "Fehler bei der Deaktivierung des Demo-Modus",
      },
    },
    "license": {
      "title": "Lizenzen",
      "plesk": {
        "title": "Plesk",
        "list-empty": "Leere Liste",
        "generate": "Liste generieren",
        "tooltip": [
          "Liste generieren oder regenerieren",
          "CSV herunterladen",
        ],
        "waiting": "Die aktuelle Bearbeitungszeit kann mehr als 2 Minuten betragen. Bitte warten Sie.",
        "search": "Suche nach Kennung, Artikel, IP, Kunde, Server",
        "goto-server": "Server anzeigen",
        "goto-subscription": "Abonnement ansehen",
        "goto-invoice": "Rechnung ansehen",
        "header": [
          "Kennung",
          "Status",
          "Artikel",
          "Erstellung",
          "Bindung",
          "Kunde",
          "Server",
          "Serverstatus",
          "Abonnement",
          "Letzte Rechnung",
          "Key ID",
          "Ablauf",
          "Aktivierungscode",
          "Beschreibung",
          "Gemeldete IP(s)",
        ],
        "sort": {
          "status": {
            "all": "Alle",
            "ACTIVE": "Aktiv",
            "TERMINATED": "Inaktiv",
          },
        },
        "errors": {
          "title": "Fehler",
          "legend": "Legende",
          "error_legend": [
            "Unbekanntes Unternehmen",
            "Gelöschter oder unbekannter Server",
            "Beendete Abonnement",
            "Falsche Serverzuweisung",
          ],
          "error_code": {
            "0": "Keine",
            "1": "Unternehmen",
            "2": "Server",
            "3": "Abonnement",
            "4": "Falsch",
          },
        },
        "modal": {
          "disable": {
            "title": "Deaktivierung der Lizenz",
            "message": "Durch Bestätigen dieser Aktion wird die Lizenz deaktiviert.",
            "warning": "Sie können diese Lizenz immer wieder reaktivieren",
            "extra": "Sind Sie sicher, dass Sie die Lizenz deaktivieren möchten?",
          },
        },
        "message": [
          "Die Lizenz wurde deaktiviert",
          "Fehler bei der Deaktivierung",
        ],
        "info": {
          "title": "Lizenzdetails",
          "button": {
            "modify": "Lizenz bearbeiten",
            "stop-modify": "Bearbeitung stoppen",
          },
        },
      },
    },
  },
  "invoice": {
    "server": "Server",
    "status": "Stand",
    "number": "Nº",
    "client_code": "Kundencode",
    "client_name": "Kunde",
    "balance": "Guthaben",
    "final_date": "Frist",
    "product": "Produkt",
    "created_at": "Erstellungsdatum",
    "create": "Erstellt am",
    "start": "Gestartet am",
    "update": "Aktualisiert am",
    "not_found": "Keine Rechnung gefunden.",
    "not_found_search": "Keine Rechnung entspricht Ihrer Suche.",
    "type": "Type",
    "due_date": "Datum des Endes",
    "amount_ht": "Betrag ohne MwSt.",
    "amount_ttc": "Betrag inkl. MwSt.",
    "changing_project_name": {
      "title": "Sind Sie sicher, dass Sie den Namen des Projekts ändern möchten?",
      "description": "Der neue Name des Projekts ist: ",
      "success": "die Namensänderung wurde erfolgreich abgeschlossen.",
    },
    "frequency": {
      "title": "Frequenz",
      "detailed_title": "Abrechnungszeitraum",
      "changing_title": "Sind Sie sicher, dass Sie die Rechnungsfrequenz ändern wollen?",
      "success": "Die Änderung des Abrechnungsfrequenz wurde erfolgreich abgeschlossen.",
      "MONTHLY": "Monatlich",
      "QUARTERLY": "Vierteljährlich",
      "BIANNUALLY": "Halbjährlich ",
      "ANNUALLY": "Jährlich",
    },
    "last_invoice": "Letzte Rechnung",
    "next_invoice": "Nächste Rechnung",
    "see_all_invoice": "Rechnungen ansehen",
    "company": "Firma",
    "price": "Preis",
    "project_name": "Projekt",
    "add_item": "Artikel hinzufügen",
    "manage_item": "Elemente verwalten",
    "generate_invoice": "Rechnung generieren",
    "payment_method": "Zahlungsmethode",
    "details": {
      "status": "Rechnungsstand",
      "billing_address": "Abgerechnet an :",
      "shipping_address": "Geliefert an :",
      "same_shipping_billing": "Geliefert und abgerechnet an:",
      "description": "Beschreibung",
      "initial_amount": "Betrag ohne Steuern",
      "discount_rate": "Diskontsatz",
      "discount_amount": "Betrag des Discounts",
      "net_amount": "Gesamtbetrag ohne Steuer",
      "vat_rate": "MwSt.-Satz",
      "vat_amount": "MwSt. Betrag",
      "total_amount": "Gesamtbetrag inkl. MwSt.",
      "remaining_amount": "Fälliger Betrag",
      "download": "Downloaden",
      "legal_notice_text": "Strafzins: Gemäß Gesetz Nr. 921442 vom 31. Dezember 1992 ist diese Rechnung zum oben angegebenen Fälligkeitsdatum ohne Skontoabzug für vorzeitige Zahlung zahlbar. Jede ausgestellte und nicht zum Fälligkeitsdatum bezahlte Rechnung wird bis zur vollständigen Bezahlung automatisch mit einem Zinssatz in Höhe des dreifachen gesetzlichen Zinssatzes gemäß Artikel L.441-6 des französischen Handelsgesetzbuches verzinst.Pauschale Entschädigung für Wiederherstellungskosten von 40 Euro (Dekret 2012-1115 vom 2. Oktober 2012).",
      "tva_notice_in_eu": "Mehrwertsteuerbefreiung - Artikel 196 der Richtlinie 2006/112/CE",
      "tva_notice_out_eu": "Mehrwertsteuer nicht anwendbar - Artikel 44 der Richtlinie 2006/112/CE",
      "facturation_date": "Rechnungsdatum",
      "credit_date": "Datum der Gutschrift :",
      "payment": "Zahlung : Nach Empfang",
      "due_date": "Verfalldatum",
      "billing_period": "Abrechnungszeitraum",
      "billing_period_sm": "Zeitraum",
      "project": "Projekt :",
      "page": "Seite",
      "of": "auf",
      "subtotal": "Zwischensumme ohne MwSt.",
      "subtotalTag": "ohne MwSt.",
      "cancel": "Abbrechen",
      "paid_by": "Bezahlt von",
      "promotion_code": "Angebotscode ",
      "item": {
        "name": "Produkt",
        "quantity": "Menge",
        "unit_price": "Einzelpreis",
        "total_price": "Gesamtpreis ohne MwSt."
      },
      "billing_date": "Rechnungsbeginndatum",
    },
    "modal": {
      "button": {
        "pay": "Bezahlen",
        "cancel": "schließen",
        "return": "Zurück",
        "next": "Nächste",
        "generate_credit": "Guthaben generieren",
        "clear": "Löschen",
        "deploy": "Bereitstellen",
      },
      "payment": {
        "invoices_page": "Seite Rechnungen",
        "servers_page": "Serverseite",
      },
      "clear": {
        "title": "Eine Rechnung oder Gutschrift begleichen",
        "header": [
          "Neuer Betrag",
        ],
        "success": "Vorgang erfolgreich abgeschlossen"
      },
      "deploy": {
        "ask_deploy": "Möchten Sie die Ressourcen wirklich bereitstellen?",
        "success": "Bereitstellung läuft",
        "failed": "Anfrage fehlgeschlagen",
      },
    },
    "recurring": {
      "not_found": "Keine Abonnements gefunden.",
      "not_found_search": "Keine Abonnements entsprechen Ihrer Suche.",
      "payment_method": {
        "text": [
          "Wählen Sie die Zahlungsmethode für das Abonnement aus und klicken Sie auf 'Anwenden'.",
          "Klicken Sie auf 'Zurücksetzen', um die Standardzahlungsmethode zu verwenden.",
          "Bei Zahlungsfehlern wird die Standardzahlungsmethode verwendet.",
        ],
      },
    },
    "next_frequency": "Nächste Frequenz",
    "account_balance": "Ihr Kontostand: {amount}€"
  },

  "credit": {
    "created_at": "Erstellungsdatum :",
    "start_date": "Anfangsdatum :",
    "expiration_date": "Haltbarkeitsdatum :",
    "credit_date": "Kreditdatum :",
    "reference_period": "Bezugszeitraum :",
    "referring_invoice": "Referenzrechnung :",
    "number": "Kredit N° ",
    "remaining_amount": "Restbetrag",
    "modal": {
      "title": "Gutschrift erstellen ({invoice})",
      "period": "Betroffener Zeitraum",
      "header1": "Artikel",
      "header2": "Menge",
      "header3": "Betrag",
      "header4": "Aktion",
      "placeholder1": "Preis",
      "placeholder2": "Menge",
      "footer": {
        "amount_allowed": "Verfügbarer Betrag ohne MwSt.:",
        "amount_excluding_vat": "Gesamt ohne MwSt.:",
        "amount_including_vat": "Gesamtbetrag inklusive Mehrwertsteuer:",
        "amount_vat": "Mehrwertsteuer:",
        "amount": "Betrag ohne MwSt.:",
        "keep_old_values": "Globalgutschrift mit Originalbeträgen",
      },
    },
  },

  "payment_method": {
    "waiting": {
      "message_less_than_hour": "In Erwartung der Zahlung, bitte warten Sie einen Moment ",
      "message": "Ihre Zahlung ist noch ausstehend, bitte kontaktieren Sie den Support.",
      "link": "Ein Ticket erstellen"
    },
    "card": {
      "empty": "Keine Zahlungsmethoden, fügen Sie eine hinzu, um fortzufahren.",
      "existing": "Bestehende Zahlungsmethoden ",
      "numbers": "Kartennummer",
      "expiration_date": "Verfallsdatum (MM/YYYY)",
      "full_name": "Vollständiger Name",
      "reference": "Reference :",
      "default": "Standardeinstellung",
      "default_ribbon": "Standard",
      "remove": "Löschen",
      "expired": "abgelaufen",
      "iban": "IBAN : ",
      "default_method": 'Diese Zahlungsmethode ist diejenige, die Sie standardmäßig verwenden. Sie wird für alle Ihre Abonnements verwendet.',
      "creation": {
        "title_sepa": "Neue IBAN-Informationen",
        "add_sepa": "Neue IBAN hinzufügen",
        "title": "Neue Karteninformationen",
        "valid_card": "Hinzufügen",
        "valid_sepa": "Akzeptieren und hinzufügen",
        "name_placeholder": "Vollständiger Name",
        "email_placeholder": "Email",
        "add_card": "Eine neue Karte hinzufügen",
        "cancel": "Abbrechen"
      },
    },
    "sepa_mandate": "Ich ermächtige/ Wir ermächtigen (A) Titan Datacenters France und Stripe, unser " +
      "Zahlungsdienstleister, Zahlungen von meinem/ unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) " +
      "weise ich mein/ weisen wir unser Kreditinstitut an auf mein/ unser Konto gezogenen " +
      "Lastschriften einzulösen. Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, " +
      "die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem " +
      "Kreditinstitut vereinbarten Bedingungen. Meine/ Unsere Rechte zu dem obigen Mandat sind in " +
      "einem Merkblatt enthalten, das ich/ wir von meinem/ unserem Kreditinstitut erhalten " +
      "kann/ können.",
    "details": {
      "payment_period": "Für den Zeitraum von",
      "to": "zu",
      "month": "monat",
      "monthly": "Monat",
      "quarterly": "Quartal",
      "biannually": "Semester",
      "annually": "Jahr",
      "initial_amount": "Betrag ohne Steuern :",
      "initial_amount_monthly": "Betrag ohne Steuern/Monat :",
      "discount": "Rabatt :",
      "net_amount": "Gesamtbetrag ohne Steuer :",
      "vat": "MwSt. :",
      "total_amount": "Gesamtbetrag inkl. MwSt. :",
      "total_amount_monthly": "Gesamtbetrag inkl. MwSt./Monat :",
      "amount_due": "Fälliger Betrag :",
      "amount_paid": "Bezahlter Betrag :",
      "remaining_amount": "Verbleibender Betrag inkl. MwSt. :",
      "amount_too_low": "Dieser Betrag ist zu niedrig, um sofort in Rechnung gestellt zu werden. Die Rechnung wird automatisch zusammen mit Ihrer Abonnementverlängerung bezahlt.",
      "amount_too_low_accept": "Ich akzeptiere die aufgeschobene Belastung",
      "non_recurring_amount": "Einmaliger Betrag ohne Steuern :",
      "recurring_amount": "Wiederkehrender Betrag ohne MwSt :"
    },
    "credit": {
      "list": "Guthabenliste",
      "id": "Guthaben-ID",
      "credit": "Guthaben",
      "allocated_amount": "Gesamt (inkl. MwSt.)",
      "available_amount": "Saldo",
      "provisional_amount": "Vorläufiges Guthaben *",
      "provision_tips": "Aktualisiert sich bei Auswahl.",
      "use": "Verwendetes Guthaben:",
      "expiration_date": "Ablaufdatum",
      "created_at": "Erstellungsdatum"
    },
    "modal": {
      "select_preferred": {
        "title": "Änderung der Standardzahlungsmethode",
        "warning": "Diese Zahlungsmethode wird für alle Ihre Abonnements verwendet.",
      },
      "confirm_payment": {
        "title": "Zahlungsübersicht :",
        "amount": "Gesamtpreis inkl. MwSt. der Bestellung :",
        "method": "Verwendete Zahlungsmethode :",
        "cgv_confirm": "Mit der Bestätigung meiner Bestellung erkläre ich, dass ich die AGB und die Datenschutzerklärung gelesen und vorbehaltlos akzeptiert habe",
        "credit": "Gutschrift gebraucht :",
        "cgv_see": "Siehe die CGV",
        "sepa_info_title": "SEPA-Informationen",
        "sepa_info": "Die übliche Verzögerung für eine SEPA-Transaktion liegt zwischen 7 und 14 Werktagen. " +
          "Ihr Service wird erst nach vollständiger Validierung der Transaktion geliefert. " +
          "Wenn Sie möchten, können Sie diese Zahlung per Karte vornehmen, um eine sofortige Servicebereitstellung zu erhalten.",
        "offline": "Zahlung außerhalb der Sitzung",
      }
    },
    "state": {
      "failed": "Die Zahlung ist fehlgeschlagen",
      "action": "Paiement en attente d'action.",
      "action-detail": "Cliquez sur confirmer pour finaliser votre paiement.",
      "pending": "Zahlung in Bearbeitung",
      "success": "Die Zahlung war erfolgreich, danke.",
      "processing": "Die Zahlung wird von der Bank bearbeitet.",
      "failed-comment": [
        'Ihre Bestellung wurde gespeichert.',
        'Sie finden es im Bereich "Unternehmen", Registerkarte "Dokumente".',
      ],
    },
    "payment_state": {
      "success": "Erfolgreich",
      "error": "Fehler",
      "waiting": "Warten",
      "canceled": "Abgebrochen",
      "processing": "Wird bearbeitet"
    },
    "list": {
      "id": "#",
      "exec_date": "Datum",
      "state": "Stand",
      "type": "Typ",
      "methodID": "ID",
      "card": "Karte",
      "credit": "Gutschrift",
      "amount": "Betrag",
      "psepa": "SEPA-Lastschriftverfahren",
      "vsepa": "SEPA-Überweisung",
      "cancel_success": "Ihre Zahlung wurde erfolgreich storniert."
    },
    "promotional_code": {
      "invalid": "Ungültiger Aktionscode.",
      "valid": "Aktionscode angewendet",
      "valid_cant_apply": "Der Aktionscode ist gültig, kann jedoch nicht auf Ihre Bestellung angewendet werden",
      "amount": "Angebotscode:",
    }
  },
  "image": {
    "modal": {
      "title": "Änderung des Speicherplatzes",
      "description": "Hier können Sie Ihren Gesamtspeicherplatz erhöhen oder verringern. Sie verwenden derzeit:",
      "downgrade": "Sie entfernen Speicherplatz, die Reduzierung Ihres Speicherplatzes erfolgt unmittelbar nach der Validierung.",
      "current": "Aktueller Speicherplatz",
      "warning": "Diese Aktion ist wichtig",
      "future": "Speicherplatz nach Änderung",
      "update": "Änderung",
      "unit-disk": "Gb",
      "price-recuring-final": "* Dieser Betrag wird der regelmäßigen Abrechnung für Ihren Speicherplatz hinzugefügt"
    },
    "edit": "Quote bearbeiten",
    "quotas": "Nutzung der Speicher",
    "template-origin": "Originalvorlage: ",
    "empty": "Kein Bild...",
    "diskSize": "Größe der Scheibe",
    "state": "Status",
    "name": "Name",
    "date": "Datum der Erstellung",
    "more-info": "Weitere Informationen",
    "less-info": "Weniger Informationen",
    "states": {
      "created": "Erstellt",
      "creating": "Erstellung von",
      "error": "Fehler",
      "deleted": "Gelöscht",
      "deleting": "Löschung läuft"
    }
  },
  "server": {
    "select": "Wählen Sie einen oder mehrere Server aus",
    "user-name": "Benutzername",
    "from": "Von ",
    "has-notification": "Dieser Server erfordert Ihre Aufmerksamkeit",
    "server-empty": "Kein Server…",
    "more-info": "Weitere Informationen",
    "less-info": "Weniger Informationen",
    "_": "Server",
    "plan": "Karte",
    "name": "Name",
    "state": "Stand",
    "uuid": "UUID",
    "oid": "OID",
    "ram": "RAM",
    "ip-protocol": "Protokoll",
    "ipv4": "IPV4",
    "ipv6": "IPV6",
    "ip": "IP",
    "os": "OS",
    "mac": "MAC",
    "cpu": "CPU",
    "vcpu": "vCPU",
    "disk": "Laufwerk",
    "network": "Netzwerk",
    "bandwidth": "Bandbreite",
    "disk-qos": "QoS Laufwerk",
    "delete-network": "Vswitch löschen",
    "reverse": "Reverse",
    "hypervisor": "Hypervisor",
    "ssh": "SSH",
    "license": "Lizenz",
    "kvmip": "KVM IP",
    "deleted_at": "Löschdatum",
    "gateway": "Gateway",
    "private-network": "Privates Netzwerk",
    "ssh-keys": "SSH Schlüssel",
    "hostname": "Name des Hosts",
    "company": "Firma",
    "termination": "Geplante Kündigung am",
    "created_at": "Erstellung",
    "ctrl-alt-del": "Ctrl-Alt-Supp",
    "demo": "Demo Modus",
    "disabled": "Server deaktiviert",
    "upgrade": "Upgrade",
    "downgrade": "Downgrade",
    "pay": "Bezahlung",
    "processor": "Prozessor : ",
    "ram-capacity": "RAM : ",
    "disk-space": "Speicherplatz : ",
    "metric_not_available": "Momentan nicht verfügbar.",
    "outsourcing": "Auslagern",
    "no-outsourcing": "Kein Outsourcing",
    "drp": "NWP",
    "actions": {
      "start": "Server",
      "stop": "Server",
      "reboot": "Neu starten",
      "hardstop": "Zwangsabschaltung",
      "resume": "Übernehmen",
      "reset": "Reset",
      "terminate": "Kündigen",
      "reset-network": "Reset Network",
      "cancel-terminate": "Kündigung abbrechen",
      "activate-drp": "Notfallwiederherstellungsplan",
      "deactivate-drp": "Deaktivieren Notfallwiederherstellungsplans",
      "schedule-reboot": "Geplanter Neustart",
    },
    "events": {
      "snapshot": "Snapshot",
      "network": "Privates Netzwerk",
      "ipkvm": "KVM",
      "changestate": "Server",
      "servercreation": "Erstellung",
      "serverdeletion": "Löschung",
      "serverupdate": "Feld aktualisieren",
      "serveraddons": "Addon(s)",
      "migration": "Hypervisor-Migration",
      "serveroperations": "Server operation",
      "storageupdate": "Update"
    },
    "states": {
      "unknow": "Bereitstellung",
      "createserverimage": "Bild erstellt",
      "starting": "Starten",
      "started": "Gestartet",
      "suspending": "Aufhängung",
      "suspended": "Aufgehängt",
      "stopping": "Wird stillgesetzt",
      "stopped": "Arrêté",
      "reseting": "Reset im Gange",
      "resuming": "Übernahme",
      "resumed": "Übernommen",
      "upgrading": "Update",
      "disabling": "Désactivation",
      "disabled": "Désactivé",
      "enabling": "Activation",
      "enabled": "Activé",
      "creating": "Erstellung",
      "created": "Créé",
      "deleting": "Suppression",
      "deleted": "Gelöscht",
      "rebooting": "Neustart",
      "restoring": "Restaurierung",
      "restored": "Restauriert",
      "terminated": "Beendet",
      "diskconfigured": "Disk konfiguriert ",
      "diskconfiguration": "Disk-Konfiguration",
      "diskcreated": "Disk erstellt",
      "diskcreation": "Création du disque",
      "diskdeleted": "Disk gelöscht",
      "diskdeletion": "Disk Löschung",
      "configurationcreation": "Konfigurationserstellung",
      "configurationcreated": "Konfiguration erstellt",
      "configurationdeletion": "Löschung der Konfiguration",
      "configurationdeleted": "Konfiguration gelöscht",
      "attach-ip": "Anhängen ip",
      "detach-ip": "Trennen ip",
      "attach": "Anhängen",
      "attached": "Angegliedert",
      "detach": "Trennen",
      "detached": "Abgetrennt",
      "install": "Beenden",
      "installed": "Installiert",
      "uninstalled": "Deinstalliert",
      "unpaid": "In Erwartung der Zahlung",
      "load-iso": "ISO Loaded",
      "loadiso": "ISO Loaded",
      "unload-iso": "ISO Unloaded",
      "unloadiso": "ISO Unloaded",
      "up": "Up",
    },
    "requests": {
      "title": "Gewünschte Aktion ",
      "action": "Aktion ",
      "user": "Benutzer",
      "ip": "IP ",
      "create": "Erstellung",
      "delete": "Löschung",
      "stop": "Arrêt",
      "start": "Start",
      "reboot": "Neustart",
      "restore": "Restaurierung",
      "refresh": "Aktualisieren ",
      "hardstop": "Abbruch",
      "reset": "Reset",
      "terminate": "Kündigung",
      "attach": "Anhängen",
      "attached": "Angegliedert",
      "detach": "Trennen",
      "detached": "Abgetrennt",
      "install": "Installieren",
      "installed": "Installiert",
      "rename": "Umbenennung",
      "decrease": "Abnahme",
      "increase": "Erhöhung",
      "update": "Aktualisieren"
    },
    "status": {
      "title": "Stand :",
      "success": "Erfolg",
      "error": "Fehler",
    },
    "entities": {
      "changestate": "Server",
      "serverupdate": "Server",
      "serverdeletion": "Server",
      "servercreation": "Server",
      "serveraddons": "Server",
      "serverreset": "Server",
      "ipkvm": "KVM IP",
      "snapshot": "Snapshot",
      "network": "Privates Netzwerk",
      "storageupdate": "Storage pool"
    },
    "pending-actions": {
      "key": "Aktion(en) bis zum Neustart : ",
      "info": "Sie müssen über die Weboberfläche neu starten",
      "addons": "Installation von Addon(s)",
      "load_iso": "ISO-Montage",
      "unload_iso": "ISO-Demontage",
      "notification": "Aktualisierung",
    },
    "notifications": {
      "addons": "Bitte starten Sie Ihren Server über die Benutzeroberfläche neu, damit die Addons wirksam werden.",
      "mount_iso": "Bitte starten Sie Ihren Server über die Benutzeroberfläche neu und starten Sie Ihren Fernzugriff.",
    },
    "server-page": "Server Seite",
    "no-addons": "Keine Aktion möglich. Die Rechnung dieses Servers ist unbezahlt.",
    "gauge": {
      "menu": {
        "title": "Modelle",
        "model": {
          "bar": "Bar",
          "arc": "Bogen",
          "circle": "Kreis",
          "stroke": "Striche",
        },
        "change-model": "Modell wechseln",
      },
      "save": {
        "button": {
          "save-server": "Servereinstellungen",
          "save-user": "Benutzereinstellungen",
        },
        "tooltip": {
          "save-server": "Als Einstellungen für diesen Server speichern",
          "save-user": "Als Standardeinstellungen speichern",
        },
        "message": {
          "successful": "Sicherung erfolgreich",
          "unsuccessful": "Speichern fehlgeschlagen",
        },
      },
    },
    "chart": {
      "activity": "Aktivität {type} ({unit})",
      "tooltip": [
        "Hineinzoomen",
        "Herauszoomen",
        "Aktualisieren",
        "Bereich nicht ausfüllen",
        "Bereich ausfüllen"
      ]
    },
    "tooltips": {
      "delete": "Sofortige Serverlöschung",
      "show-company": "Unternehmensinformationen anzeigen",
    },
    "drp-text": [
      "Aufgrund der großen Beliebtheit unseres Notfallwiederherstellungsplans (DRP) bedauern wir, Ihnen mitteilen zu müssen, dass dessen Aktivierung vorübergehend nicht verfügbar ist.",
      "Bitte versuchen Sie es später erneut oder kontaktieren Sie unser technisches Support-Team für sofortige Hilfe.",
      "Wir entschuldigen uns für etwaige Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.",
      "Mit freundlichen Grüßen,",
      "Das Titan SmallCloud Team",
      "+33(0)9 88 99 88 20"
    ],
    "alert-text": [
      "Diese Funktion ist vorübergehend außer Betrieb.",
      "Wir arbeiten aktiv daran, sie so schnell wie möglich wiederherzustellen.",
      "Wir entschuldigen uns für etwaige Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.",
      "Mit freundlichen Grüßen,",
      "Das Titan SmallCloud-Team",
      "+33(0)9 88 99 88 20"
    ],
    "uptime": {
      "title": "Verfügbarkeit",
      "down": "Down",
      "up": "Up",
      "pending": "Ausstehend",
      "checkEverySecond": "Überprüfe alle {0} Sekunden",
      "days": "Tage",
      "hours": "Stunden",
      "latency": "Latenz",
    },
  },
  "event_log": {
    "requests": {
      "title": "Gewünschte Aktion ",
      "action": "Aktion ",
      "user": "Benutzer",
      "server": "Server ",
      "old_event": "Alte Veranstaltung"
    },
    "type": {
      "network": "Netzwerk",
      "ip": "IP-Adresse",
      "iso": "ISO",
      "configuration": "Konfiguration",
      "snapshot": "Schnappschuss",
      "note": "Notiz",
      "image": "Bild",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Konsole",
      "storage": "Scheibe",
      "vswitch": "Vswitch",
      "addons": "Erweiterung",
      "state": "Bundesland",
      "ipkvm": "KVM",
      "changestate": "Server - Änderungen",
      "servercreation": "Server - Schaffung",
      "createserver": "Server - Schaffung",
      "serverdeletion": "Server - Streichung",
      "serverupdate": "Server - Aktualisieren",
      "serveraddons": "Server - Addon(s)",
      "migration": "Hypervisor-Migration",
      "serveroperations": "Server - Operationen",
      "storageupdate": "Scheibe - Aktualisieren",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentifizierung",
      "material": "Material",
      "hypervisor": "Hypervisor",
      "payment_method": "Zahlungsart",
      "company": "Unternehmen",
      "user": "Benutzer",
      "invitation": "Einladung",
      "role": "Rolle",
      "cron": "Aufgabe",
      "accounting_code": "Kontonummer",
    },
    "subtype": {
      "network": "Netzwerk",
      "ip": "IP-Adresse",
      "iso": "ISO",
      "configuration": "Konfiguration",
      "snapshot": "Schnappschuss",
      "note": "Notiz",
      "image": "Bild",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Konsole",
      "storage": "Scheibe",
      "vswitch": "Vswitch",
      "addons": "Erweiterung",
      "state": "Bundesland",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentifizierung",
      "material": "Material",
      "hypervisor": "Hypervisor",
      "payment_method": "Zahlungsart",
      "company": "Unternehmen",
      "user": "Benutzer",
      "invitation": "Einladung",
      "role": "Rolle",
      "cron": "Aufgabe",
      "accounting_code": "Kontonummer",
    },
    "action": {
      "load": "Geladen",
      "unload": "Entladen",
      "mount": "Montiert",
      "unmount": "Unmontiert",
      "create": "Schaffung",
      "creation": "Schaffung",
      "created": "Erstellt",
      "attach": "Anhang",
      "attached": "Angebracht",
      "detach": "Ablösung",
      "detached": "Losgelöst",
      "start": "Anfang",
      "started": "Gestartet",
      "stop": "Halt",
      "stopping": "Anhalten",
      "stopped": "Gestoppt",
      "resume": "Fortsetzen",
      "resumed": "Wieder aufgenommen",
      "reset": "Zurücksetzen",
      "increase": "Ansteigen",
      "decrease": "Verringerung",
      "update": "Aktualisieren",
      "updated": "Aktualisieren",
      "cancel": "Annullieren",
      "delete": "Löschen",
      "deletion": "Streichung",
      "deleted": "Gelöscht",
      "upgrade": "Aktualisierung",
      "upgraded": "Aufgerüstet",
      "downgrade": "Downgraden",
      "downgraded": "Herabgestuft",
      "install": "Einrichtung",
      "installed": "Eingerichtet",
      "uninstall": "Deinstallation",
      "uninstalled": "Deinstalliert",
      "restore": "Wiederherstellen",
      "restored": "Restauriert",
      "rename": "Umbenennen",
      "renamed": "Umbenannt",
      "configuration": "Konfiguration",
      "configured": "Konfiguriert",
      "disable": "Deaktivieren",
      "disabled": "Deaktiviert",
      "enable": "Ermöglichen",
      "enabled": "Ermöglicht",
      "hardstop": "Harter Halt",
      "reboot": "Neustart",
      "suspend": "Aussetzen",
      "revoked": "Widerrufen",
      "activate": "Aktivieren",
      "activated": "Aktiviert",
      "deactivate": "Deaktivieren",
      "deactivated": "Deaktiviert",
    },
    "status": {
      "title": "Stand :",
      "success": "Erfolg",
      "error": "Fehler",
    },
    "messages": {
      "material_hypervisor_enable": "Hypervisor aktiviert",
      "material_hypervisor_disable": "Hypervisor deaktiviert",
      "authentication_two_fa_enabled": "2FA-Authentifizierung aktiviert",
      "authentication_two_fa_disabled": "2FA-Authentifizierung deaktiviert",
      "authentication_ssh_create": "SSH-Authentifizierung erstellt",
      "authentication_ssh_created": "SSH-Authentifizierung erstellt",
      "authentication_ssh_delete": "SSH-Authentifizierung gelöscht",
      "authentication_ssh_deleted": "SSH-Authentifizierung gelöscht",
      "authentication_ssh_update": "SSH-Authentifizierung aktualisiert",
      "authentication_ssh_updated": "SSH-Authentifizierung aktualisiert",
      "ip_attach": "IP-Anfrage zur Anbindung",
      "ip_detach": "IP-Anfrage zur Abtrennung",
      "ip_attached": "IP-Adresse angebunden",
      "ip_detached": "IP-Adresse abgetrennt",
      "ip_updated": "IP-Adresse aktualisiert",
      "ip_termination": "IP-Kündigungsanfrage",
      "ip_cancel_termination": "Abgebrochene IP-Kündigungsanfrage",
      "server_iso_mount": "Image-Mount",
      "server_iso_unmount": "Image aushängen",
      "server_iso_mounted": "Server-ISO-Image gemountet",
      "server_iso_unmounted": "Server-ISO-Image ausgehängt",
      "server_iso_create": "Server-ISO-Image erstellt",
      "server_iso_deletion": "Server-ISO-Image gelöscht",
      "server_addons_upgrade": "Server-Upgrade",
      "server_addons_downgrade": "Server-Downgrade",
      "server_network_enabled": "Servernetzwerk aktiviert",
      "server_network_disabled": "Server-Netzwerk deaktiviert",
      "server_configuration_creation": "Serverkonfiguration erstellen",
      "server_configuration_created": "Serverkonfiguration erstellt",
      "server_configuration_deletion": "Löschen der Serverkonfiguration",
      "server_configuration_deleted": "Serverkonfiguration gelöscht",
      "server_storage_creation": "Speicherplatzerstellung",
      "server_storage_created": "Speicherplatz erstellt",
      "server_storage_configured": "Speicherplatz konfiguriert",
      "server_storage_deletion": "Speicherplatz löschen",
      "server_storage_deleted": "Speicherplatz gelöscht",
      "server_termination": "Beendigungsanfrage",
      "server_cancel_termination": "Beendigungsanfrage abgebrochen",
      "server_state_started": "Server gestartet",
      "server_state_stopped": "Server angehalten",
      "server_state_resumed": "Server neu gestartet",
      "server_state_stopping": "Server stoppt",
      "server_start": "Server wird gestartet",
      "server_stop": "Serverstopp",
      "server_update": "Server aktualisiert",
      "server_updated": "Server aktualisiert",
      "server_renamed": "Server umbenannt",
      "server_create": "Server erstellt",
      "server_started": "Server gestartet",
      "server_stopped": "Server gestoppt",
      "server_resumed": "Server wieder aufgenommen",
      "server_stopping": "Server stoppt",
      "server_hardstop": "Erzwungener Serverstopp",
      "server_reboot": "Server neu starten",
      "storage_snapshot_create": "Snapshot-Erstellung",
      "storage_snapshot_restore": "Snapshot-Wiederherstellung",
      "storage_snapshot_deletion": "Snapshot löschen",
      "storage_snapshot_created": "Schnappschuss erstellt",
      "storage_snapshot_restored": "Snapshot wiederhergestellt",
      "storage_snapshot_deleted": "Schnappschuss gelöscht",
      "storage_snapshot_updated": "Schnappschuss aktualisiert",
      "storage_image_create": "Image erstellen",
      "storage_image_delete": "Bild löschen",
      "storage_image_created": "Bild erstellt",
      "storage_image_deleted": "Bild gelöscht",
      "storage_image_updated": "Bild aktualisiert",
      "storage_decreased": "Speicherplatz verringert",
      "storage_increased": "Speicherplatz erhöht",
      "note_updated": "Hinweis aktualisiert",
      "console_kvmip_start": "IpKvm-Konsole wird gestartet",
      "console_kvmip_stop": "IpKvm-Konsole stoppen",
      "console_kvmip_update": "IpKvm-Konsole aktualisiert",
      "console_kvmip_started": "IpKvm-Konsole gestartet",
      "console_kvmip_stopped": "IpKvm-Konsole gestoppt",
      "console_kvmip_stopping": "IpKvm-Konsole stoppt",
      "network_vswitch_attach": "Vswitch an Netzwerk anhängen",
      "network_vswitch_detach": "Trennung des Vswitch vom Netzwerk",
      "network_vswitch_attached": "Vswitch mit Netzwerk verbunden",
      "network_vswitch_detached": "Vswitch vom Netzwerk getrennt",
      "network_vswitch_create": "Vswitch erstellen",
      "network_vswitch_created": "Vswitch erstellt",
      "network_vswitch_delete": "Einen Vswitch löschen",
      "network_vswitch_deleted": "Vswitch gelöscht",
      "network_vswitch_update": "Vswitch-Update",
      "network_vswitch_updated": "Vswitch aktualisiert",
      "payment_method_created": "Zahlungsmethode hinzugefügt",
      "createserver": "Servererstellung",
      "metric_cpu_alert": "CPU-Aktivität",
      "metric_ram_alert": "RAM-Aktivität",
      "metric_disk_alert": "Festplattenaktivität",
      "metric_net_alert": "Netzwerkaktivität",
      "company_created": "Unternehmen erstellt",
      "company_update": "Unternehmensinformationen aktualisieren",
      "company_updated": "Unternehmensinformationen aktualisiert",
      "company_invitation_create": "Einladung erstellen",
      "company_invitation_created": "Einladung versendet",
      "company_invitation_delete": "Einladung löschen",
      "company_invitation_deleted": "Einladung gelöscht",
      "company_user_revoke": "Benutzer widerrufen",
      "company_user_revoked": "Benutzer widerrufen",
      "company_user_delete": "Benutzer löschen",
      "company_user_deleted": "Benutzer gelöscht",
      "company_user_join": "Versuch, dem Unternehmen beizutreten",
      "company_user_joined": "Der Benutzer ist dem Unternehmen beigetreten",
      "company_role_create": "Rolle erstellen",
      "company_role_created": "Rolle erstellt",
      "company_role_delete": "Rolle löschen",
      "company_role_deleted": "Rolle gelöscht",
      "company_role_update": "Rolle aktualisieren",
      "company_role_updated": "Rolle aktualisiert",
      "cron_reboot_create": "Planung einer Neustartaufgabe",
      "cron_reboot_created": "Neustartaufgabe geplant",
      "cron_reboot_delete": "Löschen einer Neustartaufgabe",
      "cron_reboot_deleted": "Neustartaufgabe gelöscht",
      "cron_reboot_update": "Aktualisierung einer Neustartaufgabe",
      "cron_reboot_updated": "Neustartaufgabe aktualisiert",
      "cron_reboot_activate": "Aktivierung einer Neustartaufgabe",
      "cron_reboot_activated": "Neustartaufgabe aktiviert",
      "cron_reboot_deactivate": "Deaktivierung einer Neustartaufgabe",
      "cron_reboot_deactivated": "Neustartaufgabe deaktiviert",
      "cron_snapshot_create": "Planung einer Snapshot-Aufgabe",
      "cron_snapshot_created": "Snapshot-Aufgabe geplant",
      "cron_snapshot_delete": "Löschen einer Snapshot-Aufgabe",
      "cron_snapshot_deleted": "Snapshot-Aufgabe gelöscht",
      "cron_snapshot_update": "Aktualisierung einer Snapshot-Aufgabe",
      "cron_snapshot_updated": "Snapshot-Aufgabe aktualisiert",
      "cron_snapshot_activate": "Aktivierung einer Snapshot-Aufgabe",
      "cron_snapshot_activated": "Snapshot-Aufgabe aktiviert",
      "cron_snapshot_deactivate": "Deaktivierung einer Snapshot-Aufgabe",
      "cron_snapshot_deactivated": "Snapshot-Aufgabe deaktiviert",
      "accounting_code_create": "Erstellung einer Kontonummer",
      "accounting_code_created": "Kontonummer erstellt",
      "accounting_code_delete": "Löschung einer Kontonummer",
      "accounting_code_deleted": "Kontonummer gelöscht",
      "api_token_create": "Erstellung eines API-Schlüssels",
      "api_token_created": "API-Schlüssel erstellt",
      "api_token_delete": "Löschung eines API-Schlüssels",
      "api_token_deleted": "API-Schlüssel gelöscht",
      "api_token_update": "Aktualisierung eines API-Schlüssels",
      "api_token_updated": "API-Schlüssel aktualisiert",
    },
  },
  "intervention": {
    "title": "Dienstleistungen"
  },
  "pages": {
    "devis": {
      "title": "Zitat",
      "number": "Nummer",
      "commercial": "kommerziell ",
      "created_at": "Erstellt am",
      "expire_at": "Läuft ab am",
      "amount": "Betrag",
      "tooltip-download": "pdf herunterladen",
      "tooltip-cart": "In den Warenkorb",
      "empty": "Im Moment gibt es kein Angebot.",
      "tooltip-accept": "Angebot akzeptieren",
      "validate_at": "Angenommen am ",
      "accept": "Annehmen",
      "invoice_number": "Rechnungsnummer",
      "details": "Angebotsnummer ",
      "quote_date": "Datum zitieren ",
      "quote_validity": "Gültig bis ",
      "create_quote": "Erstellen Sie ein Angebot",
      "company": "Unternehmen",
      "tooltip-delete": "Zitat löschen",
    },
    "lang": "de",
    "ip-histo": {
      "empty": "Die Geschichte ist leer.",
      "company_uuid": "Gesellschaft",
      "server_uuid": "Server",
      "start_date": "Startdatum",
      "end_date": "Endtermin"
    },
    "login": {
      "title": "Anmelden",
      "email-label": "Email",
      "password-label": "Passwort",
      "login-action": "Anmelden",
      "email-not-validated": "Bitte bestätigen Sie Ihre E-Mail, bevor Sie sich anmelden",
      "email-confirmation-sent": "Eine Bestätigungs-E-Mail wurde an Sie gesendet (Bitte überprüfen Sie auch Ihre Spam-Mails).",
      "promotional_code_saved": "Aktionscode wurde gespeichert."
    },
    "login2fa": {
      "title": "Doppelfaktor-Authentifizierung",
      "tfa-label": "Sicherheitscode"
    },
    "register": {
      "company_invite_title": "Willkommen bei Titan SmallCloud! Um Ihr Konto abzuschließen, geben Sie uns bitte einige Informationen über sich selbst. Diese Informationen werden vertraulich behandelt und Sie können uns jederzeit bitten, sie zu löschen.",
      "title": "Um Ihre Instanzen bereitzustellen und Ihnen den besten Service zu bieten, benötigen wir weitere Informationen über Sie. Diese Informationen werden vertraulich behandelt und Sie können uns jederzeit bitten, diese zu löschen.",
      "action": "Konto erstellen",
      "billing-address": "Rechnungsadresse",
      "modal": {
        "title": "Sie haben die zuvor eingegebenen Informationen geändert.",
        "danger": "",
        "message": "",
        "button": "Bestätigen Sie",
        "extra": "Können Sie diese Änderungen bestätigen?",
      },
    },
    "dashboard": {
      "status-map": "Statuskarte TITAN",
      "status-page": "Status",
      "favorite": "Deine Favoriten",
      "notifications": "Notifications",
      "overview-server": "Übersicht Server",
      "status": {
        "bandwidth": "Bandbreite",
        "v4-peers-visibility": "Sichtbarkeit von v4-Peers",
        "v6-peers-visibility": "Sichtbarkeit von v6-Peers",
        "ping-latency": "Latenz"
      },
      "weather-cpu-ram": "CPU / RAM",
      "weather-public-network": "Public Network (WAN)",
      "weather-private-network": "Private Network (LAN)",
      "weather-storage": "Speicher",
      "pending_servers": "Servers warten auf Konfiguration",
      "last_op": "Letzte Vorgänge",
      "title-tickets": "Ihre letzten Tickets",
      "title-history": "Ihre letzten Aktionen",
      "weather-ras": "OK",
      "weather-degraded": "Degradiert",
      "weather-failure": "Kritisch",
      "started-server": "Gestartete Server : ",
      "stopped-server": "Gestoppte Server : ",
      "other-server": "Wartende Server : ",
      "modal": {
        "you_will_be_redirected_to": "Sie werden weitergeleitet zu",
        "status_link": "https://titandc.statuspage.io/",
      },
      "popup": [
        "Titan Datacacenter-Status"
      ],
    },
    "servers": {
      "mac": "MAC Adresse",
      "plan": {
        "SC1": "SC1",
        "SC2": "SC2",
        "SC3": "SC3"
      }
    },
    "server-new": {
      "devis-mode": {
        "false": "Activate the quote generation mode",
        "true": "Deactivate the generation mode of an estimate",
      },
      "title": "Ihren Server konfigurieren",
      "choose-managed": "Wählen Sie Ihren Netzwerkverwaltungsmodus",
      "choose-plan": "Wählen Sie Ihre Ressourcen",
      "choose-os": "Wählen Sie Ihr Betriebssystem",
      "setup-ssh": "Die Server-Verbindung konfigurieren",
      "licenses": "Lizens",
      "bandwidth": "Bandbreite",
      "managed-true": "Verwaltetes Netzwerk",
      "managed-false": "Dediziertes Netzwerk",
      "managed-true-text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.",
      "managed-false-text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "iops": "QoS Disk : ",
      "ssh-password": "Passwort-Anmeldung",
      "ssh-password-input": "Ein Passwort eingeben",
      "ssh-key": "SSH-Schlüsselverbindung",
      "ssh-key-input": "Geben Sie Ihren Schlüssel ein um sich anzumelden ",
      "ssh-key-public-msg": "Sie müssen angemeldet sein, um die SSH-Schlüssel zu verwenden.",
      "ssh-key-hint": "Geben Sie Ihren öffentlichen SSH-Schlüssel ein.",
      "ssh-key-placeholder": "Öffentlicher Schlüssel",
      "confirm": "Bestätigung",
      "finalize": "Bestellung abschließen",
      "order-plan": "Bestellung eines Servers",
      "order-with": "mit dem Betriebssystem",
      "order-preinstalled": "vorinstalliert",
      "order-button": "Bestellung für ",
      "month": "Monat vor Steuer",
      "duty-free": "vor Steuern*",
      "recurring_description": "* Der Betrag wird zur aktuellen periodischen Abrechnung hinzugefügt",
      "tooltip": "Geben Sie ein Passwort oder einen SSH-Schlüssel ein",
      "tooltip_password": "Geben Sie ein Passwort",
      "tooltip_windows": "Windows ist ab SC2 verfügbar.",
      "tooltip_windows_to_windows": "Windows kann nur zu Window migrieren.",
      "tooltip_license": "Sie können die Art der Lizenz nicht ändern.",
      "tooltip_add": "Zum Warenkorb hinzufügen",
      "tooltip_packetfence": "PacketFence ist nur auf einem SC3 verfügbar.",
      "order-count": "Sie haben keine Bestellung... | Sie haben eine Bestellung im Einkaufswagen... | Sie haben {nb} Bestellungen im Einkaufswagen...",
      "order-added": "Bestellung hinzugefügt...",
      "order-cart": "Einkaufswagen ansehen",
      "order-reset": "Einkaufswagen leeren",
      "version-placeholder": "Version",
      "plesk-title": "Auswahl an Plesk-Lizenzen",
      "plesk-selection": "Ihre Plesk-Lizenz auswählen :",
      "unit-price": "Preis pro Einheit :",
      "os": "Betriebssystem",
      "qty": "Mg. :",
      "pwd_hint": "Wählen Sie ein Passwort und / oder einen SSH-Schlüssel",
      "pwd_and_ssh": "Wählen Sie einen SSH-Schlüssel und / oder ein Passwort",
      "cpu-desc": " ",
      "ram-desc": " ",
      "disk-desc": " ",
      "next-step-btn": "Prochaine étape",
      "promo_code": "Code de réduction",
      "unit": {
        "from_to_gb": "{ from } GB auf { to } GB",
        "from_to_cpu": "{ from } vCPU auf { to } vCPU",
      },
    },
    "server-detail": {
      "ips": "IP-Adressen",
      "note": "Bemerkung",
      "note-edit": "Bemerkung bearbeiten",
      "note-save": "Bemerkung speichern",
      "note-help": "Styling with Markdown is supported (Hilfe, indem Sie hier klicken)",
      "note-default": `Dieser Server wurde installiert mit **{template}** am {creation_date}`,
      "note-empty": "Keine Notizen",
      "activity": "Aktivität",
      "activity-empty": "Noch keine Aktivität.",
      "graph-reset": "Graph-Reset",
      "network-activity": "Netzwerkaktivität",
      "ram-activity": "RAM Aktivität",
      "cpu-activity": "CPU Aktivität",
      "disk-activity": "Speicherplatz",
      "Vswitch-activity": "Vswitch-Aktivität",
      "history": "Verlauf",
      "last-activity": "Letzte Aktivitäten",
      "server-history": "Server Verlauf",
      "no_ip": "Keine IP-Adresse",
      "snapshots": "Snapshots",
      "snapshot-create": "Einen Snapshot erstellen",
      "snapshot-restore": "Restaurieren",
      "snapshot-delete": "Löschen",
      "snapshot-to-template": "Vorlage",
      "snapshot-to-template-modal": {
        "title": "VErzeugen eines Bildes aus diesem Schnappschuss",
        "name": "Name des Bildes"
      },
      "snapshot-empty": "Kein Snapshot…",
      "snapshot-error": "Der Snapshot ist fehlgeschlagen",
      "terminate-success": "Kündigung des Servers im Gange.",
      "terminate-canceled": "Kündigung des Servers abgebrochen",
      "modal-server-downgrade-nothing": "Keine herunterzustufenden Addons.",
      "modal-need-stop-title": "Server-Reset",
      "modal-need-stop-text": "Abschaltung des Servers erforderlich",
      "modal-need-stop-description": "Sie müssen Ihren Server stoppen, um diese Aktion auszuführen.",
      "modal-need-stopped-description": "Bitte warten Sie, bis Ihr Server abgeschaltet ist, um diese Aktion durchzuführen.",
      "modal-server-upgrade-description": "Dieses Upgrade ist erst nach einem Serverneustart abgeschlossen, der über das Dashboard ausgelöst wird.",
      "modal-mount-cdrom-title": "CD-ROM einhängen",
      "modal-mount-cdrom-text": "Eine CD-ROM kann als Remote-ISO-Datei auf den Server geladen werden. Der Server startet dann zuerst von der CD-ROM, bis die CD-ROM wieder ausgehängt wird (Neustart erforderlich). Die Konfiguration Ihrer ISO erfolgt über den KVM.",
      "modal-mount-cdrom-label": "URL des zu ladenden bootfähigen ISOs (nur https):",
      "modal-mount-cdrom-list-label": "Vordefinierte ISO-Liste",
      "modal-mount-cdrom-list-placeholder": "— Wählen Sie ein ISO —",
      "modal-unmount-cdrom-title": "CD-ROM demontieren",
      "modal-unmount-cdrom-text": "Mit dieser Aktion wird die zuvor eingelegte CD-ROM wieder ausgehängt. Wenn das Gerät gerade von der CD-ROM gebootet wird, ist ein erzwungenes Herunterfahren des Geräts erforderlich, damit das Aushängen wirksam wird..",
      "modal-server-downgrade-description": "Die Entfernung von Addons wird sofort nach der Validierung durchgeführt.",
      "see-all-server": "Server anzeigen",
      "terminate-descriptions-title": "Was ist der Grund für diese Kündigung?",
      "terminate-descriptions-placeholder": "— Gründe dafür —",
      "terminate-descriptions": {
        "over": "Das mit diesem Produkt verbundene Projekt ist beendet.",
        "unsuitable": "Das Produkt ist für meine Bedürfnisse ungeeignet.",
        "quality": "Die Servicequalität (Pannen, Verlangsamungen usw.) entspricht nicht meinen Erwartungen.",
        "assistance": "Durch die Online-Unterstützung (Kundendienst, häufig gestellte Fragen, Support usw.) kann ich meine Dienste nicht ordnungsgemäß verwalten.",
        "expensive": "Produkte sind zu teuer.",
        "loyalty": "Mangelnde Anerkennung (Treueprogramm, sinkende Preise usw.).",
        "mistake": "Ich habe bei der Bestellung einen Fehler gemacht (falsche Ressourcen, doppelte Bestellung usw.).",
      },
      "goto_images": "Meine Bilder",
      "modal-addon-exists": "Ein Upgrade wurde bereits in den Einkaufswagen gelegt",
      "modal-actual-addon": "(Aktuelle { quantity } { type })",
      "modal-none": "Keine",
      "addon-added": "Addons zum Warenkorb hinzugefügt",
      "modal-no-ip": [
        "Serverkündigung nicht möglich",
        "Die Kündigung ist nur möglich, wenn der Server mindestens eine angehängte IPv4-Adresse hat.",
        "Diese IPv4 muss eine entsprechende IPv6 haben, die nicht an einen anderen Server angehängt ist und Teil Ihres Adresspools ist.",
        "Bitte gehen Sie zu Ihrer Netzwerkschnittstelle und hängen Sie eine IPv4-Adresse an diesen Server an.",
        "Danke.",
      ],
    },
    "faq": {
      "title": "FAQ",
      "updated": "Update"
    },
    "payment-list": {
      "create-payment": "Eine Zahlung erstellen",
      "date": "Datum",
      "number": "Zahlungsnummer",
      "ref": "Referenz",
      "facture": "Zugehörige Rechnungen",
      "mode": "Reguliert von",
      "total-amount": "Zahlungsbetrag",
      "charged-amount": "Angerechneter Betrag",
      "unused-amount": "Nicht verwendeter Betrag",
      "client_code": "Kundennummer",
      "client": "Kunde",
      "no_payment": "Keine Zahlungen gefunden",
      "modes": {
        'VSEPA': "Übertragung",
        'PSEPA': "Durchflussmenge",
        'CB': "CB",
        'CREDIT': "Kredit",
        'UNPAID': "Unbezahlt",
      },
      "states": {
        'title': "Status",
        'waiting': "Anhängig",
        'processing': "Verarbeitung",
        'succeeded': "Erfolg",
        'success': "Erfolg",
        'error': "Fehler",
        'canceled': "Abgesagt",
        'requires_action': "Aktion",
      },
      "date-range": {
        'month': 'Monat',
        'all': 'Alle',
        'last-month': 'Letzten Monat',
        'last-quarter': 'Letztes Vierteljahr',
        'format': 'yyyy/MM/dd',
        'apply': 'Anwenden',
        'cancel': 'Abbrechen',
        'weekLabel': 'W',
        'customRange': 'Benutzerdefinierten Bereich',
        'daysOfWeek': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'monthNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        'monthNamesFull': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      }
    },
    "account": {
      "vat": " USt-IdNr. ",
      "title": "Kontoeinstellungen",
      "personal_infos": "Persönliche Informationen",
      "civility": "Titel",
      "civility-mr": "Herr",
      "civility-mme": "Frau",
      "civility-mx": "Mx",
      "first-name": "Vorname",
      "last-name": "Nachname",
      "name": "Name",
      "email": "Email",
      "phone": "Telefon",
      "phone-country": "Ländercode",
      "phone-country-error": "Wählen Sie ein Land",
      "phone-ex": "Beispiel:",
      "mobile": "Mobiltelefon",
      "add-phone": "Eine Telefonnummer hinzufügen",
      "save": "Speichern",
      "languages": "Sprache",
      "interface-mode": "Interface-Farbe",
      "current-password": "Jetziges Passwort",
      "new-password": "Neues Passwort",
      "confirm-new-password": "Neues Passwort bestätigen",
      "password": "Passwort",
      "confirm-password": "Kennwort bestätigen",
      "settings": "Einstellungen",
      "company_infos": "Firmeninformationen",
      "company-name": "Firmenname",
      "company-position": "Titel",
      "company-id": "D-U-N-S",
      "tva-number": "Die Umsatzsteuer-Identifikationsnummer",
      "address": "Adresse",
      "address-name": "Name",
      "address-street": "Straße",
      "address-postal-code": "Postleitzahl",
      "address-city": "Stadt",
      "address-country": "Land",
      "add-address": "Eine Adresse hinzufügen",
      "password-mismatch": "Passwort und Passwortbestätigung stimmen nicht überein",
      "recaptcha-error": "Sie müssen das Recaptcha bestätigen.",
      "password-hint": "Ihr Passwort muss 8 Zeichen enthalten (Großbuchstaben, Kleinbuchstaben, Zahlen).",
      "legal-info": "Durch die Erstellung Ihres Kontos profitieren Sie von den Dienstleistungen von Titan Datacenters Frankreich. Die über dieses Formular übermittelten Daten werden mit Ihrer Zustimmung erhoben und sind für Titan Datacenters Frankreich als Datenverantwortliche bestimmt. Diese Informationen und die Ihrer Bestellungen werden hauptsächlich zum Anlegen und Verwalten Ihres Kontos, zur Verwaltung Ihrer Bestellungen und deren Nachverfolgung, zur Personalisierung Ihrer Dienste, zur Betrugsverhinderung  und statistischen Analyse sowie zu Marketing- und gezielten Werbezwecken verarbeitet. Diese Verarbeitung basiert auf unterschiedlichen Rechtsgrundlagen, je nach den betreffenden Zwecken.",
      "legal-notice": "Impressum",
      "privacy-policy": "Datenschutzrichtlinie",
      "discount": "Abonnement-Typ",
      "auto_debit": "Abhebung am Monatsende:",
      "disabled": "Unternehmen deaktiviert:",
      "renew_with_credits": "Verwenden Sie Credits, wenn Sie Abonnements erneuern:",
      "reset": "Zurücksetzen",
      "affiliate_infos": "Partnerprogramm (optional)",
      "affiliate-code": "Partnercode (optional)",
      "code": "Codiert",
      "status": "Status",
      "balance": "Gleichgewicht",
      "vatConfirmed": "Umsatzsteuernummer bestätigt",
      "vatNotConfirmed": "Ungültige Mehrwertsteuer noch nicht bestätigt",
      "modal": {
        "title": "Kontakt",
        "message_invalid": "Ungültige Daten",
        "message_error": "Fehler beim Speichern",
        "message_success": "Kontakt erfolgreich aktualisiert",
      },
      "send_invoice": "Automatischer Versand der Rechnungen per E-Mail (Super Admin + Buchhalter)",
      "creation_date": "Erstellt am",
      "update_date": "Aktualisiert am",
      "oid": "OID",
      "affiliation_code": "Affiliate-Code",
      "server-detail-closed": "Serverdetails Geschlossen",
      "server-detail-help": "In der Serverprofilansicht das Panel mit den detaillierten Informationen offen oder geschlossen lassen",
      "create-new-company": "Ein neues Unternehmen erstellen",
      "invoice_payment_grouped": "Einzelzahlung für mehrere Rechnungen",
      "sms_alert": "Genehmigung zum Senden von SMS",
    },
    "account-ssh": {
      "title": "SSH Schlüssel",
      "management": "Meine SSH-Schlüssel verwalten",
      "ssh-key-name": "Name",
      "ssh-key-usage": "Anzahl der Verwendungen",
      "ssh-key-value": "SSH Schlüssel",
      "ssh-key-add": "Einen SSH Schlüssel hinzufügen"
    },
    "account-api": {
      "title": "API Schlüssel",
      "api-keys": "API Schlüssel",
      "api-key-name": "Name",
      "api-key-token": "API Schlüssel",
      "api-key-expire": "Gültigkeitsdatum",
      "api-key-add": "API-Schlüssel generieren"
    },
    "account-2fa": {
      "title": "Zwei-Faktoren-Authentifizierung (2FA)",
      "actions": {
        "enable": "2FA aktivieren",
        "disable": "Deaktivieren Sie die 2FA",
      },
      "enable": {
        "step1": {
          "title": "Speichern Sie den geheimen Wiederherstellungsschlüssel",
          "text": "Bitte schreiben Sie Ihren Wiederherstellungsschlüssel sorgfältig hier unten ein. Dieser ist nützlich, wenn Sie z. B. Ihr Telefon verlieren.."
        },
        "step2": {
          "title": "Scannen Sie den QR-Code mit einer TOTP-Anwendung.",
          "text": "Scannen Sie einfach den QR-Code unten mit Ihrer bevorzugten TOTP-Anwendung (Authy, Google Authenticator, etc.) und geben Sie den QR-Code in das Feld unten ein.)"
        },
        "step3": {
          "title": "Geben Sie den 6-stelligen Autorisierungscode ein",
          "text": "Bitte geben Sie unten den 6-stelligen Code ein, der auf Ihrer TOTP-Anwendung erscheint (Beeilen Sie sich, die Codes haben eine begrenzte Lebensdauer). "
        },
        "step4": {
          "title": "Scannen Sie den QR-Code mit einer TOTP-Anwendung.",
          "text": "Il vous suffit de scanner le QR code ci-dessous à l'aide de votre application de TOTP favorite (Authy, Google Authenticator, etc.)"
        }
      },
      "disable": {
        "title": "Zwei-Faktor-Authentifizierung deaktivieren",
        "text": "Die Zwei-Faktor-Authentifizierung (2FA) ist derzeit für Ihr Konto aktiviert. Um sie zu deaktivieren, geben Sie bitte Ihr Passwort und den 6-stelligen Authentifizierungscode ein, der auf Ihrer TOTP-Anwendung angezeigt wird.",
        "warning": "Ihr aktueller Wiederherstellungscode wird ungültig. Wenn Sie die 2FA später wieder aktivieren möchten, wird Ihnen ein neuer Wiederherstellungscode zugeschickt."
      }
    },
    "account-preferences": {
      "title": "Konto-Einstellungen",
      "menu-1": "Ihre gespeicherten Einstellungen",
      "cartModal": "Übersicht der Bestellung :",
      "show": "Anzeigen,",
      "hide": "Maskieren",
      "sorting-col": "Sortierspalte :",
      "sorting-dir": "Sortierrichtung :",
      "invoices": "Rechnungen anzeigen :",
      "recurrings": "Anzeigen der Abonnements :",
      "servers": "Server Display :",
      "tooltips-hide": "Um Ihre Bestellübersicht erneut anzuzeigen, gehen Sie bitte in die Rubrik \"Präferenzen\" Ihrer Kontoeinstellungen.",
      "sms-alert": "Benachrichtigungen per SMS erhalten",
      "sms-help": "Um SMS zu erhalten, muss die Sendeberechtigung im Tab 'Benachrichtigungen' der Unternehmensseite aktiviert sein. Stellen Sie sicher, dass Sie eine gültige Telefonnummer eingegeben haben.",
    },
    "network": {
      "title": "Network",
      "title-switch": "Vswitch",
      "title-ips": "IP-Pool",
      "no_ip": "Keine IP-Adresse",
      "no_vswitch": "Kein Vswitch",
      "help": "-",
      "name": "Name",
      "ports": "Ports",
      "speed": "Geschindigkeit",
      "mtu-max": "MTU max",
      "hostname": "Hostname",
      "comment": "Kommentar",
      "plan": "Plan",
      "mac": "Mac Adresse",
      "cidr": "CIDR",
      "gateway": "Gateway",
      "connected-servers": "Angeschlossene Server",
      "connect-button": "Einen Server anschließen",
      "add-network": "Einen Vswitch hinzufügen",
      "add-vm": "Einen Server anschließen",
      "add-success": "Vswitch erfolgreich erstellt",
      "delete-network": "Vswitch löschen",
      "cant-delete-network": "Es ist nicht möglich, diesen vswitch zu löschen. Server sind daran angehängt",
      "delete-vm": "Diesen Server abtrennen",
      "delete-success": "Vswitch erfolgreich gelöscht",
      "title-add-server": "Einen Server anschließen",
      "server-attach-success": "Server erfolgreich hinzugefügt",
      "server-detach-success": "Server erfolgreich entfernt",
      "order-count": "Sie haben keine Bestellung... | Sie haben {nb} IPs im Warenkorb... | Sie haben {nb} IPs im Warenkorb...",
      "order-added": "Bestellung hinzugefügt...",
      "order-cart": "Warenkorb",
      "order-reset": "Leeren Sie den Warenkorb",
      "modal-network-delete-title": "Dieses private Netzwerk löschen",
      "modal-network-delete-description": "Durch Bestätigen dieser Aktion wird dieser V-Switch gelöscht.",
      "modal-network-delete-confirm": "Sind Sie sicher, dass Sie diesen V-Schalter entfernen möchten?",
      "modal-server-detach-title": "Diesen Server abtrennen",
      "modal-server-detach-description": "Wenn Sie diese Aktion bestätigen, wird dieser Server vom V-Switch abgetrennt.",
      "modal-server-detach-confirm": "Sind Sie sicher, dass Sie diesen Server trennen wollen ?",
      "modal-server-detach-ip-title": "IP {ip} abtrennen",
      "modal-server-detach-ip-description": "Durch Bestätigung dieser Aktion wird diese IP vom Server abgetrennt.",
      "modal-server-detach-ip-confirm": "Sind Sie sicher, dass Sie diese IP abtrennen möchten?",
      "modal-server-detach-ip-warning": "Sie können diese IP mit einem anderen Server verbinden.",
      "modal-server-delete-ip-title": "IP {ip} kündigen",
      "modal-server-delete-ip-description": "Durch Bestätigung dieser Aktion wird diese IP gekündigt.",
      "modal-server-delete-ip-confirm": "Sind Sie sicher, dass Sie diese IP kündigen möchten?",
      "modal-server-delete-ip-warning": "Diese Aktion kann rückgängig gemacht werden.",
      "modal-network-managed-title": "Verwaltetes Netzwerk",
      "modal-network-managed-label-name": "Name des Networks",
      "modal-network-managed-label-gateway": "Gateway",
      "modal-network-managed-label-cidr": "CIDR",
      "reset-network": "Setzen Sie die Netzwerkschnittstelle des Servers zurück",
      "update": "Vswitch aktualisieren",
      "reset": "Zurücksetzen",
      "add-ip": "Eine IP hinzufügen",
      "goto-server": "Zum Server gehen",
      "detach-ip": "Diese IP abtrennen",
      "attach-ip": "Diese IP anhängen",
      "server-ip-attach-success": "IP erfolgreich angehängt",
      "server-ip-detach-success": "IP erfolgreich abgetrennt",
      "server-search": "Nach einem Server suchen. Doppelklick, um die Liste anzuzeigen.",
      "update-reverse": "Reverse aktualisieren",
      "open-port-list": "Portliste anzeigen",
      "close-port-list": "Portliste schließen",
      "ip-delete-success": "IP-Kündigung erfolgreich",
      "ip-delete-failed": "IP-Kündigung nicht erfolgreich",
      "ip-cancel-delete-success": "Kündigung der IP erfolgreich storniert",
      "ip-added-to-cart": "IP zum Warenkorb hinzugefügt",
      "ip-already-in-cart": "IP bereits im Warenkorb",
      "ip-check": "Überprüfungen für diese Adresse durchführen",
      "ip-summary": {
        "title": "Zusammenfassung",
        "header": [
          "Angeheftete IPs",
          "IPv4",
          "IPv6",
          "Verfügbare IPs",
        ],
      },
      "title-choose-subscription": "Abonnement auswählen",
      "subtitle-choose-subscription": "Wählen Sie das Abonnement aus, für das die IP gekündigt wird",
      "subscription-search": "Suche nach einem Abonnement. Doppelklick, um die Liste anzuzeigen.",
      "drp": "DRP",
      "show-chart": "Aktivität anzeigen",
      "hide-chart": "Aktivität ausblenden",
    },
    "company": {
      "tabs": {
        "info": "Information",
        "users": "Benutzer",
        "payments": "Zahlungsmittel",
        "reglements": "Verordnungen",
        "historic": "Historisch",
        "notifications": "Benachrichtigungen",
        "piggy": "Guthaben",
      },
      "title": "Firma",
      "title-info": "Informationen zur Firma",
      "title-users": "Benutzer und Funktionen",
      "title-user-invite": "Einen Benutzer einladen",
      "title-role-create": "Rolle erstellen",
      "title-role-update": "Rolle aktualisieren",
      "title-authorization-update": "Berechtigungen aktualisieren",
      "title-notifications": "Benachrichtigungsverwaltung",
      "title-piggy": "Nutzung der Guthaben",
      "address-billing": "Rechnungsadresse",
      "address-shipping": "Lieferadresse",
      "address-copy": "Verwenden Sie diese Adresse als Ihre Lieferadresse.",
      "company": "Firma",
      "save-success": "Erfolgreich gespeicherte Informationen",
      "user-add": "Einen Benutzer einladen",
      "user-add-success": "Einladung erfolgreich an den Benutzer gesendet",
      "user-revoke-success": "Benutzer erfolgreich widerrufen",
      "invitation-delete-success": "Einladung mit Erfolg widerrufen",
      "user-empty": "Bitte laden Sie einen Benutzer ein.",
      "user-role-change-success": "Die Funktion des Benutzers wurde erfolgreich geändert",
      "user-filter-place-holder": "Einen Benutzer suchen...",
      "complete-company-error": "Bitte vervollständigen Sie zuerst Ihre Firmendaten (Name, Adresse und Umsatzsteuer-Identifikationsnummer).",
      "same_address": "Liefer- und Rechnungsadresse sind gleich",
      "item_added": "Artikel zum Warenkorb hinzugefügt",
      "piggy": {
        "amount": "Anzahl verbleibender Guthaben",
        "provisioning": "Automatische Bereitstellung",
        "add-pack": "Paket hinzufügen",
        "header": [
          "Erstellung",
          "Nutzung",
          "Firma",
          "Benutzer",
          "Anzahl der Gutschriften",
          "Gesendet an",
        ],
      },
    },
    "tickets": {
      "title": "Tickets",
      "title-new": "Ein neues Ticket öffnen",
      "service": "Betroffener Dienst",
      "server": "Betroffener Server",
      "object": "Betreff des Tickets",
      "object-placeholder": "Geben Sie hier Ihr Betreff ein",
      "message": "Ihre Nachricht",
      "message-placeholder": "Geben Sie hier Ihre Nachricht ein.",
      "add-ticket": "Neues Ticket",
      "send-ticket": "Senden",
      "sending-ticket": "Versenden",
      "close-ticket": "Das Ticket schließen",
      "close-ticket-force": "Schließen ohne Antwort",
      "cancel": "Zurrück zur Liste",
      "close-text": "Haben wir alle Ihre Fragen beantwortet?",
      "ticket-added": "Ticket hinzugefügt…",
      "ticket-added-text": "Unser Team hat Ihre Anfrage berücksichtigt.",
      "chat": "Chat",
      "level": "Priorität",
      "levels": {
        "low": "Niedrig",
        "medium": "Normal",
        "high": "Dringend"
      },
      "categories": {
        "billing": "Buchhaltung",
        "technical": "Technik",
        "sales": "Kommerziell",
        "other": "Sonstige"
      },
      "total": "Gesamt",
      "company": "Betroffenes Unternehmen",
      "pending_client": "Warten auf Kundenantwort",
      "pending_admin": "Warten auf Technikerantwort",
      "pending_you": "Warten auf Ihre Antwort",
    },
    "invoices": {
      "title": {
        "invoice": "Rechnungen",
        "documents": "Unterlagen",
        "recurring": "Abonnements",
        "recurrings_invoices": "Abonnement-Rechnungen :",
        "admin_title": "Rechnungen, Gutschriften und Bestellungen",
      },
      "differed": "Diese Rechnung wird automatisch zusammen mit Ihrer Abonnementverlängerung bezahlt.",
      "pay": "Rechnung bezahlen",
      "auto_debit_part_title": "Ihre Leistungen werden derzeit per Lastschriftverfahren gezahlt.",
      "auto_debit_part_one": "Die Bezahlung Ihrer Bestellung erfolgt am Ende des Monats.",
      "auto_debit_part_two": "Wenn Sie Fragen haben, wenden Sie sich bitte an unseren Support :",
      "auto_debit_btn": "Ein Ticket erstellen",
      "last-invoice": "Aktuelle Rechnung",
      "details": "Rechnungs-Nr. ",
      "order_details": "Bestellnummer",
      "pending": "in Erwartung",
      "multi-pay": "Mehrere Rechnungen bezahlen",
      "order_cancel": {
        "title": "Die Bestellung stornieren",
        "msg": "Sind Sie sicher, dass Sie diese Bestellung stornieren möchten ?",
        "btn": "Die Bestellung stornieren"
      },
      "tabs": {
        "invoice_tab": "Rechnung",
        "order_tab": "Bestellung",
        "credit_tab": "Anerkennung",
        "payment_tab": "Bezahlung",
        "payment_list_tab": "Bezahlungsliste",
        "expenses_list_tab": "Ausgabenliste",
        "payment_tab_header": "Einzelheiten",
        "payment_detail_header": [
          "Rechnungsnummer",
          "Betrag",
          "Rabatt",
          "Nettobetrag",
          "MwSt.",
          "Gesamtbetrag",
          "Bezahlt",
          "Saldo",
        ],
      },
      "no-payments": "Keine Zahlungen.",
    },
    "cgv": {
      "title": "Allgemeine Bedingungen und Konditionen",
      "action": "Akzeptieren Sie die AGBs",
      "next": "Nächste",
      "previous": "Vorherige",
      "download": "Herunterladen",
      "accept": "Ich akzeptiere die Allgemeinen Verkaufsbedingungen.",
      "signed-at": "Sie haben unsere Allgemeinen Geschäftsbedingungen am {Datum} unterzeichnet von der IP-Adresse {ip}.",
      "up-to-date": "Die AGB wurden seit Ihrer Unterschrift nicht geändert.",
      "not-up-to-date": "Die AGB wurden seit Ihrer Unterschrift geändert.",
      "current-version": "Die aktuelle Version ist die V{version}.",
      "read": "Konsultieren Sie die Allgemeinen Geschäftsbedingungen (AGB)",
    },
    "email-validation": {
      "title": "Bestätigungs-E-Mail ",
      "send-action": "Senden Sie mir den Bestätigungslink zurück"
    },
    "forget-password": {
      "title": "Passwort vergessen",
      "send-action": "Senden Sie mir Anweisungen zum Reset",
      "confirm-success": "Eine E-Mail mit den Anweisungen zum Reset wurde an Sie gesendet (Bitte überprüfen Sie auch Ihre Spam-Mails)."
    },
    "forget-password-change": {
      "title": "Neues Passwort",
      "action": "Bestätigen"
    },
    "commercial": {
      "title": "Vertrieb",
      "last_name": "Nachname",
      "first_name": "Vorname",
      "email": "Email",
      "phone": "Telefon",
      "status": "Status",
      "disabled": "Deaktiviert",
      "create_commercial": "Erstellen Sie einen Verkäufer",
    },
    "cron": {
      "add-cron": "Aufgabe hinzufügen",
      "header": [
        "Aufgabe",
        "Name",
        "Firma",
        "Benutzer",
        "Server",
        "Planung",
        "Wiederkehrend",
        "Status",
        "Aktiviert",
      ],
      "job": {
        "type": {
          "schedule-reboot": "Neustart",
          "schedule-snapshot": "Snapshot",
          "undefined": "",
        },
        "message": {
          "create-success": "Job erstellt",
          "delete-success": "Job gelöscht",
          "update-success": "Job aktualisiert",
        },
        "creation": {
          "title": "Erstellen einer Aufgabe",
          "label": [
            "Firma",
            "Server",
            "Typ",
            "Wiederkehrende Aufgabe",
            "Planung",
          ],
          "no-credit": [
            "Keine Guthaben verfügbar.",
            "Ohne Guthaben können geplante wiederkehrende Aufgaben auf einem Server nicht ausgeführt werden.",
            "Wir laden Sie ein, ein Guthabenpaket zu kaufen, um die Ausführung mehrerer Aufgaben pro Server zu ermöglichen.",
            "Bitte beachten Sie, dass diese Guthaben auch den Empfang von SMS-Benachrichtigungen erlauben.",
            "Wir danken Ihnen für Ihr Verständnis."
          ],
        },
        "toggle": {
          "button-tip-true": "Klicken, um die Aufgabe zu aktivieren",
          "button-tip-false": "Klicken, um die Aufgabe zu deaktivieren",
          "true": "Aufgabe deaktiviert",
          "false": "Aufgabe aktiviert",
        },
      },
      "modal": {
        "delete-job-title": "Aufgabe löschen {job}",
        "delete-job-description": "Durch Bestätigen dieser Aktion wird diese Aufgabe gelöscht.",
        "delete-job-confirm": "Sind Sie sicher, dass Sie diese Aufgabe löschen möchten?",
        "delete-job-warning": "Diese Aktion ist umkehrbar.",
      },
    },
  },
  "unit": {
    "GB": "Go",
    "vcpu": "vCores",
    "mbps": "mbps",
    "Cores": "vCores",
  },
  "form": {
    "required-field": "Pflichtfeld",
    "missing-fields": "Bitte füllen Sie die fehlenden Felder aus",
    "password-constraints": "Kennwort mindestens 6 Zeichen",
    "email-invalid": "Ungültige E-Mail Adresse",
    "affiliate-code-invalid": "Ungültiger Zugehörigkeitscode",
    "country-invalid": "Land ungültig",
    "password-condition": {
      "characters": "Zeichen mind.",
      "contains-number": "1 Zahl",
      "contains-uppercase": "1 Großbuchstabe",
      "contains-special": "1 Sonderzeichen",
    },
  },
  "beta-functionnality-modal": {
    "title": "Eingeschränkte Zugriffsfunktionalität.",
    "content": "Um diese Funktion zu aktivieren, müssen Sie unsere Verkaufsabteilung telefonisch oder per Ticket kontaktieren."
  },
  "tour": {
    "next": "Weiter",
    "previous": "Vorherige",
    "end": "Ende",
    "skip": "Den Besuch überspringen",
    "global": {
      "play": "Die Tour spielen",
      "dashboard": {
        "header": {
          "title": "Dashboard"
        },
        "content": "Hier haben Sie eine Übersicht über Ihre Ticketinformationen, Aktionen und Verbrauchsstatistiken.\n",
      },
      "servers": {
        "header": {
          "title": "Hier können Sie Ihre Server mit vielen Funktionen steuern:\n"
        },
        "content": "- Fernzugriff (Fehlersuche über kvm)<br />" +
          "- Upgrade/Downgrade von CPU/RAM/Disk-Ressourcen<br />" +
          "- ISO-Einrichtung und Wiederherstellungs-ISO<br />" +
          "- Stoppen / Starten von Servern<br />" +
          "- ipv6-Aktivierung und Erstellung von Reverses<br />" +
          "- Zurücksetzen, Snapshot- und Vorlagenerstellung<br />" +
          "- Statistiken zu CPU/RAM/Festplatte/Netzwerkressourcen",
      },
      "networks": {
        "header": {
          "title": "Netzwerk Tab"
        },
        "content": "Erstellen und verwalten Sie Ihre privaten Netzwerke, um Ihre Serverplattform zu sichern und einzurichten."
      },
      "company": {
        "header": {
          "title": "Unternehmen Tab"
        },
        "content": "Hier können Sie die Informationen zu Ihrem Unternehmen sowie Ihre Zahlungsmethoden und Ihr Rollenmanagement verwalten.",
      },
      "tickets": {
        "header": {
          "title": "Ticket Tab"
        },
        "content": "Hier können Sie mit unseren verschiedenen Abteilungen kommunizieren: Technik, Vertrieb und Buchhaltung.",
      },
      "affiliations": {
        "header": {
          "title": "Zugehörigkeiten Tab"
        },
        "content": "Hier können Sie Ihr Mitgliedskonto und die darauf gutgeschriebenen Beträge verwalten<br />" +
          "« Setzen Sie sich mit unserer Verkaufsabteilung in Verbindung, um Ihre Anspruchsberechtigung zu prüfen. »",
      },
      "recurring": {
        "header": {
          "title": "Abonnement Tab"
        },
        "content": "Verwalten Sie die Liste Ihrer Abonnements und die Namen Ihrer Projekte, die auf Ihren Rechnungen erscheinen.",
      },
      "invoice": {
        "header": {
          "title": "Dokument Tab"
        },
        "content": "Hier finden Sie alle Ihre Buchhaltungsunterlagen (Bestellungen, Rechnungen und Gutschriften).",
      },
      "vms": {
        "header": {
          "title": "Caddie"
        },
        "content": "Hier können Sie Ihre Server bestellen und in weniger als eine Minute geliefert bekommen.",
      },
      "header-user": {
        "header": {
          "title": "Kontoeinstellungen"
        },
        "content": "- Hier können Sie Ihre verschiedenen Kontoeinstellungen verwalten<br />" +
          "- Wechseln Sie die Schnittstellen zwischen Ihren verschiedenen Konten<br />" +
          "- Verwalten Sie Ihren SSH- und API-Schlüssel-Shop<br />" +
          "- Aktivieren Sie Ihre 2FA-Authentifizierung<br />" +
          "- Kauf Ihrer Dienstleistungen (punktuelle Eingriffe und Microsoft-Lizenz)<br />" +
          "- Zugriff auf die API-Dokumentation<br />"
      }
    }
  },
  "object_search": {
    "empty": "Geben Sie mindestens {length} Zeichen ein",
    "selected": "Der ausgewählte Wert ist {value}",
    "match": "Der Name muss in der Liste existieren"
  },
  "company_search": {
    "placeholder": "Kunde suchen",
    "empty": "Suchen und dann ein Unternehmen auswählen",
    "selected": "Das ausgewählte Unternehmen ist {value}",
  },
  "cron-date": {
    "minute": "Minute",
    "hour": "Stunde",
    "month": "Monat",
    "day-of-month": "Tag des Monats",
    "day-of-week": "Wochentag",
    "help": "Steht das Sternchen (*) für jeden möglichen Wert des betreffenden Feldes.",
  },
  "days-of-week": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  "months-of-year": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  "Forbidden": "Zugriff verweigert",
  "errors": {
    "UPDATE_SUCCESSFUL": "Update erfolgreich",
    "NO_DOCUMENT_FOUND": "Existiert nicht!",
    "PAGE_NOT_FOUND": "Seite nicht gefunden.",
    "ALREADY_EXISTS": "Bereits vorhanden",
    "TASK_ALREADY_RUNNING": "Aufgabe läuft bereits",
    "INVALID_RECAPTCHA": "Ungültiges Recaptcha.",
    "UPDATE_UNSUCCESSFUL": "Aktualisierung fehlgeschlagen",

    "TOO_MANY_KVM": "Sie haben zu viele KVMs geöffnet",

    "PERMISSION_DENIED": "Sie haben keine Berechtigung für diese Aktion.",
    "2FA_INVALID_SECRET": "Geheimnis ungültig",
    "USER_UPDATE_SSH_KEY_SUCCESS": "Erfolgreich aktualisiert",
    "USER_UPDATED_SUCCESSFULLY": "Erfolgreich aktualisiert",
    "TOKEN_UPDATE_SUCCESS": "Erfolgreich aktualisiert",

    "SERVER_RESET_FAIL_NOT_LICENSED_OS_TO_LICENSED_OS": "Diese Funktion ist für Ihren Benutzer noch nicht verfügbar. Bitte kontaktieren Sie den Support.",

    "IMAGES_EMPTY": "Sie haben kein Image, Sie können eines aus dem Snapshot eines Rechners erstellen.",

    "APP_BETA_FUNCTIONNALITY": "In Kürze wird eine Titan-Mobilanwendung es Ihnen ermöglichen, alle Funktionen zu steuern.",

    "BETA_FUNCTIONNALITY": "Um diese Funktion zu aktivieren, müssen Sie unsere Verkaufsabteilung per Telefon oder Ticket kontaktieren.",

    "INVALID_EMAIL_OR_PASSWORD": "Ungültige Emailadresse oder Passwort.",
    "MISSING_EMAIL_OR_PASSWORD": "Fehlende E-Mail oder Passwort.",
    "ACCOUNT_NOT_VALIDATED": "Ihr Konto wurde nicht validiert, bitte überprüfen Sie Ihre E-Mails.",
    "USER_OR_COMPANY_MISSING_NAME_OR_ADDRESS_INFORMATION": "Sie müssen zunächst Ihre persönlichen und beruflichen Daten ausfüllen.",

    "INTERNAL_ERROR": "Interner Fehler.",
    "BAD_PERMISSION": "Erlaubnis verweigert.",
    "BAD_REQUEST": "Falsche Anfrage.",
    "SERVER_NOT_FOUND": "Server nicht gefunden.",
    "COMPANY_NOT_FOUND": "Firma nicht gefunden.",
    "INVOICE_NOT_FOUND": "Rechnung nicht gefunden.",
    "INVOICE_ALREADY_PAID": "Rechnung bereits bezahlt.",
    "PAYMENT_METHOD_NOT_FOUND": "Zahlungsmethode nicht gefunden.",
    "PLAN_NOT_FOUND": "Modell nicht gefunden",
    "CREDIT_NOT_FOUND": "Guthaben nicht gefunden",
    "CREDIT_EXPIRED": "Guthaben abgelaufen",
    "CREDIT_SPENT": "Ungültiges Guthaben, bitte kontaktieren Sie den Support..",
    "CREDIT_INVALID": "Ungültiges Guthaben, bitte kontaktieren Sie den Support.",
    "PAYMENT_FAIL": "Die Zahlung ist fehlgeschlagen.",
    "PROJECT_NAME_INVALID": "Der Projektname ist nicht gültig, die Länge muss zwischen 4 und 20 Zeichen beinhalten.",
    "PROJECT_NAME_CONFLICT": "Der Name des Projekts ist bereits vergeben, bitte wählen Sie einen anderen..",
    "ROLE_NAME_INVALID": "Rollenname ungültig, Länge muss zwischen 2 und 50 Zeichen betragen.",
    "REVERSE_INVALID": "Ungültiges Reverse: Stellen Sie sicher, dass Sie die richtige IP-Adresse in der DNS-Zone einstellen.",

    "AVATAR_INVALID_TYPE": "Der ausgewählte Avatar hat nicht das richtige Format (jpg oder png).",
    "AVATAR_INVALID_SIZE": "Der ausgewählte Avatar erfüllt nicht die Bedingungen.",
    "AVATAR_UPDATED_SUCCESSFULLY": "Avatar erfolgreich aktualisiert",
    "PHONE_NUMBER_INVALID": "Die Telefonnummer ist ungültig.",

    // Snapshot
    "SNAPSHOT_NOT_FOUND": "Snapshot nicht gefunden.",
    "SNAPSHOT_RESTORE_STARTED": "Wiederherstellung des laufenden Schnapshots.",
    "SNAPSHOT_RENAME_SUCCESS": "Snapshot erfolgreich umbenannt.",
    "SNAPSHOT_DELETE_SUCCESS": "Snapshot erfolgreich gelöscht.",
    "SNAPSHOT_CREATE_FAIL_LIMIT_EXCEEDED": "Das Limit für Schnapshots wurde erreicht..",
    "SNAPSHOT_DELETE_INVALID_STATE": "Snapshot kann nicht gelöscht werden: Ungültiger Zustand.",
    "SNAPSHOT_RESTORE_INVALID_SNAPSHOT_STATE": "Der Snapshot kann nicht wiederhergestellt werden: ungültiger Snapshot-Status.",
    "SNAPSHOT_CREATE_FAIL_TOO_FAST": "Kann keinen neuen Snapshot erstellen: zwischen der Erstellung neuer Snapshots müssen mindestens 30 Minuten liegen.",

    // Image
    "IMAGE_CREATE_FAIL_INSUFFICIENT_SPACE": "Ihr Speicherplatz ist nicht ausreichend.",
    "IMAGE_CREATE_FAIL_QUOTA_EXCEEDED": "Die Quote für die Erstellung von Bildern wurde überschritten.",
    "SERVER_CREATE_FAIL_SERVER_DISK_IMAGE_AND_TEMPLATE_CONFLICT": "Konflikt zwischen dem Disk-Image und der Vorlage.",
    "SERVER_CREATE_FAIL_INVALID_DISK_SIZE": "Ungültige Plattengröße.",
    "SERVER_CREATE_FAIL_INVALID_IMAGE_STATE": "Ungültiger Bildstatus.",
    "SERVER_CREATE_FAIL_DISK_SOURCE_INVALID": "Ungültige Plattenquelle.",
    "SERVER_CREATE_FAIL_IMAGE_UUID_MISSING": "Fehlender eindeutiger Bezeichner.",
    "SERVER_CREATE_FAIL_TEMPLATE_UUID_MISSING": "Fehlender eindeutiger Bezeichner.",
    "IMAGE_DELETE_SUCCESS": "Bild erfolgreich gelöscht.",
    "IMAGE_UPDATE_SUCCESS": "Bild erfolgreich aktualisiert.",
    "IMAGE_CREATE_SUCCESS": "Bild erfolgreich erstellt.",
    "NO_IMAGE": "Sie haben kein Bild, Sie können eines aus einem Snapshot erstellen",
    "IMAGE_NOT_FOUND": "Bild nicht gefunden.",
    "IMAGE_DELETE_FAIL_IS_ACTUALLY_USED": "Dieses Bild wird verwendet, Sie können es nicht löschen.",
    "IMAGE_CREATE_FAIL_PLESK_DISABLED": "Es ist nicht möglich, PLESK-Vorlagen zu erstellen.",
    "IMAGE_CREATE_FAIL_NAME_ALREADY_USED": "Dieser Name ist bereits in Gebrauch.",
    "IMAGE_INVALID_NAME_SIZE": "Die Größe des Namens ist nicht korrekt.",
    "IMAGE_INVALID_OS_NAME_SIZE": "Die Größe des Betriebssystemnamens ist falsch.",
    "IMAGE_INVALID_OS_VERSION_SIZE": "Die Größe der Betriebssystemversion ist falsch.",
    "IMAGE_CREATE_FAIL_SNAPSHOT_INVALID_STATE": "Der Snapshot befindet sich in einem ungültigen Zustand.",
    "IMAGE_FAIL_TO_RETRIEVE_ORIGINAL_TEMPLATE": "Es ist unmöglich, die Originalvorlage zu finden.",
    "INVALID_TARGET_STATE": "Ziel nicht vorhanden oder in einem nicht konformen Zustand mit der Anfrage.",

    // Addon
    "ADDON_INSTALL_FAIL_INVALID_PLAN": "Ungültiger Plan.",
    "ADDON_INSTALL_FAIL_INVALID_QUANTITY": "Anzahl der Addons ungültig.",
    "ADDON_INSTALL_FAIL_INVALID_ITEM": "Addon ungültig.",
    "ADDON_INSTALL_FAIL_CPU_LIMIT_EXCEEDED": "Die Installation des/der Addons ist nicht möglich: die CPU-Quote wurde überschritten, bitte wenden Sie sich an den Support",
    "ADDON_INSTALL_FAIL_RAM_LIMIT_EXCEEDED": "Die Installation des/der Addons ist nicht möglich: die CPU-Quotz wurde überschritten, bitte wenden Sie sich an den Support",
    "ADDON_INSTALL_FAIL_DISK_LIMIT_EXCEEDED": "Das/die Addon(s) kann/können nicht installiert werden: die Disk-Quote wurde überschritten, bitte kontaktieren Sie den Support.",
    "ADDON_INSTALL_FAIL_SRV_ON_TERMINATION": "Der Server ist zur Löschung vorgesehen.",
    "ADDON_UNINSTALL_FAIL_SERVER_BAD_STATE": "Addons können nicht deinstalliert werden: Der Server muss gestoppt werden.",
    "ADDON_UNINSTALL_FAIL_NOT_FOUND": "Addon(s) können nicht deinstalliert werden: Addons nicht gefunden.",
    "ADDON_UNINSTALL_FAIL_SERVER_DO_NOT_BELONG_TO_SERVER": "Addons können nicht deinstalliert werden: Addons sind nicht installiert.",
    "ADDON_UNINSTALL_FAIL_ITEM_TYPE_DISK_INVALID": "Addons können nicht deinstalliert werden: Disk Addons können nicht deinstalliert werden.",
    "ADDON_UNINSTALL_FAIL_INVALID_QUANTITY": "Addons können nicht deinstalliert werden: ungültige Menge.",
    "ADDON_UNINSTALL_FAIL_SRV_ON_TERMINATION": "Addons können nicht deinstalliert werden: Das Löschen des Servers ist geplant.",
    "ADDON_UNINSTALL_SUCCESS": "Addons erfolgreich deinstalliert.",
    "ADDON_LIST_FAIL": "Der Server ist zur Löschung vorgesehen.",
    "ADDON_ERR_INVOICE_NOT_PAID": "Addon-Hinzufügung nicht möglich. Rechnung(en) unbezahlt.",

    // Invoice
    "INVOICE_PAYMENT_NOT_FOUND": "Sie können die Zahlung der Rechnung nicht finden.",
    "INVOICE_CONTACT_NOT_RECOGNIZED": "Der Kontakt für die Rechnung ist unauffindbar.",
    "INVOICE_CREATE_FAIL": "Es kann keine neue Rechnung erstellt werden.",
    "INVOICE_CREATE_SUCCESS": "Neue Rechnung erstellt.",
    "INVOICE_CREATE_NOT_REQUIRED": "Die Erstellung einer neuen Rechnung ist noch nicht erforderlich.",
    "INVOICE_ITEM_BUILD_FAIL": "Es ist nicht möglich die Artikel in der Rechnung zu erstellen.",

    // Order
    "ORDER_CANCEL_SUCCESS": "Bestellung erfolgreich storniert.",
    "ORDER_ALREADY_INVOICE": "Die Bestellung ist bereits in der Rechnung enthalten.",
    "ORDER_ALREADY_CANCELED": "Die Bestellung ist bereits storniert.",
    "ORDER_ALREADY_PAID": "Die Bestellung ist bereits bezahlt.",
    "ORDER_HAS_SUCCESS_PAYMENT": "Die Bestellung wurde bereits mit einer erfolgreichen Zahlung registriert. Bitte wenden Sie sich an den Helpdesk.",
    "ORDER_HAS_WAITING_PAYMENT": "Eine ausstehende Zahlung ist bereits auf der Bestellung vermerkt. Bitte warten Sie.",
    "ORDER_HAS_DEPLOYED_ITEM": "Der Auftrag enthält bereits ein eingesetztes Material. Bitte kontaktieren Sie den Support.",
    "ORDER_NOT_CANCELABLE": "Der Auftrag kann nicht storniert werden. Bitte kontaktieren Sie den Support.",

    // Recurring invoices
    "RECURRING_INVOICE_NOT_FOUND": "Abonnement nicht gefunden.",
    "RECURRING_INVOICE_UPDATE_FAIL": "Abonnement kann nicht aktualisiert werden.",
    "RECURRING_INVOICE_CANCEL_SUCCESS": "Abonnement erfolgreich beendet.",
    "RECURRING_INVOICE_UPDATE_SUCCESS": "Abonnement erfolgreich aktualisiert",
    "RECURRING_INVOICE_CREATE_FAIL": "Es kann kein neues Abonnement erstellt werden.",
    "RECURRING_INVOICE_CREATE_SUCCESS": "Neues Abonnement erfolgreich erstellt.",
    "RECURRING_INVOICE_INVALID_FREQ": "Die Abonnementhäufigkeit ist ungültig",
    "RECURRING_INVOICE_ITEM_BUILD_FAIL": "Artikel können Ihrem Abonnement nicht hinzugefügt werden.",
    "RECURRING_INVOICE_NOT_ONGOING": "Das Abonnement ist nicht in Bearbeitung.",
    "RECURRING_INVOICE_START_TOO_EARLY": "Das Startdatum des Abonnements ist zu früh. Entfernen Sie es, um es nach Zahlungseingang dynamisch zu starten.",
    "RECURRING_INVOICE_CANCELED": "Das Abonnement wird gekündigt.",
    "RECURRING_INVOICE_PENDING": "Abonnement steht noch aus.",
    "RECURRING_INVOICE_ONGOING": "Löschen nicht möglich. Ein aktives Abonnement in diesem Konto erfordert mindestens eine registrierte Zahlungsmethode.",
    "RECURRING_ITEM_NOT_LICENSE_TYPE": "Das Element ist nicht vom Lizenztyp",

    // Payment methods
    "PAYMENT_METHOD_ADD_FAIL_EMPTY_NAME": "Sie müssen den vollständigen Namen auf der Karte angeben.",
    "PAYMENT_METHOD_UPDATE_SUCCESS": "Das Zahlungsmittel wurde erfolgreich aktualisiert.",
    "PAYMENT_METHOD_REMOVE_SUCCESS": "Das Zahlungsmittel wurde erfolgreich gelöscht.",
    "PAYMENT_METHOD_ADD_SUCCESS": "Das Zahlungsmittel wurde erfolgreich hinzugefügt.",
    "PAYMENT_METHOD_ALREADY_REGISTERED": "Das Zahlungsmittel ist bereits registriert.",
    "PAYMENT_METHOD_ADD_FAIL": "Die neue Zahlungsmethode kann nicht hinzugefügt werden.",
    "PAYMENT_EXCEED_AMOUNT": "Der Betrag der Zahlung übersteigt den zu zahlenden Betrag.",
    "PAYMENT_FAILED": "Zahlung fehlgeschlagen.",
    "PAYMENT_METHOD_IS_DEFAULT": "Löschen der Standardzahlungsmethode verboten.",
    "Credit card number is not long enough": "Kreditkartennummer ist nicht lang genug",
    "Invalid credit card number": "Ungültige Kreditkartennummer",
    "Test numbers are not allowed": "Testnummern sind nicht erlaubt",
    "Invalid year": "Ungültiges Jahr",
    "Invalid month": "Ungültiger Monat",
    "Credit card has expired": "Kreditkarte ist abgelaufen",
    "Invalid CVV": "Ungültige CVV-Nummer",
    "Unknown credit card method": "Unbekannter Kreditkartentyp",

    // Payment delay
    "PAYMENT_DELAY_ALREADY_EXISTS": "Diese Zahlungsverzögerung existiert bereits.",

    // Credit
    "CREDIT_CREATE_SUCCESS": "Das Guthaben wurde erfolgreich erstellt.",
    "CREDIT_FUNDING_EXCEEDED": "Die Finanzierung des Guthaben für eine gezielte Rechnung wurde überschritten..",
    "CREDIT_FUNDING_INVOICE_NOT_PAID": "Eine Rechnung für eine gezielte Förderung ist nicht bezahlt.",
    "CREDIT_FUNDING_ITEM_NOT_FOUND": "Die Finanzierung für ein Artikel in der gezielten Rechnung wurde nicht gefunden.",
    "CLEAR_AMOUNT_EXCEED": "Der Betrag übersteigt das Dokumentenguthaben.",

    // Migration
    "MIGRATE_FAIL_ITEM_NOT_DEPLOYED": "Die Migration kann nicht durchgeführt werden, ein oder mehrere Artikel sind noch nicht bereitgestellt.",

    // Server
    "BAD_OID": "Ungültige OID",

    // Delete
    "SERVER_DELETE_FAIL_ALREADY_ON_TERMINATION": "Die Löschung des Servers ist bereits geplant.",
    "SERVER_DELETE_FAIL_PLAN_NOT_FOUND": "Die Löschung des Servers ist nicht möglich: Plan nicht gefunden.",
    "SERVER_DELETION_PENDING_SUCCESS": "Löschung des/der aktuellen Server(s).",
    "SERVER_DELETION_PENDING": "Unerlaubte Aktion. Die Serverbeendigung wurde angefordert.",
    "SERVER_DELETE_SUCCESS": "Server erfolgreich gelöscht.",
    "SERVER_DELETE_FAIL_RECURRING_NOT_FOUND": "Der Server kann nicht gelöscht werden: Wiederkehrende Rechnung nicht gefunden.",
    "SERVER_DELETE_FAIL_WAN_CONFIG_ERROR": "Der Server kann nicht gelöscht werden: WAN-Konfigurationsfehler.",
    "SERVER_DELETE_FAIL_UPDATE_RECURRING_ERROR": "Der Server kann nicht gelöscht werden: Fehler beim Aktualisieren der wiederkehrenden Rechnung.",
    "SERVER_CANCEL_DELETE_FAIL_ALREADY_DELETED": "Löschen des Servers kann nicht rückgängig gemacht werden: Der Server ist bereits gelöscht.",
    "SERVER_DELETE_FAIL_ACTION_NOT_SCHEDULED": "Die Löschung dieses Servers ist nicht geplant.",
    // Create
    "SERVER_CREATE_FAIL": "Entsprechende Rechnung unauffindbar",
    "SERVER_CREATE_FAIL_FREE_WAN_NOT_FOUND": "Der/die Server können nicht erstellt werden: Keine IP-Adresse mehr verfügbar. Bitte wenden Sie sich an den Support.",
    "SERVER_CREATE_FAIL_SERVER_LIMIT_EXCEEDED": "Der/die Server können nicht erstellt werden: Die Serverquote wurde überschritten. Wenden Sie sich an den Support.",
    "SERVER_CREATE_FAIL_CPUS_LIMIT_EXCEEDED": "Der/die Server kann/können nicht erstellt werden: Das CPU-Kontingent wurde überschritten, bitte kontaktieren Sie den Support.",
    "SERVER_CREATE_FAIL_RAM_LIMIT_EXCEEDED": "Der/die Server kann/können nicht erstellt werden: Das RAM-Kontingent wurde überschritten, bitte kontaktieren Sie den Support.",
    "SERVER_CREATE_FAIL_DISK_LIMIT_EXCEEDED": "Der/die Server kann/können nicht erstellt werden: Das Festplattenkontingent wurde überschritten, bitte kontaktieren Sie den Support.",
    "SERVER_CREATE_FAIL_IDENT_MISSING": "Der/die Server kann/können nicht erstellt werden: Passwort und/oder SSH-Schlüssel fehlen.",
    "SERVER_CREATE_FAIL_ADDON_QUANTITY_EXCEEDED": "Der/die Server kann/können nicht erstellt werden: Das Addon-Limit wurde überschritten, bitte kontaktieren Sie den Support.",
    "SERVER_CREATE_FAIL_INVALID_ADDON": "Der/die Server kann/können nicht erstellt werden: Addon(s) nicht gefunden oder ungültig.",
    "SERVER_CREATE_FAIL_INVALID_QUANTITY": "Kann den/die Server nicht erstellen: falsche Anzahl.",
    "SERVER_CREATE_FAIL_INVALID_PLAN": "Der/die Server kann/können nicht erstellt werden: Plan nicht gefunden oder ungültig.",
    "SERVER_CREATE_FAIL_INVALID_CPU_QUANTITY": "Der/die Server kann/können nicht erstellt werden: ungültige CPU-Menge.",
    "SERVER_CREATE_FAIL_INVALID_TEMPLATE": "Der/die Server kann/können nicht erstellt werden: Template nicht gefunden oder ungültig.",
    "SERVER_CREATE_FAIL_INVALID_PASSWORD": "Der/die Server kann/können nicht erstellt werden: Ungültiges Passwort.",
    "SERVER_CREATE_FAIL_FREE_MAC_NOT_FOUND": "Der/die Server kann/können nicht erstellt werden: keine MAC-Adresse vorhanden",
    "SERVER_CREATE_FAIL_FREE_IP_NOT_FOUND": "Der/die Server kann/können nicht erstellt werden: keine IP-Adresse verfügbar",
    "SERVER_CREATE_FAIL_MISSING_LICENSE": "Der/die Server kann/können nicht erstellt werden: fehlende Lizenz.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_INVALID": "Der/die Server kann/können nicht erstellt werden: Ungültiger Projektname.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_DUPLICATE": "Der/die Server kann/können nicht erstellt werden: Der Projektname existiert bereits.",
    // Reset
    "SERVER_RESET_SUCCESS": "Reset des Servers in Arbeit.",
    "SERVER_RESET_FAIL_MISSING_PASSWORD_OR_KEY": "Der Server kann nicht zurückgesetzt werden: Passwort und/oder SSH-Schlüssel fehlen.",
    "SERVER_RESET_FAIL_INVALID_PLAN": "Reset des Servers nicht möglich: Plan nicht gefunden oder ungültig.",
    "SERVER_RESET_FAIL_INVALID_TEMPLATE": "Reset des Servers nicht möglich: WAN-Eingang nicht gefunden.",
    "SERVER_RESET_FAIL_WAN_NOT_FOUND": "Reset des Servers nicht möglich: WAN-Eingang nicht gefunden.",
    // Update
    "SERVER_UPDATE_SUCCESS": "Server erfolgreich aktualisiert.",
    "SERVER_UPDATE_FAIL_INVALID_NAME": "Aktualisierung des Servers nicht möglich: neuer Name ungültig.",
    "SERVER_UPDATE_FAIL_MISSING_PARAMETER": "Aktualisierung des Servers nicht möglich: Parameter fehlt.",
    "SERVER_UPDATE_FAIL_UPDATE_REVERSE": "Der Server kann nicht rückwärts aktualisiert werden, bitte kontaktieren Sie den Support.",
    "SERVER_UPDATE_FAIL_INVALID_REVERSE": "Aktualisierung des Servers nicht möglich: ungültiger Reverse.",
    "SERVER_UPDATE_FAIL_WAN_NOT_FOUND": "Aktualisierung des Servers nicht möglich: WAN-Eintrag nicht gefunden.",
    "SERVER_UPDATE_FAIL_NOTES_TOO_LONG": "Aktualisierung des Servers nicht möglich: Das Feld \"Bemerkung\" ist zu lang.",
    "SERVER_UPDATE_FAIL_NO_PRIMARY_IP": "Aktualisierung des Servers nicht möglich: Keine primäre IP gefunden.",
    // Actions
    "SERVER_ACTION_FAIL_UNKNOWN_ACTION": "Aktion nicht bekannt.",
    "SERVER_ACTION_FAIL_CONFLICT": "Die Aktion ist nicht mit dem aktuellen Serverstatus kompatibel.",
    "SERVER_ACTION_SUCCESS": "Aktion auf dem zu bearbeitenden Server",
    // ISO
    "SERVER_LOAD_ISO_BAD_PATH": "Das ISO kann nicht geladen werden: Der Pfad ist ungültig.",
    "SERVER_LOAD_ISO_BAD_PROTOCOL": "ISO kann nicht geladen werden: Protokoll ist ungültig",
    "SERVER_LOAD_ISO_SUCCESS": "ISO erfolgreich geladen.",
    "SERVER_UNLOAD_ISO_SUCCESS": "ISO erfolgreich demontiert.",

    // Template
    "TEMPLATE_DELETE_SUCCESS": "Template erfolgreich gelöscht.",
    "TEMPLATE_GET_FAIL_INVALID_OS": "Template erfolgreich gelöscht.",
    "TEMPLATE_DELETE_NOT_FOUND": "Das Löschen des Templates ist nicht möglich: Tempalte nicht gefunden.",

    // IP KVM
    "IPKVM_FAIL_ACTION_UNKNOWN": "Unbekannte Aktion auf dem IP KVM.",
    "IPKVM_SEND_ACTION_SUCCESS": "Aktion auf dem IP KVM, der bearbeitet wird.",
    "IPKVM_GET_URI_FAIL_KVM_NOT_STARTED": "Der IP KVM ist nicht auf dem Server gestartet.",
    "IPKVM_FAIL_INVALID_DEADLINE": "Die Aktion kann auf dem IP-KVM nicht ausgeführt werden: Das Zeitlimit ist ungültig.",
    "IPKVM_FAIL_TOO_LONG_DEADLINE": "Aktion auf dem IP KVM kann nicht ausgeführt werden: Das Timeout darf nicht die 5 Stunden überschreiten.",
    "IPKVM_FAIL_NOT_STOPPED": "Aktion unmöglich: KVM wurde nicht gestoppt",

    // Migrate
    "MIGRATE_FAIL_MISSING_SRC": "Migration kann nicht durchgeführt werden: fehlende Quelle (Hypervisor oder Server).",
    "MIGRATE_FAIL_CONFLICT_SRC": "Die Migration kann nicht durchgeführt werden: Konflikt an der Quelle (Hypervisor oder Server).",
    "MIGRATE_FAIL_HYPERV_SRC_EQUAL_DST": "Migration unmöglich: Die Hypervisoren (Quelle und Ziel) sind gleich.",
    "MIGRATE_FAIL_HYPERV_SRC_NOT_FOUND": "Migration kann nicht durchgeführt werden: Quell-Hypervisor nicht gefunden.",
    "MIGRATE_FAIL_HYPERV_DST_NOT_FOUND": "Migration kann nicht durchgeführt werden: Ziel-Hypervisor nicht gefunden.",
    "MIGRATE_SUCCESS": "Migration kann nicht durchgeführt werden: Ziel-Hypervisor nicht gefunden.",

    // Event / History
    "SERVER_EVENT_FAIL_INVALID_INTEGER": "Ereignisse können nicht vom Server abgerufen werden: Ungültige Ganzzahl.",
    "SERVER_EVENT_FAIL_INTEGER_LIMIT_EXCEEDED": "Ereignisse können nicht vom Server abgerufen werden: Ungültige Ganzzahl.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_START": "Serververlauf kann nicht abgerufen werden: Ungültiger Startzeitstempel.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_END": "Serververlauf kann nicht abgerufen werden: Ungültiger Endzeitstempe.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_TIMER": "Serververlauf kann nicht abgerufen werden: Intervall darf nicht 12 Stunden überschreiten.",

    // Network
    "NETWORK_NOT_FOUND": "Netzwerk nicht gefunden.",
    "NETWORK_DELETE_FAIL_NOT_FOUND": "Netzwerk kann nicht gelöscht werden: Netzwerk nicht gefunden.",
    "NETWORK_DELETE_FAIL_SERVER_ATTACHED": "Das Netzwerk kann nicht entfernt werden: Ein Server ist noch angeschlossen.",
    "NETWORK_CREATE_FAIL_COUNT_EXCEEDED": "Das Netzwerk kann nicht erstellt werden: Quote überschritten, bitte kontaktieren Sie den Support.",
    "NETWORK_CREATE_FAIL_INVALID_CIDR": "Das Netzwerk kann nicht erstellt werden: ungültige CIDR.",
    "NETWORK_CREATE_FAIL_CIDR_CONFLICT": "Das Netzwerk kann nicht erstellt werden: ungültige CIDR.",
    "NETWORK_CREATE_FAIL_CIDR_TOO_LARGE": "Unmöglich, das Netzwerk zu erstellen: der CIDR ist zu groß, ein Netzwerk kann nicht mehr als 65536 IPs (/16) haben.",
    "NETWORK_CREATE_FAIL_MANAGED_SERVICES_DISABLED": "Das verwaltete Netzwerk kann nicht erstellt werden: Die verwalteten Dienste sind für Ihr Unternehmen deaktiviert.",
    "NETWORK_CREATE_FAIL_INVALID_NAME": "Das Netzwerk kann nicht erstellt werden: ungültiges Namensformat.",
    "NETWORK_CREATE_FAIL_GATEWAY_NOT_FOUND": "Das Netzwerk kann nicht erstellt werden: Gateway nicht gefunden.",
    "NETWORK_ATTACH_FAIL_SERVER_FULL": "Server kann nicht angeschlossen werden: Alle Server-Ports werden verwendet.",
    "NETWORK_ATTACH_FAIL_MAX_SERVER_EXCEEDED": "Server kann nicht angeschlossen werden: Alle Netzwerkports werden verwendet",
    "NETWORK_ATTACH_FAIL_NETWORK_DOWN": "Der Server kann nicht angeschlossen werden: Das Netzwerk ist ausgefallen.",
    "NETWORK_ATTACH_FAIL_SERVER_DISABLED": "Server kann nicht angeschlossen werden: Der Server ist deaktiviert. Wenden Sie sich an den Support.",
    "NETWORK_ATTACH_FAIL_MANAGED_SERVICE_CONFLICT": "Server konnte nicht angehängt werden: Konflikt mit verwalteten Diensten erkannt.",
    "NETWORK_ATTACH_FAIL_MULTIPLE_MANAGED_NETWORKS": "Le serveur ne peut pas être connecté : Un serveur géré ne peut être connecté qu'à un seul réseau.",
    "NETWORK_ATTACH_FAIL_ALREADY_ATTACHED_TO_THIS_SERVER": "Der Server ist bereits an dieses Netzwerk angeschlossen.",
    "NETWORK_DETACH_FAIL_SERVER_DISABLED": "Der Server kann nicht getrennt werden: Der Server ist deaktiviert, bitte wenden Sie sich an den Support.",
    "NETWORK_DETACH_FAIL_SERVER_NOT_ATTACHED": "Server kann nicht getrennt werden: Der Server ist nicht mit dem Netzwerk verbunden.",
    "NETWORK_DETACH_FAIL_MANAGED_SERVER": "Server kann nicht getrennt werden: Verwaltete Server können nicht manuell von einem Netzwerk getrennt werden.",
    "NETWORK_DETACH_FAIL_NETWORK_DOWN": "Es ist nicht möglich, den Server zu trennen: Das Netzwerk ist ausgefallen.",
    "NETWORK_UPDATE_FAIL_INVALID_NAME": "Netzwerk kann nicht aktualisiert werden: ungültiges Namensformat.",
    "NETWORK_SET_GATEWAY_FAIL_NOT_MANAGED": "Das Gateway kann nicht eingestellt werden: Das Netzwerk wird nicht verwaltet.",
    "NETWORK_SET_GATEWAY_FAIL_IP_NOT_FOUND": "Das Gateway kann nicht eingestellt werden: Die IP-Adresse kann nicht gefunden werden.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_MANAGED": "Das Gateway kann nicht entfernt werden: Das Netzwerk wird nicht verwaltet.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_FOUND": "Das Gateway kann nicht gelöscht werden: Gateway nicht gefunden.",
    "NETWORK_ADD_FW_RULE_FAIL_NOT_MANAGED": "Firewall-Regel kann nicht hinzugefügt werden: Netzwerk wird nicht verwaltet.",
    "NETWORK_ADD_FW_RULE_FAIL_INTERFACE_NOT_FOUND": "Die Firewall-Regel kann nicht hinzugefügt werden: Schnittstelle nicht gefunden.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_SOURCE": "Firewall-Regel kann nicht hinzugefügt werden: ungültige Quelle.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PORT": "Firewall-Regel kann nicht hinzugefügt werden: ungültiger Port.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PROTOCOL": "Firewall-Regel kann nicht hinzugefügt werden: Ungültiges Protokoll.",
    "NETWORK_REMOVE_FW_RULE_FAIL_NOT_FOUND": "Firewall-Regel kann nicht gelöscht werden: Regel nicht gefunden.",
    "NETWORK_ADD_FW_RULE_SUCCESS": "Firewall-Regel erfolgreich hinzugefügt.",
    "NETWORK_REMOVE_FW_RULE_SUCCESS": "Firewall-Regel erfolgreich entfernt.",
    "NETWORK_SET_GATEWAY_SUCCESS": "Gateway erfolgreich definiert.",
    "NETWORK_DELETE_GATEWAY_SUCCESS": "Gateway erfolgreich gelöscht.",
    "NETWORK_ATTACH_SERVER_SUCCESS": "Server erfolgreich angeschlossen.",
    "NETWORK_DETACH_SERVER_SUCCESS": "Server erfolgreich abgetrennt.",
    "NETWORK_DELETION_SUCCESS": "Löschen des aktuellen Netzwerks.",
    "NETWORK_UPDATE_SUCCESS": "Netzwerk erfolgreich aktualisiert.",

    // Metric
    "METRIC_EMPTY_NET_IN": "Eingehende Netzwerkdaten nicht verfügbar.",
    "METRIC_EMPTY_NET_OUT": "Ausgehende Netzwerkdaten nicht verfügbar.",
    "METRIC_UNAVAILABLE": "Daten nicht verfügbar.",

    // Ticket
    "TICKET_NOT_FOUND": "Ticket nicht gefunden.",
    "TICKET_CREATE_FAIL": "Das Ticket kann nicht erstellt werden: Die Server-UUID fehlt.",
    "TICKET_CREATE_FAIL_INVALID_OBJECT": "Das Ticket konnte nicht erstellt werden: ungültiges Objekt.",
    "TICKET_CREATE_FAIL_INVALID_CATEGORY": "Das Ticket konnte nicht erstellt werden: ungültige Kategorie.",
    "TICKET_CREATE_FAIL_INVALID_MESSAGE": "Das Ticket kann nicht erstellt werden: ungültige Meldung.",
    "TICKET_CREATE_FAIL_INVALID_LEVEL": "Das Ticket kann nicht erstellt werden: Ungültige Prioritätsstufe.",
    "TICKET_CREATE_FAIL_UNKNOWN_SERVER": "Das Ticket kann nicht erstellt werden: Server nicht gefunden.",
    "TICKET_ADD_MESSAGE_INVALID_TEXT": "Die Nachricht kann nicht zum Ticket hinzugefügt werden: Ungültiges Nachrichtenformat.",
    "TICKET_ADD_MESSAGE_FAIL_MISSING_TICKET_UUID": "Die Nachricht kann nicht zum Ticket hinzugefügt werden: fehlende Ticket-UUID.",
    "TICKET_ADD_MESSAGE_FAIL_TICKET_LOCKED": "Die Nachricht kann dem Ticket nicht hinzugefügt werden: Das Ticket ist von einem anderen Benutzer gesperrt.",
    "TICKET_DELETE_FAIL_MISSING_UUID": "Das Ticket kann nicht gelöscht werden: UUID fehlt.",
    "TICKET_GET_FAIL_MISSING_TICKET_UUID": "Das Ticket kann nicht abgerufen werden: UUID fehlt.",
    "TICKET_LOCK_FAIL_ALREADY_LOCKED": "Das Ticket ist bereits gesperrt.",
    "TICKET_UNLOCK_FAIL_ALREADY_UNLOCKED": "Das Ticket ist nicht gesperrt",
    "TICKET_UNLOCK_FAIL_LOCKED_BY_OTHER_USER": "Das Ticket kann nicht entsperrt werden: Das Ticket ist von einem anderen Benutzer gesperrt.",
    "TICKET_LOCK_SUCCESS": "Ticket erfolgreich gesperrt",
    "TICKET_UNLOCK_SUCCESS": "Ticket erfolgreich entriegelt.",
    "TICKET_CREATE_SUCCESS": "Ticket erfolgreich erstellt.",
    "TICKET_CLOSE_SUCCESS": "Ticket erfolgreich geschlossen.",
    "TICKET_DELETE_SUCCESS": "Ticket erfolgreich gelöscht.",
    "TICKET_ADD_MESSAGE_SUCCESS": "Nachricht erfolgreich zum Ticket hinzugefügt.",
    "TICKET_DELETE_MESSAGE_SUCCESS": "Ticketnachricht erfolgreich gelöscht.",
    "TICKET_UPDATE_LEVEL_SUCCESS": "Ticketlevel erfolgreich aktualisiert.",
    "TICKET_UPDATE_LEVEL_FAIL_CLOSED": "Ticketlevel kann nicht aktualisiert werden: Ticket ist geschlossen.",
    "TICKET_INVALID_MESSAGE": "Die Länge der Ticketnachrichten muss zwischen 3 und 5.000 Zeichen liegen.",
    "ERR_TICKET_ADMIN_CANNOT_CREATE_FOR_HIMSELF": "Sie können kein Ticket für sich selbst erstellen.",
    "TICKET_CLOSED": "Das Ticket ist geschlossen",

    // Contact
    "CONTACT_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha ist ungültig.",
    "CONTACT_REQUEST_FAIL_INVALID_EMAIL": "Ungültiges E-Mail-Format.",
    "CONTACT_REQUEST_SUCCESS": "E-Mail erfolgreich gesendet.",

    // Job
    "JOB_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha ungültig.",
    "JOB_REQUEST_FAIL_INVALID_EMAIL": "Ungültiges E-Mail-Format.",
    "JOB_REQUEST_SUCCESS": "E-Mail erfolgreich gesendet.",

    // User
    "USER_2FA_ENABLE_FAIL_WRONG_CODE": "Falscher Validierungscode",
    "USER_UPDATE_INVALID_FIRSTNAME": "Benutzer kann nicht aktualisiert werden: ungültiger Vorname.",
    "USER_LIST_FAIL": "Die Liste der Benutzer kann nicht abgerufen werden.",
    "USER_NOT_FOUND": "Benutzer nicht gefunden.",
    "USER_DELETE_FAIL_NOT_FOUND": "Benutzer kann nicht gelöscht werden: Benutzer nicht gefunden.",
    "USER_DELETE_SUCCESS": "Benutzer erfolgreich gelöscht.",
    "USER_RESET_PASSWORD_FAIL_INVALID_TOKEN": "Das Passwort des Benutzers kann nicht zurückgesetzt werden: Ungültiges Token.",
    "USER_RESET_PASSWORD_FAIL_INVALID_PASSWORD": "Benutzerpasswort kann nicht zurückgesetzt werden: ungültiges Passwortformat.",
    "USER_RESET_PASSWORD_SUCCESS": "Passwort-Reset erfolgreich durchgeführt.",
    "USER_RESET_PASSWORD_ASK_SUCCESS": "Wenn Sie bereits ein Konto haben, wird eine Nachricht mit Anweisungen zum Zurücksetzen Ihres Passworts an Ihre E-Mail gesendet.",
    "USER_UPDATE_INVALID_LASTNAME": "Ungültiger Nachname.",
    "USER_UPDATE_INVALID_PHONE_NUMBER": "Ungültige Telefonnummer.",
    "USER_UPDATE_INVALID_MOBILE_NUMBER": "Ungültige Handynummer.",
    "USER_UPDATE_SUCCESS": "Benutzer erfolgreich aktualisiert.",
    // API KEY
    "USER_ADD_API_KEY_SUCCESS": "API-Schlüssel erfolgreich erstellt",
    // SSH key
    "USER_DELETE_SSH_KEY_SUCCESS": "SSH-Schlüssel erfolgreich gelöscht.",
    "USER_DELETE_SSH_KEY_FAIL_MISSING_NAME": "Der SSH-Schlüsselname fehlt.",
    "USER_DELETE_SSH_KEY_FAIL_NOT_FOUND": "SSH-Schlüssel nicht gefunden.",
    "USER_ADD_SSH_KEY_FAIL_EMPTY": "Der SSH-Schlüssel ist leer.",
    "USER_ADD_SSH_KEY_FAIL_KEY_INVALID": "Der SSH-Schlüssel ist ungültig.",
    "USER_ADD_SSH_KEY_SUCCESS": "SSH-Schlüssel erfolgreich hinzugefügt.",
    "USER_ADD_SSH_KEY_FAIL_ALREADY_NAME_EXIST": 'Schlüsselname existiert bereits.',
    // Password
    "USER_UPDATE_PASSWORD_FAIL_WRONG_CURRENT_PASSWORD": "Das aktuelle Passwort ist falsch.",
    "USER_UPDATE_PASSWORD_FAIL_NEW_PASSWORD_INVALID": "Das neue Passwort ist ungültig.",
    "USER_UPDATE_PASSWORD_SUCCESS": "Passwort erfolgreich aktualisiert.",
    // Registration
    "USER_CREATE_FAIL_DEFAULT_PREFERENCES": "Fehler beim Erstellen der Standardbenutzereinstellungen.",
    "USER_CREATE_FAIL_COMPANY": "Fehler beim Erstellen des Benutzerunternehmens.",
    "USER_UPDATE_FAIL_COMPANIES": "Fehler beim Zuordnen des Benutzers zu Unternehmen.",
    "VALIDATE_REGISTRATION_FIRST": "Bitte validieren Sie zuerst Ihre Registrierung.",
    "VALIDATE_USER_SUCCESS": "Ihre Registrierung wurde bestätigt.",

      // Company
    "COMPANY_DISABLED": "Ihr Geschäft ist deaktiviert, bitte kontaktieren Sie den Support für weitere Informationen.",
    // Create
    "COMPANY_CREATE_FAIL_USER_ASSIGNMENT": "Der Benutzer kann der neu angelegten Firma nicht zugewiesen werden.",
    "COMPANY_CREATE_FAIL_ALREADY_EXISTS": "Erstellung fehlgeschlagen, das Unternehmen existiert bereits",
    // Delete
    "COMPANY_DELETE_FAIL": "Es ist nicht möglich, alle Benutzer in der Firma zu löschen.",
    "COMPANY_DELETE_SUCCESS": "Erfolgreich unterdrücktes Unternehmen.",
    // (Re)Validation
    "VALIDATE_USER_FAIL_CREATE_CONTACT_ERROR": "Der neue Kontakt kann nicht erstellt werden.",
    "VALIDATE_USER_FAIL_UPDATE_COMPANY_ERROR": "Die Buchhalter-Nummer kann der Firma nicht zugeordnet werden.",
    "REVALIDATE_USER_FAIL_ALREADY_VALIDATED": "Der Benutzer ist bereits validiert.",
    "REVALIDATE_USER_FAIL_ASSIGN_NEW_COMPANY": "Die neue Firma kann dem neuen Benutzer nicht zugewiesen werden.",
    "REVALIDATE_USER_SUCCESS": "Bestätigungs-E-Mail erfolgreich gesendet.",
    // Update
    "INVALID_EMAIL": "Die E-Mail-Adresse ist ungültig",
    "COMPANY_CREATE_SUCCESS": "Unternehmen erfolgreich erstellt.",
    "COMPANY_UPDATE_SUCCESS": "Firma erfolgreich aktualisiert.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_TYPE": "Firma kann nicht aktualisiert werden: falscher Adresstyp.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_NAME": "Aktualisierung der Firma nicht möglich: Adressname ungültig.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET": "Aktualisierung der Firma nicht möglich: Ungültiger Firmenname.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET_2": "Aktualisierung der Firma nicht möglich: ungültige Straße.",
    "COMPANY_UPDATE_FAIL_INVALID_POSTAL_CODE": "Firma kann nicht aktualisiert werden: ungültige Postleitzahl.",
    "COMPANY_UPDATE_FAIL_INVALID_CITY": "Aktualisierung der Firma nicht möglich: Ungültiger Ort.",
    "COMPANY_UPDATE_FAIL_INVALID_COUNTRY": "Aktualisierung der Firma nicht möglich: Ungültiges Land.",
    "COMPANY_UPDATE_FAIL_INVALID_PHONE": "Aktualisierung der Firma nicht möglich: Ungültige Telefonnummer.",
    // Register user with company
    "REGISTER_COMPANY_FAIL_RECAPTCHA_ERROR": "Recaptcha ungültig.",
    "REGISTER_COMPANY_FAIL_COMPANY_NAME_ALREADY_USED": "Das neue Konto kann nicht registriert werden: Der Firmenname ist bereits in Gebrauch.",
    "REGISTER_COMPANY_FAIL_USER_EMAIL_ALREADY_USED": "Neues Konto kann nicht registriert werden: E-Mail bereits verwendet.",
    "REGISTER_COMPANY_FAIL_COMPANY_EMAIL_ALREADY_USED": "Neues Konto kann nicht registriert werden: E-Mail bereits verwendet.",
    "REGISTER_COMPANY_FAIL_ASSIGN_USER_ERROR": "Es ist nicht möglich, die neue Firma dem neuen Benutzer zuzuordnen.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_EMAIL": "Das neue Konto konnte nicht registriert werden: ungültige Benutzer-E-Mail-Adresse.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_EMAIL": "Das neue Konto konnte nicht registriert werden: ungültige Firmen-E-Mail-Adresse.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_NAME": "Neues Konto kann nicht registriert werden: ungültiger Firmenname.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_CIVILITY": "Das neue Konto konnte nicht registriert werden: ungültige Höflichkeit.",
    "REGISTER_COMPANY_FAIL_INVALID_COUNTRY": "Neues Konto kann nicht registriert werden: ungültiges Land.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET": "Neues Konto kann nicht registriert werden: ungültige Straße.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET_2": "Das neue Konto konnte nicht registriert werden: ungültiges Straßenzusatzzeichen.",
    "REGISTER_COMPANY_FAIL_INVALID_POSTAL_CODE": "Neues Konto kann nicht registriert werden: ungültige Firmen-Postleitzahl.",
    "REGISTER_COMPANY_FAIL_INVALID_CITY": "Das neue Konto konnte nicht registriert werden: ungültiger Firmenort.",
    "REGISTER_COMPANY_FAIL_INVALID_PREFERRED_LANGUAGE": "Neues Konto kann nicht registriert werden: ungültige bevorzugte Sprache.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_FIRST_NAME": "Neues Konto kann nicht registriert werden: ungültiger Vorname.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_LAST_NAME": "Neues Konto kann nicht registriert werden: ungültiger Nachname",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PASSWORD": "Neues Konto kann nicht registriert werden: ungültiges Passwort.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PHONE": "Neues Konto kann nicht registriert werden: ungültige Benutzer-Telefonnummer",
    "REGISTER_COMPANY_FAIL_INVALID_USER_MOBILE": "Neues Konto kann nicht registriert werden: ungültige Mobiltelefonnummer",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_PHONE": "Neues Konto kann nicht registriert werden: ungültige Firmenrufnummer.",
    "REGISTER_COMPANY_FAIL_INVALID_AFFILIATE_CODE": "Neues Konto kann nicht registriert werden: ungültiger Affiliate-Code.",
    "REGISTER_COMPANY_SUCCESS": "Neue Firma erfolgreich registriert.",
    "REGISTER_COMPANY_FAIL_INVALID_VAT_NUMBER": "Ungültige Umsatzsteuer-Identifikationsnummer.",
    "INVALID_VAT_NUMBER": "Ungültige Umsatzsteuer-Identifikationsnummer.",
    "REGISTER_COMPANY_FAIL_CHECK_VAT_NUMBER": "Fehler beim Prüfen.",
    "VAT_NUMBER_EMPTY": "Bitte geben Sie die USt-IdNr. ein",
    // Register user to company
    "REGISTER_USER_FAIL_RECAPTCHA_ERROR": "Recaptcha ungültig.",
    "REGISTER_USER_FAIL_INVALID_FIRSTNAME": "Der Benutzer kann nicht zur Firma hinzugefügt werden: Ungültiger Vorname.",
    "REGISTER_USER_FAIL_INVALID_LASTNAME": "Benutzer kann nicht zur Firma hinzugefügt werden: ungültiger Nachname.",
    "REGISTER_USER_FAIL_INVALID_PASSWORD": "Benutzer kann nicht zur Firma hinzugefügt werden: Ungültiges Passwort.",
    "REGISTER_USER_FAIL_INVALID_PHONE": "Benutzer kann nicht zur Firma hinzugefügt werden: ungültige Telefonnummer.",
    "REGISTER_USER_FAIL_INVALID_MOBILE": "Benutzer kann nicht zur Firma hinzugefügt werden: ungültige Handynummer.",
    "REGISTER_USER_FAIL_INVALID_CIVILITY": "Der Benutzer kann nicht zur Firma hinzugefügt werden: Höflichkeit ungültig.",
    "REGISTER_USER_FAIL_INVALID_PREFERRED_LANGUAGE": "Der Benutzer kann nicht zur Firma hinzugefügt werden: ungültige bevorzugte Sprache.",
    "REGISTER_USER_FAIL_INVALID_EMAIL": "Der Benutzer kann nicht zur Firma hinzugefügt werden: ungültige E-Mail-Adresse.",
    "REGISTER_USER_FAIL_EMAIL_ALREADY_EXIST": "Der Benutzer kann nicht zur Firma hinzugefügt werden: Die eingegebene E-Mail-Adresse ist bereits registriert.",
    "REGISTER_USER_FAIL_CGV_NOT_FOUND": "Benutzer kann nicht zur Firma hinzugefügt werden: AGBs nicht gefunden.",
    "REGISTER_USER_FAIL_COMPANY_NOT_FOUND_OR_INVALID_TOKEN": "Firma oder Einladungstoken nicht gefunden.",
    "REGISTER_USER_FAIL_EMAIL_REJECTED": "Der Benutzer kann nicht zur Firma hinzugefügt werden: E-Mail-Adresse abgelehnt.",
    "REGISTER_USER_FAIL_CLEAR_INVITATION": "Die Einladung kann nicht abgelehnt werden.",
    "REGISTER_USER_FAIL_INVALID_OR_EXPIRED_LINK": "Der Registrierungslink ist ungültig oder abgelaufen.",
    "REGISTER_USER_SUCCESS": "Neuer Benutzer erfolgreich registriert.",
    // Invite user to company
    "INVITE_USER_FAIL_INVALID_EMAIL": "Benutzer kann nicht eingeladen werden: ungültiges E-Mail-Format.",
    "INVITE_USER_FAIL_BAD_PERMISSION": "Sie haben nicht die Berechtigung, einen neuen Benutzer einzuladen.",
    "INVITE_USER_FAIL_ALREADY_INVITED": "Der Benutzer hat bereits eine Rolle im Unternehmen.",
    "INVITE_USER_FAIL_ALREADY_ADMIN_OR_ACCOUNTANT": "Der Benutzer ist bereits Administrator oder Buchhalter einer anderen Firma.",
    "INVITE_USER_FAIL_INVALID_POSITION": "Benutzer kann nicht eingeladen werden: ungültige Position.",
    // Join
    "JOIN_COMPANY_FAIL_INVALID_COMPANY_OR_TOKEN": "Firma oder Einladungstoken nicht gefunden.",
    "JOIN_COMPANY_FAIL_EMAIL_REJECTED": "Ihre E-Mail Adresse wurde abgelehnt.",
    "JOIN_COMPANY_FAIL_UNABLE_TO_UPDATE_USER": "Der Benutzer kann der Firma nicht zugewiesen werden.",
    "JOIN_COMPANY_FAIL_INVITATION_NOT_FOUND": "Ich kann die Einladung nicht finden.",
    // Revoke
    "REVOKE_USER_FAIL_BAD_PERMISSION": "Nur Administratoren können Benutzer sperren.",
    "REVOKE_USER_FAIL_SELF_REVOCATION": "Sie können sich nicht von einem Unternehmen trennen.",
    "REVOKE_USER_FAIL_EMAIL_NOT_FOUND": "Kann die E-Mail-Adresse des zu widerrufenden Benutzers nicht finden",
    "REVOKE_USER_FAIL_IS_ADMIN": "Unmöglich, den Ersteller des Unternehmens zu widerrufen.",
    "REVOKE_USER_FAIL_WRONG_ROLE": "Der Benutzer mit der angegebenen Rolle kann nicht gefunden werden.",
    "REVOKE_USER_FAIL_ACCOUNTING_ERROR": "Die Abrechnungsdaten des Benutzers können nicht gelöscht werden.",
    "REVOKE_USER_SUCCESS": "Benutzer erfolgreich gesperrt.",

    // Role
    "ROLE_NOT_FOUND": "Funktion nicht gefunden",
    "ROLE_CREATE_FAIL_BAD_PERMISSION": "Rolle nicht gefunden.",
    "ROLE_DELETE_FAIL_BAD_PERMISSION": "Nur die Administratoren einer Firma können Funktionen löschen.",
    "ROLE_GET_FAIL_BAD_PERMISSION": "Nur die Administratoren einer Firma können die Liste der Funktionen abrufen.",
    "ROLE_UPDATE_FAIL_BAD_PERMISSION": "Nur die Administratoren einer Firma können die Funktionen aktualisieren.",
    "ROLE_DELETE_FAIL_NOT_FOUND": "Funktion kann nicht gelöscht werden: Funktion nicht in der Firma gefunden.",
    "ROLE_UPDATE_FAIL_NOT_FOUND": "Aktualisierung der Funktion nicht möglich: Funktion im Unternehmen nicht gefunden.",
    "ROLE_UPDATE_FAIL_ALREADY_EXIST": "Aktualisierung der Funktion nicht möglich: Funktion im Unternehmen nicht gefunden.",
    "ROLE_CREATE_FAIL_ALREADY_EXIST": "Die Funktion kann nicht erstellt werden: Der Name der Funktion existiert bereits.",
    "ROLE_DELETE_FAIL_IN_USE": "Rolle kann nicht gelöscht werden: Rolle ist einem oder mehreren Benutzern zugewiesen.",
    "ROLE_CREATE_SUCCESS": "Funktion erfolgreich erstellt",
    "ROLE_UPDATE_SUCCESS": "Funktion erfolgreich aktualisiert.",
    "ROLE_DELETE_SUCCESS": "Funktion erfolgreich gelöscht.",

    // CGV
    "CGV_NOT_FOUND": "Die neuesten AGBs wurden nicht gefunden",
    "CGV_VALIDATE_FAIL_UPDATE_ERROR": "Fehler beim Signieren der AGBs für den Benutzer.",
    "CGV_VALIDATE_FAIL_INVALID_VERSION": "Sie haben nicht die neueste Version der AGB unterschrieben.",
    "CGV_VALIDATE_FAIL": "Bitte beachten Sie, dass Sie die AGBs unterschreiben müssen, um unsere Dienste nutzen zu können.",
    "CGV_VALIDATE_SUCCESS_ALREADY_SIGNED": "Sie haben die aktuellen Geschäftsbedingungen bereits unterschrieben.",
    "CGV_VALIDATE_SUCCESS": "Wir danken Ihnen für die Unterzeichnung unserer AGBs.",

    // Token
    "TOKEN_NOT_FOUND": "Token nicht gefunden.",
    "TOKEN_CREATE_FAIL_UPDATE_USER": "Es konnte kein Token für den aktuellen Benutzer erzeugt werden.",
    "TOKEN_CREATE_FAIL_INVALID_DATE": "Das Token kann nicht generiert werden: ungültiges Ablaufdatum.",
    "TOKEN_CREATE_FAIL_NAME_ALREADY_USED": "Unmöglich, das Token zu erzeugen: Der Name des Tokens besteht bereits.",
    "TOKEN_GET_FAIL_EMPTY_NAME": "Der Name des Tokens ist ungültig.",
    "TOKEN_REVOKE_SUCCESS": "Token erfolgreich gesperrt.",

    // Plan
    "PLAN_LIST_FAIL": "Die Liste der Pläne kann nicht abgerufen werden.",

    // IPs
    "IP_NOT_FOUND": "IP nicht gefunden.",
    "IP_BLACKLISTED": "IP auf der schwarzen Liste.",
    "IP_DELETE_FAIL_ALREADY_ON_TERMINATION": "Die Löschung dieser IP-Adresse ist bereits geplant.",
    "IP_DELETE_FAIL_ACTION_NOT_SCHEDULED": "Die Löschung dieser IP-Adresse ist nicht geplant.",
    "IP_DELETE_FAIL_IPV4_DELETION_SCHEDULED": "Die Löschung der entsprechenden IPv4-Adresse ist geplant.",
    "IP_V6_UNAVAILABLE": "IPv6 nicht verfügbar.",
    "IP_V4_V6_COUPLE_NOT_FOUND": "IP_V4_V6_COUPLE_NOT_FOUND",
    // Attach/detach
    "DETACH_IP_SUCCESS": "IP erfolgreich abgetrennt.",
    "ATTACH_IP_SUCCESS": "IP erfolgreich angehängt.",
    "ATTACH_IP_FAIL_NO_MORE_AVAILABLE_IP": "IP kann nicht angehängt werden: keine IP-Adresse mehr verfügbar.",
    "ATTACH_IP_FAIL_IP_IS_NOT_IN_YOUR_COMPANY_POOL": "IP kann nicht angehängt werden: Die IP-Adresse befindet sich nicht im Firmenpool.",
    "ATTACH_IP_FAIL_SERVER_MANAGED": "IP konnte nicht angehängt werden: IP ist nicht mit Server(n) kompatibel. Bitte benutzen Sie die PNAT-Regel.",
    "DETACH_IP_FAIL_BAD_SERVER_IP": "IP kann nicht getrennt werden: Die IP-Adresse ist nicht an den Server angeschlossen.",
    "DETACH_IP_FAIL_IP_NOT_FOUND": "IP kann nicht getrennt werden: IP-Adresse nicht gefunden.",
    "DETACH_IP_FAIL_SERVER_MANAGED": "IP kann nicht getrennt werden: das Trennen von IP ist nicht mit Servern kompatibel.",
    "DETACH_IP_FAIL_PRIMARY_ADDRESS": "IP kann nicht abgetrennt werden: Primäre IP-Adresse.",
    // PNAT rules add/del
    "ADD_IP_PNAT_RULE_SUCCESS": "PNAT-Regel erfolgreich hinzugefügt.",
    "DEL_IP_PNAT_RULE_SUCCESS": "PNAT-Regel erfolgreich gelöscht.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "PNAT-Regel kann nicht hinzugefügt werden: ungültige Port-Quelle.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "PNAT-Regel kann nicht hinzugefügt werden: Ungültiges Port-Ziel.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "PNAT-Regel kann nicht hinzugefügt werden: ungültiges Protokoll.",
    "ADD_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Die PNAT-Regel kann nicht hinzugefügt werden: Der Server wird nicht verwaltet.",
    "ADD_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "PNAT-Regel kann nicht hinzugefügt werden: IP-Adresse nicht gefunden.",
    "ADD_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "PNAT-Regel kann nicht hinzugefügt werden: private Schnittstelle nicht gefunden.",
    "ADD_IP_PNAT_RULE_FAIL_TRANSPARENT_CONFLICT_PNAT": "Kann keine PNAT-Regel hinzufügen: Sie müssen alle vorhandenen PNAT-Regeln von dieser IP entfernen, bevor Sie den transparenten Modus zulassen können.",
    "ADD_IP_PNAT_RULE_FAIL_PNAT_CONFLICT_TRANSPARENT": "Kann die PNAT-Regel nicht hinzufügen: Sie müssen transparente Weiterleitungen für diese IP deaktivieren, bevor Sie die PNAT-Regel hinzufügen können.",
    "ADD_IP_PNAT_RULE_FAIL_SRC_PORT_CONFLICT": "Kann keine PNAT-Regel hinzufügen: Für diese IP wurde bereits eine PNAT-Regel definiert.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "PNAT-Regel kann nicht gelöscht werden: ungültiger Quellport.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "PNAT-Regel kann nicht gelöscht werden: ungültiges Port-Ziel.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "PNAT-Regel kann nicht gelöscht werden: ungültiges Protokoll.",
    "DEL_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Die PNAT-Regel kann nicht gelöscht werden: der Server ist nicht verwaltet.",
    "DEL_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "PNAT-Regel kann nicht gelöscht werden: IP-Adresse nicht gefunden.",
    "DEL_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "PNAT-Regel kann nicht gelöscht werden: private Schnittstelle nicht gefunden.",
    "DEL_IP_PNAT_RULE_FAIL_NOT_FOUND": "PNAT-Regel kann nicht gelöscht werden: PNAT-Regel nicht gefunden.",

    // Demo
    "DEMO_SEND_CREATE_SERVER_SUCCESS": "Anfrage zum Erstellen des Servers im Demo-Modus erfolgreich gesendet.",

    // Managed services
    "MANAGED_SERVICES_ENABLED": "Managed Services sind für dieses Unternehmen bereits aktiviert.",
    "MANAGED_SERVICES_SUCCESS": "Managed Services sind für dieses Unternehmen bereits aktiviert.",

    // Promotional code
    "PROMOTION_CODE_NOT_FOUND": "Codice promozionale non valido.",
    "PROMOTION_CODE_ALREADY_EXISTS": "Der Aktionscode ist bereits vorhanden.",
    "PROMOTION_CODE_INVALID": "Der Aktionscode ist ungültig. Die Länge muss zwischen 4 und 60 Zeichen liegen.",

    // Items
    "ITEM_INVALID_QUANTITY": "Ungültige Artikelmenge.",

    // Generate affiliation Links
    "GENERATE_AFF_LINKS_SUCCESS": "Der Partnerlink wurde erfolgreich erstellt. Sie ist in Ihrer Zeitung eingetragen.",
    "UPDATE_AFF_LINKS_SUCCESS": "Der Partnerlink wurde erfolgreich aktualisiert.",

    // Email validation
    "VALIDATE_EMAIL": "Es wurde eine E-Mail an Sie gesendet, um Ihr Konto zu aktivieren (bitte überprüfen Sie auch Ihre Spam-E-Mails).",
    "VALIDATE_EMAIL_SUCCESS": "Ihre E-Mail wurde erfolgreich validiert.",
    "VALIDATE_EMAIL_SUCCESS_ALREADY_VALIDATED": "Ihre E-Mail wurde bereits validiert.",
    "VALIDATE_EMAIL_INVITE_SUCCESS": "Sie haben die Einladung angenommen.",
    "VALIDATE_EMAIL_FAIL": "Benutzerkonto kann nicht validiert werden. Wenden Sie sich an den Support.",
    "VALIDATE_EMAIL_FAIL_TOKEN_INVALID": "Ungültiger Link, bitte senden Sie eine Validierungsanfrage für Ihre E-Mail erneut.",

    // Parameters
    "PARAM_SINGLE_NOT_FOUND": "SINGLE-Typ-Parameter nicht gefunden.",
    "PARAM_ALREADY_EXISTS": "Parameter existiert bereits",
    
    // Transfer
    "TRANSFER_SUCCESS": "Übertragung erfolgreich",
    "ERR_TRANSFER_NO_TYPE": "Übertragungstyp nicht angegeben",
    "ERR_TRANSFER_SAME_COMPANY": "Übertragung auf dasselbe Unternehmen nicht möglich.",
    "ERR_TRANSFER_DEMO_MODE_SERVER": "Übertragung nicht möglich, Server im DEMO-Modus.",
    "ERR_TRANSFER_GETTING_SUBSCRIPTIONS": "Fehler beim Abrufen der Abonnements",
    "ERR_TRANSFER_STATE_INVALID": "Übertragung nicht möglich, Server gelöscht oder Status ungültig",
    // Stripe
    "stripe": {
      "account_closed": "Bankkonto geschlossen",
      "account_country_invalid_address": "Ungültiges Land des Bankkontos",
      "account_error_country_change_requires_additional_steps": "Länderwechsel erfordert zusätzliche Schritte",
      "account_information_mismatch": "Einige Kontoinformationen stimmen nicht überein",
      "account_invalid": "Die als Wert für den Stripe-Account-Header angegebene Kontonummer ist nicht gültig",
      "account_number_invalid": "Die angegebene Bankkontonummer ist nicht gültig",
      "acss_debit_session_incomplete": "Die ACSS-Debit-Sitzung ist noch nicht bereit",
      "alipay_upgrade_required": "Diese Methode zur Erstellung von Alipay-Zahlungen wird nicht mehr unterstützt",
      "amount_too_large": "Der angegebene Betrag ist höher als der maximal erlaubte Betrag",
      "amount_too_small": "Der angegebene Betrag ist geringer als der minimal erlaubte Betrag",
      "api_key_expired": "Der bereitgestellte API-Schlüssel ist abgelaufen",
      "application_fees_not_allowed": "Anwendung von Gebühren nicht erlaubt",
      "authentication_required": "Authentifizierung erforderlich",
      "balance_insufficient": "Unzureichendes Guthaben",
      "balance_invalid_parameter": "Ungültiger Parameter für die Guthabenmethode",
      "bank_account_bad_routing_numbers": "Das Bankkonto unterstützt diese Währung nicht",
      "bank_account_declined": "Zahlung auf diesem Bankkonto nicht autorisiert",
      "bank_account_exists": "Das Bankkonto existiert bereits",
      "bank_account_restricted": "Das Bankkonto akzeptiert diese Zahlungsmethode nicht",
      "bank_account_unusable": "Bankkonto unbrauchbar",
      "bank_account_unverified": "Bankkonto nicht verifiziert",
      "bank_account_verification_failed": "Verifizierung des Bankkontos fehlgeschlagen",
      "billing_invalid_mandate": "Ungültiges Zahlungsmandat",
      "bitcoin_upgrade_required": "Bitcoin-Zahlungen werden nicht mehr unterstützt",
      "capture_charge_authorization_expired": "Mittel können nicht abgebucht werden, da die Autorisierung abgelaufen ist",
      "capture_unauthorized_payment": "Sie sind nicht berechtigt, die Mittel abzubuchen",
      "card_error": "Ungültiges Zahlungsmittel",
      "card_decline_rate_limit_exceeded": "Ablehnungs-Limit überschritten",
      "card_declined": "Karte abgelehnt",
      "cardholder_phone_number_required": "Telefonnummer erforderlich für die Erstellung europäischer Karten",
      "charge_already_captured": "Mittel bereits abgebucht",
      "charge_already_refunded": "Mittel bereits erstattet",
      "charge_disputed": "Mittel bereits zurückgefordert",
      "charge_exceeds_source_limit": "Limit über den Zeitraum überschritten",
      "charge_expired_for_capture": "Ablauffrist für die Abbuchung der Mittel",
      "charge_invalid_parameter": "Ungültiger Parameter für die Abbuchung der Mittel",
      "charge_not_refundable": "Mittel nicht erstattbar",
      "clearing_code_unsupported": "Der bereitgestellte Clearing-Code wird nicht unterstützt",
      "country_code_invalid": "Ungültiger Ländercode",
      "country_unsupported": "Land nicht unterstützt",
      "coupon_expired": "Gutschein abgelaufen",
      "customer_max_payment_methods": "Maximale Anzahl von Zahlungsmethoden erreicht",
      "customer_max_subscriptions": "Maximale Anzahl von Abonnements erreicht",
      "debit_not_authorized": "Zahlung nicht autorisiert",
      "email_invalid": "Ungültige E-Mail-Adresse",
      "expired_card": "Karte abgelaufen",
      "insufficient_funds": "Unzureichende Mittel",
      "intent_invalid_state": "Zahlungsabsicht in einem ungültigen Zustand",
      "invalid_card_type": "Nicht unterstützter Kartentyp",
      "invalid_charge_amount": "Ungültiger Belastungsbetrag",
      "payment_intent_mandate_invalid": "Ungültiges Zahlungsmandat",
      "payment_method_bank_account_blocked": "Bankkonto gesperrt",
      "payouts_limit_exceeded": "Tageslimit überschritten",
      "payouts_not_allowed": "Zahlung nicht erlaubt",
    },
  },
  "tools": {
    "coming_soon": "Feature kommt noch.",
    "bad_level": "Unzureichendes Service-Level",
  },
}
