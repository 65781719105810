import serverService from "../server.service";
import { tryCatch } from "@/components/shared/util";
import itemService from "@/services/item.service";
import {getSearchParams} from "@/services/utils/searchBuilder";
import cartService from "@/services/cart.service";
import {createToast} from "mosha-vue-toastify";

export const ServerPlans = ["SC1", "SC2", "SC3"]
// export const ServerStatus = ["started", "stopped", "waiting", "pending"]
export const ServerStatus = ["started", "stopped", "pending"]

export const ServerStatusSearch = ["started", "pending"]
export const ServerActions = ["start", "stop", "reboot"]
export const ServerStatusAdmin = ServerStatus.concat(["deleted"])

const itemType = "IP";

export function getGraylogColorClass(state) {
  if (state === null) return "info";
  if (state === "success" || state === "migrate") {
    return "success";
  } else if (state === "error") {
    return "danger";
  } else {
    return "info";
  }
}

const StatesMapping = {
  // "waiting": ["unknow","unpaid", "suspended", "disabled"],
  "pending": [
    "unknow","unpaid", "suspended", "disabled",
    "suspending",
    "starting",
    "stopping",
    "resuming",
    "upgrading",
    "disabling",
    "enabling",
    "creating",
    "deleting",
    "reseting",
    "restoring",
    "rebooting",
    "creating",
    "updating"
  ],
  "started": ["started"],
  "stopped": ["stopped"],
  "deleted": ["deleted"],
}

export function FilterStatetoFullState(states) {
  let newState = []
  for (const state in states) {
    newState = newState.concat(StatesMapping[states[state]])
  }
  return newState
}

export function computeOsName(template) {
  return template.os + " " + template.version.replaceAll("_", " ").replace(/\b(\w)/g, s => s.toUpperCase());
}

export function getIP(server, type = 4) {
  let ip_item = getMainFirstIp(server, type);
  if (ip_item) {
    return ip_item.address;
  }
}

export function getReverse(server) {
  let ip_item = getMainFirstIp(server, 4);
  if (ip_item) {
    return ip_item.reverse;
  }
}

export function getMainFirstIp(server, type = 4) {
  if (
    server.items.mac.sub_items &&
    server.items.mac.sub_items.length > 0
  ) {
    const ip_vx = server.items.mac.sub_items.find(
      (sub_item) => sub_item.ip.version === type
    );
    
    if (ip_vx !== undefined && ip_vx !=null) {
      return ip_vx.ip;
    }
  }
  
  return {gateway:""}
}

export async function onConfirmReverseEdit(oid, text) {
  this.message_success = null;
  this.message_error = null;

  const result = await tryCatch(() =>
    serverService.updateIP(oid, {
      reverse: text,
    })
  );

  this.message_success = result.successMsg;
  this.message_error = result.errorMsg;

  return result.isSuccess;
}

export function getGraphIcon(type) {
  switch (type) {
    case 'cpu':
      return "fas fa-microchip"
    case 'ram':
      return "fas fa-memory"
    case 'net':
    case 'network':
      return "fas fa-network-wired"
    case 'disk':
      return "fas fa-hdd"
    default:
      return "fas fa-exclamation"
  }
}

export function getMax(item, server) {
  let parts = []
  switch (item) {
    case "cpu":
      return server.items.cpu.quantity;
    case "ram":
      return server.items.ram.quantity;
    case "disk":
      return server.items.disk.quantity * server.items.disk.item_unit.value;
    case "network":
      parts = server.items.mac.description.split(" ");
      return Number.parseInt(parts[0]);
  }
}
export function getUnit(item, server) {
  let parts = []
  switch (item) {
    case "cpu":
      return server.items.cpu.item_unit.unit;
    case "ram":
      return server.items.ram.item_unit.unit;
    case "disk":
      return server.items.disk.item_unit.unit;
    case "network":
      parts = server.items.mac.description.split(" ");
      return parts[1];
  }
}

export async function addIp(ctx, serverOid = null) {
  let result = await tryCatch(() =>
    itemService.getItemList(getSearchParams({type: itemType, enabled: true}))
  );
  if (result.isSuccess) {
    const item = result.response[0];
    await ctx.reloadOrder();
    if (item) {
      return await addIPToCart(ctx, item, serverOid)
    }
  } else {
    return result.errorMsg;
  }
}

async function addIPToCart(ctx, item, serverOid = null) {
  let payload = {
    cart_oid: ctx.user.oid,
    item_oid: item.oid,
    quantity: 1,
  }
  if (serverOid) {
    payload.server_oid = serverOid;
  }
  const result = await tryCatch(() =>
    cartService.createItem(payload)
  );
  if (result.isSuccess) {
    ctx.getOrders(result.response.cart_oid);
    createToast(ctx.$t("pages.network.ip-added-to-cart"), {
      type: "success",
      showIcon: true,
      position: "bottom-right",
      onclick() {
        document.querySelector('#order-cart').click();
      }
    });
    return null;
  }
  return result.errorMsg;
}

export async function getCompanyServers(companyOid) {
  let serverList = [];
  if (companyOid) {
    let result = await tryCatch(() => serverService.list(companyOid));
    const servers = result.response;
    serverList = servers.filter(
      (server) => server.state !== "deleted" && server.state !== "unpaid"
    );
  }
  return serverList;
}
