import {common} from '@/settings'
import axios from "axios";


const API_URL = common.api.url;
const API_CARTS = '/cart';

class CartService {
  getOrderItem(orders, itemOid, groupOID="") {
    // console.log("Recherche oid: " + itemOid.toString() + "  pour group : "+ groupOID)

    if (orders && orders.groups && orders.groups.length) {
      for (let i = 0; i < orders.groups.length; i++) {
        if (orders.groups[i].oid === groupOID || orders.groups[i].item_type !== "") {
          for (let j = 0; j < orders.groups[i].items.length; j++) {
            if (orders.groups[i].items[j].oid === itemOid) {
              return orders.groups[i].items[j]
            }
          }
        }
      }
    }
    return null
  }
  
  buyCart(payload) {
    return axios.post(API_URL + API_CARTS + "/buy", payload)
  }

  createCartServer(payload) {
    return axios.post(API_URL + API_CARTS + "/server", payload)
  }

  createItem(payload) {
    return axios.post(API_URL + API_CARTS, payload)
  }

  updateItem(oid, payload) {
    return axios.put(API_URL + API_CARTS + '/' + oid, payload)
  }

  getCarts() {
    return axios.get(API_URL + API_CARTS)
  }

  getCart(payload) {
    return axios.get(API_URL + API_CARTS + "/grouped",  { params: payload })
  }

  deleteCartItem(data) {
    return axios.delete(API_URL + API_CARTS, {data: data})
  }
}

export default new CartService()
