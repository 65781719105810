<template>
  <b-modal
    id="changeLogModal"
    v-model="changelogModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    scrollable
    size="lg"
    @close="hideModal()"
  >
    <ModalHeader/>

    <div class="mt-3">
      <Loading v-if="loading"/>
      <div v-else id="change-log">
        <Markdown :source="latestVersionsChangelog"/>
        <h1 style="border-bottom: 1px solid hsla(210, 18%, 87%, 1);">Older versions</h1>
        <b-tabs
          id="tabs-version"
          not-save-tab
          vertical
          piils
        >
          <b-tab
            v-for="(otherVersion, index) in otherVersionsChangelog"
            :key="index"
            :title="otherVersion.version"
          >
            <Markdown
              :source="otherVersion.data"
              style="padding: 0; margin: 0"
            />
          </b-tab>
        </b-tabs>
        <!--        <div-->
        <!--          v-for="(otherVersion, index) in otherVersionsChangelog"-->
        <!--          :key="index"-->
        <!--        >-->
        <!--          <b-card-header-->
        <!--            class="p-1 d-flex flex-fill"-->
        <!--            header-tag="header"-->
        <!--            role="tab"-->
        <!--          >-->
        <!--            <b-button-->
        <!--              v-b-toggle="`collapse-${otherVersion.ver}`"-->
        <!--              block-->
        <!--              style="background: none; border: none"-->
        <!--            >-->
        <!--              {{ otherVersion.version }}-->
        <!--              <span class="when-opened">-->
        <!--                <a class="fas fa-chevron-down" />-->
        <!--              </span>-->
        <!--              <span class="when-closed">-->
        <!--                <a class="fas fa-chevron-right" />-->
        <!--              </span>-->
        <!--            </b-button>-->
        <!--          </b-card-header>-->
        <!--          <b-collapse-->
        <!--            :id="`collapse-${otherVersion.ver}`"-->
        <!--            accordion="version-accordion"-->
        <!--            role="tabpanel"-->
        <!--          >-->
        <!--            <b-card-body>-->
        <!--              <Markdown :source="otherVersion.data" />-->
        <!--            </b-card-body>-->
        <!--          </b-collapse>-->
        <!--        </div>-->
      </div>
    </div>
    <template #footer>
      <div class="modal-buttons mt-3 justify-content-center">
        <button
          id="btn-confirm-modal"
          class="mb-4 btn btn-cancel"
          @click="hideModal()"
        >
          {{ $t("invoice.modal.button.cancel") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Markdown from "../shared/Markdown";
import {mapState} from "vuex";
import Loading from "../shared/Loading";
import ModalHeader from "../shared/ModalHeader";
// import {BButton, BCardBody, BCardHeader, BCollapse, vBToggle} from "bootstrap-vue-3";
import BModal from "@/components/shared/BModal";
import {isTitanSuperAdmin} from "@/services/utils/company";
// import Tab from "@/components/shared/Tab.vue";

export default {
  // directives: {
  //   "b-toggle": vBToggle
  // },
  components: {
    // Tab,
    // BCardBody,
    // BCollapse,
    // BButton,
    // BCardHeader,
    BModal,
    ModalHeader,
    Markdown,
    Loading,
  },
  props: {
    version: {
      type: String,
      default: "",
    },
  },
  emits: ["confirmClose"],
  data() {
    return {
      changelogModal: false,
      latestVersionsChangelog: "",
      otherVersionsChangelog: [],
      loading: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    async showModal() {
      this.changelogModal = true
      this.loading = true;
      var versionsChangelogData = "";
      try {
        var result = await this.axios.get("/changelog/changelog.json");
        var data = result.data;

        versionsChangelogData = await this.axios.get(
          "/changelog/" +
          this.getType() +
          "/en/v" +
          data[this.getType()].latest +
          ".md"
        );
        this.otherVersionsChangelog = [];
        for (let i = 0; i < data[this.getType()].visible.length; i++) {
          let d = await this.axios.get(
            "/changelog/" +
            this.getType() +
            "/en/v" +
            data[this.getType()].visible[i] +
            ".md"
          );
          this.otherVersionsChangelog.push({
            data: d.data,
            version: data[this.getType()].visible[i],
            ver: data[this.getType()].visible[i].replaceAll(".", "-"),
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.latestVersionsChangelog = versionsChangelogData.data;
        this.loading = false;
      }
    },
    hideModal() {
      this.changelogModal = false
      this.$emit("confirmClose");
    },
    getLanguage() {
      if (this.user.preference.preferred_language === "fr") return "fr";
      return "en";
    },
    getType() {
      if (this.user && isTitanSuperAdmin(this.user)) return "sa";
      return "all";
    },
  },
};
</script>

<style scoped>
.collapsed > .when-closed,
:not(.collapsed) > .when-opened {
  display: none;
}
</style>

<style lang="sass">
#tabs-version

  .nav-tabs
    border: none

    .nav-link
      border-radius: 5px !important

  .nav-item
    padding: 5px 5px 0 5px !important

    .active:nth-child(1)
      background: #ececec !important

  .card-header
    border: 1px solid #f0f5f5
    padding: 0.5rem 1rem 1rem 1rem

  .card-body
    padding: 0 1rem 1rem 1rem

#change-log
  .markdown-body h1,h2,h3
    color: black !important

.dark
  #tabs-version
    .nav-item
      .active:nth-child(1)
        color: #a7b9b9
        background: rgba(85, 85, 85, 0.5) !important
        
  .markdown-body
    background-color: #cccccc

</style>

<style lang="sass" scoped>
</style>
