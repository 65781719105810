import {tryCatch} from "@/components/shared/util";
import paramService from "@/services/param.service";

export function computeStatusColor(status) {
  switch (status) {
    case "closed":
      return "counter-dark";

    case "in_progress":
      return "counter-info";

    case "processing":
      return "counter-info";

    case "assigned":
      return "counter-warning";
      
    case "pending":
      return "counter-success";

    case "pending_client":
      return "counter-info";

    case "new_pending_client":
      return "counter-info";
      
    case "pending_admin":
      return "counter-success";

    case "new_pending_admin":
      return "counter-success";

    case "new":
      return "counter-warning";
  }
}


const StatesMapping = {
  "waiting": ["unpaid", "suspended", "disabled"],
  "pending": [
    "suspending",
    "starting",
    "stopping",
    "resuming",
    "upgrading",
    "disabling",
    "enabling",
    "creating",
    "deleting",
    "reseting",
    "restoring",
    "rebooting",
    "creating",
    "updating"
  ],
  "started": ["started"],
  "stopped": ["stopped"],
  "deleted": ["deleted"],
}

export function FilterStatetoFullState(states) {
  let newState = []
  for (const state in states) {
    newState = newState.concat(StatesMapping[states[state]])
  }
  return newState
}

export function computeLevelColor(status) {
  switch (status) {
    case "low":
      return "";
    case "medium":
      return "text-info";
    case "high":
      return "text-danger";
  }
}

export function fileUpload(fileList) {
  // console.log("fileList", fileList)
  let attachments = [];
  if (fileList.length > 0) {
    fileList.forEach((document) => {
      let reader = new FileReader()
      reader.readAsArrayBuffer(document.file)
      reader.onloadend = async () => {
        
        let attachment = {
          file_name: document.file.name,
          content_type: document.file.type,
          body: [].slice.call(new Uint8Array(reader.result))
          }
        attachments.push(attachment);
        }
      })
  } else {
    attachments = [];
  }
  return attachments
}

export async function getLastUpdate() {
  let result = await tryCatch(() =>
    paramService.get("ticket_update_control")
  );
  if (result.isSuccess && result.response) {
    return result.response.num_value
  }
  return null
}

export const priorities = ["low", "medium", "high"]
export const categories = ["technical", "billing", "sales"]
