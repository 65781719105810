<template>
  <b-modal
    id="modal-ticket-creation"
    v-model="modalTicketCreation"
    :scrollable="true"
    :centered="true"
    :hide-header-close="true"
    hide-header
    :hide-footer="showButtons"
    size="lg"
    body-height="ticket"
  >
    <TicketsCreation
      ref="ticketCreation"
      :admin="admin"
      :return-to-tickets="returnToTickets"
      :server-oid="serverOid"
      :show-buttons="showButtons"
      @confirm="confirmModal()"
      @cancel="cancelModal()"
    />
    <template #footer>
      <div
        v-if="!showButtons"
        class="footer-buttons"
        :class="{'admin': admin}"
      >
        <button
          id="btn-submit-tickets-new"
          class="btn-cancel"
          @click.prevent="cancelModal()"
        >
          {{ $t("modal.cancel") }}
        </button>
        <button
          id="btn-submit-tickets-new"
          class="btn-validate"
          @click.prevent="submit()"
        >
          {{ $t("modal.confirm") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import BModal from "@/components/shared/BModal.vue";
import TicketsCreation from "@/views/tickets/TicketsCreation.vue";

export default {
  name: "ModalTicketCreation",
  components: {
    TicketsCreation,
    BModal,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    returnToTickets: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    serverOid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalTicketCreation: false,
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    show() {
      this.modalTicketCreation = true;
    },
    hide() {
      this.modalTicketCreation = false;
    },
    cancelModal() {
      this.modalTicketCreation = false;
    },
    confirmModal() {
      this.modalTicketCreation = false;
    },
    submit() {
      this.$refs.ticketCreation.submit();
    },
  },
};
</script>

<style lang="sass" scoped>
.footer-buttons
  display: flex
  flex: 1 1 auto
  justify-content: space-between
  margin-top: 1rem
  margin-bottom: 1rem
</style>
