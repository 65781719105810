module.exports = {
  "or": "o",

  "affiliation": {
    "tooltip-uncomplete": "Confirmación pendiente, esta transacción se confirmará a principios de mes",
    "title": "Afiliaciones",
    "threshold": "Rango",
    "thresholds": "Rangos",
    "transactions": "Actas",
    "companies": "Compañías afiliadas",
    "click-to-copy": "Haga clic para copiar el enlace de afiliación",
    "click-to-copy-success": "Copiado",
    "code": "Código de programa",
    "name": "Nombre del programa",
    "date": "Fecha",
    "total": "Total disponible",
    "forecast-total": "Pronóstico total",
    "valid-date": "Está activo desde",
    "valid-date-future": "Será válido el",
    "amount": "Facturación mensual para generar",
    "percentage": "Porcentaje",
    "empty-transactions": "Aún no hay transacciones…",
    "empty-companies": "Aún no hay empresas…",
    "monthly-ca": "Facturación mensual generada",
    "ca": "Facturación generada",
    "percentage-apply": "Porcentaje aplicado",
    "teasing-warning": "Actualmente su empresa no tiene un programa de afiliación.\n" +
      "Para activar este programa, póngase en contacto con nuestro departamento de ventas por correo electrónico o por teléfono.",
    "teasing-detail": "Nuestro programa de afiliación le permite liberarse de facturar a sus clientes, pero también recibir una remuneración por todos los servicios cobrados en el marco de este programa.",
    "tabs": {
      "info": "Información",
      "earn": "Mis ganancias",
      "my-links": "Mis enlaces",
      "generate-unique-link": "Generar un nuevo enlace"
    }
  },

  "affiliation-links": {
    "title": "Mis enlaces de afiliación",
    "new": "Crear un enlace personalizado",
    "code": "Código general de afiliación",
    "company": "Empresa",
    "used_by_company": "¿Utilizado por la empresa?",
    "email": "Correo",
    "actions": "Acciones",
    "false": "No",
    "true": "Sí",
    "modal": {
      "title": "Eliminar este enlace de afiliación",
      "danger": "Esta acción es irreversible.",
      "message": "",
      "button": "Borrar",
      "extra": "¿Está seguro?"
    }
  },

  "help": {
    "key-rename": "Renombrar la llave",
    "key-expire-edit": "Editar la fecha de caducidad de la llave",
    "ssh-rename": "RenoRenombrar clave",
    "ssh-rewrite": "Editar clave",
    "network": "Al conectar su servidor a su Vswitch, se vinculará automáticamente una tarjeta de red.",
    "server-password": "El usuario de SSH sera indicado en la sección de administrador \"Más informaciones\" del panel. Para iniciar sesión como root, utilice el comando \"su\" y su contraseña.",
    "server-ssh": "La administración de claves se puede gestionar desde el menú de su cuenta.",
    "server-create": "-",
    "server-snapshots": "Aunque es útil, un snapshot no reemplaza la realización de una copia de seguridad.",
    "image-rename": "Renommer l'image.",
    "image-os-rename": "Cambiar el nombre del sistema operativo.",
    "image-os-version-rename": "Cambiar el nombre de la versión del sistema operativo.",
    "server-rename": "Renommer le serveur.",
    "server-rename-reverse": "Renombrar el reverse.",
    "server-open-ssh": "Abrir un terminal SSH.",
    "server-iso-mount": "Montar un ISO.",
    "server-iso-unmount": "Desmontar un ISO.",
    "server-pin-dash": "Fijar en el dashboard.",
    "account-ssh": "Sus claves serán visibles durante el proceso de creación de su servidor.",
    "account-api": "Disponible en la próxima versión.",
    "account-2fa": "La autenticación de doble factor permite reforzar la seguridad de su cuenta.",
    "user-pref": "Para personalizar su experiencia SmallCloud, registrad sus preferencias. Puede modificarles a cualquier momento.",
    "licenses": "ATENCIÓN: La elección de su licencia es definitiva. Una vez que haya finalizado su pedido, no podrá cambiar la licencia.",
    "company-user": "El tipo 'Contable' permite acceder a las facturas y procesar los pagos.\nEl tipo 'Gerente' brinda acceso a la gestión técnica de servidores.\nEl tipo 'Administrador' otorga acceso a todas las funcionalidades.",
    "company-del-user": "Suprimir el usuario",
    "company-send-inv": "Reenviar invitación",
    "company-role-edit": "Editar acceso.",
    "company-del-invitation": "Eliminar invitación.",
    "company-del-role": "Eliminar acceso.",
    "show-user-vcard": "Ver la vCard del usuario.",
    "reinit-filter": "Restablecer los filtros",
    "tickets-prio-change": "Cambiar la prioridad",
    "tickets-header-link": "Escribir un nuevo ticket en el soporte.",
    "tickets-link-to-vm": "Enlace con la MV en una nueva pestaña",
    "subscription-rename": "Renombrar el proyecto.",
    "subscription-change-freq": "Cambiar la frecuencia.",
    "subscription-see-all": "Ver todas las facturas de esta proyecto.",
    "subscription-see-next": "Ver el próximo vencimiento.",
    "subscription-next-frequency": "La nueva frecuencia se tendrá en cuenta al renovar la factura.",
    "network-rename": "Renombrar conmutador virtual",
    "company-oid": "Identificador único de la empresa.",
    "ticket-indexer": "Actualizar boletos desde IMAP",
    "subscription-payment-method": "Asignar o restablecer el método de pago para la suscripción",
  },

  "severity": {
    "0": "Comprobar configuración",
    "1": "Información",
    "2": "Advertencia",
    "3": "Alerta",
  },

  "actions": {
    "send": "Enviar",
    "filter": "Filtrar",
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "save": "Guardar",
    "generate": "Generar",
    "close": "Cerrar",
    "delete": "Borrar",
    "back": "Regresar",
    "next": "Siguiente",
    "buy": "Comprar",
    "quit": "Dejar",
    "validate": "Validar",
    "pay": "Paga",
    "enable": "Habilitar",
    "disable": "Deshabilitar",
    "apply": "Solicitar",
    "reboot": "Reinicie ahora",
    "reboot-acceptation": "Acepto que mi servidor se reinicie de inmediato",
    "more": "Mostrar más",
    "less": "Mostrar menos",
    "search-servers": "Encontrar servidores",
    "search-networks": "Encuentra redes",
    "click-to-copy": "Haga clic para copiar",
    "copied": "Copiado",
    "change-avatar": "Change logo",
    "remove-avatar": "Borrar avatar",
    "create_notification": "Crear notificación",
    "buy_space": "Comprar almacenamiento",
    "create": "Crear",
    "update": "Actualizar",
    "refund": "Reembolso",
    "add": "Agregar",
    "message-avatar": "Haz clic para editar",
    "download": "Descargar",
    "new": "Nuevo",
    "modify": "Modificar",
    "create-list": "Crear una lista",
    "show-list": "Ver/Editar la lista",
    "open-all": "Abrir todo",
    "close-all": "Cerrar todo",
    "re-init": "Reiniciar",
    "new-f": "Nuevo",
    "open-list": "Abrir la lista",
    "close-list": "Cerrar la lista",
    "terminate": "Cancelar",
    "cancel-terminate": "Cancelar la cancelación",
    "multi-pay": "Pago múltiple",
    "cancel-delete": "Cancelar eliminación",
    "phone-link": "Llamada Directa",
    "init-drp": "Acceder al PRD",
    "reset-drp": "Salir del PRD",
  },
  "popup": {
    "coming-soon": "Coming soon",
  },
  "modal": {
    "definitive-action": "Esta acción es irreversible",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "finalize": "Finalizar el pedido",
    "confirm-text": "Esta operación debe confirmarse escribiendo \"CONFIRMAR\" más abajo",
    "confirm-placeholder": "Escribe CONFIRMAR",
    "confirmation-word": "CONFIRMAR",
    "server-list": {
      "attached-servers": "Servidores adjuntos",
      "not-attached-servers": "Servidores no adjuntos",
      "all": "Todos",
      "no-server": "Ningún servidor",
    },
    "dialog-reset-network": {
      "title": "Restablecer interfaz de red",
      "warning": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, la interfaz de red del servidor se reiniciará.",
      "extra": "¿Está seguro de que desea restablecer la interfaz de red del servidor?",
      "message2": "Al confirmar esta acción, la interfaz de red del servidor se reiniciará.",
      "extra2": "¿Está seguro de que desea restablecer la interfaz de red de estos servidores?"
    },
    "dialog-reset": {
      "title": "Reinicio del servidor",
      "warning": "Acción irreversible",
      "message": "Al confirmar esta acción, su servidor se restablecerá. Tus datos e instantáneas se borrarán permanentemente.",
      "extra": "¿Está seguro de que quiere restablecer este servidor?"
    },
    "dialog-terminate": {
      "title": "Terminación del servidor",
      "warning": "Acción peligrosa pero reversible",
      "message": "Al confirmar esta acción, su servidor se terminará.",
      "extra": "¿Está seguro de que quiere terminar este servidor?",
      "button": "Terminar"
    },
    "dialog-cancel-terminate": {
      "title": "Cancelación de la terminación del servidor",
      "warning": "Acción peligrosa",
      "message": "Al confirmar esta acción, la terminación su servidor se cancelará.",
      "extra": "¿Está seguro de que quiere cancelar la terminación de este servidor?",
      "button": "Cancelar la terminación"
    },
    "dialog-snapshot-restore": {
      "title": "Restaurando snapshot",
      "warning": "Acción irreversible",
      "message": "Al confirmar esta acción, su servidor será restaurado gracias al snapshot.",
      "extra": "¿Está seguro de que quiere restaurar este snapshot?"
    },
    "dialog-snapshot-delete": {
      "title": "Supresión del snapshot",
      "warning": "Acción irreversible",
      "message": "Al confirmar esta acción, su snapshot será borrado.",
      "extra": "¿Está seguro de que quiere borrar al snapshot?"
    },
    "dialog-ip-detach": {
      "title": "Despegar una IP",
      "warning": "Acción irreversible",
      "message": "Al confirmar esta acción, esta IP será despagada de este servidor.",
      "extra": "¿Está seguro de que quiere despegar esta IP?"
    },
    "dialog-payment-method-remove": {
      "title": "Borrar este medio de pago.",
      "warning": "Acción importante.",
      "message": "Al confirmar esta acción, este medio de pago será borrado.",
      "extra": "¿Estás seguro de que quieres cancelar este pedido?",
      "extra2": "Las suscripciones que utilizan este método de pago se restablecerán al método de pago predeterminado.",
    },
    "dialog-order-cancel": {
      "title": "Cancele esta orden.",
      "warning": "Acción importante.",
      "message": "Al confirmar esta acción, este comando será cancelado.",
      "extra": "¿Estás seguro de que quieres borrar este pedido será cancelado?"
    },
    "dialog-changing-frequency": {
      "title": "¿Está seguro de que quiere cambiar la frecuencia de facturación?",
      "warning": "El cambio entrará en vigor la próxima vez que factures",
      "message": "La nueva frecuencia de facturación: ",
    },
    "dialog-changing-project-name": {
      "title": "¿Está seguro de que quiere cambiar el nombre del proyecto?",
      "warning": "El cambio de nombre sobre su factura se hará efectivo a la próxima facturación",
      "message": "El nombre de su nuevo proyecto será: ",
    },
    "dialog-ConfirmRoleChange": {
      "title": "¿Está seguro de que quiere cambiar la función de ?",
      "warning": "Acción importante",
      "message": "La nueva función será : ",
    },
    "dialog-user-delete": {
      "title": "Suprimir esta invitación",
      "warning": "Acción definitiva",
      "message": "Al confirmar esta acción, la invitación será suprimido.",
      "extra": "¿Está seguro de que quiere suprimir la invitación?"
    },
    "dialog-invitation-delete": {
      "title": "Eliminar esta invitación",
      "warning": "Esta operación es definitiva",
      "message": "Al confirmar la operación se eliminará esta invitación.",
      "extra": "¿Realmente quieres borrar este usuario?"
    },
    "dialog-ticket-level-change": {
      "title": "Cambiar el nivel de prioridad de su ticket",
      "warning": "Acción importante",
      "message": "Al confirmar esta acción, el ticket cambiará de nivel de prioridad.",
      "extra": "¿Está seguro de que quiere cambiar el nivel de prioridad de su ticket?"
    },
    "dialog-company-update": {
      "title": "Modificación de las informaciones de la empresa",
      "success": "Estas nuevas informaciones serán visibles en su próxima factura.",
      "message": "Al confirmar esta acción, las informaciones de sus empresa se modificarán.",
      "extra": "¿Está seguro de que quiere cambiar estas informaciones?",
    },
    "dialog-payment-cancel": {
      "title": "Cancelar un pago pendiente.",
      "warning": "Esta acción es definitiva.",
      "message": "Al confirmar esta acción, su pago será cancelado.",
      "button": "Confirmar"
    },
    "dialog-payment-refund": {
      "title": "Reembolso de este pago",
      "warning": "Esta acción es definitiva",
      "message": "Al confirmar esta acción, realizará este reembolso",
      "button": "Confirmar"
    },
    "dialog-delete-image": {
      "title": "Eliminar la imagen.",
      "warning": "Esta acción es definitiva",
      "message": "Al confirmar esta acción, su imagen será eliminada permanentemente.",
      "button": "Borrar"
    },
    "dialog-role-delete": {
      "title": "Eliminación de roles",
      "warning": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, se eliminará su función.",
      "extra": "¿Está seguro de que desea eliminar este rol?"
    },
    "dialog-ticket-creation": {
      "title": "Creación de entradas",
      "success": "El nuevo ticket se creará después de la confirmación",
      "message": "Al confirmar esta acción, se creará el nuevo ticket.",
      "button": "Confirmar"
    },
    "dialog-ticket-close": {
      "title": "Ticket de cierre",
      "title2": "Cierre del ticket sin respuesta",
      "warning": "El boleto se cerrará después de la confirmación",
      "message": "Confirmar esta acción cerrará el ticket.",
      "button": "Confirmar"
    },
    "dialog-item-price-update": {
      "title": "Nuevo precio añadido",
      "warning": "Esta acción es importante",
      "message": "Estás modificando el artículo {item} - Nuevo precio: {price}",
      "button": "Crear",
      "extra": "¿Estás seguro?"
    },
    "dialog-item-price-remove": {
      "title": "Eliminar un nuevo precio",
      "warning": "Esta acción es irreversible",
      "message": "Estás eliminando el artículo actualizado {item} - El nuevo precio era: {price}",
      "button": "Eliminar",
      "extra": "¿Estás seguro?"
    },
    "dialog-item-creation": {
      "title": "Creando un artículo",
      "warning": "Esta acción es importante",
      "mensaje": "Estás creando un elemento {item} de tipo: {type}",
      "button": "Crear",
      "extra": "¿Estás seguro?"
    },
    "dialog-item-update": {
      "title": "Actualizando un artículo",
      "warning": "Esta acción es importante",
      "message": "Estás actualizando el elemento {item} de tipo: {type}",
      "button": "Actualizar",
      "extra": "¿Estás seguro?"
    },
    "dialog-item-account-remove": {
      "title": "Eliminar un nuevo código de cuenta",
      "warning": "Esta acción es irreversible",
      "message": "Está eliminando la actualización del elemento {item} - El nuevo código de cuenta era: {code}",
      "button": "Eliminar",
      "extra": "¿Estás seguro?"
    },
    "dialog-item-account-update": {
      "title": "Se agregó un nuevo código de cuenta",
      "warning": "Esta acción es importante",
      "message": "Estás modificando el elemento {item} - Nuevo código de cuenta: {code}",
      "button": "Crear",
      "extra": "¿Estás seguro?"
    },
    "dialog-multi-pay-confirm": {
      "title": "Pago múltiple",
      "warning": "Esta acción registrará un pago",
      "message": "¿Valida la creación de este pago?",
      "button": "Validar",
    },
    "dialog-confirm-hyperV-migration": {
      "title": "Activarás una migración",
      "warning": "Esta acción es importante",
    },
    "dialog-confirm-hyperV-change-state": {
      "title": "Cambio de estado del hipervisor - { hostname } -",
      "danger": "Deshabilitarás este hipervisor",
      "warning": "Está a punto de activar este hipervisor",
      "message": "¿Está seguro de que desea cambiar el estado del hipervisor - { hostname } -?"
    },
    "dialog-confirm-hyperV-change-state-all": {
      "title": "Cambiar el estado de todos los hipervisores",
      "danger": "Estás a punto de cambiar el estado de todos los hipervisores",
      "message": "¿Estás seguro de que deseas continuar con esta operación?",
    },
    "dialog-quote-delete": {
      "title": "Borrar cita",
      "warning": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, esta cita será eliminada",
      "extra": "¿Está seguro de que desea eliminar esta cita?"
    },
    "dialog-quote-accept": {
      "title": "Aceptar una cotización",
      "warning": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, esta cotización será aceptada",
      "extra": "¿Está seguro de que desea aceptar esta cotización?"
    },
    "dialog-notification-delete": {
      "title": "Eliminar notificación",
      "warning": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, esta notificación será eliminada",
      "extra": "¿Está seguro de que desea eliminar esta notificación?"
    },
    "dialog-vat-update": {
      "title": "Vas a agregar el IVA al país {country}.",
      "warning": "Esta acción es importante",
      "message": "El nuevo IVA para {country} será {percentage}% el {date}",
      "button": "Actualizar",
      "extra": "¿Estás seguro?"
    },
    "dialog-vat-remove": {
      "title": "Eliminarás el IVA del país {country}.",
      "warning": "Esta acción es importante",
      "message": "Se eliminará el IVA de {percentage}% el {date} en {country}.",
      "button": "Actualizar",
      "extra": "¿Estás seguro?"
    },
    "dialog-start-demo": {
      "title": "Iniciar demostración",
      "warning": "Esta acción desplegará un servidor no pagado",
      "message": "Al confirmar esta acción, el servidor se implementará sin pago por parte del cliente.",
      "button": "Iniciar",
      "extra": "¿Está seguro de que desea habilitar el modo de demostración?"
    },
    "dialog-stop-demo": {
      "title": "Detener demostración",
      "warning": "Esta acción desplegará un servidor no pagado",
      "message": "Al confirmar, el servidor se desactivará hasta que el cliente pague. Todavía puedes activar el modo de demostración.",
      "button": "Detener",
      "extra": "¿Está seguro de que desea desactivar el modo de demostración?"
    },
    "dialog-enable-server": {
      "title": "Habilitar servidor",
      "success": "Esta acción activará el servidor",
      "message": "Al confirmar esta acción, el servidor se activará incluso si el cliente no ha pagado su factura. Siempre puede desactivarlo",
      "button": "Habilitar",
      "extra": "¿Está seguro de que desea activar el servidor?"
    },
    "dialog-disable-server": {
      "title": "Deshabilitar servidor",
      "danger": "Esta acción desactivará el servidor",
      "message": "Al confirmar esta acción, se deshabilitará toda comunicación con el servidor. Siempre puedes volver a habilitarla",
      "button": "Deshabilitar",
      "extra": "¿Está seguro de que desea desactivar el servidor?"
    },
    "dialog-commercial-delete": {
      "title": "Eliminar Ventas",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, este comercial será eliminado",
      "extra": "¿Está seguro de que desea eliminar este comercial?"
    },
    "dialog-alert-delete": {
      "title": "Eliminar una tarea",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, esta tarea será eliminada",
      "extra": "¿Estás seguro de que quieres eliminar esta tarea?",
    },
    "dialog-line-delete": {
      "title": "Eliminar una línea",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, esta línea será eliminada",
      "extra": "¿Está seguro de que desea eliminar esta línea?"
    },
    "dialog-changing-outsourcing": {
      "title": "Modificación de subcontratación",
      "warning": "¿Está seguro de que desea cambiar el nivel de subcontratación?",
      "message": "Al confirmar esta acción, se modificará el nivel de externalización",
    },
    "dialog-delete-server": {
      "title": "Eliminar servidor inmediatamente",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, el servidor se eliminará.",
      "extra": "¿Estás seguro de que deseas eliminar este servidor?"
    },
    "dialog-invoice-ip": {
      "title": "Acuerdo antes de la cancelación",
      "warning": "Se facturará la antigua dirección IP principal de este servidor",
      "message": "Al confirmar esta acción, se facturará la antigua dirección IP principal del servidor.",
      "extra": "¿Estás seguro de que quieres continuar?"
    },
    "dialog-cancel-terminate-ip": {
      "title": "Cancelar la cancelación de la dirección IP",
      "warning": "Esta acción es reversible",
      "message": "Al confirmar esta acción, se cancelará la cancelación de la IP.",
      "extra": "¿Estás seguro de que quieres cancelar la cancelación de esta IP?",
      "button": "Cancelar cancelación"
    },
    "dialog-changing-payment-state": {
      "title": "¿Estás seguro de que deseas cambiar el estado de pago?",
      "warning": "El cambio se aplicará de inmediato.",
      "message": "El nuevo estado de pago es:",
    },
    "dialog-subscription-update": {
      "title": "Actualizar suscripción",
      "warning": "Si ha eliminado artículos, se perderán.",
      "message": "Al confirmar esta acción, se modificará la suscripción.",
      "extra": "¿Estás seguro de que quieres modificar esta suscripción?",
    },
    "dialog-add-ipv6": {
      "title": "Agregar IPV6 al servidor",
      "success": "La dirección IPV6 se agregará después de la confirmación",
      "message": "Siempre puedes eliminarla de tu pool de direcciones más tarde",
      "button": "Confirmar",
    },
    "dialog-ticket-trash-delete": {
      "title": "Eliminar un ticket de la 'Papelera'",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, el ticket se eliminará (no se eliminará de la bandeja de entrada de correo electrónico).",
      "extra": "¿Está seguro de que desea eliminar este ticket?",
    },
    "dialog-ticket-trash-delete-many": {
      "title": "Eliminar varios tickets de la 'Papelera'",
      "danger": "Esta acción es irreversible",
      "message": "Al confirmar esta acción, los tickets seleccionados se eliminarán (no se eliminarán de la bandeja de entrada de correo electrónico).",
      "extra": "¿Está seguro de que desea eliminar los tickets seleccionados?",
    },
    "dialog-apply-sub-payment-method": {
      "title": "¿Está seguro de que desea utilizar este método de pago?",
      "warning": "El cambio se aplicará en su próximo ciclo de facturación",
      "message": "Método de pago a utilizar: {payment_method}",
    },
    "dialog-remove-sub-payment-method": {
      "title": "¿Está seguro de que desea volver al método de pago predeterminado?",
      "warning": "El cambio se aplicará en su próximo ciclo de facturación",
      "message": "Al confirmar esta acción, se restablecerá el método de pago",
    },
    "scheduled-date": {
      "title-schedule-reboot": "Programar un reinicio",
      "title-schedule-snapshot": "Programación de snapshots",
      "already-scheduled": "Fecha ya programada | Fechas ya programadas",
      "new-schedule": "Nueva programación",
      "validation-error": "La hora debe ser al menos 5 minutos en el futuro",
      "dialog-delete-scheduled-date": {
        "title": "Eliminar programación",
        "warning": "El cambio se aplicará tras la confirmación",
        "message": "Al confirmar esta acción, la programación será eliminada.",
        "extra": "¿Estás seguro de que quieres eliminar esta programación?",
      },
    },
    "project-selection": {
      "title": "Lista de proyectos",
      "placeholder": "Nombre del proyecto",
      "name": "Nombre del proyecto",
      "no-project": "Ningún proyecto",
      "number-of-projects": "Ningún proyecto | 1 proyecto | {nb} proyectos",
    },
    "server-selection": {
      "title": "Lista de servidores",
      "number-of-servers": "Ningún servidor | 1 servidor | {nb} servidores",
    },
  },

  "ip": {
    "type": "V",
    "reverse": "Reverse",
    "attach": "Conectar una IP",
    "detach": "Despegar una IP",
    "buy": "Comprar nuevas IPs",
  },

  "cart": {
    "apply-all": "Aplicar para todos los servidores",
    "dialog-update-all-pass": {
      "title": "Copia de la autenticación a los servidores",
      "info": "",
      "message": "¿Desea aplicar esta contraseña y/o claves SSH para todos los servidores de la cesta?",
      "extra": "¿Está seguro?"
    },
    "quote": {
      "title": "Creación de un presupuesto",
      "create": "Generar cotización",
      "validity_period": {
        "text": "Período de validez",
        "periode": "Mes"
      },
      "payment_methode": "Forma de pago",
      "payment_type": {
        'PSEPA': "Domiciliación bancaria",
        'card': "CB",
      },
      "modal": {
        "title": "Añadir presupuesto a la cesta",
        "message": "Tienes la opción de sobrescribir tu cesta actual, fusionarla o cancelar la acción actual",
        "merge": "Merge",
        "erase": "sobrescribir",
        "cancel": "Cancelar"
      },
    },
    "debug": {
      "validate": "Validar Orden",
    },
    "no-authentication": "Ningún medio de autentificación",
    "update": "Solicite este servidor",
    "title": "Su cesta",
    "empty": "No hay pedido en la cesta…",
    "summary": "Resumen de su pedido:",
    "quantity": "Cantidad",
    "add": "Añadir a la cesta",
    "continue": "Continuar el pedido",
    "no_modal": "No volver a mostrar este mensaje",
    "pay": "Pagar el pedido",
    "payment": "Pago",
    "order": "Pedir un servidor",
    "order-add": "Add a server",
    "removed": "Línea eliminada",
    "updated": "Pedido actualizado",
    "success": "Pedido pagado con éxito",
    "selected_ssh_keys": "Clave SSH seleccionada :",
    "password_use": "Contraseña utilizada :",
    "project_name": "Nombre del proyecto",
    "project_name_create": "Elija o crea un proyecto",
    "project_name_input_placeholder": "Ingrese el nombre del proyecto",
    "project_name_feedback": "Entre 4 y 60 caracteres",
    "project_name_tip": "Elija el nombre de su proyecto o crealo, si no se generará automáticamente.",
    "select_project_name": "Nombre del proyecto seleccionado: ",
    "project_name_placeholder": "Nombre del proyecto",
    "project_name_selector": "Selección del nombre del proyecto",
    "error_redirect": "Será redirigido a la página de Documentos donde podrá finalizar el pago.",
    "license": "Incluyendo licencia",
    "update-credential": "Credenciales actualizadas.",
    "project": "Proyecto",
    "frequency": "Frecuencia",
    "unit_amount": "Importe por unidad",
    "monthly_unit_amount": "Importe de la unidad mensual",
    "first_payment": "Primer pago",
    "period": "Período de {fecha1} a {fecha2}",
    "discount": "Descuento",
    "monthly": "Importe mensual",
    "quarterly": "Importe trimestral",
    "biannually": "Importe semestral",
    "annually": "Importe anual",
    "added_to_cart": "Añadido al carrito",
    "label1": "Empresa",
    "label2": "Comercial",
    "button_paid": [
      "Por favor seleccione un método de pago"
    ],
    "item_type": {
      "ip": "Dirección IP"
    },
    "project-modal": {
      "title": "Elegir el Nombre del Proyecto",
      "comment": [
        "Todos los elementos del mismo proyecto se agruparán en una sola factura.",
      ],
      "header": [
        "Lista de Proyectos Existentes",
        "Nuevo Proyecto",
      ],
      "divided": "O",
    },
  },

  "user": {
    "name": "Nombre",
    "title": "Título",
    "phone": "Teléfono",
    "mobile": "Móvil",
    "email": "Correo electrónico",
    "authorization": "Tipo",
    "role": "Acceso",
    "roles": {
      "SUPER_ADMINISTRATOR": "Super Administrador",
      "ADMINISTRATOR": "Administrador",
      "ACCOUNTANT": "Contable",
      "MANAGER": "Manager",
      "TITAN_SUPER_ADMINISTRATOR": "TITAN - Super Administrador",
      "TITAN_ADMINISTRATOR": "TITAN - Administrador",
      "TITAN_ACCOUNTANT": "TITAN - Contable",
      "TITAN_COMMERCIAL": "TITAN - Comercial",
      "TITAN_MANAGER": "TITAN - Manager",
      "UNKNOWN": "Desconocido",
      "undefined": "Undefined",
      "none": "Todos",
    }
  },

  "role": {
    "name": "Nombre de acceso",
    "allowed-servers": "Servidores permitidos",
    "allowed-networks": "Redes permitidas",
    "authorization": "Tipo",
    "role": "Acceso",
    "type": "Tipo",
    "servers": "Servidores",
    "networks": "Redes",
    "custom-roles": "Acceso",
    "add": "Agregar un acceso",
    "number_of_users": "Usuarios",
    "number_of_invites": "Invitaciones",
  },

  "tickets": {
    "modal": {
      "title": "Confirmación del mensaje"
    },
    "tickets": "Tickets",
    "tickets-empty": "No tiene ninguno tickets.",
    "open-ticket": "Abrir ticket",
    "status": {
      "processing": "En curso",
      "in_progress": "En curso",
      "closed": "Cerrado",
      "assigned": "Assignado",
      "pending": "Pendiente",
      "pending_admin": "Pendiente",
      "pending_client": "En curso",
      "new_pending_admin": "Pendiente",
      "new_pending_client": "En curso",
      "new": "Nuevo",
    },
    "attachments": {
      "received": {
        "title": "Lista de archivos",
        "header": [
          "Apellido",
          "Amable",
        ],
        "popup": "Ver archivo adjunto | Ver archivos adjuntos",
        "error_message": "Error al leer",
      },
      "sent": {
        "title": "Documento(s) para agregar",
        "popup": "Agregar documentos",
        "text": [
          "Deja tus archivos aquí",
          "o",
          "haga clic para seleccionarlos",
        ],
        "placeholder": "Nombre de archivo",
      },
      "content_type_error": "Tipo de archivo no autorizado",
    },
    "message": {
      "tooltip": [
        "Ver más",
        "Ver menos"
      ]
    },
    "trash": {
      "message": [
        "Eliminación exitosa",
        "Error durante la eliminación",
      ],
    },
  },
  "invoices": {
    "invoice": "Factura",
    "order_title": "Pedido",
    "credit_title": "Crédito",
    "next_invoice_title": "Próxima factura:",
    "download-pdf": "Descargar PDF",
    "download-multi-disabled": "Seleccione facturas para descargar",
    "download-multi": "Descargar facturas seleccionadas",
    "payment-multi-disabled": "Seleccionar facturas no pagadas",
    "payment-multi": "Pagar facturas seleccionadas",
    "payment-multi-unpaid": "Por favor, seleccione solo facturas impagadas.",
    "recurring": {
      "states": {
        "pending": "Pendiente",
        "ongoing": "En curso",
        "canceled": "Terminado"
      }
    },
    "states": {
      "all": "Todo",
      "upcoming": "Inminente",
      "ribbon_pending": "No pagada",
      "pending": "Pendiente de pago",
      "ribbon_unpaid": "No pagada",
      "unpaid": "Pendiente",
      "ribbon_paid": "Pagada",
      "paid": "Pagada",
      "ribbon_canceled": "Cancelada",
      "canceled": "Cancelada",
      "available": "Disponible",
      "spent": "Gastado",
      "expired": "Caducado",
    },
    "types": {
      "order": "Pedido",
      "invoice": "Factura",
      "credit": "Nota de crédito"
    }
  },

  "kvm": {
    "title": "Acceso remoto",
    "p1": "Se está creando su acceso remoto",
    "p2": "Por favor espera un momento.",
    "cancel": "Cancelar",
    "start": "Empezar el acceso remoto",
    "stop": "Parar el acceso remoto",
    "fullscreen": "Modo de pantalla completa",
    "fullscreen_leave": "Salir del modo pantalla completa",
    "expand": "Aumentar",
    "compress": "Reducir",
    "pip": "Cambiar al modo PIP"
  },

  "menu": {
    "upgrade": {
      "text": "Nueva versión de la aplicación disponible: ",
      "button": "Recargar la página",
      "later": "Más tarde",
    },
    "faq": "FAQ",
    "accounts": "Cuentas",
    "affiliations": "Afiliaciones",
    "menu": "Menú",
    "dashboard": "Tablero",
    "ip-histo": "Historial de IPs",
    "vms-sidebar": "Servidores / Imágenes",
    "vms": "Servidores",
    "image": "Imágenes",
    "container": "Contenedor",
    "dedicated-serveur": "Servidores dedicados",
    "vms-add": "Pedido de servidores",
    "companies": "Empresa",
    "contacts": "Contactos",
    "network": "Redes",
    "templates": "Templates",
    "company": "Empresas",
    "tickets": "Tickets",
    "recurring": "Suscripciones",
    "documents": "Documentos",
    "settings": "Configuraciones",
    "logout": "Cerrar sesión",
    "lang": "Idioma",
    "dark-mode-header": "Dark mode",
    "dark-mode": "Dark",
    "light-mode": "Light",
    "notifications": "Notificaciones de servidores",
    "intervention": "Otros elementos",
    "stop_impersonate": "Volver al usuario normal",
    "tools":"Herramientas",
    "cron": "Tareas programadas",
  },

  "filters": {
    "search": "Búsqueda",
    "search-server": "Buscar un servidor, sistema operativo, IP, MAC...",
    "reset": "Reiniciar",
    "os": "Sistema operativo",
    "all": "Todos",
    "service": "Servicio",
    "level": "Prioridad",
    "project": "Nombre del Proyecto",
    "periodicity": "Periodicidad",
    "affiliationLinks-per-page": "Número de enlaces por página : ",
    "servers-per-page": "Servidores por página: ",
    "invoices-credits-per-page": "Facturas por página: ",
    "invoices-orders-per-page": "Pedidos y facturas por página: ",
    "recurring-per-page": "Suscripciones por página: ",
    "images-per-page": "Número de imágenes por página :",
    "tickets-per-page": "Tickets por página: ",
    "payments-per-page": "Pagos por página: ",
    "clients-per-page": "Clientes por página: ",
    "orders-per-page": "Pedidos por página: ",
    "ipsHisto-per-page": "Ip por página : ",
    "item-per-page": "Items por página : ",
    "discount-per-page": "Discount por página : ",
    "country-per-page": "Countries por página : ",
    "events-per-page": "Eventos por página : ",
    "elements-per-page": "Elementos por página : ",
    "notifications-per-page": "Notificaciones por página : ",
    "invoices-quote-per-page": "Cotizaciones por página : ",
    "ip-per-page": "IPs por página:",
    "license-per-page": "Número de licencias por página:",
    "line-per-page": "Número de líneas por página:",
    "invoice_types": {
      "invoice": "Factura",
      "order": "Pedido",
      "credit": "Nota de crédito"
    },
    "status": {
      "started": "Empezado",
      "stopped": "Parado",
      "pending": "Pendiente",
      "waiting": "Esperando",
      "new": "Nuevo",
      "closed": "Cerrado",
      "deleted": "Eliminado"
    },
    "ticket": {
      "processing": "Pendiente",
      "in_progress": "Pendiente",
      "closed": "Cerrado",
      "assigned": "Assignado",
      "pending": "Pendiente",
      "pending_admin": "Esperando",
      "pending_client": "Pendiente",
      "new": "Nuevo",
      "new_pending_admin": "Nuevo",
      "new_pending_client": "Nuevo",
      "number": "Número de billete",
      "object": "Objeto",
      "company": "Empresa",
    }
  },

  "ticket": {
    "server": "Servidor | Servidores",
    "status": "Estado",
    "number": "Nº",
    "user": "Creador",
    "object": "Objeto",
    "service": "Servicio",
    "product": "Producto",
    "created_at": "Fecha de creación",
    "messages": "Mensajes",
    "company": "Empresa",
    "lock": "Bloquear",
    "unlock": "Desbloquear",
    "company_info": "Información del cliente",
    "last_reboot": "Último reinicio",
    "indexer": "Reindexar",
    "state-date": "Último estado",
  },
  "admin": {
    "client": {
      "actif": {
        "true": "Inactivo",
        "false": "Activo"
      },
      "filter": "Filtro de clientes",
    },
    "discount": {
      "actif": {
        "true": "Activo",
        "false": "Inactivo"
      },
      "type": {
        "FIX": "Fijado",
        "PERCENTAGE": "Porcentaje"
      },
    },
    "item": {
      "title": "Artículos",
      "button": "Nuevo",
      "sort": {
        "sort1": {
          "title": "Elegible para descuentos",
          "state": {
            "true": "Activo",
            "false": "Inactivo"
          },
        },
        "sort2": {
          "title": "Suscripción elegible",
          "state": {
            "true": "Si",
            "false": "No"
          },
        },
        "sort3": {
          "title": "Activo",
          "state": {
            "true": "Si",
            "false": "No"
          },
        },
      },
      "header": {
        "name": "Nombre",
        "plan": "Planear",
        "category": "Categoria",
        "enabled": "Activo",
        "type": "Typo",
        "description": "Descripción",
        "price": "Precio actual sin IVA",
        "discount_enabled": "Descuentos",
        "status": "Estado",
        "subtype": "Subtipo",
      },
      "type": {
        "CPU": "CPU",
        "DISK": "Disk",
        "RAM": "RAM",
        "HARDWARE": "Hardware",
        "SERVICE": "Servicio",
        "LICENSE": "Licencia",
        "QOS": "QOS",
        "MAC": "MAC",
        "IP": "IP",
        "SNAPSHOT": "Snapshot",
        "STORAGE": "Pool Storage",
        "CREDIT": "Crédito",
        "REBOOT": "Reinicio de servidor",
        "SMS": "SMS",
        "rent": "Alquiler",
        "buy": "Compra"
      },
      "creation": {
        "creation_title": "Creando un nuevo artículo",
        "update_title": "Actualización de un artículo",
        "modal": {
          "label1": "Nombre",
          "placeholder1": "",
          "label2": "Descripción",
          "placeholder2": "Descripción",
          "label3": "Tipo de elemento",
          "placeholder3": "",
          "label4": "Método de facturación",
          "placeholder4": "",
          "label5": "Descuento",
          "placeholder5": "",
          "label6": "Activo",
          "placeholder6": "",
          "label7": "Precio sin IVA",
          "placeholder7": "Precio sin IVA",
          "label8": "Código de contabilidad",
          "placeholder8": "Código de contabilidad",
          "label9": "Código de Plesk",
          "placeholder9": "",
          "label10": "Subtipo",
          "label11": "Número de créditos",
          "edit_price": {
            "label1": "Precio sin IVA",
            "placeholder1": "Precio sin IVA",
            "label2": "Fecha de aplicación",
            "placeholder2": "Fecha de aplicación",
          },
          "edit_code": {
            "label1": "Código de contabilidad",
            "placeholder1": "Código de contabilidad",
            "label2": "Fecha de aplicación",
            "placeholder2": "Fecha de aplicación",
          },
        },
        "message": {
          "create_success": "Creación exitosa",
          "update_success": "Actualización exitosa",
          "remove_success": "Eliminación exitosa",
        },
      },
      "add_item": {
        "title": "Agregar un artículo a una suscripción",
        "header1": "Artículo",
        "header2": "Número de serie",
        "header3": "ID del servidor",
        "header4": "Cantidad",
        "checkbox1": "Crear factura parcial",
        "checkbox2": "El elemento está desplegado",
        "start_date": "Fecha de inicio de la factura",
      },
      "manage_item": {
        "title": "Gestión de elementos de la suscripción",
        "title-list": "Lista de elementos de la suscripción",
        "delete-item": "Eliminar elemento",
        "delete-all": "Eliminar suscripción"
      },
    },
    "accounting": {
      "tabs": {
        "title": {
          "invoices": "Documentos",
          "taxes": "Impuestos",
          "payment": "Pagos",
          "orderForm": "Pedidos",
          "csv": "Exportar CSV"
        },
      },
      "menu": [
        "Clientela",
        "Membresías",
        "Documentos",
        "Suscripciones",
        "Elementos",
        "Cupones",
        "Impuestos",
        "Plazos de pago",
        "Tareas diarias",
        "Externalización",
        "Vari",
        "Licencias",
      ],
    },
    "company": {
      "contact": {
        "title": "Contactos de la empresa",
        "user": "Usuario",
        "email": "Dirección de correo electrónico",
        "role": "Rol",
        "phone": "Teléfono",
        "role_from": "Estás a punto de cambiar el rol de {name}",
        "role_to": "Su nuevo rol será: {name}",
        "tooltip": [
          "Ver como este usuario",
          "Generar una vCard",
          "Información detallada",
        ],
      },
      "tabs": [
        "Clientela",
        "Cita",
        "Comercial",
      ],
      "tab_title": "Comerciales",
      "modal": {
        "title": "Asignar un vendedor",
      },
      "popup": [
        "Edición en curso",
        "Haz click para editar",
      ],
      "detail": {
        "tab": [
          "Info",
          "IVA",
          "Contactos",
          "Comercial",
          "Membresías",
          "Cuotas y limitaciones",
          "Transferencia entre empresas",
          "Configuraciones adicionales",
        ],
        "info": {
          "label": [
            "Nombre de la Sociedad",
            "Correo electrónico",
            "Nivel de reducción",
            "Empresa deshabilitada",
            "Método de pago activo",
            "Comercial",
            "Retraso de pago",
            "Cantidad autorizada",
            "Ninguna",
            "Implementación automática de recursos",
            "Envío automático de la factura por correo electrónico",
            "Pago único para varias facturas",
            "URL para el PRD",
            "Número de teléfono",
            "Número de créditos",
            "Autorización para enviar SMS",
          ],
          "option": [
            "Ninguna",
            "Inmediato | {days} día | {days} días",
            "Fin de mes | {days} día fin de mes | {days} días fin de mes",
          ],
          "help": [
            "Para agregar un nuevo descuento, por favor haga clic en este botón en modo de edición.",
          ],
        },
        "vat": {
          "label": [
            "Número de IVA intracomunitario",
            "Válido",
            "Comprobado",
          ],
          "information": [
            "Dirección",
            "Código de país",
            "Apellido",
            "Consulta de fecha",
            "Número de valor agregado",
            "Estado",
          ],
          "status": {
            "true": "Válido",
            "false": "No válido",
          },
          "popup": [
            "Consultar y ver información",
            "Controlar",
            "Cancelar cambio"
          ],
        },
        "commercial": {
          "message": [
            "No hay ningún vendedor asignado a esta empresa.",
          ],
          "title": "Ventas de la empresa",
          "button": [
            "Asignar un vendedor",
            "Cambiar comercial",
          ],
          "header": [
            "Apellido",
            "Nombre de pila",
            "Correo electrónico",
          ],
          "modal": {
            "title": "Asignar un vendedor",
          },
        },
        "quota": {
          "label": [
            "Cuota",
            "Restricciones",
          ],
          "header": [
            "Redes",
            "Servidores",
            "UPC",
            "Memoria",
            "Disco",
            "Instantáneas",
            "Almacenamiento",
            "Interfaces",
          ],
        },
        "transfer": {
          "header": [
            "Tipo de transferencia",
            "Empresa receptora",
            "Suscripción(es) a transferir",
            "Servidor(es) a transferir",
          ],
          "type": {
            "1": "Transferencia completa a otra empresa",
            "2": "Transferencia de suscripción(es) a otra empresa",
            "3": "Transferencia de servidor(es) a otra empresa",
          },
        },
      },
    },
    "coupon": {
      "title": "Cupones de descuento",
      "button_new": "Nuevo",
      "button_close": "Cerrar",
      "button_add": "Agregar",
      "status": {
        "title": "Estado",
        "enable": "Activo",
        "disable": "Inactivo",
      },
      "code": "Código",
      "use": "Usar",
      "description": "Descripción",
      "expiry_date": "Fecha de vencimiento",
      "never": "nunca",
      "no_coupon": "No se encontró ningún cupón",
      "popover": {
        "modify": "Modificar",
        "enable": "Habilitar",
        "duplicate": "Duplicar",
        "disable": "Deshabilitar",
        "suppress": "Eliminar",
        "at_start_date": "Efectivo cuando se crea",
        "always": "siempre",
        "unlimited": "0 = ilimitado",
        "need": "Debe seleccionar al menos un artículo e ingresar un código",
        "need_member": "Debe ingresar un código y, o bien ingresar un descuento total o seleccionar al menos un artículo",
      },
      "modal": {
        "title": "Detalle del cupón",
        "code": "Código:",
        "description": "Descripción:",
        "expiry_date": "Fecha de vencimiento: ",
        "max_use": "Máx. de usos: ilimitado | Máx. de usos: {count}",
        "first_order": "Solo en el primer pedido de una empresa",
        "clients": "Cliente objetivo: | Clientes objetivo:",
        "items": "Ningún elemento | Elemento objetivo: {count} | Elementos objetivos: {count}",
        "number": "No",
        "invoice_date": "Fecha de la factura",
        "client": "Cliente",
        "total": "Total sin IVA",
        "discount_amount": "Importe del descuento"
      },
      "creation": {
        "title": "Creación/Actualización de un cupón",
        "tab1": {
          "title": "Configuración de cupones",
          "label": "",
          "code": "Código de cupón",
          "first_order": "Solo en el primer pedido de una empresa",
          "clients": "Apuntar a un cliente específico",
          "description": "Descripción",
          "items": "Número de elementos seleccionados",
          "start_date": "Fecha de inicio",
          "expiry_date": "Fecha de vencimiento",
          "max_use": "Número máximo de usos totales",
          "max_use_client": "Número máximo de usos por cliente",
          "amount": "Descuento total",
          "help": "Ingrese ya sea un descuento total o una lista de artículos",
        },
        "tab2": {
          "title": "Administración de artículos",
          "label1": "Elemento seleccionado | Elemento seleccionado | Elementos seleccionados",
          "label2": "Agregar uno o más artículos",
          "label3": "Cantidad máxima por cupón",
          "header": [
            "Artículo",
            "Tipo de reducción",
            "Valor de descuento",
            "Cantidad máxima",
          ],
          "discount": {
            "type": {
              "FIX": "Cantidad fija",
              "PERCENTAGE": "Porcentaje"
            },
            "type_sign": {
              "FIX": "€",
              "PERCENTAGE": "%"
            },
            "help": "Vacío significa que no hay límite",
          },
        }
      },
      "alert": [
        "Cupón Activado",
        "Cupón desactivado"
      ],
      "switch": {
        "coupon": "General",
        "member": "Miembro",
        "affiliate": "Afiliado"
      },
    },
    "multi_payment": {
      "title": "Pago Múltiple",
      "amount_received": "Cantidad recibida",
      "total_amount_received": "Importe total recibido ({total} €)",
      "payment_date": "Fecha de pago",
      "payment_method": "Método de Pago",
      "reference_number": "Número de referencia",
      "unpaid_invoices": {
        "title": "Facturas sin pagar",
        "header1": "Fecha",
        "deadline": "fecha de vencimiento",
        "header2": "Número de factura",
        "header3": "Cantidad",
        "header4": "Cantidad adeudada",
        "header5": "Pago",
      },
      "non_chargeable_amount": "Cantidad no imputable {amount}€",
    },
    "payment": {
      "modal": {
        "invoice_date": "Fecha de la factura",
        "payment_amount": "Importe del pago",
        "refund_amount": "Importe reembolsado",
        "reference_number": "Referencia",
        "client": "Cliente",
        "invoice_number": "Factura",
        "status": "Estado",
        "bank_status_code": "Código de estado del banco",
        "bank_status_message": "Mensaje de estado bancario",
        "dispute": "Disputa",
        "bank_payment_id": "Referencia bancaria",
      },
      "tab": {
        "title": "Agregar pago",
        "header1": "tipo",
        "header2": "ID de transacción",
        "header3": "Cantidad",
        "header4": "Fecha de ejecución",
        "modal_question": "¿Está seguro de que desea crear este pago?",
      },
    },
    "refund": {
      "refund_number": "Referencia {referencia}",
      "initial_amount": "- Importe de la liquidación con impuestos incluidos: ",
      "message_no_invoice": "No se pagó ninguna factura por este acuerdo",
      "checkbox1": "No crear nota de crédito",
      "checkbox2": "Reembolso parcial",
      "help": "Elige o ingresa un motivo",
      "related_invoice": "Factura relacionada | Facturas relacionadas",
      "header1": "Nombre",
      "header2": "Cantidad",
      "header3": "Precio sin IVA",
      "credit": "Crédito restante sin impuestos",
      "amount_excluding_vat": "Importe sin IVA",
      "amount_including_vat": "Cantidad con IVA incluido",
      "total_excluyendo_vat": "Total sin IVA",
      "total_incluido_vat": "Total con IVA incluido",
      "prepend": "Importe del reembolso incluyendo impuestos",
      "total_credit": "Crédito total restante sin impuestos",
      "total_refund": "Reembolso total",
      "reasons": [
        "Motivo de reembolso",
        "Pago duplicado",
        "Fraudulento",
        "Solicitado por el cliente",
      ],
      "placeholder": [
        "Referencia",
        "Banco",
        "Fecha de creación",
        "Razón para tenerlo",
        "Cantidad",
        "Precio",
        "Importe del reembolso sin crédito",
      ],
      "excluding_vat": "Excluido IVA",
      "including_vat": "IVA incluido",
    },
    "migration": {
      "title": "Migración de hipervisor",
      "label1": "Modo de migración",
      "label2": "Seleccionar hipervisor de origen",
      "label3": "UUID del servidor de origen",
      "label4": "Seleccionar hipervisor de destino",
      "label5": "Hipervisor",
      "no_hypervisor": "No hay hipervisor disponible",
      "success_message1": "El hipervisor { hostname } será { action }.",
      "success_message2": "Migración iniciada",
      "title-list": "Lista de Hipervisores",
      "state": {
        "disable": "Desactivado",
        "enable": "Activado",
      },
      "success_message": "Los cambios se realizaron con éxito",
    },
    "notification": {
      "button_migration": "Migración de HyperV",
      "button_management": "Gestión de hiperV",
      "button_assignment": "Asignar notificación",
      "header": [
        "Gravedad",
        "Título",
        "Mensaje",
        "Reiniciar",
        "Servidores"
      ],
      "modal": {
        "title": "Asignar una notificación",
        "title_add": "Notificación",
        "label": [
          "Gravedad",
          "Título",
          "Mensaje",
          "Reanudar la fuerza",
          "OID de servidor"
        ],
      },
      "reboot": [
        "Sí",
        "No",
      ],
    },
    "vat": {
      "title": "Cambiar la tasa de IVA de un país",
      "filter": "IVA > 0",
      "header": [
        "País",
        "Código de país",
        "Porcentaje",
      ],
      "label": [
        "Fecha de aplicación",
        "Porcentaje"
      ],
      "message": [
        "Actualización exitosa",
        "Fecha elegida < fecha de hoy"
      ],
    },
    "export": {
      "label": [
        "Filtro de fecha",
        "Escrituras",
        "Compañías",
        "Nuevas empresas",
        "Empresas actualizadas",
        "Descargar CSV",
        "Descargando...",
        "Contactos",
        "Para CRM",
        "Nuevos contactos",
        "Contactos actualizados",
      ],
    },
    "retraso en el pago": {
      "title": "Plazos de pago",
      "button": "Nuevo",
      "sort": {
        "state": {
          "verdadero": "Activo",
          "falso": "Inactivo"
        },
      },
      "header": [
        "Número de días",
        "Fin de mes",
        "Activo",
      ],
      "operation": {
        "title": [
          "Crear un término de pago",
          "Actualización de un plazo de pago",
        ],
        "header": [
          "Número de días",
          "Fin de mes",
          "Activo",
        ],
        "mensage": [
          "Creación exitosa",
          "Error al crear",
          "Actualización exitosa",
          "Error al actualizar",
        ],
      },
    },
    "alert": {
      "title": "Tareas diarias",
      "button": "Nuevo",
      "sort": {
        "state": {
          "true": "Activa",
          "false": "Inactiva",
        },
      },
      "header": [
        "Tipo de tarea",
        "Categoría de la plantilla",
        "Nombre de la plantilla",
        "Extensión de la plantilla",
        "Tipo de envío",
        "Núm. de envíos máx. por día",
        "Fecha límite",
        "Tipo de fecha límite",
        "Email Cco",
        "Activa",
        "Desactivar servidor",
      ],
      "operation": {
        "title": [
          "Creación de una tarea",
          "Actualización de una tarea",
        ],
        "header": [
          "Tipo de tarea",
          "Categoría de la plantilla",
          "Nombre de la plantilla",
          "Extensión de la plantilla",
          "Tipo de envío",
          "Núm. de envíos máx. por día",
          "Fecha límite",
          "Tipo de fecha límite",
          "Email Cco",
          "Activa",
          "Desactivar servidor",
        ],
        "message": [
          "Creación exitosa",
          "Error durante la creación",
          "Actualización exitosa",
          "Error durante la actualización",
        ],
      },
      "type": {
        "before_server_deletion": "Antes de la eliminación del servidor",
        "delete_server": "Eliminación del servidor",
        "delete_ip": "Eliminación de IP",
        "invoice_unpaid": "Factura no pagada",
        "payment_method_expired": "Método de pago expirado",
        "invoice_renew": "Facturación",
        "payment_error": "Error de pago",
        "cart_is_paid": "Carrito pagado",
        "ticket_before_closing": "Antes del cierre del ticket",
        "ticket_closing": "Cierre del ticket",
      },
      "template_category": {
        "server": "Servidor",
        "renewal": "Renovación",
        "payment": "Pago",
        "invoice": "Facturas",
        "ticket": "Ticket",
        "ip": "Dirección IP",
      },
      "send_type": {
        "email": "Email",
      },
      "time_diff_type": {
        "day": "Día",
        "month": "Mes",
      },
      "help": [
        "Número de veces que el email se envía en el mismo día o mes (-1: Siempre, 0: Una vez)",
        "Número de días/meses antes (valor negativo) o después (valor positivo) de la fecha de control",
        "Dirección de correo electrónico que recibe el mensaje además del cliente (opcional)",
        "Activa/Desactiva la tarea",
        "Solo para facturas no pagadas. Ejecuta la desactivación del servidor si está marcado.",
      ],
    },
    "miscellaneous": {
      "title": "Varios",
      "none_option": "Ninguno",
      "param": {
        "title": "Configuración",
        "button": "Nuevo",
        "header": [
          "Apellido",
          "Amable",
          "Valor",
          "Fraseología",
          "Fecha",
          "Valor mínimo",
          "Valor máximo",
          "Clave del diccionario",
          "Valor del diccionario",
        ],
        "operation": {
          "title": [
            "Creación",
            "Actualización",
          ],
          "header": [
            "Apellido",
            "Amable",
            "Valor",
            "Fraseología",
            "Fecha",
            "Valor mínimo",
            "Valor máximo",
            "Fraseología",
          ],
          "message": [
            "Creación exitosa",
            "Error al crear",
            "Actualización exitosa",
            "Error al actualizar",
            "Eliminado con éxito",
            "Error al borrar",
          ],
        },
      },
      "function": {
        "title": "Funciones",
        "all": "Todos",
        "help": [
          "Elija una compañía o deje en blanco para todas las compañías",
          "Ingrese la IPv4 principal y haga clic en el botón. El campo 'redirección' se borrará.",
          "Ingrese una dirección IPv4 y elija una o más comprobaciones para realizar. Luego, presione el botón.",
        ],
        "function": [
          "Recálculo de saldo",
          "Restablecer redirección IPv4 primaria",
          "Controles en la dirección IP",
          "Crear una suscripción",
        ],
        "message": {
          "successful": "Actualización exitosa",
          "unsuccessful": "Actualización fallida",
        },
        "api-result": {
          "title": "Resultados de los controles de IP {ip}",
          "more": "Mostrar resultado sin procesar",
          "less": "Ocultar resultado sin procesar",
          "header": [
            "PING",
            "NMAP",
            "TRACEROUTE",
            "ABUSE",
          ],
          "show-result": "Haga clic en el control a realizar",
          "result-ping": [
            "Número de paquetes transmitidos: ",
            "Número de paquetes recibidos: ",
            "Porcentaje de paquetes perdidos: "
          ],
          "result-nmap": [
            "Lista de puertos abiertos: ",
            "No hay puertos abiertos"
          ],
          "result-traceroute": [
            "Salto {hop}: ",
            "Sin resultado"
          ],
          "result-abuse": [
            "Parte de la lista blanca",
            "Dirección IP en lista negra",
            "Dirección IP no en lista negra",
            "Sin resultado"
          ],
        },
        "api-buttons": {
          "button-ping": "Lanzar un Ping en esta dirección IP",
          "button-nmap": "Lanzar un Nmap en esta dirección IP",
          "button-traceroute": "Lanzar un Traceroute en esta dirección IP",
          "button-abuse": "Lanzar un Abuso en esta dirección IP",
        },
        "outsourcing": {
          "header": [
            "Empresa a facturar",
            "Servidor(es)",
            "Artículo",
            "Frecuencia",
            "Nombre del proyecto",
            "Empresa propietaria de los servidores",
            "Tipo de suscripción",
          ],
          "number-of-servers": "Ningún servidor | 1 servidor | {nb} servidores",
        },
      },
      "ticket-trash": {
        "title": "Tickets en la Papelera",
      },
    },
    "outsourcing": {
      "title": "Tercerización",
      "button": "Nuevo",
      "no_param": "Por favor crea todos los parámetros necesarios para crear niveles de servicio.",
      "header": [
        "Nivel",
        "Fraseología",
        "Descripción",
        "criticidad",
        "Tercerización",
        "Prioridad",
        "Intervención",
        "Precio alto",
        "El",
        "Artículo",
      ],
      "operation": {
        "title": [
          "Creación",
          "Actualización",
        ],
        "header": [
          "Nivel de externalización",
          "Fraseología",
          "Descripción",
          "criticidad",
          "Tercerización",
          "Prioridad",
          "Tiempo de intervención",
          "Precio alto",
          "Fecha de aplicación",
          "Artículo",
        ],
        "message": [
          "Creación exitosa",
          "Error al crear",
          "Actualización exitosa",
          "Error al actualizar",
          "Eliminado con éxito",
          "Error al borrar",
          "Por favor, rellene los campos obligatorios",
          "Campos obligatorios",
        ],
      },
    },
    "param": {
      "outsoursing": {
        "outsourcing": "Nivel de servicio",
        "standard": "Tercerización estándar",
        "advanced": "Tercerización avanzada",
        "full": "Tercerización completa",
      },
      "outsourcing_criticality": {
        "outsourcing_criticality": "Criticalidad",
        "low": "Bajo",
        "medium": "Moderado",
        "high": "Alta",
      },
      "outsourcing_priority": {
        "outsourcing_priority": "Prioridad",
        "medium": "Promedio",
        "high": "Alta",
        "priority": "Prioridad",
      },
      "outsourcing_level": {
        "outsourcing_level": "Tercerización",
        "none": "Ninguno",
        "medium": "Moderado",
        "full": "Completa",
      },
      "intervention_type": {
        "intervention_type": "Tipo de intervención",
        "day": "Día",
        "hour": "Hora",
        "minute": "Minuto",
      },
    },
    "ticket-indexer": {
      "title": "Reindexación de boletos",
      "header": [
        "Número máximo de correos electrónicos a procesar",
        "Fecha más antigua para procesar",
      ],
      "help": "Deje estos valores vacíos para reindexar todos los correos electrónicos",
      "mensaje": [
        "Tarea iniciada"
      ],
    },
    "subscription": {
      "type": {
        "0": "Estándar",
        "1": "Servicios gestionados",
        "2": "Plan de recuperación ante desastres",
        "3": "IP",
        "4": "Almacenamiento",
        "5": "Crédito",
      },
      "title": "Detalles de la suscripción",
      "return": "Zurück",
      "return-text": "Zurück zur Abonnementliste",
      "tab": [
        "General",
        "Facturas",
      ],
      "overview": {
        "header": [
          "Nombre del Proyecto",
          "Frecuencia",
          "Fecha de Inicio",
          "Fecha de Fin",
          "Fecha de Actualización",
          "Estado",
          "Pago desactivado para la renovación de facturas",
        ],
        "footer": [
          "Total sin IVA",
          "IVA",
          "Total con IVA",
          "Descuento",
          "Total Neto sin IVA",
        ],
        "update": {
          "success": "Actualización de suscripción exitosa",
          "nothing": "No hay información para actualizar",
          "commercial-success": "Actualización comercial exitosa",
          "date-warning": "Por favor, ingrese una fecha de fin",
          "partial-billing": "Crear factura parcial",
        },
        "detail": {
          "title": "Abonnementartikel",
          "header": [
            "Artículo",
            "Descripción",
            "Servidor",
            "Cantidad",
            "Precio unitario",
            "Descuento",
            "Monto neto",
            "Desplegado",
          ],
        },
        "button": {
          "modify": "Modificar suscripción",
          "add-item": "Agregar artículo",
          "validate": "Validar cambios",
          "cancel": "Cancelar cambios",
          "create-invoice": "Crear factura",
          "generate-invoice": "Generar factura",
        },
      },
    },
    "demo": {
      "enable": {
        "title": "Activación del modo demo",
        "server-name": "Nombre del servidor",
        "transfer": "¿Transferir el servidor a un cliente?",
        "message-success": "Modo demo activado con éxito",
        "message-error": "Error durante la activación del modo demo",
      },
      "disable": {
        "title": "Desactivación del modo demo",
        "server-name": "Nombre del servidor",
        "return": "Retorno del servidor al pool Titan",
        "true": "Sí",
        "false": "No",
        "project": "Suscripción de destino",
        "frequency": "Frecuencia",
        "billing": "¿Facturar?",
        "billing-date": "Facturación desde",
        "message-success": "Modo demo desactivado con éxito",
        "message-error": "Error durante la desactivación del modo demo",
      },
    },
    "license": {
      "title": "Licencias",
      "plesk": {
        "title": "Plesk",
        "list-empty": "Lista vacía",
        "generate": "Generar lista",
        "tooltip": [
          "Generar o regenerar la lista",
          "Descargar un CSV",
        ],
        "waiting": "El tiempo de procesamiento actual puede superar los 2 minutos. Por favor, espere.",
        "search": "Buscar por identificador, elemento, IP, cliente, servidor",
        "goto-server": "Ver el servidor",
        "goto-subscription": "Ver la suscripción",
        "goto-invoice": "Ver la factura",
        "header": [
          "Identificador",
          "Estado",
          "Elemento",
          "Creación",
          "Vinculación",
          "Cliente",
          "Servidor",
          "Estado servidor",
          "Suscripción",
          "Última factura",
          "Key ID",
          "Expiración",
          "Código de activación",
          "Descripción",
          "IP(s) reportada(s)",
        ],
        "sort": {
          "status": {
            "all": "Todos",
            "ACTIVE": "Activo",
            "TERMINATED": "Inactivo",
          },
        },
        "errors": {
          "title": "Errores",
          "legend": "Leyenda",
          "error_legend": [
            "Empresa desconocida",
            "Servidor eliminado o desconocido",
            "Suscripción terminada",
            "Asignación incorrecta del servidor",
          ],
          "error_code": {
            "0": "Ninguno",
            "1": "Empresa",
            "2": "Servidor",
            "3": "Suscripción",
            "4": "Incorrecto",
          },
        },
        "modal": {
          "disable": {
            "title": "Desactivación de la licencia",
            "message": "Al confirmar esta acción, la licencia será desactivada.",
            "warning": "Siempre podrás reactivar esta licencia",
            "extra": "¿Está seguro de que desea desactivar la licencia?",
          },
        },
        "message": [
          "La licencia ha sido desactivada",
          "Error durante la desactivación",
        ],
        "info": {
          "title": "Detalles de la licencia",
          "button": {
            "modify": "Modificar la licencia",
            "stop-modify": "Detener la modificación",
          },
        },
      },
    },
  },
  "invoice": {
    "server": "Servidor",
    "status": "Estado",
    "number": "Nº",
    "client_code": "Código de cliente",
    "client_name": "Cliente",
    "balance": "saldo",
    "final_date": "Fecha límite",
    "product": "Producto",
    "created_at": "Fecha de creación",
    "create": "Creado el",
    "start": "Empezado el",
    "update": "Actualizado el",
    "not_found": "No factura encontrada",
    "not_found_search": "No factura encontrada en su búsqueda.",
    "type": "Tipo",
    "due_date": "Fecha de finalización",
    "amount_ht": "Importe sin IVA",
    "amount_ttc": "Importe incl. IVA",
    "changing_project_name": {
      "title": "¿Está seguro de que quiere cambiar el nombre del proyecto?",
      "description": "El nuevo nombre del proyecto será: ",
      "success": "Cambio de nombre realizado con éxito.",
    },
    "frequency": {
      "title": "Frequencia",
      "detailed_title": "Período de facturación",
      "changing_title": "¿Está seguro de que quiere cambiar la frequencia de facturación?",
      "success": "El cambio de la frequencia de facturación a sido realizada con éxito",
      "MONTHLY": "Mensual",
      "QUARTERLY": "Trimestrial",
      "BIANNUALLY": "Semestrial ",
      "ANNUALLY": "Annual",
    },
    "last_invoice": "Última factura",
    "next_invoice": "Próxima factura",
    "see_all_invoice": "Ver las facturas",
    "company": "Empresa",
    "price": "Precio",
    "project_name": "Proyecto",
    "add_item": "Agregar elemento",
    "manage_item": "Administrar elementos",
    "generate_invoice": "Generar una factura",
    "payment_method": "Método de pago",
    "details": {
      "status": "Estado de la factura",
      "billing_address": "Facturado a:",
      "shipping_address": "Entregado a:",
      "same_shipping_billing": "Entrega y facturación a:",
      "description": "Descripción",
      "initial_amount": "Cantidad sin IVA",
      "discount_rate": "Tasa de descuento",
      "discount_amount": "Total del descuento",
      "net_amount": "Total sin IVA",
      "vat_rate": "Tipo de IVA",
      "vat_amount": "Importe del IVA",
      "total_amount": "Total IVA incluido",
      "remaining_amount": "La cantidad debida",
      "download": "Descargar",
      "legal_notice_text": "Tasa impositiva de penalización : En aplicación con la ley n° 921442 del 31 de deciembre de 1992, se puede pagar esta factura hasta la fecha de caducidad indicada abajo sin descuento por pago adelantado. Toda factura emitida y no pagada en su fecha de vencimiento devengará automáticamente intereses a una tasa equivalente a tres veces la tasa legal definida en el artículo L.441-6 del Código de Comercio, hasta el pago total. Indemnización global por gastos de recuperación de 40 euros (Decreto 2012-1115 de 2 de octubre de 2012).",
      "tva_notice_in_eu": "Exoneración de IVA - artículo 196 de la Directiva  2006/112/CE",
      "tva_notice_out_eu": "IVA no aplicable - artículo 44 de la Directiva 2006/112/CE",
      "facturation_date": "Fecha de factura",
      "credit_date": "Fecha de la nota de crédito :",
      "payment": "Pago en el momento de recibir la factura ",
      "due_date": "Fecha de caducidad",
      "billing_period": "Periodo de facturación",
      "billing_period_sm": "Periodo",
      "project": "Proyecto:",
      "page": "página",
      "of": "sobre",
      "subtotal": "Subtotal",
      "subtotalTag": "sin IVA",
      "cancel": "Cancelar",
      "paid_by": "Pagada por",
      "promotion_code": "Promotional code ",
      "item": {
        "name": "Producto",
        "quantity": "Q.T",
        "unit_price": "P.U",
        "total_price": "Total sin IVA"
      },
      "billing_date": "Fecha de inicio de facturación",
    },
    "modal": {
      "button": {
        "pay": "Pagar",
        "cancel": "Cerrar",
        "return": "Regresar",
        "next": "Siguiente",
        "generate_credit": "Generar crédito",
        "clear": "Borrar",
        "deploy": "Desplegar",
      },
      "payment": {
        "invoices_page": "Página de facturas",
        "servers_page": "Página de servidores",
      },
      "clear": {
        "title": "Liquidar una factura o nota de crédito",
        "header": [
          "Nuevo Monto",
        ],
        "success": "Operación completada con éxito"
      },
      "deploy": {
        "ask_deploy": "¿Está seguro de que desea desplegar los recursos?",
        "success": "Despliegue en progreso",
        "failed": "Solicitud fallida",
      },
    },
    "recurring": {
      "not_found": "No suscripción encontrada.",
      "not_found_search": "Ningun suscripción se corresponde a su búsqueda.",
      "payment_method": {
        "text": [
          "Seleccione el método de pago a utilizar para la suscripción y haga clic en 'Aplicar'.",
          "Para utilizar el método de pago predeterminado, haga clic en 'Restablecer'.",
          "En caso de fallo en el pago, se utilizará el método de pago predeterminado.",
        ],
      },
    },
    "next_frequency": "Próxima frecuencia",
    "account_balance": "El saldo de tu cuenta: {amount}€"
  },

  "credit": {
    "created_at": "Fecha de creación :",
    "start_date": "Fecha de inicio :",
    "expiration_date": "Fecha de caducidad :",
    "credit_date": "Fecha de crédito :",
    "reference_period": "Périodo de reférencia :",
    "referring_invoice": "Factura de referencia :",
    "number": "Crédito N° ",
    "remaining_amount": "Cantidad restante",
    "modal": {
      "title": "Creando una nota de crédito ({factura})",
      "period": "Período en cuestión",
      "header1": "Artículo",
      "header2": "Cantidad",
      "header3": "Cantidad",
      "header4": "Acción",
      "placeholder1": "Precio",
      "placeholder2": "Cantidad",
      "footer": {
        "amount_allowed": "Cantidad disponible sin IVA:",
        "amount_excluding_vat": "Total sin IVA:",
        "amount_including_vat": "Total con IVA incluido:",
        "amount_vat": "IVA:",
        "amount": "Importe sin IVA:",
        "keep_old_values": "Nota de crédito global con montos originales",
      },
    },
  },

  "payment_method": {
    "waiting": {
      "message_less_than_hour": "Su pago está pendiente, por favor espere el siguiente tiempo ",
      "message": "Su pago sigue pendiente, por favor contacte con el servicio de asistencia.",
      "link": "Crear un ticket"
    },
    "card": {
      "empty": "No hay métodos de pago, añada uno para continuar.",
      "existing": "Métodos de pago existentes ",
      "numbers": "Número de la tarjeta:",
      "expiration_date": "Fecha de caducidad (MM/AAAA)",
      "full_name": "Nombre del titulario",
      "reference": "Reference:",
      "default": "Por defecto:",
      "default_ribbon": "defecto",
      "remove": "Borrar",
      "expired": "Tarjeta caducada",
      "iban": "IBAN : ",
      "default_method": 'Esta tarjeta está su medio de pago prederterminado, estará utilizada para las próximas suscripciones.',
      "creation": {
        "title_sepa": "Información sobre el nuevo IBAN",
        "add_sepa": "Add a new IBAN",
        "title": "Informaciones de la nueva tarjeta",
        "valid_card": "Añadir esta tarjeta",
        "valid_sepa": "Aceptar y agregar",
        "name_placeholder": "Nombre Apedillo",
        "email_placeholder": "Correo electrónico",
        "add_card": "Añadir una nueva tarjeta",
        "cancel": "Cancelar"
      },
    },
    "sepa_mandate": "Mediante la firma de esta orden de domiciliación, el deudor autoriza a (A) " +
      "Titan Datacenters France, nuestro proveedor de servicios de pago, a enviar instrucciones a " +
      "la entidad del deudor para adeudar su cuenta " +
      "y (B) a la entidad para efectuar los adeudos en su cuenta siguiendo esas instrucciones. " +
      "Como parte de sus derechos, el deudor está legitimado al reembolso por " +
      "su entidad  en los términos y condiciones del contrato suscrito con la misma. La solicitud " +
      "de reembolso deberá efectuarse dentro de las ocho semanas que siguen a la fecha de adeudo en cuenta. " +
      "Puede obtener información adicional sobre sus derechos en su entidad financiera.",
    "details": {
      "payment_period": "Para el período comprendido entre el",
      "to": "y el",
      "month": "mes",
      "monthly": "Mes",
      "quarterly": "Trimestre",
      "biannually": "Semestre",
      "annually": "Año",
      "initial_amount": "Cantidad sin IVA:",
      "initial_amount_monthly": "Cantidad sin IVA/Mes:",
      "discount": "Descuento:",
      "net_amount": "Total sin IVA:",
      "vat": "IVA:",
      "total_amount": "Total IVA incl.:",
      "total_amount_monthly": "Total IVA incl./Mes:",
      "amount_due": "Cantidad adeudada:",
      "amount_paid": "Cantidad a pagar:",
      "remaining_amount": "La cantidad restante, incluyendo el IVA:",
      "amount_too_low": "Esta cantidad es demasiado baja para cobrarla de inmediato, la factura se pagará automáticamente junto con la renovación de su suscripción.",
      "amount_too_low_accept": "Acepto el débito diferido",
      "non_recurring_amount": "Importe no recurrente sin impuestos :",
      "recurring_amount": "Importe recurrente sin IVA :"
    },
    "credit": {
      "list": "Lista de Créditos",
      "id": "ID de Crédito",
      "credit": "Crédito",
      "allocated_amount": "Total (incl. IVA)",
      "available_amount": "Saldo",
      "provisional_amount": "Saldo provisional *",
      "provision_tips": "Se actualiza al seleccionar.",
      "use": "Crédito Utilizado:",
      "expiration_date": "Fecha de Vencimiento",
      "created_at": "Fecha de Creación"
    },
    "modal": {
      "select_preferred": {
        "title": "Cambio de medio de pago predeterminado",
        "warning": " Este medio de pago estará utilizado par todas sus suscripctiones.",
      },
      "confirm_payment": {
        "title": "Resumen de pago :",
        "amount": "Total del pedido :",
        "method": "Medio de pago utilizado:",
        "cgv_confirm": "Al validar mi pedido, declaro que he leído y aceptado sin reservas: las CVG y la política de privacidad",
        "credit": "Nota de crédito utilizada:",
        "cgv_see": "Ver los ToS",
        "sepa_info_title": "Información SEPA",
        "sepa_info": "El retraso habitual para una transacción SEPA es de entre 7 y 14 días hábiles. " +
          "Su servicio se entregará solo después de la validación completa de la transacción. " +
          "Si lo desea, puede realizar este pago con tarjeta para disfrutar de la entrega inmediata del servicio.",
        "offline": "Pago fuera de línea",
      }
    },
    "state": {
      "failed": "El pago a fallado",
      "action": "Paiement en attente d'action.",
      "action-detail": "Cliquez sur confirmer pour finaliser votre paiement.",
      "pending": "Pago en tratamiendo",
      "success": "Pago logrado, gracias.",
      "processing": "El pago está siendo procesado por el banco.",
      "failed-comment": [
        'Tu pedido ha sido guardado.',
        'Puede encontrarlo en la sección "Empresa", pestaña "Documentos"',
      ],
    },
    "payment_state": {
      "success": "Éxito",
      "error": "Error",
      "waiting": "Esperando",
      "canceled": "Cancelado",
      "processing": "Procesando"
    },
    "list": {
      "id": "#",
      "exec_date": "Fecha : ",
      "type": "Tipo : ",
      "state": "Estado",
      "methodID": "ID",
      "amount": "Total:",
      "card": "Carte",
      "credit": "Nota de crédito",
      "psepa": "PSEPA",
      "vsepa": "VSEPA",
      "cancel_success": "Su pago ha sido cancelado con éxito."
    },
    "promotional_code": {
      "invalid": "Código de descuento inválido",
      "valid": "Código de descuento applicado",
      "valid_cant_apply": "Código de descuento válido pero no applicado en su pedido",
      "amount": "Código de descuento:"
    }
  },
  "image": {
    "modal": {
      "title": "Modificación del espacio de almacenamiento",
      "description": "Aquí puedes aumentar o disminuir el espacio total de almacenamiento. Actualmente estás usando:",
      "downgrade": "Usted está eliminando el almacenamiento, la reducción de su espacio de almacenamiento se hará inmediatamente después de la validación.",
      "current": "Espacio de almacenamiento actual",
      "warning": "Esta acción es importante",
      "future": "Espacio de almacenamiento tras el cambio",
      "update": "Modificación",
      "unit-disk": "GB",
      "price-recuring-final": "* Este importe se añadirá a la facturación periódica de su espacio de almacenamiento"
    },
    "edit": "Editar la cuota",
    "quotas": "Uso del almacenamiento",
    "template-origin": "Plantilla original: ",
    "empty": "No hay imagen...",
    "diskSize": "Tamaño del disco",
    "state": "Estado",
    "name": "Nombre",
    "date": "Fecha de creación",
    "more-info": "Más información",
    "less-info": "Menos información",
    "states": {
      "created": "Creado",
      "creating": "Creando",
      "error": "Error",
      "deleted": "Borrado",
      "deleting": "Eliminación en curso"
    }
  },
  "server": {
    "select": "Seleccione uno o varios servidores",
    "user-name": "Nombre de usuario",
    "from": "De ",
    "has-notification": "Este servidor requiere tu atención",
    "server-empty": "No servidores…",
    "more-info": "Más informaciones",
    "less-info": "Menos informaciones",
    "_": "Servidores",
    "plan": "Plan",
    "name": "Nombre",
    "state": "Estado",
    "uuid": "UUID",
    "oid": "OID",
    "ram": "RAM",
    "ip-protocol": "Protocolo",
    "ipv4": "IPV4",
    "ipv6": "IPV6",
    "ip": "IP",
    "os": "OS",
    "mac": "MAC",
    "cpu": "CPU",
    "vcpu": "vCPU",
    "disk": "Disco",
    "hypervisor": "Hypervisor",
    "ssh": "SSH",
    "kvmip": "KVM IP",
    "deleted_at": "Borrado a",
    "network": "Red",
    "bandwidth": "Bandwidth",
    "disk-qos": "Disco QoS",
    "license": "Licencia",
    "delete-network": "Borrar este Vswitch",
    "reverse": "Reverso",
    "gateway": "Portal",
    "private-network": "Red privada",
    "ssh-keys": "Clave SSH",
    "hostname": "Nombre del host",
    "company": "Empresa",
    "termination": "Programar fecha de teminación",
    "created_at": "Creación",
    "ctrl-alt-del": "Ctrl-Alt-Del",
    "demo": "Modo de demostración",
    "disabled": "Servidor deshabilitado",
    "upgrade": "Añadir unos addons",
    "downgrade": "Downgrade",
    "pay": "Pago",
    "processor": "Procesador : ",
    "ram-capacity": "RAM : ",
    "disk-space": "Espacio del disco : ",
    "metric_not_available": "Temporalmente no disponible.",
    "outsourcing": "Tercerización",
    "no-outsourcing": "Sin outsourcing",
    "drp": "PRD",
    "actions": {
      "start": "Encienda",
      "stop": "Apagar",
      "reboot": "Reboot",
      "hardstop": "Apago forzado",
      "resume": "Resumir",
      "reset": "Reiniciar",
      "terminate": "Terminar",
      "reset-network": "Reset Network",
      "cancel-terminate": "Cancelar la terminación",
      "activate-drp": "Activar el PRD",
      "deactivate-drp": "Desactivar el PRD",
      "schedule-reboot": "Reinicio programado",
    },
    "events": {
      "snapshot": "Snapshot",
      "network": "Red privada",
      "ipkvm": "KVM",
      "changestate": "Servidor",
      "servercreation": "Creación",
      "serverdeletion": "Supresión",
      "serverupdate": "Campo actualizado",
      "serveraddons": "Complemento",
      "migration": "Migración de hipervisor",
      "serveroperations": "Server operation",
      "storageupdate": "Update"
    },
    "states": {
      "unknow": "Aprovisionamiento",
      "createserverimage": "Imagen creada",
      "starting": "Empezando",
      "started": "Empezado",
      "suspending": "Suspendiendo",
      "suspended": "Suspendido",
      "stopping": "Parando",
      "stopped": "Parado",
      "reseting": "Reinicialización en progreso",
      "resuming": "Resumiendo",
      "resumed": "Resumido",
      "upgrading": "Actualizando",
      "disabling": "Desactivando",
      "disabled": "Desactivado",
      "enabling": "Activando",
      "enabled": "Activado",
      "creating": "Creando",
      "created": "Creado",
      "deleting": "Borrando",
      "deleted": "Borrado",
      "rebooting": "Reiniciando",
      "restoring": "Restaurando",
      "restored": "Restaurado",
      "terminated": "Terminado",
      "diskconfigured": "Disco configurado",
      "diskconfiguration": "Configuración del Disco",
      "diskcreated": "Disco creado",
      "diskcreation": "Creación del Disco",
      "diskdeleted": "Disco borrado",
      "diskdeletion": "Supresión del Disco",
      "configurationcreation": "Creación de la configuración",
      "configurationcreated": "Configuración creada",
      "configurationdeletion": "Supresión de la configuración",
      "configurationdeleted": "Configuración borrada",
      "attach-ip": "Conectar ip",
      "detach-ip": "Despegar ip",
      "attach": "Conectar",
      "attached": "Conectado",
      "detach": "Despegar",
      "detached": "Despegado",
      "install": "Instalar",
      "installed": "Instalado",
      "uninstalled": "Desinstalado",
      "unpaid": "En espera del pago",
      "load-iso": "ISO Loaded",
      "loadiso": "ISO Loaded",
      "unload-iso": "ISO Unloaded",
      "unloadiso": "ISO Unloaded",
      "up": "Up",
    },
    "requests": {
      "title": "Acción solicitada ",
      "action": "Acción ",
      "user": "Usuario ",
      "ip": "IP ",
      "create": "Crear",
      "delete": "Eliminar",
      "stop": "Parar",
      "start": "Comienzo",
      "reboot": "Reiniciar",
      "restore": "Restaurar",
      "refresh": "Actualizar ",
      "hardstop": "Parada forzada",
      "reset": "Reinicializar",
      "terminate": "Terminación",
      "attach": "Conectar",
      "attached": "Conectado",
      "detach": "Despegar",
      "detached": "Despegado",
      "install": "Instalar",
      "installed": "Instalado",
      "rename": "Renombrar",
      "decrease": "Disminuir",
      "increase": "Aumentar",
      "update": "Actualización"
    },
    "status": {
      "title": "Estado:",
      "success": "éxito",
      "error": "fallo",
    },
    "entities": {
      "changestate": "Servidor",
      "serverupdate": "Servidor",
      "serverdeletion": "Servidor",
      "servercreation": "Servidor",
      "serveraddons": "Servidor",
      "serverreset": "Servidor",
      "ipkvm": "KVM IP",
      "snapshot": "Snapshot",
      "network": "Red privada",
      "storageupdate": "Storage pool"
    },
    "pending-actions": {
      "key": "Accion(es) que requieren un reinicio : ",
      "info": "Debe reiniciar a través de la interfaz web",
      "addons": "Instalación de complemento",
      "load_iso": "Montaje ISO",
      "unload_iso": "Desmontaje ISO",
      "notification": "Potenciar",
    },
    "notifications": {
      "addons": "Reinicia tu servidor desde la interfaz para que los complementos surtan efecto.",
      "mount_iso": "Reinicia tu servidor desde la interfaz e inicia tu acceso remoto.",
    },
    "server-page": "Página servidor",
    "no-addons": "No es posible realizar ninguna acción. La factura de este servidor no está pagada.",
    "gauge": {
      "menu": {
        "title": "Modelos",
        "model": {
          "bar": "barra",
          "arc": "arco",
          "circle": "Círculo",
          "stroke": "Trazos",
        },
        "change-model": "Cambiar de modelo",
      },
      "save": {
        "button": {
          "save-server": "Preferencias del Servidor",
          "save-user": "Preferencias de usuario",
        },
        "tooltip": {
          "save-server": "Guardar como preferencias para este servidor",
          "save-user": "Guardar como preferencias predeterminadas",
        },
        "message": {
          "successful": "Copia de seguridad exitosa",
          "unsuccessful": "No se pudo guardar",
        },
      },
    },
    "chart": {
      "activity": "Actividad {type} ({unit})",
      "tooltip": [
        "Acercar",
        "Alejar",
        "Actualizar",
        "No rellenar área del gráfico",
        "Rellenar área del gráfico"
      ]
    },
    "tooltips": {
      "delete": "Eliminación inmediata del servidor",
      "show-company": "Ver información de la empresa",
    },
    "drp-text": [
      "Debido a la gran popularidad de nuestro Plan de Recuperación ante Desastres (PRA), lamentamos informarle que su activación no está disponible temporalmente.",
      "Por favor, inténtelo de nuevo más tarde o póngase en contacto con nuestro equipo de soporte técnico para obtener asistencia inmediata.",
      "Le pedimos disculpas por cualquier inconveniente que esto pueda causar y le agradecemos su comprensión.",
      "Atentamente,",
      "El equipo de Titan SmallCloud",
      "+33(0)9 88 99 88 20"
    ],
    "alert-text": [
      "Esta función está temporalmente fuera de servicio.",
      "Estamos trabajando activamente para restaurarla lo antes posible.",
      "Pedimos disculpas por cualquier inconveniente que esto pueda causar y agradecemos su comprensión.",
      "Atentamente,",
      "El equipo de Titan SmallCloud",
      "+33(0)9 88 99 88 20"
    ],
    "uptime": {
      "title": "Tiempo activo",
      "down": "Down",
      "up": "Up",
      "pending": "Pendiente",
      "checkEverySecond": "Comprobar cada {0} segundos",
      "days": "días",
      "hours": "horas",
      "latency": "Latencia",
    },
  },
  "event_log": {
    "requests": {
      "title": "Acción solicitada ",
      "action": "Acción ",
      "user": "Usuario ",
      "server": "Servidor ",
      "old_event": "Viejo evento"
    },
    "type": {
      "network": "Red",
      "ip": "Dirección IP",
      "iso": "ISO",
      "configuration": "Configuración",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Servidor",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disco",
      "vswitch": "Vswitch",
      "addons": "Complemento",
      "state": "Estado",
      "ipkvm": "KVM",
      "changestate": "Server - Cambios",
      "servercreation": "Server - Crear",
      "createserver": "Server - Crear",
      "serverdeletion": "Server - Borrar",
      "serverupdate": "Server - Actualizar",
      "serveraddons": "Server - Addon(s)",
      "migration": "Migración de hipervisor",
      "serveroperations": "Server - Operaciones",
      "storageupdate": "Disco - Actualizar",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Autenticación",
      "material": "Material",
      "hypervisor": "Hipervisor",
      "payment_method": "Método de pago",
      "company": "Empresa",
      "user": "Usuario",
      "invitation": "Invitación",
      "role": "Rol",
      "cron": "Tarea",
      "accounting_code": "Número de cuenta",
    },
    "subtype": {
      "network": "Red",
      "ip": "Dirección IP",
      "iso": "ISO",
      "configuration": "Configuración",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Servidor",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disco",
      "vswitch": "Vswitch",
      "addons": "Complemento",
      "state": "Estado",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Autenticación",
      "material": "Material",
      "hypervisor": "Hipervisor",
      "payment_method": "Método de pago",
      "company": "Empresa",
      "user": "Usuario",
      "invitation": "Invitación",
      "role": "Rol",
      "cron": "Tarea",
      "accounting_code": "Número de cuenta",
    },
    "action": {
      "load": "Cargado",
      "unload": "Descargado",
      "mount": "Montado",
      "unmount": "Desmontado",
      "create": "Crear",
      "creation": "Crear",
      "created": "Creado",
      "attach": "Conectar",
      "attached": "Conectado",
      "detach": "Despegar",
      "detached": "Despegado",
      "start": "Encienda",
      "started": "Empezado",
      "stop": "Apagar",
      "stopping": "Parada",
      "stopped": "Detenido",
      "resume": "Resumir",
      "resumed": "Reanudado",
      "reset": "Reiniciar",
      "increase": "Aumentar",
      "decrease": "Disminuir",
      "update": "Actualizar",
      "updated": "Actualizar",
      "cancel": "Cancelar",
      "delete": "Borrar",
      "deletion": "Borrar",
      "deleted": "Eliminado",
      "upgrade": "Mejora",
      "upgraded": "Actualizado",
      "downgrade": "Degradar",
      "downgraded": "Degradado",
      "install": "Instalar",
      "installed": "Instalado",
      "uninstall": "Desinstalar",
      "uninstalled": "Desinstalado",
      "restore": "Restaurar",
      "restored": "Restaurado",
      "rename": "Rebautizar",
      "renamed": "Renombrado",
      "configuration": "Configuración",
      "configured": "Configurado",
      "disable": "Deshabilitar",
      "disabled": "Desactivado",
      "enable": "Activación",
      "enabled": "Activado",
      "hardstop": "Parada fuerte",
      "reboot": "Reiniciar",
      "suspend": "Suspender",
      "revoked": "Revocado",
      "activate": "Activar",
      "activated": "Activado",
      "deactivate": "Desactivar",
      "deactivated": "Desactivado",
    },
    "status": {
      "title": "Estado:",
      "success": "éxito",
      "error": "fallo",
    },
    "messages": {
      "material_hypervisor_enable": "Hipervisor habilitado",
      "material_hypervisor_disable": "Hipervisor deshabilitado",
      "authentication_two_fa_enabled": "Autenticación 2FA habilitada",
      "authentication_two_fa_disabled": "Autenticación 2FA deshabilitada",
      "authentication_ssh_create": "Autenticación SSH creada",
      "authentication_ssh_created": "Autenticación SSH creada",
      "authentication_ssh_delete": "Autenticación SSH eliminada",
      "authentication_ssh_deleted": "Autenticación SSH eliminada",
      "authentication_ssh_update": "Autenticación SSH actualizada",
      "authentication_ssh_updated": "Autenticación SSH actualizada",
      "ip_attach": "Solicitud de asignación de IP",
      "ip_detach": "Solicitud de desasignación de IP",
      "ip_attached": "Dirección IP asignada",
      "ip_detached": "Dirección IP desasignada",
      "ip_updated": "Dirección IP actualizada",
      "ip_termination": "Solicitud de cancelación de IP",
      "ip_cancel_termination": "Solicitud de cancelación de IP cancelada",
      "server_iso_mount": "Montaje de imagen",
      "server_iso_unmount": "Desmontar imagen",
      "server_iso_mounted": "Imagen ISO del servidor montada",
      "server_iso_unmounted": "Imagen ISO del servidor desmontada",
      "server_iso_create": "Imagen ISO del servidor creada",
      "server_iso_deletion": "Imagen ISO del servidor eliminada",
      "server_addons_upgrade": "Actualización del servidor",
      "server_addons_downgrade": "Degradación del servidor",
      "server_network_enabled": "Red del servidor habilitada",
      "server_network_disabled": "Red del servidor desactivada",
      "server_configuration_creation": "Creación de la configuración del servidor",
      "server_configuration_created": "Configuración del servidor creada",
      "server_configuration_deletion": "Eliminación de la configuración del servidor",
      "server_configuration_deleted": "Configuración del servidor eliminada",
      "server_storage_creation": "Creación de espacio en disco",
      "server_storage_created": "Espacio en disco creado",
      "server_storage_configured": "Espacio en disco configurado",
      "server_storage_deletion": "Eliminación de espacio en disco",
      "server_storage_deleted": "Espacio en disco eliminado",
      "server_termination": "Solicitud de terminación",
      "server_cancel_termination": "Solicitud de terminación cancelada",
      "server_state_started": "Servidor iniciado",
      "server_state_stopped": "Servidor detenido",
      "server_state_resumed": "Servidor reiniciado",
      "server_state_stopping": "Servidor deteniéndose",
      "server_start": "Servidor iniciando",
      "server_stop": "Servidor detenido",
      "server_update": "Servidor actualizado",
      "server_updated": "Servidor actualizado",
      "server_renamed": "Servidor renombrado",
      "server_create": "Servidor creado",
      "server_started": "Servidor iniciado",
      "server_stopped": "Servidor detenido",
      "server_resumed": "Servidor reanudado",
      "server_stopping": "Servidor deteniéndose",
      "server_hardstop": "Parada forzada del servidor",
      "server_reboot": "Servidor reiniciando",
      "storage_snapshot_create": "Creación de instantáneas",
      "storage_snapshot_restore": "Restauración de instantáneas",
      "storage_snapshot_deletion": "Eliminación de instantáneas",
      "storage_snapshot_created": "Instantánea creada",
      "storage_snapshot_restored": "Instantánea restaurada",
      "storage_snapshot_deleted": "Instantánea eliminada",
      "storage_snapshot_updated": "Instantánea actualizada",
      "storage_image_create": "Creando una imagen",
      "storage_image_delete": "Eliminando una imagen",
      "storage_image_created": "Imagen creada",
      "storage_image_deleted": "Imagen eliminada",
      "storage_image_updated": "Imagen actualizada",
      "storage_decreased": "Espacio en disco reducido",
      "storage_increased": "Espacio en disco aumentado",
      "note_updated": "Nota actualizada",
      "console_kvmip_start": "Consola IpKvm iniciando",
      "console_kvmip_stop": "Consola IpKvm parada",
      "console_kvmip_update": "Consola IpKvm actualizada",
      "console_kvmip_started": "Consola IpKvm iniciada",
      "console_kvmip_stopped": "Consola IpKvm detenida",
      "console_kvmip_stopping": "Consola IpKvm deteniéndose",
      "network_vswitch_attach": "Adjuntar conmutador virtual a la red",
      "network_vswitch_detach": "Separación de conmutador virtual de red",
      "network_vswitch_attached": "Vswitch conectado a la red",
      "network_vswitch_tached": "Vswitch desconectado de la red",
      "network_vswitch_create": "Creando un conmutador virtual",
      "network_vswitch_created": "Conmutador virtual creado",
      "network_vswitch_delete": "Eliminando un conmutador virtual",
      "network_vswitch_deleted": "Vswitch eliminado",
      "network_vswitch_update": "Actualización de conmutador virtual",
      "network_vswitch_updated": "Vswitch actualizado",
      "payment_method_created": "Método de pago agregado",
      "createserver": "Creación del servidor",
      "metric_cpu_alert": "Actividad del CPU",
      "metric_ram_alert": "Actividad de la RAM",
      "metric_disk_alert": "Actividad del Disco",
      "metric_net_alert": "Actividad de la Red",
      "company_created": "Empresa creada",
      "company_update": "Actualización de información de la empresa",
      "company_updated": "Información de la empresa actualizada",
      "company_invitation_create": "Creación de una invitación",
      "company_invitation_created": "Envío de una invitación",
      "company_invitation_delete": "Eliminación de una invitación",
      "company_invitation_deleted": "Invitación eliminada",
      "company_user_revoke": "Revocación de un usuario",
      "company_user_revoked": "Usuario revocado",
      "company_user_delete": "Eliminación de un usuario",
      "company_user_deleted": "Usuario eliminado",
      "company_user_join": "Intento de unirse a la empresa",
      "company_user_joined": "El usuario se ha unido a la empresa",
      "company_role_create": "Creación de un rol",
      "company_role_created": "Rol creado",
      "company_role_delete": "Eliminación de un rol",
      "company_role_deleted": "Rol eliminado",
      "company_role_update": "Actualización de un rol",
      "company_role_updated": "Rol actualizado",
      "cron_reboot_create": "Programación de una tarea de reinicio",
      "cron_reboot_created": "Tarea de reinicio programada",
      "cron_reboot_delete": "Eliminación de una tarea de reinicio",
      "cron_reboot_deleted": "Tarea de reinicio eliminada",
      "cron_reboot_update": "Actualización de una tarea de reinicio",
      "cron_reboot_updated": "Tarea de reinicio actualizada",
      "cron_reboot_activate": "Activación de una tarea de reinicio",
      "cron_reboot_activated": "Tarea de reinicio activada",
      "cron_reboot_deactivate": "Desactivación de una tarea de reinicio",
      "cron_reboot_deactivated": "Tarea de reinicio desactivada",
      "cron_snapshot_create": "Programación de una tarea de instantánea",
      "cron_snapshot_created": "Tarea de instantánea programada",
      "cron_snapshot_delete": "Eliminación de una tarea de instantánea",
      "cron_snapshot_deleted": "Tarea de instantánea eliminada",
      "cron_snapshot_update": "Actualización de una tarea de instantánea",
      "cron_snapshot_updated": "Tarea de instantánea actualizada",
      "cron_snapshot_activate": "Activación de una tarea de instantánea",
      "cron_snapshot_activated": "Tarea de instantánea activada",
      "cron_snapshot_deactivate": "Desactivación de una tarea de instantánea",
      "cron_snapshot_deactivated": "Tarea de instantánea desactivada",
      "accounting_code_create": "Creación de número de cuenta",
      "accounting_code_created": "Número de cuenta creado",
      "accounting_code_delete": "Eliminación de número de cuenta",
      "accounting_code_deleted": "Número de cuenta eliminado",
      "api_token_create": "Creación de una clave API",
      "api_token_created": "Clave API creada",
      "api_token_delete": "Eliminación de una clave API",
      "api_token_deleted": "Clave API eliminada",
      "api_token_update": "Actualización de una clave API",
      "api_token_updated": "Clave API actualizada",
    },
  },
  "intervention": {
    "title": "Servicios"
  },
  "pages": {
    "devis": {
      "title": "Cita",
      "number": "Número",
      "commercial": "Comercial ",
      "created_at": "Creado el",
      "expire_at": "Expira el",
      "amount": "Importe",
      "tooltip-download": "Descargar pdf",
      "tooltip-cart": "Añadir a la cesta",
      "empty": "Por el momento no hay cotización.",
      "tooltip-accept": "Aceptar la cotización",
      "validate_at": "Aceptado el ",
      "accept": "Aceptar",
      "invoice_number": "Número de factura ",
      "details": "N° de cotización ",
      "quote_date": "Fecha de cotización ",
      "quote_validity": "Válido hasta el ",
      "create_quote": "Crear una cotización",
      "company": "Empresa",
      "tooltip-delete": "Eliminar cita",
    },
    "lang": "es",
    "ip-histo": {
      "empty": "La historia está vacía.",
      "company_uuid": "Empresa",
      "server_uuid": "Servidor",
      "start_date": "Fecha de inicio",
      "end_date": "Fecha final"
    },
    "login": {
      "title": "Acceso",
      "email-label": "Email",
      "password-label": "Contraseña",
      "login-action": "Acceso",
      "email-not-validated": "Valide su correo electrónico antes de iniciar sesión",
      "email-confirmation-sent": "Un correo electrónico de confirmación ha sido enviado (por favor también revise sus spam).",
      "promotional_code_saved": "El código de promoción ha sido guardado."
    },
    "login2fa": {
      "title": "Autenticación de dos factores",
      "tfa-label": "Código de seguridad"
    },
    "register": {
      "company_invite_title": "¡Bienvenidos a Titan SmallCloud! Para aprobar su cuenta, por favor, rellene este formulario. Estas informaciones se quedarán confidenciales. Puede contactarnos en cualquier momento para solicitar que las borremos.",
      "title": "Para implementar sus instancias y brindarle el mejor servicio, necesitamos informaciones. Estas informaciones se mantendran confidenciales. Puede contactarnos en cualquier momento para eliminarlas.",
      "action": "Registrar",
      "billing-address": "Dirección de facturación",
      "modal": {
        "title": "Ha modificado la información previamente rellenada.",
        "danger": "",
        "message": "",
        "button": "Confirmar",
        "extra": "¿Puede confirmar estos cambios?",
      },
    },
    "dashboard": {
      "status-map": "Mapa de estado TITAN",
      "status-page": "Estado",
      "favorite": "Tus favoritos",
      "notifications": "Notifications",
      "overview-server": "Servidor general",
      "status": {
        "bandwidth": "Ancho de banda",
        "v4-peers-visibility": "v4 peer visibility",
        "v6-peers-visibility": "v6 peer visibility",
        "ping-latency": "Latencia"
      },
      "weather-cpu-ram": "CPU/RAM",
      "weather-public-network": "Red pública (WAN)",
      "weather-private-network": "Red privada (LAN)",
      "weather-storage": "Memoria",
      "pending_servers": "Los servidores esperan la configuración",
      "last_op": "Últimas operaciones",
      "title-tickets": "Sus últimos tickets",
      "title-history": "Sus últimas acciones",
      "weather-ras": "OK",
      "weather-degraded": "Degradado",
      "weather-failure": "Crítica",
      "started-server": "Servidores iniciados : ",
      "stopped-server": "Servidores parados : ",
      "other-server": "Servidores en espera : ",
      "modal": {
        "you_will_be_redirected_to": "Serás redirigido a",
        "status_link": "https://titandc.statuspage.io/",
      },
      "popup": [
        "Estado del centro de datos Titan"
      ],
    },
    "servers": {
      "mac": "MAC dirección",
      "plan": {
        "SC1": "SC1",
        "SC2": "SC2",
        "SC3": "SC3"
      }
    },
    "server-new": {
      "devis-mode": {
        "false": "Activate the quote generation mode",
        "true": "Deactivate the generation mode of an estimate",
      },
      "title": "Configura su servidor",
      "choose-managed": "Elige como gestionar las redes",
      "choose-plan": "Elige sus recursos",
      "choose-os": "Elige su sistema operativo",
      "setup-ssh": "Configura su conexión",
      "licenses": "Licencias ",
      "bandwidth": "Bandwidth",
      "managed-true": "Red gestionada",
      "managed-false": "Red dedicada",
      "managed-true-text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.",
      "managed-false-text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "iops": "Disco QoS : ",
      "ssh-password": "Conecte con su contraseña",
      "ssh-password-input": "Entre su contraseña",
      "ssh-key": "Conecte con su clave SSH",
      "ssh-key-input": "Entre su clave para conectar",
      "ssh-key-hint": "Entre su clave SSH pública",
      "ssh-key-placeholder": "Clave SSH pública",
      "ssh-key-public-msg": "Tiene que estar conectado par utilizar sus claves SSH.",
      "confirm": "Confirmación",
      "finalize": "Completar el pedido",
      "order-plan": "Pedido del servidor",
      "order-with": "con el sistema operativo",
      "order-preinstalled": "preinstalado",
      "order-button": "Pedido para",
      "month": "mes sin IVA",
      "duty-free": "sin IVA*",
      "recurring_description": "* El total será adicional a la facturación recurrente actual",
      "tooltip": "Entra una contraseña o una clave SSH",
      "tooltip_password": "Entra una contraseña",
      "tooltip_windows": "Windows está disponible desde una SC2.",
      "tooltip_windows_to_windows": "Windows sólo puede migrar a Window.",
      "tooltip_license": "No se puede cambiar el tipo de licencia.",
      "tooltip_add": "Añadir a la cesta",
      "tooltip_packetfence": "PacketFence solo está disponible en un SC3.",
      "order-count": "No tiene ningún pedido...|Tiene un pedido en la cesta ...|Tiene {número} pedidos en la cesta",
      "order-added": "Pedido añadido…",
      "order-cart": "Ir a la cesta",
      "order-reset": "Vaciar la cesta",
      "version-placeholder": "Versión",
      "plesk-title": "Selección de las licencias Plesk",
      "plesk-selection": "Elige su licencia:",
      "unit-price": "Precio Unitario:",
      "os": "Sistema operativo",
      "qty": "Cant.:",
      "pwd_hint": "Elige una contraseña y /o una clave SSH",
      "pwd_and_ssh": "Elige una clave SSH y / o una contraseña",
      "cpu-desc": " ",
      "ram-desc": " ",
      "disk-desc": " ",
      "next-step-btn": "Siguiente",
      "promo_code": "Código de descuento",
      "unit": {
        "from_to_gb": "{ from } GB a { to } GB",
        "from_to_cpu": "{ from } vCPU a { to } vCPU",
      },
    },
    "server-detail": {
      "ips": "Direcciones IP",
      "note": "Nota",
      "note-edit": "Editar la nota",
      "note-save": "Conservar la nota",
      "note-help": "Estilizar con Markdown es compatible (ayuda pinchando aquí)",
      "note-default": `Este servidor ha sido instalado con **{templates}** el {creation_date}`,
      "note-empty": "Sin notas",
      "activity": "Actividad",
      "activity-empty": "No hay actividad todavía",
      "graph-reset": "Restablecer el gráfico",
      "network-activity": "Actividad de la red",
      "ram-activity": "Actividad de la RAM",
      "cpu-activity": "Actividad del CPU",
      "disk-activity": "Espacio del Disco",
      "Vswitch-activity": "Actividad de Vswitch",
      "history": "Historial",
      "last-activity": "Última actividad",
      "server-history": "Historial del servidor",
      "no_ip": "Sin dirección IP",
      "snapshots": "Snapshots",
      "snapshot-create": "Crear un snapshot",
      "snapshot-restore": "Reiniciar",
      "snapshot-delete": "Borrar",
      "snapshot-to-template": "Modelo",
      "snapshot-to-template-modal": {
        "title": "Generar una imagen a partir de esta instantánea",
        "name": "Nombre de la imagen"
      },
      "snapshot-empty": "No snapshot.",
      "snapshot-error": "Snapshot ha fallado",
      "terminate-success": "El servidor esta terminando",
      "terminate-canceled": "Terminación del servidor cancelada.",
      "modal-server-downgrade-nothing": "No hay que bajar los complementos.",
      "modal-need-stop-title": "Restablecimiento del servidor",
      "modal-need-stop-text": "Parada de servidor necesaria.",
      "modal-need-stop-description": "Tiene que parar su servidor para continuar",
      "modal-need-stopped-description": "Por favor, espera que su servidor se apague para continuar",
      "modal-server-upgrade-description": "Esta actualización se completará solo después de que se active un reinicio del servidor desde el panel.",
      "modal-mount-cdrom-title": "Carga de CD-ROM",
      "modal-mount-cdrom-text": "Se puede cargar un CD-ROM en el servidor desde un archivo ISO remoto. El servidor se iniciará en el CD-ROM con prioridad hasta que este último se descargue (reboot necesario). La configuración de su ISO se realiza desde el KVM.",
      "modal-mount-cdrom-label": "URL de un ISO de arranque para cargar (solo https) :",
      "modal-mount-cdrom-list-label": "Lista ISO predefinida",
      "modal-mount-cdrom-list-placeholder": "— Seleccione una ISO —",
      "modal-unmount-cdrom-title": "Desmontar de CD-ROM",
      "modal-unmount-cdrom-text": "Esta acción desmontará el CD-ROM cargado. Si el servidor se inicia actualmente desde el CD-ROM, se debe realizar una parada forzada de la máquina para que el desmantelamiento sea efectivo.",
      "modal-server-downgrade-description": "La eliminación de los complementos se llevará a cabo inmediatamente después de la validación.",
      "see-all-server": "Mostrar los servidores",
      "terminate-descriptions-title": "¿Cuál es el motivo de esta terminación?",
      "terminate-descriptions-placeholder": "— Razones —",
      "terminate-descriptions": {
        "over": "El proyecto asociado a este producto ha terminado.",
        "unsuitable": "El producto no es adecuado para mis necesidades.",
        "quality": "La calidad del servicio (averías, ralentizaciones, etc.) no cumple con mis expectativas.",
        "assistance": "La asistencia en línea (servicio al cliente, preguntas frecuentes, soporte, etc.) no me permite administrar correctamente mis servicios.",
        "expensive": "Los productos son demasiado caros.",
        "loyalty": "Falta de reconocimiento (programa de fidelización, bajada de precios, etc.).",
        "mistake": "Cometí un error al realizar el pedido (recursos incorrectos, pedido doble, etc.).",
      },
      "goto_images": "Mis imágenes",
      "modal-addon-exists": "Ya se agregó una actualización al carrito",
      "modal-actual-addon": "(Real { quantity } { type })",
      "modal-none": "Ninguno",
      "addon-addon": "Complementos agregados al carrito",
      "modal-no-ip": [
        "No es posible la terminación del servidor",
        "La terminación solo es posible si el servidor tiene al menos una dirección IPv4 adjunta.",
        "Esta IPv4 debe tener una IPv6 correspondiente que no esté adjunta a otro servidor y forme parte de su pool de direcciones.",
        "Por favor, vaya a su interfaz de red y adjunte una dirección IPv4 a este servidor.",
        "Gracias.",
      ],
    },
    "faq": {
      "title": "FAQ",
      "updated": "Updated"
    },
    "payment-list": {
      "create-payment": "Crear un pago",
      "date": "Fecha",
      "number": "Número de pago",
      "ref": "Referencia",
      "facture": "Facturas relacionadas",
      "mode": "Reguladas por",
      "total-amount": "Importe",
      "charged-amount": "Importe cobrado",
      "unused-amount": "Cantidad no utilizada",
      "client_code": "Código de cliente",
      "client": "Cliente",
      "no_payment": "No se encontraron pagos",
      "modes": {
        'VSEPA': "Transferencia",
        'PSEPA': "Debit",
        'CB': "CB",
        'CREDIT': "Crédito",
        'UNPAID': "Sin pagar",
      },
      "states": {
        'title': "Estado",
        'waiting': "Pendiente",
        'processing': "En proceso",
        'succeeded': "Éxito",
        'success': "Éxito",
        'error': "Error",
        'canceled': "Cancelado",
        'requires_action': "Acción",
      },
      "date-range": {
        'month': 'Mes',
        'all': 'Todos',
        'last-month': 'El mes pasado',
        'last-quarter': 'Último cuarto',
        'format': 'yyyy/MM/dd',
        'apply': 'Solicitar',
        'cancel': 'Cancelar',
        'weekLabel': 'W',
        'customRange': 'Rango personalizado',
        'daysOfWeek': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'monthNames': ['Ene', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
        'monthNamesFull': ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      }
    },
    "account": {
      "vat": "Número de IVA",
      "title": "Configuraciones de la cuenta",
      "personal_infos": "Informaciones personales",
      "civility": "Civilidad",
      "civility-mr": "Señor",
      "civility-mme": "Señora",
      "civility-mx": "Sx",
      "first-name": "Nombre",
      "name": "Nombre",
      "last-name": "Apedillo",
      "email": "Correo electrónico",
      "phone": "Teléfono",
      "phone-country": "Código de país",
      "phone-country-error": "Elige un país",
      "phone-ex": "Ejemplo:",
      "mobile": "Móvil",
      "add-phone": "Ingresar el número de teléfono",
      "save": "Guardar",
      "languages": "Idioma",
      "interface-mode": "Color de interfaz",
      "current-password": "Contraseña actual",
      "new-password": "Nueva contraseña",
      "confirm-new-password": "Confirme nueva contraseña",
      "password": "Contraseña",
      "confirm-password": "Confirmar contraseña",
      "settings": "Configuraciones",
      "company_infos": "Informaciones de la empresa",
      "company-name": "Nombre de la empresa",
      "company-position": "Función",
      "company-id": "D-U-N-S",
      "tva-number": "Número de IVA",
      "address": "Dirección",
      "address-name": "Nombre",
      "address-street": "Calle",
      "address-postal-code": "Código postal",
      "address-city": "Ciudad",
      "address-country": "País",
      "add-address": "Ingresar una dirección",
      "password-mismatch": "Las contraseñas no coinciden",
      "recaptcha-error": "Por favor valide el recaptcha",
      "password-hint": "La contraseña debe contener al menos de 8 caracteres (mayúsculas, minúsculas, número)",
      "legal-info": "Una vez que haya creado su cuenta, podrá disfrutar de los servicios de Titan Datacenters.\nLas informaciones que rellenó a través de este formulario se recopila con su consentimiento y está destinada a Titan Datacenters como controlador de datos.\n\nSus datos personales y la información de sus pedidos serán utilizados principalmente para crear y administrar su cuenta, y para procesar y rastrear sus pedidos. Además, nos permitirá brindarle acceso a la prevención del fraude, el análisis estadístico y diversas herramientas de marketing y publicidad.\n\nEstas bases legales son diferentes y específicas para el procesamiento en cuestión.\n\nSiempre puede retirar su consentimiento en cualquier momento.",
      "legal-notice": "Aviso legal",
      "privacy-policy": "Política de privacidad",
      "discount": "Tipo de suscripción",
      "auto_debit": "Retirada a fin de mes:",
      "disabled": "Empresa inhabilitada:",
      "renew_with_credits": "Use créditos al renovar suscripciones:",
      "reset": "Reiniciar",
      "affiliate_infos": "Programa de afiliación (opcional)",
      "affiliate-code": "Código de afiliación (opcional)",
      "code": "Codificado",
      "status": "Estado",
      "balance": "Balance",
      "vatConfirmed": "Número de IVA confirmado",
      "vatNotConfirmed": "IVA no válido aún no confirmado",
      "modal": {
        "title": "Contacto",
        "message_invalid": "Datos inválidos",
        "message_error": "Error al guardar",
        "message_success": "Contacto actualizado con éxito",
      },
      "send_invoice": "Envío automático de facturas por correo electrónico (Super Admin + Contables)",
      "creation_date": "Creado el",
      "update_date": "Actualizado el",
      "oide": "OID",
      "affiliation_code": "Código de afiliación",
      "server-detail-closed": "Detalles del servidor cerrados",
      "server-detail-help": "En el perfil del servidor, mantén abierto o cierra el panel de información detallada",
      "create-new-company": "Crear una nueva empresa",
      "invoice_payment_grouped": "Pago único para varias facturas",
      "sms_alert": "Autorización para enviar SMS",
    },
    "account-ssh": {
      "title": "Clave SSH",
      "management": "Gestionar sus claves SSH",
      "ssh-key-name": "Nombre",
      "ssh-key-usage": "Número de usos",
      "ssh-key-value": "Clave SSH",
      "ssh-key-add": "Añadir una clave SSH"
    },
    "account-api": {
      "title": "Clave API",
      "api-keys": "Claves API",
      "api-key-name": "Nombre",
      "api-key-token": "Claves API",
      "api-key-expire": "Fecha de caducidad",
      "api-key-add": "Generar una clave API"
    },
    "account-2fa": {
      "title": "Autenticación de dos factores (2FA)",
      "actions": {
        "enable": "Habilita 2FA",
        "disable": "Desactivar 2FA",
      },
      "enable": {
        "step1": {
          "title": "Anote el código secreto de recuperación",
          "text": "Escriba cuidadosamente el código de recuperación siguiente. Se le pedirá este código para recuperar su cuenta si pierde su teléfono, por ejemplo."
        },
        "step2": {
          "title": "Escanee este código QR con una aplicación TOTP",
          "text": "Simplemente escanee el código QR siguiente con su aplicación TOTP favorita (Authy, Google Authenticator, etc.)"
        },
        "step3": {
          "title": "Ingrese el código de autorización de seis dígitos",
          "text": "Ingrese el código de 6 dígitos que aparece en su aplicación TOTP (apúrate, los códigos tienen una vida útil limitada)."
        },
        "step4": {
          "title": "Scannez le QR code à l'aide d'une application de TOTP",
          "text": "Il vous suffit de scanner le QR code ci-dessous à l'aide de votre application de TOTP favorite (Authy, Google Authenticator, etc.)"
        },
      },
      "disable": {
        "title": "Deshabilitar la autenticación de dos factores",
        "text": "La autenticación de dos factores está actualmente habilitada en su cuenta. Para deshabilitarla, ingrese su contraseña junto con el código de autorización actual de seis dígitos de su aplicación TOTP.",
        "warning": "Su código de recuperación secreto actual quedará obsoleto. Si desea reactivar 2FA más tarde, se le comunicará un nuevo código de recuperación."
      },
    },
    "account-preferences": {
      "title": "Preferencias de la cuenta",
      "menu-1": "Sus preferencias registradas",
      "cartModal": "Resumen del pedido:",
      "show": "Mostrar",
      "hide": "Esconder",
      "sorting-col": "Columna de ordenación:",
      "sorting-dir": "Sentido de ordenación:",
      "invoices": "Visualización de facturas:",
      "recurrings": "Visualización de suscripciones:",
      "servers": "Visualización de servidores:",
      "tooltips-hide": "Para muestrar de nuevo el resumen de pedido, por favor diríjase en la sección \" Preferencias \" de la configuración de su cuenta.",
      "sms-alert": "Recibir notificaciones por SMS",
      "sms-help": "Para recibir SMS, la autorización de envío debe estar habilitada en la pestaña 'Notificaciones' de la página de la empresa. Asegúrate de haber ingresado un número de teléfono válido.",
    },
    "network": {
      "title": "Redes",
      "title-switch": "Vswitch",
      "title-ips": "Grupo de IP",
      "no_ip": "Sin dirección IP",
      "no_vswitch": "No hay Vswitch",
      "help": "-",
      "name": "Nombre",
      "ports": "Puertos",
      "speed": "Velocidad",
      "mtu-max": "MTU max",
      "hostname": "Nombre del host",
      "comment": "Comentario",
      "plan": "Suscripción",
      "mac": "Dirección MAC",
      "cidr": "CIDR",
      "gateway": "Puerta",
      "connected-servers": "Conectar su servidor",
      "connect-button": "Conectar su servidor",
      "add-network": "Añadir un Vswitch",
      "add-vm": "Conectar un servidor",
      "add-success": "Vswitch creado con éxito",
      "delete-network": "Suprimir este Vswitch",
      "cant-delete-network": "Imposible eliminar este vswitch. Los servidores están unidos a él.",
      "delete-vm": "Borrar este servidor",
      "delete-success": "Vswitch eliminado con éxito",
      "title-add-server": "Conectar un servidor",
      "server-attach-success": "Servidor conectado correctamente",
      "server-detach-success": "Servidor suprimido correctamente",
      "order-count": "No tiene pedidos… | Tiene una IP en la cesta… | Tiene {nb} direcciones IP en la cesta…",
      "order-added": "Pedido añadido…",
      "order-cart": "Ver la cesta",
      "order-reset": "Vaciar la cesta",
      "modal-network-delete-title": "Suprimir este Vswitch",
      "modal-network-delete-description": "Al confirmar esta acción, este Vswitch se suprimirá",
      "modal-network-delete-confirm": "¿Está seguro de que quiere eliminar este Vswitch?",
      "modal-server-detach-title": "Desconectar este servidor",
      "modal-server-detach-description": "Al confirmar esta acción, este servidor se desconectará del Vswitch",
      "modal-server-detach-confirm": "¿Está seguro de que quiere desconectar este servidor?",
      "modal-server-detach-ip-title": "Desvincular IP {ip}",
      "modal-server-detach-ip-description": "Al confirmar esta acción, esta IP se desvinculará del servidor.",
      "modal-server-detach-ip-confirm": "¿Estás seguro de que quieres desvincular esta IP?",
      "modal-server-detach-ip-warning": "Podrás vincular esta IP a otro servidor.",
      "modal-server-delete-ip-title": "Cancelar IP {ip}",
      "modal-server-delete-ip-description": "Al confirmar esta acción, esta IP se cancelará.",
      "modal-server-delete-ip-confirm": "¿Estás seguro de que quieres cancelar esta IP?",
      "modal-server-delete-ip-warning": "Esta acción es reversible.",
      "modal-network-managed-title": "Red gestionada",
      "modal-network-managed-label-name": "Nombre",
      "modal-network-managed-label-gateway": "Puerta",
      "modal-network-managed-label-cidr": "CIDR",
      "reset-network": "Restablecer la interfaz de red de los servidores",
      "update": "Actualizar Vswitch",
      "reset": "Restablecer",
      "add-ip": "Agregar una IP",
      "goto-server": "Ir al servidor",
      "detach-ip": "Desvincular esta IP",
      "attach-ip": "Vincular esta IP",
      "server-ip-attach-success": "IP adjuntada correctamente",
      "server-ip-detach-success": "IP desconectada correctamente",
      "server-search": "Buscar un servidor. Haz doble clic para mostrar la lista.",
      "update-reverse": "Actualizar reverse",
      "open-port-list": "Mostrar lista de puertos",
      "close-port-list": "Cerrar lista de puertos",
      "ip-delete-success": "IP cancelada con éxito",
      "ip-delete-failed": "Cancelación de la IP no exitosa",
      "ip-cancel-delete-success": "Cancelación de la terminación de la IP realizada con éxito",
      "ip-added-to-cart": "IP agregada al carrito",
      "ip-already-in-cart": "IP ya está en el carrito",
      "ip-check": "Realizar controles en esta dirección",
      "ip-summary": {
        "title": "Resumen",
        "header": [
          "IPs adjuntas",
          "IPv4",
          "IPv6",
          "IPs disponibles",
        ],
      },
      "title-choose-subscription": "Elegir suscripción",
      "subtitle-choose-subscription": "Seleccione la suscripción para la cual se terminará la IP",
      "subscription-search": "Buscar una suscripción. Haz doble clic para mostrar la lista.",
      "drp": "PRD",
      "show-chart": "Mostrar actividad",
      "hide-chart": "Ocultar actividad",
    },
    "company": {
      "tabs": {
        "info": "Información",
        "users": "Usuarios",
        "payments": "Medios de pago",
        "reglements": "Pagos",
        "historic": "Histórico",
        "notifications": "Notificaciones",
        "piggy": "Créditos",
      },
      "title": "Empresa",
      "title-info": "Informaciones de la empresa",
      "title-users": "Usuarios y Funciones",
      "title-user-invite": "Invita a un usuario",
      "title-role-create": "Crea un rol",
      "title-role-update": "Actualizar un Rol",
      "title-authorization-update": "Actualizar Autorizaciones",
      "title-notifications": "Gestión de notificaciones",
      "title-piggy": "Uso de los créditos",
      "address-billing": "Dirección de facturación",
      "address-shipping": "Dirección de entrega",
      "address-copy": "Utilizar come dirección de entrega",
      "company": "Empresa",
      "save-success": "Informaciones salvadas correctamente",
      "user-add": "Invita a un usuario",
      "user-add-success": "Invitación enviada correctamente",
      "user-revoke-success": "Usuario revocado con éxito",
      "invitation-delete-success": "Invitación revocada con éxito",
      "user-empty": "Por favor, invita a un usuario",
      "user-role-change-success": "La función del usuario ha sido modificada",
      "user-filter-place-holder": "Buscar un usuario...",
      "complete-company-error": "Primero complete la información de su empresa (nombre, dirección y número de IVA).",
      "same_address": "La dirección de entrega y facturación son idénticas",
      "item_added": "Artículo(s) añadido(s) al carrito",
      "piggy": {
        "amount": "Número de créditos restantes",
        "provisioning": "Provisionamiento automático",
        "add-pack": "Añadir un paquete",
        "header": [
          "Creación",
          "Uso",
          "Empresa",
          "Usuario",
          "Número de créditos",
          "Enviado a",
        ],
      },
    },
    "tickets": {
      "title": "Tickets",
      "title-new": "Abre un nuevo ticket",
      "server": "Servicio",
      "service": "Servidor en cuestión",
      "object": "Tema del ticket",
      "object-placeholder": "Ingrese su tema aquí",
      "message": "Su mensaje",
      "message-placeholder": "Ingrese su mensaje aquí",
      "add-ticket": "Nuevo ticket",
      "send-ticket": "Enviado",
      "sending-ticket": "Envío",
      "close-ticket": "Cerrar el ticket",
      "close-ticket-force": "Cerrar sin respuesta",
      "cancel": "Volver a la lista de tickets",
      "close-text": "¿Hemos respondido todas sus preguntas?",
      "ticket-added": "Ticket añadido…",
      "ticket-added-text": "Nuestros equipos han recibido su mensaje.",
      "chat": "Charla",
      "level": "Nivel de prioridad",
      "levels": {
        "low": "Bajo",
        "medium": "Medio",
        "high": "Alto"
      },
      "categories": {
        "billing": "Facturación",
        "technical": "Técnico",
        "sales": "Venta",
        "other": "Otro"
      },
      "total": "Total",
      "company": "Cliente relacionado",
      "pending_client": "Esperando respuesta del cliente",
      "pending_admin": "Esperando respuesta del técnico",
      "pending_you": "Esperando su respuesta",
    },
    "invoices": {
      "title": {
        "invoice": "Facturas",
        "documents": "Documentos",
        "recurring": "Suscripciones",
        "recurrings_invoices": "Facturas de la suscripción:",
        "admin_title": "Facturas, Notas de Crédito y Órdenes de Compra",
      },
      "differed": "Esta factura se pagará automáticamente junto con la renovación de su suscripción.",
      "pay": "Pagar la factura",
      "auto_debit_part_title": "Actualmente, sus servicios son pagados por domiciliación bancaria.",
      "auto_debit_part_one": "El pago de su pedido será efectuado al fin del mes.",
      "auto_debit_part_two": "Si tiene alguna pregunta, por favor póngase en contacto con la asistencia :",
      "auto_debit_btn": "Crear un ticket",
      "last-invoice": "Factura reciente",
      "details": "N° de factura ",
      "order_details": "N° del pedido ",
      "pending": "Pendiente",
      "multi-pay": "Pago de varias facturas",
      "order_cancel": {
        "title": "Cancelar el pedido",
        "msg": "¿Está seguro de que quiere cancelar este pedido?",
        "btn": "Cancelar"
      },
      "tabs": {
        "invoice_tab": "Factura",
        "order_tab": "Pedido",
        "credit_tab": "Crédito",
        "payment_tab": "Pago",
        "payment_list_tab": "Lista de los pagos",
        "expenses_list_tab": "Lista de gastos",
        "payment_tab_header": "Detalles",
        "payment_detail_header": [
          "N° de Factura",
          "Importe",
          "Descuento",
          "Importe Neto",
          "IVA",
          "Importe Total",
          "Pagado",
          "Saldo",
        ],
      },
      "no-payments": "No hay pagos.",
    },
    "cgv": {
      "title": "Términos de servicio",
      "action": "Aceptar términos y condiciones",
      "next": "Siguiente",
      "previous": "Anterior",
      "download": "Descargar",
      "accept": "Aceptar los términos del servicio.",
      "signed-at": "Usted firmó nuestros términos del servicio el {date} desde la dirección IP {ip}.",
      "up-to-date": "Nuestros ToS no han sido actualizados desde su firma.",
      "not-up-to-date": "Nuestros ToS no han sido actualizados desde su firma.",
      "current-version": "La version actual es V{version}.",
      "read": "Lee nuestros términos del servicio",
    },
    "email-validation": {
      "title": "Validación por correo electrónico",
      "send-action": "Por favor, reenvíe el enlace de confirmación"
    },
    "forget-password": {
      "title": "Contraseña olvidada",
      "send-action": "Por favor, envíe instrucciones para reiniciar mi contraseña",
      "confirm-success": "Un correo electrónico ha sido enviado para restablecer su contraseña (por favor también revise sus spam)."
    },
    "forget-password-change": {
      "title": "Nueva contraseña",
      "action": "Confirmar"
    },
    "commercial": {
      "title": "Comercial",
      "last_name": "Apellido",
      "first_name": "Nombre",
      "email": "Email",
      "phone": "Teléfono",
      "status": "Status",
      "disabled": "Desactivado",
      "create_commercial": "Crear un vendedor",
    },
    "cron": {
      "add-cron": "Agregar una tarea",
      "header": [
        "Tarea",
        "Nombre",
        "Empresa",
        "Usuario",
        "Servidor",
        "Programación",
        "Recurrente",
        "Estado",
        "Activada",
      ],
      "job": {
        "type": {
          "schedule-reboot": "Reinicio",
          "schedule-snapshot": "Instantánea",
          "undefined": "",
        },
        "message": {
          "create-success": "Trabajo creado",
          "delete-success": "Trabajo eliminado",
          "update-success": "Trabajo actualizado",
        },
        "creation": {
          "title": "Creación de una tarea",
          "label": [
            "Empresa",
            "Servidor",
            "Tipo",
            "Tarea recurrente",
            "Programación",
          ],
          "no-credit": [
            "No hay créditos disponibles.",
            "Sin créditos, las tareas recurrentes programadas en un servidor no podrán ejecutarse.",
            "Le invitamos a comprar un paquete de créditos para permitir la ejecución de múltiples tareas por servidor.",
            "Tenga en cuenta que estos créditos también permiten la recepción de alertas por SMS.",
            "Le agradecemos su comprensión."
          ],
        },
        "toggle": {
          "button-tip-true": "Haz clic para activar la tarea",
          "button-tip-false": "Haz clic para desactivar la tarea",
          "true": "Tarea desactivada",
          "false": "Tarea activada",
        },
      },
      "modal": {
        "delete-job-title": "Eliminar tarea {job}",
        "delete-job-description": "Al confirmar esta acción, esta tarea será eliminada.",
        "delete-job-confirm": "¿Está seguro de que desea eliminar esta tarea?",
        "delete-job-warning": "Esta acción es reversible.",
      },
    },
  },
  "unit": {
    "GB": "Go",
    "vcpu": "vCores",
    "mbps": "mbps",
    "Cores": "vCores",
  },
  "form": {
    "required-field": "Campo necesario",
    "missing-fields": "Por favor, complete los campos faltantes",
    "password-constraints": "La contraseña debe contener al menos 6 caracteres.",
    "email-invalid": "Su correo electrónico no es válido",
    "affiliate-code-invalid": "Código de afiliación no válido",
    "country-invalid": "El país no es válido",
    "password-condition": {
      "characters": "caracteres min.",
      "contains-number": "1 cifra",
      "contains-uppercase": "1 mayúscula",
      "contains-special": "1 caracter especial"
    },
  },
  "beta-functionnality-modal": {
    "title": "Funcionalidad de acceso limitada.",
    "content": "Para activar esta función, debe ponerse en contacto con nuestro departamento de ventas por teléfono o ticket."
  },
  "tour": {
    "next": "Siguiente",
    "previous": "Anterior",
    "end": "Finalizar",
    "skip": "Saltearse la visita",
    "global": {
      "play": "Reproducir el recorrido",
      "dashboard": {
        "header": {
          "title": "Dashboard"
        },
        "content": "Aquí encontrará un resumen de la información de sus tickets, acciones y estadísticas de consumo.",
      },
      "servers": {
        "header": {
          "title": "Aquí puedes controlar tus servidores con muchas funciones:"
        },
        "content": "- Acceso remoto (resolución de problemas mediante kvm)<br />" +
          "- Upgrade / Downgrade de recursos de cpu/ram/disco<br />" +
          "- Montaje de ISO y recuperación de ISO<br />" +
          "- Stop / Start de servidores<br />" +
          "- Activación de ipv6 y creación de reversos<br />" +
          "- Restablecimiento, creación de instantáneas y plantillas<br />" +
          "- Estadísticas de recursos de cpu/ram/disco/red",
      },
      "networks": {
        "header": {
          "title": "Ficha Red"
        },
        "content": "Cree y gestione sus redes privadas para asegurar y configurar su plataforma de servidores."
      },
      "company": {
        "header": {
          "title": "Ficha de la empresa"
        },
        "content": "Aquí puede gestionar la información de su empresa, así como los métodos de pago y la gestión de roles.",
      },
      "tickets": {
        "header": {
          "title": "Ficha del ticket"
        },
        "content": "Aquí podrá comunicarse con nuestros diferentes servicios: técnico, comercial y contable.",
      },
      "affiliations": {
        "header": {
          "title": "Afiliaciones"
        },
        "content": "Aquí puede gestionar su cuenta de afiliación y las  sumas acreditadas<br />" +
          "« Póngase en contacto con nuestro departamento de ventas para verificar su elegibilidad. »",
      },
      "recurring": {
        "header": {
          "title": "Ficha de suscripción"
        },
        "content": "Gestione la lista de sus suscripciones y los nombres de sus proyectos que aparecen en sus facturas.",
      },
      "invoice": {
        "header": {
          "title": "Documento"
        },
        "content": "Aquí puede encontrar todos sus documentos contables (pedidos, facturas y notas de crédito)",
      },
      "vms": {
        "header": {
          "title": "Caddie"
        },
        "content": "Aquí puede pedir sus servidores y recibirlos en menos de un minuto.",
      },
      "header-user": {
        "header": {
          "title": "Configuración de la cuenta"
        },
        "content": "- Aquí puede gestionar los diferentes parámetros de su cuenta<br />" +
          "- Cambia de interfaz entre sus diferentes cuentas<br />" +
          "- Gestione su tienda de claves SSH y API<br />" +
          "- Activar la autenticación 2FA<br />" +
          "- Compre sus servicios (intervención puntual y licencia de Microsoft)<br />" +
          "- Acceder a la documentación de la API"
      }
    }
  },
  "object_search": {
    "empty": "Ingrese al menos {length} caracteres",
    "selected": "El valor seleccionado es {value}",
    "match": "El nombre debe existir en la lista"
  },
  "company_search": {
    "placeholder": "Buscar un cliente",
    "empty": "Buscar y luego seleccionar una compañía",
    "selected": "La compañía seleccionada es {value}",
  },
  "cron-date": {
    "minute": "Minuto",
    "hour": "Hora",
    "month": "Mes",
    "day-of-month": "Día del mes",
    "day-of-week": "Día de la semana",
    "help": "El asterisco (*) representa cualquier valor posible para el campo correspondiente.",
  },
  "days-of-week": ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
  "months-of-year": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
  "Forbidden": "Acceso denegado",
  "errors": {
    "UPDATE_SUCCESSFUL": "Actualización exitosa",
    "NO_DOCUMENT_FOUND": "¡No existe!",
    "PAGE_NOT_FOUND": "Página no encontrada.",
    "ALREADY_EXISTS": "Ya existe",
    "TASK_ALREADY_RUNNING": "Tarea ya ejecutándose",
    "INVALID_RECAPTCHA": "Recaptcha no válido.",
    "UPDATE_UNSUCCESSFUL": "Actualización sin éxito",

    "TOO_MANY_KVM": "Tiene demasiados KVMs abiertos",

    "PERMISSION_DENIED": "No tienes permisos para esta acción.",
    "2FA_INVALID_SECRET": "Secreto inválido",
    "USER_UPDATE_SSH_KEY_SUCCESS": "Actualizado con éxito",
    "USER_UPDATED_SUCCESSFULLY": "Actualizado con éxito",
    "TOKEN_UPDATE_SUCCESS": "Actualizado con éxito",

    "SERVER_RESET_FAIL_NOT_LICENSED_OS_TO_LICENSED_OS": "Esta función aún no está disponible para su usuario. Póngase en contacto con el servicio de asistencia.",

    "IMAGES_EMPTY": "No tienes ninguna imagen, puedes crear una a partir de la instantánea de una máquina.",

    "APP_BETA_FUNCTIONNALITY": "Pronto, una aplicación móvil de titanio te permitirá controlar todas las funciones.",

    "BETA_FUNCTIONNALITY": "Para activar esta función, debe ponerse en contacto con nuestro departamento de ventas por teléfono o ticket.",

    "INVALID_EMAIL_OR_PASSWORD": "Dirección de correo electrónico o contraseña no válidos.",
    "MISSING_EMAIL_OR_PASSWORD": "Falta el correo electrónico o la contraseña.",
    "ACCOUNT_NOT_VALIDATED": "Su cuenta no está validada, revise sus correos electrónicos.",
    "USER_OR_COMPANY_MISSING_NAME_OR_ADDRESS_INFORMATION": "Primero debe completar su información personal y profesional.",

    "INTERNAL_ERROR": "Error interno.",
    "BAD_PERMISSION": "Permiso incorrecto.",
    "BAD_REQUEST": "Solicitud incorrecta.",
    "SERVER_NOT_FOUND": "Servidor no encontrado.",
    "COMPANY_NOT_FOUND": "Empresa no encontrada.",
    "INVOICE_NOT_FOUND": "Factura no encontrada.",
    "INVOICE_ALREADY_PAID": "Factura ya pagada.",
    "PAYMENT_METHOD_NOT_FOUND": "Medio de pago no encontrado.",
    "PLAN_NOT_FOUND": "Modelo no encontrado",
    "CREDIT_NOT_FOUND": "Nota de crédito no encontrada.",
    "CREDIT_EXPIRED": "La nota de crédito ha expirado.",
    "CREDIT_SPENT": "Nota de crédito inválida, tiene que contactar asistencia.",
    "CREDIT_INVALID": "Crédito inválido, tiene que contactar asistencia",
    "PAYMENT_FAIL": "El pago ha fallado.",
    "PROJECT_NAME_INVALID": "El nombre del proyecto esta inválido, debe contener entre 4 y 40 caracteres.",
    "PROJECT_NAME_CONFLICT": "El nombre del proyecto esta ya utilizado, tiene que elegir otro.",
    "ROLE_NAME_INVALID": "El nombre del rol no es válido, la longitud debe tener entre 2 y 50 caracteres.",
    "REVERSE_INVALID": "Reverso no válido: asegúrese de establecer la dirección IP adecuada en la zona DNS.",

    "AVATAR_INVALID_TYPE": "El avatar elegido no está en el formato correcto (jpg o png).",
    "AVATAR_INVALID_SIZE": "El avatar elegido no respeta las condiciones.",
    "AVATAR_UPDATED_SUCCESSFULLY": "Avatar actualizado correctamente",
    "PHONE_NUMBER_INVALID": "El número de teléfono no es válido.",

    // Snapshot
    "SNAPSHOT_NOT_FOUND": "Snapshot no encontrado.",
    "SNAPSHOT_RESTORE_STARTED": "Restauración del snapshot pendiente.",
    "SNAPSHOT_RENAME_SUCCESS": "Snapshot rebautizado con éxito.",
    "SNAPSHOT_DELETE_SUCCESS": "Snapshot suprimido con éxito.",
    "SNAPSHOT_CREATE_FAIL_LIMIT_EXCEEDED": "Número máximo de snapshop excedido.",
    "SNAPSHOT_DELETE_INVALID_STATE": "No se puede eliminar el snapshot: estado no válido.",
    "SNAPSHOT_RESTORE_INVALID_SNAPSHOT_STATE": "No se puede restaurar el snapshot: estado de instantánea no válido.",
    "SNAPSHOT_CREATE_FAIL_TOO_FAST": "No se puede crear una nueva snapshot: deben transcurrir al menos 30 minutos entre la creación de nuevas snapshot.",

    // Image
    "IMAGE_CREATE_FAIL_INSUFFICIENT_SPACE": "Su espacio de almacenamiento es insuficiente.",
    "IMAGE_CREATE_FAIL_QUOTA_EXCEEDED": "Se ha superado la cuota de creación de imágenes..",
    "SERVER_CREATE_FAIL_SERVER_DISK_IMAGE_AND_TEMPLATE_CONFLICT": "Conflicto entre la imagen de disco y la plantilla.",
    "SERVER_CREATE_FAIL_INVALID_DISK_SIZE": "Tamaño de disco no válido.",
    "SERVER_CREATE_FAIL_INVALID_IMAGE_STATE": "Estado de la imagen no válido.",
    "SERVER_CREATE_FAIL_DISK_SOURCE_INVALID": "Fuente de disco no válida.",
    "SERVER_CREATE_FAIL_IMAGE_UUID_MISSING": "Falta el identificador único.",
    "SERVER_CREATE_FAIL_TEMPLATE_UUID_MISSING": "Falta el identificador único.",
    "IMAGE_DELETE_SUCCESS": "Imagen eliminada con éxito.",
    "IMAGE_UPDATE_SUCCESS": "Imagen actualizada con éxito.",
    "IMAGE_CREATE_SUCCESS": "Imagen creada con éxito.",
    "NO_IMAGE": "No tiene imagen, puede crear una a partir de una instantánea",
    "IMAGE_NOT_FOUND": "Imagen no encontrada.",
    "IMAGE_DELETE_FAIL_IS_ACTUALLY_USED": "Esta imagen se utiliza, no se puede eliminar.",
    "IMAGE_CREATE_FAIL_PLESK_DISABLED": "Es imposible crear plantillas PLESK.",
    "IMAGE_CREATE_FAIL_NAME_ALREADY_USED": "Este nombre ya está en uso.",
    "IMAGE_INVALID_NAME_SIZE": "El tamaño del nombre es incorrecto.",
    "IMAGE_INVALID_OS_NAME_SIZE": "El tamaño del nombre del SO es incorrecto.",
    "IMAGE_INVALID_OS_VERSION_SIZE": "El tamaño de la versión del sistema operativo es incorrecto.",
    "IMAGE_CREATE_FAIL_SNAPSHOT_INVALID_STATE": "La instantánea está en un estado no válido.",
    "IMAGE_FAIL_TO_RETRIEVE_ORIGINAL_TEMPLATE": "Imposible encontrar la plantilla original.",
    "INVALID_TARGET_STATE": "Destino inexistente o en un estado no conforme con la solicitud.",

    // Addon
    "ADDON_INSTALL_FAIL_INVALID_PLAN": "El plan no esta válido.",
    "ADDON_INSTALL_FAIL_INVALID_QUANTITY": "La cantidad de addons no esta válida.",
    "ADDON_INSTALL_FAIL_INVALID_ITEM": "El addon no esta válido.",
    "ADDON_INSTALL_FAIL_CPU_LIMIT_EXCEEDED": "No se pueden instalar los complementos: se ha excedido la cuota de CPU, póngase en contacto con la asistencia.",
    "ADDON_INSTALL_FAIL_RAM_LIMIT_EXCEEDED": "No se pueden instalar los complementos: se ha excedido la cuota de RAM, póngase en contacto con la asistencia.",
    "ADDON_INSTALL_FAIL_DISK_LIMIT_EXCEEDED": "No se pueden instalar los complementos: se ha excedido la cuota de Disco, póngase en contacto con la asistencia.",
    "ADDON_INSTALL_FAIL_SRV_ON_TERMINATION": "El servidor esta programado para terminación.",
    "ADDON_UNINSTALL_FAIL_SERVER_BAD_STATE": "No se pueden desinstalar los complementos: el servidor debe estar detenido.",
    "ADDON_UNINSTALL_FAIL_NOT_FOUND": "No se pueden desinstalar complementos: complementos no encontrados.",
    "ADDON_UNINSTALL_FAIL_SERVER_DO_NOT_BELONG_TO_SERVER": "No se pueden desinstalar los complementos: los complementos no están instalados.",
    "ADDON_UNINSTALL_FAIL_ITEM_TYPE_DISK_INVALID": "No se pueden desinstalar los complementos): los complementos de disco no se pueden desinstalar.",
    "ADDON_UNINSTALL_FAIL_INVALID_QUANTITY": "No se pueden desinstalar los complementos: cantidad no válida.",
    "ADDON_UNINSTALL_FAIL_SRV_ON_TERMINATION": "No se pueden desinstalar los complementos: la eliminación del servidor está programada.",
    "ADDON_UNINSTALL_SUCCESS": "Complemento(s) desinstalado correctamente.",
    "ADDON_LIST_FAIL": "Incapaz de enumerar los addons.",
    "ADDON_ERR_INVOICE_NOT_PAID": "No se puede añadir el addon. Factura(s) sin pagar.",

    // Invoice
    "INVOICE_PAYMENT_NOT_FOUND": "No se puede encontrar el pago de la factura.",
    "INVOICE_CONTACT_NOT_RECOGNIZED": "Contacto de factura no reconocido.",
    "INVOICE_CREATE_FAIL": "No se puede crear una nueva factura.",
    "INVOICE_CREATE_SUCCESS": "Nueva factura creada.",
    "INVOICE_CREATE_NOT_REQUIRED": "Aún no se requiere la creación de una nueva factura.",
    "INVOICE_ITEM_BUILD_FAIL": "No se pueden generar elementos de factura.",

    // Order
    "ORDER_CANCEL_SUCCESS": "Pedido cancelado con éxito.",
    "ORDER_ALREADY_INVOICE": "El pedido ya se convertió en factura.",
    "ORDER_ALREADY_CANCELED": "El pedido ya esta cancelado.",
    "ORDER_ALREADY_PAID": "El pedido ya esta pagado.",
    "ORDER_HAS_SUCCESS_PAYMENT": "El pedido estuve registrado con un pago exitoso. Póngase en contacto con la asistencia.",
    "ORDER_HAS_WAITING_PAYMENT": "Un pago pendiente estuve registrado sobre el pedido. Espere por favor.",
    "ORDER_HAS_DEPLOYED_ITEM": "El pedido ya contiene un artículo desplegado. Póngase en contacto con la asistencia.",
    "ORDER_NOT_CANCELABLE": "El pedido no se puede cancelar. Póngase en contacto con la asistencia.",

    // Recurring invoices
    "RECURRING_INVOICE_NOT_FOUND": "Suscripción no encontrada.",
    "RECURRING_INVOICE_UPDATE_FAIL": "No se puede actualizar la suscripción.",
    "RECURRING_INVOICE_CANCEL_SUCCESS": "Suscripción cancelada con éxito.",
    "RECURRING_INVOICE_UPDATE_SUCCESS": "Suscripción actualizada correctamente.",
    "RECURRING_INVOICE_CREATE_FAIL": "No se puede crear una nueva suscripción.",
    "RECURRING_INVOICE_CREATE_SUCCESS": "Nueva suscripción creada con éxito.",
    "RECURRING_INVOICE_INVALID_FREQ": "La frecuencia de suscripción no es válida",
    "RECURRING_INVOICE_ITEM_BUILD_FAIL": "No se pueden agregar artículos a su suscripción.",
    "RECURRING_INVOICE_NOT_ONGOING": "La suscripción no está en curso.",
    "RECURRING_INVOICE_START_TOO_EARLY": "La fecha de inicio de la suscripción es demasiado temprana, elimínela para iniciarla dinámicamente después de realizar el pago.",
    "RECURRING_INVOICE_CANCELED": "La suscripción está cancelada.",
    "RECURRING_INVOICE_PENDING": "Suscripción pendiente.",
    "RECURRING_INVOICE_ONGOING": "No es posible eliminar. Una suscripción activa en esta cuenta requiere al menos un método de pago registrado.",
    "RECURRING_ITEM_NOT_LICENSE_TYPE": "El elemento no es de tipo licencia",

    // Payment methods
    "PAYMENT_METHOD_ADD_FAIL_EMPTY_NAME": "Nombre completo sobre la tarjeta.",
    "PAYMENT_METHOD_UPDATE_SUCCESS": "Medio de pago actualizado con éxito.",
    "PAYMENT_METHOD_REMOVE_SUCCESS": "Medio de pago borrado con éxito.",
    "PAYMENT_METHOD_ADD_SUCCESS": "Medio de pago añadido con éxito.",
    "PAYMENT_METHOD_ALREADY_REGISTERED": "Medio de pago ya registrado.",
    "PAYMENT_METHOD_ADD_FAIL": "No se puede añadir el nuevo medio de pago.",
    "PAYMENT_EXCEED_AMOUNT": "El importe del pago es superior al importe a pagar.",
    "PAYMENT_FAILED": "Pago fallido.",
    "PAYMENT_METHOD_IS_DEFAULT": "Prohibida la eliminación del método de pago predeterminado.",
    "Credit card number is not long enough": "El número de la tarjeta de crédito no es lo suficientemente largo",
    "Invalid credit card number": "Número de tarjeta de crédito no válido",
    "Test numbers are not allowed": "No se permiten números de prueba",
    "Invalid year": "Año no válido",
    "Invalid month": "Mes inválido",
    "Credit card has expired": "La tarjeta de crédito ha caducado",
    "Invalid CVV": "Número de CVV no válido",
    "Unknown credit card method": "Tipo de tarjeta de crédito desconocido",

    // Payment delay
    "PAYMENT_DELAY_ALREADY_EXISTS": "Este retraso en el pago ya existe.",
    
    // Credit
    "CREDIT_CREATE_SUCCESS": "Nota de crédito creada con éxito.",
    "CREDIT_FUNDING_EXCEEDED": "La nota de crédito de aquella factura se ha excedido.",
    "CREDIT_FUNDING_INVOICE_NOT_PAID": "Aquella factura no pagada.",
    "CREDIT_FUNDING_ITEM_NOT_FOUND": "La nota de crédito de un artículo dirigido no encontrada.",
    "CLEAR_AMOUNT_EXCEED": "El monto supera el saldo del documento.",

    // Migration
    "MIGRATE_FAIL_ITEM_NOT_DEPLOYED": "No se puede migrar, uno o más elementos aún no se implementaron.",

    // Server
    "BAD_OID": "OID no válido",

    // Delete
    "SERVER_DELETE_FAIL_ALREADY_ON_TERMINATION": "Este servidor ya está programado para terminación.",
    "SERVER_DELETE_FAIL_PLAN_NOT_FOUND": "No se puede cancelar el servidor: suscripción no encontrada.",
    "SERVER_DELETION_PENDING_SUCCESS": "Cancelación de servidor(es) pendiente.",
    "SERVER_DELETION_PENDING": "Acción no autorizada. Se ha solicitado la terminación del servidor.",
    "SERVER_DELETE_SUCCESS": "Servidor suprimido con éxito.",
    "SERVER_DELETE_FAIL_RECURRING_NOT_FOUND": "No se puede suprimir el servidor: factura recurrente no encontrada.",
    "SERVER_DELETE_FAIL_WAN_CONFIG_ERROR": "No se puede eliminar el servidor: error de configuración de WAN.",
    "SERVER_DELETE_FAIL_UPDATE_RECURRING_ERROR": "No se puede eliminar el servidor: actualizar error recurrente.",
    "SERVER_CANCEL_DELETE_FAIL_ALREADY_DELETED": "No se puede deshacer la eliminación del servidor: el servidor ya está eliminado.",
    "SERVER_DELETE_FAIL_ACTION_NOT_SCHEDULED": "La eliminación de este servidor no está programada.",
    // Create
    "SERVER_CREATE_FAIL": "No se puede encontrar la factura correspondiente.",
    "SERVER_CREATE_FAIL_FREE_WAN_NOT_FOUND": "No se puede crear el servidor: no hay suficiente WAN disponible, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_SERVER_LIMIT_EXCEEDED": "No se puede crear el servidor: se ha excedido la cuota de servidores, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_CPUS_LIMIT_EXCEEDED": "No se puede crear el servidor: se ha excedido la cuota de CPU, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_RAM_LIMIT_EXCEEDED": "No se puede crear el servidor: se ha excedido la cuota de RAM, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_DISK_LIMIT_EXCEEDED": "No se puede crear el servidor: se ha excedido la cuota de Disco, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_IDENT_MISSING": "No se puede crear el servidor: falta contraseña y/o clave SSH.",
    "SERVER_CREATE_FAIL_ADDON_QUANTITY_EXCEEDED": "No se puede crear el servidor: se ha excedido el límite de addons, póngase en contacto con la asistencia.",
    "SERVER_CREATE_FAIL_INVALID_ADDON": "No se puede crear el servidor: addon(s) no encontrados o no válidos.",
    "SERVER_CREATE_FAIL_INVALID_QUANTITY": "No se puede crear el servidor: cantidad incorrecta.",
    "SERVER_CREATE_FAIL_INVALID_PLAN": "No se puede crear el servidor: suscripción desconocida.",
    "SERVER_CREATE_FAIL_INVALID_CPU_QUANTITY": "No se puede crear el servidor: cantidad de CPU(s) no válida.",
    "SERVER_CREATE_FAIL_INVALID_TEMPLATE": "No se puede crear el servidor: plantilla no válida.",
    "SERVER_CREATE_FAIL_INVALID_PASSWORD": "No se puede crear el servidor: contraseña invalida.",
    "SERVER_CREATE_FAIL_FREE_MAC_NOT_FOUND": "No se puede crear el servidor: no hay una dirección MAC disponible.",
    "SERVER_CREATE_FAIL_FREE_IP_NOT_FOUND": "No se puede crear el servidor: no hay una dirección IP disponible.",
    "SERVER_CREATE_FAIL_MISSING_LICENSE": "No se puede crear el servidor: falta licencia.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_INVALID": "No se puede crear el servidor: nombre de proyecto no válido.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_DUPLICATE": "No se puede crear el servidor: el nombre del proyecto ya existe.",
    // Reset
    "SERVER_RESET_SUCCESS": "El reinicio del servidor esta pendiente.",
    "SERVER_RESET_FAIL_MISSING_PASSWORD_OR_KEY": "No se puede reiniciar el servidor: faltan la contraseña y/o las claves SSH.",
    "SERVER_RESET_FAIL_INVALID_PLAN": "No se puede reiniciar el servidor: suscripción no válida o desconocida.",
    "SERVER_RESET_FAIL_INVALID_TEMPLATE": "No se puede reiniciar el servidor: modelo no válido.",
    "SERVER_RESET_FAIL_WAN_NOT_FOUND": "No se puede reiniciar el servidor: entrada WAN no encontrada.",
    // Update
    "SERVER_UPDATE_SUCCESS": "Servidor actualizado con éxito.",
    "SERVER_UPDATE_FAIL_INVALID_NAME": "No se puede actualizar el servidor: formato del nuevo nombre inválido.",
    "SERVER_UPDATE_FAIL_MISSING_PARAMETER": "No se puede actualizar el servidor: falta un parámetro.",
    "SERVER_UPDATE_FAIL_UPDATE_REVERSE": "No se puede actualizar la entrada inversa del servidor, póngase en contacto con la asistencia.",
    "SERVER_UPDATE_FAIL_INVALID_REVERSE": "No se puede actualizar el servidor: el formato del inverso del servidor esta inválido.",
    "SERVER_UPDATE_FAIL_WAN_NOT_FOUND": "No se puede actualizar el servidor: entrada WAN no encontrada.",
    "SERVER_UPDATE_FAIL_NOTES_TOO_LONG": "No se puede actualizar el servidor: el campo de notas es demasiado largo.",
    "SERVER_UPDATE_FAIL_NO_PRIMARY_IP": "No se puede actualizar el servidor: no se encontró IP principal.",
    // Actions
    "SERVER_ACTION_FAIL_UNKNOWN_ACTION": "Acción desconocida.",
    "SERVER_ACTION_FAIL_CONFLICT": "La acción no es compatible con el estado actual del servidor.",
    "SERVER_ACTION_SUCCESS": "Acción del servidor enviada con éxito.",
    // ISO
    "SERVER_LOAD_ISO_BAD_PATH": "No se puede cargar ISO: la ruta es inválida.",
    "SERVER_LOAD_ISO_BAD_PROTOCOL": "No se puede cargar ISO: el protócolo no es válido.",
    "SERVER_LOAD_ISO_SUCCESS": "ISO cargado correctamente.",
    "SERVER_UNLOAD_ISO_SUCCESS": "ISO desmantelado con éxito.",

    // Template
    "TEMPLATE_DELETE_SUCCESS": "Modelo eliminado con éxito.",
    "TEMPLATE_GET_FAIL_INVALID_OS": "No se pueden recuperar los modelos.",
    "TEMPLATE_DELETE_NOT_FOUND": "No se puede eliminar el modelo: no se encuentra.",

    // IP KVM
    "IPKVM_FAIL_ACTION_UNKNOWN": "Acción desconocida en el IP del KVM.",
    "IPKVM_SEND_ACTION_SUCCESS": "Acción del IP del KVM esta pendiente.",
    "IPKVM_GET_URI_FAIL_KVM_NOT_STARTED": "El IP del KVM no se inicia en el servidor.",
    "IPKVM_FAIL_INVALID_DEADLINE": "No se puede enviar la acción en el IP del KVM: el tiempo de espera no válido.",
    "IPKVM_FAIL_TOO_LONG_DEADLINE": "No se puede enviar la acción en el IP del KVM: el tiempo de espera no debe exceder las 5 horas.",
    "IPKVM_FAIL_NOT_STOPPED": "Acción imposible: el KVM no se detiene",

    // Migrate
    "MIGRATE_FAIL_MISSING_SRC": "No se puede migrar: falta la fuente (hipervisor o servidor).",
    "MIGRATE_FAIL_CONFLICT_SRC": "No se puede migrar: conflicto de fuente ((hipervisor o servidor).",
    "MIGRATE_FAIL_HYPERV_SRC_EQUAL_DST": "No se puede migrar: la fuente y la destinación del hipervisor estan las mismas.",
    "MIGRATE_FAIL_HYPERV_SRC_NOT_FOUND": "No se puede migrar: fuente del hiperversor no encontrada.",
    "MIGRATE_FAIL_HYPERV_DST_NOT_FOUND": "No se puede migrar: destinación del hipervisor no encontrada.",
    "MIGRATE_SUCCESS": "Pedido de migración enviado con éxito.",

    // Event / History
    "SERVER_EVENT_FAIL_INVALID_INTEGER": "No se pueden recuperar eventos del servidor: número inválido.",
    "SERVER_EVENT_FAIL_INTEGER_LIMIT_EXCEEDED": "No se pueden recuperar eventos del servidor: el número de eventos debe estar entre 1-50.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_START": "No se puede recuperar el historial del servidor: marca de tiempo de inicio no válida.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_END": "No se puede recuperar el historial del servidor: marca de tiempo de finalización no válida.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_TIMER": "No se puede recuperar el historial del servidor: el intervalo no debe sobrepasar las 12 horas.",

    // Network
    "NETWORK_NOT_FOUND": "Red no encontrada.",
    "NETWORK_DELETE_FAIL_NOT_FOUND": "No se puede suprimir la red: no encontrada.",
    "NETWORK_DELETE_FAIL_SERVER_ATTACHED": "No se puede suprimir la red: el servidor todavía está conectado.",
    "NETWORK_CREATE_FAIL_COUNT_EXCEEDED": "No se puede crear una red: cuota excedida, póngase en contacto con la asistencia.",
    "NETWORK_CREATE_FAIL_INVALID_CIDR": "No se puede crear una red: CIDR inválido.",
    "NETWORK_CREATE_FAIL_CIDR_CONFLICT": "No se puede crear una red: CIDR se superpone con otra red.",
    "NETWORK_CREATE_FAIL_CIDR_TOO_LARGE": "No se puede crear una red: CIDR demasiado grande, una sola red no debe superar las 65536 IP (/16).",
    "NETWORK_CREATE_FAIL_MANAGED_SERVICES_DISABLED": "No se puede crear una red: los servicios gestionados están deshabilitados para su empresa.",
    "NETWORK_CREATE_FAIL_INVALID_NAME": "No se puede crear una red: formato de nombre no válido.",
    "NETWORK_CREATE_FAIL_GATEWAY_NOT_FOUND": "No se puede crear una red: puerta de enlace no encontrada.",
    "NETWORK_ATTACH_FAIL_SERVER_FULL": "No se puede conectar un servidor: se utilizan todos los puertos del servidor.",
    "NETWORK_ATTACH_FAIL_MAX_SERVER_EXCEEDED": "No se puede conectar un servidor: se utilizan todos los puertos de la red.",
    "NETWORK_ATTACH_FAIL_NETWORK_DOWN": "No se puede conectar un servidor: red inactiva.",
    "NETWORK_ATTACH_FAIL_SERVER_DISABLED": "No se puede conectar un servidor: el servidor está deshabilitado, póngase en contacto con la asistencia.",
    "NETWORK_ATTACH_FAIL_MANAGED_SERVICE_CONFLICT": "No se puede conectar un servidor: conflicto con el servicio gestionado",
    "NETWORK_ATTACH_FAIL_MULTIPLE_MANAGED_NETWORKS": "No se puede conectar un servidor: un servidor administrado solo se puede conectar a una única red administrada.",
    "NETWORK_ATTACH_FAIL_ALREADY_ATTACHED_TO_THIS_SERVER": "El servidor ya está conectado a esta red",
    "NETWORK_DETACH_FAIL_SERVER_DISABLED": "No se puede desconectar un servidor: el servidor está deshabilitado, póngase en contacto con la asistencia.",
    "NETWORK_DETACH_FAIL_SERVER_NOT_ATTACHED": "No se puede desconectar un servidor: el servidor no está conectado a la red.",
    "NETWORK_DETACH_FAIL_MANAGED_SERVER": "No se puede desconectar un servidor: los servidores administrados no se pueden desconectar manualmente de la red.",
    "NETWORK_DETACH_FAIL_NETWORK_DOWN": "No se puede desconectar el servidor: red inactiva.",
    "NETWORK_UPDATE_FAIL_INVALID_NAME": "No se puede actualizar la red: formato de nombre no válido.",
    "NETWORK_SET_GATEWAY_FAIL_NOT_MANAGED": "No se puede configurar la puerta de enlace: la red no esta gestionada.",
    "NETWORK_SET_GATEWAY_FAIL_IP_NOT_FOUND": "No se puede configurar la puerta de enlace: dirección IP no encontrada.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_MANAGED": "No se puede eliminar la puerta de enlace: la red no esta gestionada.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_FOUND": "No se puede eliminar la puerta de enlace: puerta de enlace no encontrada.",
    "NETWORK_ADD_FW_RULE_FAIL_NOT_MANAGED": "No se puede agregar la regla de firewall: la red no está gestionada.",
    "NETWORK_ADD_FW_RULE_FAIL_INTERFACE_NOT_FOUND": "No se puede agregar la regla de firewall: interfaz no encontrada.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_SOURCE": "No se puede agregar la regla de firewall: fuente inválida.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PORT": "No se puede agregar la regla de firewall: puerto inválido.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PROTOCOL": "No se puede agregar la regla de firewall: protocolo inválido.",
    "NETWORK_REMOVE_FW_RULE_FAIL_NOT_FOUND": "No se puede eliminar la regla de firewall: regla no encontrada.",
    "NETWORK_ADD_FW_RULE_SUCCESS": "La regla de firewall se agregó correctamente.",
    "NETWORK_REMOVE_FW_RULE_SUCCESS": "Regla de firewall eliminada con éxito.",
    "NETWORK_SET_GATEWAY_SUCCESS": "Puerta de enlace establecida correctamente.",
    "NETWORK_DELETE_GATEWAY_SUCCESS": "Puerta de enlace eliminada correctamente",
    "NETWORK_ATTACH_SERVER_SUCCESS": "Servidor conectado con éxito.",
    "NETWORK_DETACH_SERVER_SUCCESS": "Servidor desconectado con éxito.",
    "NETWORK_DELETION_SUCCESS": "Supresión de red pendiente.",
    "NETWORK_UPDATE_SUCCESS": "Red actualizada con éxito.",

    // Metric
    "METRIC_EMPTY_NET_IN": "Los datos de red entrantes no están disponibles.",
    "METRIC_EMPTY_NET_OUT": "Los datos de red salientes no están disponibles.",
    "METRIC_UNAVAILABLE": "Datos no disponibles.",

    // Ticket
    "TICKET_NOT_FOUND": "Ticket no encontrado.",
    "TICKET_CREATE_FAIL": "No se puede crear un nuevo ticket: falta el servidor UUID.",
    "TICKET_CREATE_FAIL_INVALID_OBJECT": "No se puede crear un nuevo ticket: objeto inválido.",
    "TICKET_CREATE_FAIL_INVALID_CATEGORY": "No se puede crear un nuevo ticket: categoría inválida.",
    "TICKET_CREATE_FAIL_INVALID_MESSAGE": "No se puede crear un nuevo ticket: mensaje inválido.",
    "TICKET_CREATE_FAIL_INVALID_LEVEL": "No se puede crear un nuevo ticket: nivel inválido.",
    "TICKET_CREATE_FAIL_UNKNOWN_SERVER": "No se puede crear un nuevo ticket: servidor no encontrado.",
    "TICKET_ADD_MESSAGE_INVALID_TEXT": "No se puede añadir un mensaje en el ticket: formato del mensaje inválido.",
    "TICKET_ADD_MESSAGE_FAIL_MISSING_TICKET_UUID": "No se puede añadir un mensaje en el ticket: falta la UUID del ticket.",
    "TICKET_ADD_MESSAGE_FAIL_TICKET_LOCKED": "No se puede añadir un mensaje en el ticket: ticket bloqueado por otra persona.",
    "TICKET_DELETE_FAIL_MISSING_UUID": "No se puede suprimir el ticket: falta UUID.",
    "TICKET_GET_FAIL_MISSING_TICKET_UUID": "No se puede recuperar el ticket: falta UUID.",
    "TICKET_LOCK_FAIL_ALREADY_LOCKED": "Ticket ya bloqueado.",
    "TICKET_UNLOCK_FAIL_ALREADY_UNLOCKED": "El ticket no esta bloqueado",
    "TICKET_UNLOCK_FAIL_LOCKED_BY_OTHER_USER": "No se puede desbloquear el ticket: ticket bloqueado por otra persona.",
    "TICKET_LOCK_SUCCESS": "Ticket bloqueado con éxito.",
    "TICKET_UNLOCK_SUCCESS": "Ticket desbloqueado con éxito.",
    "TICKET_CREATE_SUCCESS": "Ticket creado con éxito.",
    "TICKET_CLOSE_SUCCESS": "El ticket ha sido cerrado con éxito.",
    "TICKET_DELETE_SUCCESS": "Ticket suprimido con éxito.",
    "TICKET_ADD_MESSAGE_SUCCESS": "El mensaje ha sido enviado con éxito.",
    "TICKET_DELETE_MESSAGE_SUCCESS": "Mensaje del ticket suprimido con éxito.",
    "TICKET_UPDATE_LEVEL_SUCCESS": "El nivel del ticket se actualizó correctamente.",
    "TICKET_UPDATE_LEVEL_FAIL_CLOSED": "No se puede actualizar el nivel del ticket: el ticket está cerrado.",
    "TICKET_INVALID_MESSAGE": "La longitud del mensaje del ticket debe tener entre 3 y 5,000 caracteres.",
    "ERR_TICKET_ADMIN_CANNOT_CREATE_FOR_HIMSELF": "No puedes crear un ticket para ti mismo.",
    "TICKET_CLOSED": "el ticket está cerrado",

    // Contact
    "CONTACT_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha inválida.",
    "CONTACT_REQUEST_FAIL_INVALID_EMAIL": "Formato del correo electrónico inválido.",
    "CONTACT_REQUEST_SUCCESS": "Correo electrónico enviado con éxito.",

    // Job
    "JOB_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha inválida.",
    "JOB_REQUEST_FAIL_INVALID_EMAIL": "Formato del correo electrónico inválido.",
    "JOB_REQUEST_SUCCESS": "Correo electrónico enviado con éxito",

    // User
    "USER_2FA_ENABLE_FAIL_WRONG_CODE": "Código de validación incorrecto",
    "USER_UPDATE_INVALID_FIRSTNAME": "No se ha podido actualizar el usuario: nombre no válido",
    "USER_LIST_FAIL": "No se puede recuperar la lista de usuarios.",
    "USER_NOT_FOUND": "Usuario no encontrado.",
    "USER_DELETE_FAIL_NOT_FOUND": "No se puede suprimir el usuario: usuario no encontrado.",
    "USER_DELETE_SUCCESS": "Usuario suprimido con éxito.",
    "USER_RESET_PASSWORD_FAIL_INVALID_TOKEN": "No se puede reiniciar la contraseña del usuario: ficha inválida.",
    "USER_RESET_PASSWORD_FAIL_INVALID_PASSWORD": "No se puede reiniciar la contraseña del usuario: formato inválido.",
    "USER_RESET_PASSWORD_SUCCESS": "Contraseña reiniciada con éxito.",
    "USER_RESET_PASSWORD_ASK_SUCCESS": "Si tiene una cuenta existente, se enviará a su correo electrónico un mensaje con instrucciones para restablecer su contraseña.",
    "USER_UPDATE_INVALID_LASTNAME": "Apellido inválido.",
    "USER_UPDATE_INVALID_PHONE_NUMBER": "Número de teléfono inválido.",
    "USER_UPDATE_INVALID_MOBILE_NUMBER": "Número de móvil inválido.",
    "USER_UPDATE_SUCCESS": "Usuario actualizado con éxito",
    // API KEY
    "USER_ADD_API_KEY_SUCCESS": "Clave creada con éxito.",
    // SSH key
    "USER_DELETE_SSH_KEY_SUCCESS": "Clave SSH suprimida con éxito.",
    "USER_DELETE_SSH_KEY_FAIL_MISSING_NAME": "Falta el nombre de la clave SSH.",
    "USER_DELETE_SSH_KEY_FAIL_NOT_FOUND": "Clave SSH no encontrada.",
    "USER_ADD_SSH_KEY_FAIL_EMPTY": "La clave SSH esta vacía.",
    "USER_ADD_SSH_KEY_FAIL_KEY_INVALID": "La clave SSH no es válida.",
    "USER_ADD_SSH_KEY_SUCCESS": "Clave SSH añadida con éxito.",
    "USER_ADD_SSH_KEY_FAIL_ALREADY_NAME_EXIST": "El nombre de la clave ya existe.",
    // Password
    "USER_UPDATE_PASSWORD_FAIL_WRONG_CURRENT_PASSWORD": "La contraseña actual esta incorrecta.",
    "USER_UPDATE_PASSWORD_FAIL_NEW_PASSWORD_INVALID": "La nueva contraseña no es válida.",
    "USER_UPDATE_PASSWORD_SUCCESS": "Contreseña actualizada con éxito.",
    // Registration
    "USER_CREATE_FAIL_DEFAULT_PREFERENCES": "Error al crear las preferencias predeterminadas del usuario.",
    "USER_CREATE_FAIL_COMPANY": "Error al crear la empresa del usuario.",
    "USER_UPDATE_FAIL_COMPANIES": "Error al asociar al usuario con las empresas.",
    "VALIDATE_REGISTRATION_FIRST": "Por favor, valide su registro primero.",
    "VALIDATE_USER_SUCCESS": "Su registro ha sido validado.",

    // Company
    "COMPANY_DISABLED": "Su negocio está desactivado, comuníquese con el servicio de asistencia para obtener más información.",
    // Create
    "COMPANY_CREATE_FAIL_USER_ASSIGNMENT": "No se puede asignar al usuario a la nueva empresa creada.",
    "COMPANY_CREATE_FAIL_ALREADY_EXISTS": "Creación fallida, la empresa ya existe",
    // Delete
    "COMPANY_DELETE_FAIL": "No se puede eliminar la empresa de todos sus usuarios.",
    "COMPANY_DELETE_SUCCESS": "Empresa borrada con éxito.",
    // (Re)Validation
    "VALIDATE_USER_FAIL_CREATE_CONTACT_ERROR": "No se puede crear un nuevo contacto.",
    "VALIDATE_USER_FAIL_UPDATE_COMPANY_ERROR": "No se puede asignar una identificación a la empresa.",
    "REVALIDATE_USER_FAIL_ALREADY_VALIDATED": "El usuario ya está validado.",
    "REVALIDATE_USER_FAIL_ASSIGN_NEW_COMPANY": "No se puede asignar la nueva empresa al nuevo usuario.",
    "REVALIDATE_USER_SUCCESS": "Correo electrónico de validación enviado con éxito.",
    // Update
    "INVALID_EMAIL": "La dirección de correo electrónico no es válida",
    "COMPANY_CREATE_SUCCESS": "Empresa creada exitosamente.",
    "COMPANY_UPDATE_SUCCESS": "Empresa actualizada con éxito.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_TYPE": "No se puede actualizar la dirección de la empresa: tipo de dirección incorrecta.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_NAME": "No se puede actualizar la empresa: nombre de dirección inválido.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET": "No se puede actualizar la empresa: dirección inválida.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET_2": "No se puede actualizar la empresa: calle inválida.",
    "COMPANY_UPDATE_FAIL_INVALID_POSTAL_CODE": "No se puede actualizar la empresa: código potal inválido.",
    "COMPANY_UPDATE_FAIL_INVALID_CITY": "No se puede actualizar la empresa: ciudad inválida.",
    "COMPANY_UPDATE_FAIL_INVALID_COUNTRY": "No se puede actualizar la empresa: país inválido.",
    "COMPANY_UPDATE_FAIL_INVALID_PHONE": "No se puede actualizar la empresa: número de teléfono no válido.",
    // Register user with company
    "REGISTER_COMPANY_FAIL_RECAPTCHA_ERROR": "Recaptcha inválida.",
    "REGISTER_COMPANY_FAIL_COMPANY_NAME_ALREADY_USED": "No se puede registrar un nuevo usuario: el nombre de la empresa ya está utilizado.",
    "REGISTER_COMPANY_FAIL_USER_EMAIL_ALREADY_USED": "No se puede registrar un nuevo usuario: el correo electrónico del usuario ya está utilizado",
    "REGISTER_COMPANY_FAIL_COMPANY_EMAIL_ALREADY_USED": "No se puede registrar un nuevo usuario: el correo electrónico de la empresa ya está utilizado.",
    "REGISTER_COMPANY_FAIL_ASSIGN_USER_ERROR": "No se puede asignar la nueva empresa al nuevo usuario.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_EMAIL": "No se puede registrar un nuevo usuario: correo electrónico de usuario no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_EMAIL": "No se puede registrar un nuevo usuario: correo electrónico de empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_NAME": "No se puede registrar un nuevo usuario: nombre de empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_CIVILITY": "No se puede registrar un nuevo usuario: civilidad de usuario no válida.",
    "REGISTER_COMPANY_FAIL_INVALID_COUNTRY": "No se puede registrar un nuevo usuario: país de la empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET": "No se puede registrar un nuevo usuario: calle de empresa no válida.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET_2": "No se puede registrar un nuevo usuario: calle 2 de empresa no válida.",
    "REGISTER_COMPANY_FAIL_INVALID_POSTAL_CODE": "No se puede registrar un nuevo usuario: código postal de la empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_CITY": "No se puede registrar un nuevo usuario: valor de ciudad de empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_PREFERRED_LANGUAGE": "No se puede registrar un nuevo usuario: idioma preferido no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_FIRST_NAME": "No se puede registrar un nuevo usuario: nombre no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_LAST_NAME": "No se puede registrar un nuevo usuario: apedillo no válido",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PASSWORD": "No se puede registrar un nuevo usuario: contraseña no válida.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PHONE": "No se puede registrar un nuevo usuario: número de teléfono de usuario no válido",
    "REGISTER_COMPANY_FAIL_INVALID_USER_MOBILE": "No se puede registrar un nuevo usuario: número de móvil de usuario no válido",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_PHONE": "No se puede registrar un nuevo usuario: número de teléfono de empresa no válido.",
    "REGISTER_COMPANY_FAIL_INVALID_AFFILIATE_CODE": "No se puede registrar un nuevo usuario: código de afiliado no válido.",
    "REGISTER_COMPANY_SUCCESS": "Nuevo compañia registrado con éxito.",
    "REGISTER_COMPANY_FAIL_INVALID_VAT_NUMBER": "Número de IVA no válido.",
    "INVALID_VAT_NUMBER": "Número de IVA no válido.",
    "REGISTER_COMPANY_FAIL_CHECK_VAT_NUMBER": "Error al verificar.",
    "VAT_NUMBER_EMPTY": "Por favor, ingrese el número de IVA",
    // Register user to company
    "REGISTER_USER_FAIL_RECAPTCHA_ERROR": "Recaptcha inválida.",
    "REGISTER_USER_FAIL_INVALID_FIRSTNAME": "No se puede registrar el usuario en la empresa: nombre no válido.",
    "REGISTER_USER_FAIL_INVALID_LASTNAME": "No se puede registrar el usuario en la empresa: apedillo no válido.",
    "REGISTER_USER_FAIL_INVALID_PASSWORD": "No se puede registrar el usuario en la empresa: contraseña no válida.",
    "REGISTER_USER_FAIL_INVALID_PHONE": "No se puede registrar el usuario en la empresa: número de teléfono no válido..",
    "REGISTER_USER_FAIL_INVALID_MOBILE": "No se puede registrar el usuario en la empresa: número de teléfono movíl no válido.",
    "REGISTER_USER_FAIL_INVALID_CIVILITY": "No se puede registrar el usuario en la empresa: civilidad no válida.",
    "REGISTER_USER_FAIL_INVALID_PREFERRED_LANGUAGE": "No se puede registrar el usuario en la empresa: idioma preferido no válido.",
    "REGISTER_USER_FAIL_INVALID_EMAIL": "No se puede registrar el usuario en la empresa: correo electrónico no válido.",
    "REGISTER_USER_FAIL_EMAIL_ALREADY_EXIST": "No se puede registrar el usuario en la empresa: correo electrónico ya existe.",
    "REGISTER_USER_FAIL_CGV_NOT_FOUND": "No se puede registrar el usuario en la empresa: CGV no encontrado.",
    "REGISTER_USER_FAIL_COMPANY_NOT_FOUND_OR_INVALID_TOKEN": "No se puede encontrar la empresa o el token de invitación no es válido.",
    "REGISTER_USER_FAIL_EMAIL_REJECTED": "No se puede registrar el usuario en la empresa: correo electrónico rechazado.",
    "REGISTER_USER_FAIL_CLEAR_INVITATION": "No se puede borrar la invitación.",
    "REGISTER_USER_FAIL_INVALID_OR_EXPIRED_LINK": "Enlace de registro no válido o caducado.",
    "REGISTER_USER_SUCCESS": "Nuevo usuario registrado con éxito.",
    // Invite user to company
    "INVITE_USER_FAIL_INVALID_EMAIL": "No se puede invitar al usuario: formato de correo electrónico no válido.",
    "INVITE_USER_FAIL_BAD_PERMISSION": "No tiene permiso para invitar a un usuario.",
    "INVITE_USER_FAIL_ALREADY_INVITED": "El usuario ya tiene un rol en la empresa.",
    "INVITE_USER_FAIL_ALREADY_ADMIN_OR_ACCOUNTANT": "El usuario ya es administrador o contador de otra empresa.",
    "INVITE_USER_FAIL_INVALID_POSITION": "Incapaz de invitar al usuario: posición inválida..",
    // Join
    "JOIN_COMPANY_FAIL_INVALID_COMPANY_OR_TOKEN": "No se puede encontrar la empresa o el token de invitación no es válido.",
    "JOIN_COMPANY_FAIL_EMAIL_REJECTED": "Su dirección de correo electrónico fue rechazado.",
    "JOIN_COMPANY_FAIL_UNABLE_TO_UPDATE_USER": "Incapaz de asignar al usuario a la empresa.",
    "JOIN_COMPANY_FAIL_INVITATION_NOT_FOUND": "No se puede encontrar la invitación.",
    // Revoke
    "REVOKE_USER_FAIL_BAD_PERMISSION": "Solo el administrador puede revocar un usuario.",
    "REVOKE_USER_FAIL_SELF_REVOCATION": "No se puede revocarse si mismo de una empresa.",
    "REVOKE_USER_FAIL_EMAIL_NOT_FOUND": "No se puede encontrar el correo electrónico del usuario para revocarle",
    "REVOKE_USER_FAIL_IS_ADMIN": "No se puede revocar al creador de la empresa.",
    "REVOKE_USER_FAIL_WRONG_ROLE": "No se puede encontrar el usuario solicitado con función.",
    "REVOKE_USER_FAIL_ACCOUNTING_ERROR": "No se puede eliminar la información de la cuenta del usuario.",
    "REVOKE_USER_SUCCESS": "Usuario revocado con éxito.",

    // Role
    "ROLE_NOT_FOUND": "Función no encontrada.",
    "ROLE_CREATE_FAIL_BAD_PERMISSION": "Solo los administradores de la empresa pueden crear nuevas funciones.",
    "ROLE_DELETE_FAIL_BAD_PERMISSION": "Solo los administradores de la empresa pueden suprimir nuevas funciones.",
    "ROLE_GET_FAIL_BAD_PERMISSION": "Solo los administradores de la empresa pueden obtener la lista de funciones",
    "ROLE_UPDATE_FAIL_BAD_PERMISSION": "Solo los administradores de la empresa pueden actualizar la lista de funciones",
    "ROLE_DELETE_FAIL_NOT_FOUND": "No se puede eliminar la función: función no encontrada en la empresa.",
    "ROLE_UPDATE_FAIL_NOT_FOUND": "No se puede actualizar la función: función no encontrada en la empresa",
    "ROLE_UPDATE_FAIL_ALREADY_EXIST": "No se puede actualizar la función: la función ya existe.",
    "ROLE_CREATE_FAIL_ALREADY_EXIST": "No se puede crear una función: la función ya existe.",
    "ROLE_DELETE_FAIL_IN_USE": "No se puede eliminar el rol: el rol está asignado a uno o más usuarios.",
    "ROLE_CREATE_SUCCESS": "Función creada con éxito.",
    "ROLE_UPDATE_SUCCESS": "Función actualizada con éxito.",
    "ROLE_DELETE_SUCCESS": "Función suprimida con éxito.",

    // CGV
    "CGV_NOT_FOUND": "No se puede encontrar la última CGV.",
    "CGV_VALIDATE_FAIL_UPDATE_ERROR": "No se puede actualizar al usuario con la última firma de CGV.",
    "CGV_VALIDATE_FAIL_INVALID_VERSION": "No firmó la última versión de CGV.",
    "CGV_VALIDATE_FAIL": "Tenga en cuenta que debe estar de acuerdo con CGV para utilizar nuestros servicios.",
    "CGV_VALIDATE_SUCCESS_ALREADY_SIGNED": "Ya ha firmado el último CGV.",
    "CGV_VALIDATE_SUCCESS": "Gracias por firmar nuestro CGV.",

    // Token
    "TOKEN_NOT_FOUND": "Token no encontrado.",
    "TOKEN_CREATE_FAIL_UPDATE_USER": "No se puede crear el token para un usuario actual.",
    "TOKEN_CREATE_FAIL_INVALID_DATE": "No se puede crear el token: fecha de expiración inválida.",
    "TOKEN_CREATE_FAIL_NAME_ALREADY_USED": "No se puede crear el token: el nombre ya existe.",
    "TOKEN_GET_FAIL_EMPTY_NAME": "El nombre del token no es válido.",
    "TOKEN_REVOKE_SUCCESS": "Token revocado con éxito.",

    // Plan
    "PLAN_LIST_FAIL": "No se puede recuperar la lista de suscripciones.",

    // IPs
    "IP_NOT_FOUND": "IP no encontrada.",
    "IP_BLACKLISTED": "IP en lista negra.",
    "IP_DELETE_FAIL_ALREADY_ON_TERMINATION": "La eliminación de esta dirección IP ya está programada.",
    "IP_DELETE_FAIL_ACTION_NOT_SCHEDULED": "La eliminación de esta dirección IP no está programada.",
    "IP_DELETE_FAIL_IPV4_DELETION_SCHEDULED": "La eliminación de la dirección IPv4 correspondiente está programada.",
    "IP_V6_UNAVAILABLE": "IPV6 no disponible.",
    "IP_V4_V6_COUPLE_NOT_FOUND": "IP_V4_V6_COUPLE_NOT_FOUND",
    // Attach/detach
    "DETACH_IP_SUCCESS": "IP desconectada con éxito.",
    "ATTACH_IP_SUCCESS": "IP conectada correctamente",
    "ATTACH_IP_FAIL_NO_MORE_AVAILABLE_IP": "No se puede conectar IP: no hay más direcciones IP disponibles.",
    "ATTACH_IP_FAIL_IP_IS_NOT_IN_YOUR_COMPANY_POOL": "No se puede conectar IP: la dirección IP no está en el grupo de la empresa.",
    "ATTACH_IP_FAIL_SERVER_MANAGED": "No se puede conectar IP: la conexión directa de IP no es compatible con los servidores administrados, utilice las reglas PNAT en su lugar.",
    "DETACH_IP_FAIL_BAD_SERVER_IP": "No se puede desconectar IP: la dirección IP no está conectada al servidor.",
    "DETACH_IP_FAIL_IP_NOT_FOUND": "No se puede desconectar IP: no se encontró la dirección IP.",
    "DETACH_IP_FAIL_SERVER_MANAGED": "No se puede desconectar IP: la separación de IP no es compatible con servidores administrados.",
    "DETACH_IP_FAIL_PRIMARY_ADDRESS": "No se puede desconectar la IP: Dirección IP principal.",
    // PNAT rules add/del
    "ADD_IP_PNAT_RULE_SUCCESS": "Regla PNAT ingresada con éxito.",
    "DEL_IP_PNAT_RULE_SUCCESS": "Regla PNAT eliminada correctamente.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "No se puede ingresar la regla PNAT: puerto de origen no válido.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "No se puede ingresar la regla PNAT: puerto de destino no válido.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "No se puede ingresar la regla PNAT: protocolo inválido.",
    "ADD_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "No se puede ingresar la regla PNAT: el servidor no está gestionado.",
    "ADD_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "No se puede ingresar la regla PNAT: dirección IP no encontrada.",
    "ADD_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "No se puede ingresar la regla PNAT: interfaz privada no encontrada.",
    "ADD_IP_PNAT_RULE_FAIL_TRANSPARENT_CONFLICT_PNAT": "No se puede ingresar la regla PNAT: primero debe eliminar todas las reglas PNAT existentes en esta IP antes de habilitar el modo transparente.",
    "ADD_IP_PNAT_RULE_FAIL_PNAT_CONFLICT_TRANSPARENT": "No se puede agregar la regla PNAT: primero debe deshabilitar la redirección transparente en esta IP antes de ingresar reglas PNAT.",
    "ADD_IP_PNAT_RULE_FAIL_SRC_PORT_CONFLICT": "No se puede agregar la regla PNAT: una regla PNAT del puerto de origen de esta IP ya está definida.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "No se peudo borrar la regla PNAT: puerto de origen no válido.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "No se peudo borrar la regla PNAT: puerto de destino no válido.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "No se peudo borrar la regla PNAT: protocolo inválido.",
    "DEL_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "No se peudo borrar la regla PNAT: el servidor no está gestionado.",
    "DEL_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "No se peudo borrar la regla PNAT: dirección IP no encontrada.",
    "DEL_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "No se peudo borrar la regla PNAT: interfaz privada no encontrada.",
    "DEL_IP_PNAT_RULE_FAIL_NOT_FOUND": "No se peudo borrar la regla PNAT: regla PNAT no encontrada.",

    // Demo
    "DEMO_SEND_CREATE_SERVER_SUCCESS": "Solicitud del servidor de demostración enviada correctamente.",

    // Managed services
    "MANAGED_SERVICES_ENABLED": "Los servicios administrados ya están habilitados.",
    "MANAGED_SERVICES_SUCCESS": "Solicitud de activación de servicios gestionados enviada correctamente.",

    // Promotional code
    "PROMOTION_CODE_NOT_FOUND": "Código promocional no válido.",
    "PROMOTION_CODE_ALREADY_EXISTS": "El código promocional ya existe.",
    "PROMOTION_CODE_INVALID": "El código de promoción no es válido, la longitud debe tener entre 4 y 60 caracteres.",

    // Items
    "ITEM_INVALID_QUANTITY": "Cantidad de artículo no válida.",

    // Generate affiliation Links
    "GENERATE_AFF_LINKS_SUCCESS": "El enlace de afiliación se ha generado con éxito. Está registrado en sus medios de comunicación impresos.",
    "UPDATE_AFF_LINKS_SUCCESS": "El enlace de afiliación ha sido actualizado con éxito.",

    // Email validation
    "VALIDATE_EMAIL": "Se le ha enviado un correo electrónico para activar su cuenta (verifique también sus correos electrónicos no deseados).",
    "VALIDATE_EMAIL_SUCCESS": "Su correo electrónico ha sido validado con éxito.",
    "VALIDATE_EMAIL_SUCCESS_ALREADY_VALIDATED": "Tu correo electrónico ya ha sido validado.",
    "VALIDATE_EMAIL_INVITE_SUCCESS": "Has aceptado la invitación.",
    "VALIDATE_EMAIL_FAIL": "No se puede validar la cuenta de usuario, comuníquese con el soporte.",
    "VALIDATE_EMAIL_FAIL_TOKEN_INVALID": "Enlace no válido, vuelva a enviar una solicitud de validación para su correo electrónico.",
    
    // Parameters
    "PARAM_SINGLE_NOT_FOUND": "No se encontró el parámetro de tipo SINGLE.",
    "PARAM_ALREADY_EXISTS": "El parámetro ya existe",
    
    // Transfer
    "TRANSFER_SUCCESS": "Transferencia exitosa",
    "ERR_TRANSFER_NO_TYPE": "Tipo de transferencia no especificado",
    "ERR_TRANSFER_SAME_COMPANY": "No es posible la transferencia a la misma empresa.",
    "ERR_TRANSFER_DEMO_MODE_SERVER": "Transferencia imposible, servidor en modo DEMO.",
    "ERR_TRANSFER_GETTING_SUBSCRIPTIONS": "Error al obtener las suscripciones",
    "ERR_TRANSFER_STATE_INVALID": "Transferencia imposible, servidor eliminado o estado inválido",
    // Stripe
    "stripe": {
      "account_closed": "Cuenta bancaria cerrada",
      "account_country_invalid_address": "País de la cuenta bancaria inválido",
      "account_error_country_change_requires_additional_steps": "Cambiar de país requiere pasos adicionales",
      "account_information_mismatch": "Alguna información de la cuenta no coincide",
      "account_invalid": "El ID de cuenta proporcionado como valor para el encabezado Stripe-Account no es válido",
      "account_number_invalid": "El número de cuenta bancaria proporcionado no es válido",
      "acss_debit_session_incomplete": "La sesión de débito ACSS aún no está lista",
      "alipay_upgrade_required": "Este método de creación de pagos Alipay ya no está soportado",
      "amount_too_large": "El monto especificado es mayor que el máximo permitido",
      "amount_too_small": "El monto especificado es menor que el mínimo permitido",
      "api_key_expired": "La clave API proporcionada ha expirado",
      "application_fees_not_allowed": "La aplicación de comisiones no está permitida",
      "authentication_required": "Autenticación requerida",
      "balance_insufficient": "Saldo insuficiente",
      "balance_invalid_parameter": "Parámetro proporcionado inválido para el método de saldo",
      "bank_account_bad_routing_numbers": "La cuenta bancaria no soporta esta divisa",
      "bank_account_declined": "Pago no autorizado en esta cuenta bancaria",
      "bank_account_exists": "La cuenta bancaria ya existe",
      "bank_account_restricted": "La cuenta bancaria no acepta este método de pago",
      "bank_account_unusable": "Cuenta bancaria inutilizable",
      "bank_account_unverified": "Cuenta bancaria no verificada",
      "bank_account_verification_failed": "La verificación de la cuenta bancaria ha fallado",
      "billing_invalid_mandate": "Mandato de pago inválido",
      "bitcoin_upgrade_required": "El pago con Bitcoin ya no se soporta",
      "capture_charge_authorization_expired": "Los fondos no pueden retirarse porque la autorización ha expirado",
      "capture_unauthorized_payment": "No está autorizado para retirar los fondos",
      "card_decline_rate_limit_exceeded": "Se ha superado el límite de tasa de rechazo de la tarjeta",
      "card_error": "Método de pago no válido",
      "card_declined": "Tarjeta rechazada",
      "cardholder_phone_number_required": "Número de teléfono del titular de la tarjeta requerido para la creación de tarjetas europeas",
      "charge_already_captured": "Fondos ya retirados",
      "charge_already_refunded": "Fondos ya reembolsados",
      "charge_disputed": "Fondos ya disputados",
      "charge_exceeds_source_limit": "El cargo excede el límite de la fuente",
      "charge_expired_for_capture": "El cargo ha expirado para la captura",
      "charge_invalid_parameter": "Parámetro inválido para retirar los fondos",
      "charge_not_refundable": "Fondos no reembolsables",
      "clearing_code_unsupported": "El código de compensación proporcionado no está soportado",
      "country_code_invalid": "Código de país inválido",
      "country_unsupported": "País no soportado",
      "coupon_expired": "Cupón expirado",
      "customer_max_payment_methods": "Se ha alcanzado el número máximo de métodos de pago",
      "customer_max_subscriptions": "Se ha alcanzado el número máximo de suscripciones",
      "debit_not_authorized": "Pago no autorizado",
      "email_invalid": "Dirección de correo electrónico inválida",
      "expired_card": "Tarjeta expirada",
      "insufficient_funds": "Fondos insuficientes",
      "intent_invalid_state": "Intención de pago en un estado inválido",
      "invalid_card_type": "Tipo de tarjeta no soportado",
      "invalid_charge_amount": "Monto de cargo inválido",
      "payment_intent_mandate_invalid": "Mandato de pago inválido",
      "payment_method_bank_account_blocked": "Cuenta bancaria bloqueada",
      "payouts_limit_exceeded": "Se ha superado el límite de pagos",
      "payouts_not_allowed": "Pago no permitido",
    },
  },
  "tools": {
    "coming_soon": "Característica por venir.",
    "bad_level": "Nivel de servicio insuficiente",
  },
}
